import "./index.scss";
import {
  Avatar,
  Divider,
  Input,
  Tooltip,
  Typography,
  Popover,
  Tag,
} from "antd";
import {
  StarOutlined,
  StarFilled,
  TeamOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import StatusTag from "../StatusTag";
import React, { useEffect, useRef } from "react";
import { useHover, useUnmountedRef } from "ahooks";
import { useState } from "react";
import projectApi from "../../apis/project";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { useSelector } from "react-redux";
import StatusText from "../StatusText";
import noImg from "../../assets/noImg.jpeg";
import { getLicenseValid } from "../../unit/unit";
const { Paragraph } = Typography;

/* 
    props: 参数
    hasBorder: boolean 默认为 true
    width: string 宽 默认为50%
    pic: string 头像 图片
    title: string 标题 
    showCollection: boolean 是否显示收藏图标 默认为true
    isCollection: boolean 是否收藏
    collectHandle: function 点击收藏的函数 第一个参数:是否收藏 第二个参数:详细内容对象
    cardData: object 详细内容对象
    columns: array[object] 展示的列表信息
    btnList: array[ReactNode]
    imgClick: function 图片、标题点击回调
    marginBottom: string margin-bottom属性 默认为20px
    titleIcon: 标题是否显示图标
    getProjectInfo: 修改名称后回调
 */

const ProgressPopoverContent = (props) => {
  const { progressInfo } = props;
  return (
    <div className="progressPopoverContent">
      {(progressInfo || [])?.map((item) => (
        <div
          className={`itemContent itemContent_${item.title}`}
          key={item.title}
        >
          <div className="itemTitleBox">{item.title}</div>
          {(item?.value || [])?.map((_) => (
            <div className="itemBox" key={_.title}>
              <div className="itemProgressName">{_.title}</div>
              <div
                className="itemProgress"
                style={{
                  color:
                    _.status === "已完成"
                      ? "#AAD461"
                      : _.status === "进行中"
                      ? "#FEC054"
                      : "#787878",
                }}
              >
                {_.status}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
const EditTitle = (props) => {
  const { cardData, editUser, getProjectInfo, projectInfo, userInfo } = props;
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const { id } = useParams();

  const successEdit = () => {
    if (!editValue) {
      message.warning("项目名称不能为空");
      return;
    }
    projectApi.changeProjectName(id, editValue).then((res) => {
      if (res.code === 200) {
        setIsEdit(false);
        setEditValue(null);
        getProjectInfo && getProjectInfo();
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    });
    // window.location.reload()
  };

  return (
    <div
      className="editTitle"
      style={{
        width: localStorage.getItem("show_project_type_tag")
          ? "calc(100% - 70px)"
          : "100%",
      }}
      ref={ref}
    >
      {isEdit ? (
        <>
          <Input
            value={editValue}
            maxLength={100}
            style={{ width: "300px", height: "25px" }}
            onChange={(e) => {
              setEditValue(e.target.value);
            }}
          ></Input>
          <div className="iconBox">
            <Tooltip placement="top" title="保存" arrowPointAtCenter>
              <CheckOutlined
                onClick={() => {
                  successEdit();
                }}
                style={{ color: "#0068B2" }}
              />
            </Tooltip>
            <Tooltip placement="top" title="取消" arrowPointAtCenter>
              <CloseOutlined
                style={{ color: "red" }}
                onClick={() => {
                  setIsEdit(false);
                  setEditValue(null);
                }}
              />
            </Tooltip>
          </div>
        </>
      ) : (
        <Paragraph
          ellipsis={{
            tooltip: {
              title: (
                <div className="projectNameParagraphTip">
                  {cardData.project_name}
                </div>
              ),
              overlayClassName: "projectNameParagraphTooltip",
            },
            rows: 1,
            expandable: false,
          }}
          style={{
            maxWidth: `calc(100% - ${isHovering && !isEdit ? "55px" : "0px"})`,
            marginBottom: "0",
          }}
        >
          {cardData.project_name}
        </Paragraph>

        // <div style={{ maxWidth: "calc(100% - 55px)" }}>
        //   {cardData.project_name}
        // </div>
      )}

      {isHovering && !isEdit ? (
        <div className="iconBox">
          {projectInfo?.project_permission_urls.includes("CDD_PRJ_MBR_MGT") &&
            userInfo?.co_type_code !== "INDIV_ORG" && (
              <Tooltip placement="top" title="成员管理" arrowPointAtCenter>
                <TeamOutlined
                  onClick={() => {
                    getLicenseValid().then((res) => {
                      if (res) {
                        editUser && editUser();
                      }
                    });
                  }}
                />
              </Tooltip>
            )}
          {projectInfo?.project_permission_urls.includes("CDD_PRJ_MBR_MGT") && (
            <Tooltip placement="top" title="修改项目名称" arrowPointAtCenter>
              <EditOutlined
                onClick={() => {
                  setIsEdit(true);
                  setEditValue(cardData.project_name);
                }}
              />
            </Tooltip>
          )}
        </div>
      ) : null}
    </div>
  );
};

const MyCard = (props) => {
  const {
    hasBorder = true,
    width,
    pic,
    title,
    isCollection,
    collectHandle,
    cardData,
    showCollection = true,
    columns,
    btnList,
    imgClick,
    marginBottom,
    titleIcon,
    editUser,
    getProjectInfo,
    progress,
    projectInfo,
    projectStatus,
    wholeLine,
    rightTopIcon,
    unClick = false,
  } = props;

  // const [progressList,setProgressList] = useState([])
  // const [progressCode,setProgressCode] = useState(null)
  const [showProgress, setShowProgress] = useState(progress);

  const progressList = useSelector(
    (state) => state.Project.projectProgressCodeList
  );
  const progressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );
  const progressName = useSelector(
    (state) => state.Project.projectProgressName
  );
  const progressInfo = useSelector(
    (state) => state.Project.projectProgressInfo
  );
  const userInfo = useSelector((state) => state.User.userInfo);

  const { id } = useParams();

  const [picUrl, setPicUrl] = useState(pic);

  useEffect(() => {
    if (!progressCode || progressCode === "CDD_FIRST_PROCESS_6_FINISH" || !id) {
      setShowProgress(false);
    } else {
      setShowProgress(true);
    }
  }, [progressCode]);

  let styleObj = {
    border: hasBorder ? "1px solid #EFEFEF" : "none",
    width: width ? `calc(${width} - 10px)` : "calc(50% - 10px)",
    marginBottom: marginBottom ? marginBottom : "20px",
  };

  const projectTagColorObject = {
    team_project: "#0068B2",
    personal_project: "#9E9E9E",
  };

  const initValue = (type, key, format) => {
    if (type === "text") {
      if (format) {
        return format(cardData[key], cardData);
      }
      return cardData[key] || "";
    }
    if (type === "tag") {
      return (
        <StatusText
          status={cardData[key]}
          errorTips={cardData?.fail_msg}
        ></StatusText>
      );
    }
  };

  let progressObj = {};
  let progressIndex = 0;
  if (progressCode) {
    progressObj = progressList?.find((_) => _.dic_item_code === progressCode);
    progressIndex = progressList?.findIndex(
      (_) => _.dic_item_code === progressCode
    );
  }
  return (
    <div className="myCardContent" style={styleObj}>
      <div className="infoBox">
        {picUrl ? (
          <img
            src={picUrl}
            onError={(e) => {
              setPicUrl(noImg);
            }}
            className="infoImg"
            onClick={() => {
              imgClick && imgClick(cardData);
            }}
          ></img>
        ) : (
          <Avatar
            className={unClick ? "hoverPic unHoverPic" : "hoverPic"}
            size={116}
            shape="square"
            onClick={() => {
              imgClick && imgClick(cardData);
            }}
          >
            {title?.length >= 6 ? title.substring(0, 6) : title}
          </Avatar>
        )}

        <div className="infoValue">
          <div
            className="titleBox"
            style={{ width: titleIcon ? "calc(100% + 20px)" : "100%" }}
          >
            <div
              style={{
                width: `${showCollection ? "calc(100% - 6px)" : "100%"}`,
                display: "flex",
                alignItems: "center",
              }}
            >
              {titleIcon ? (
                <EditTitle
                  getProjectInfo={getProjectInfo}
                  cardData={cardData}
                  editUser={editUser}
                  projectInfo={projectInfo}
                  userInfo={userInfo}
                ></EditTitle>
              ) : (
                <Paragraph
                  ellipsis={{
                    tooltip: {
                      title: (
                        <div className="projectNameParagraphTip">{title}</div>
                      ),
                      overlayClassName: "projectNameParagraphTooltip",
                    },
                    rows: 1,
                    expandable: false,
                  }}
                  style={{
                    maxWidth: `${
                      showCollection ? "calc(100% - 75px)" : "calc(100% - 70px)"
                    }`,
                    marginBottom: "0",
                  }}
                  className={unClick ? "title unTitle" : "title"}
                  onClick={() => {
                    imgClick && imgClick(cardData);
                  }}
                >
                  {title}
                </Paragraph>
              )}
              {localStorage.getItem("show_project_type_tag") && (
                <Tag
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    background: "#ffffff",
                    height: "18px",
                    fontSize: "10px",
                    borderRadius: "20px",
                    padding: "0 8px",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: projectTagColorObject[cardData?.project_type],
                    borderColor: projectTagColorObject[cardData?.project_typ],
                  }}
                >
                  {cardData?.project_type_name}
                </Tag>
              )}
            </div>
            {showCollection &&
              (isCollection == 1 ? (
                <Tooltip placement="top" title="取消关注" arrowPointAtCenter>
                  <StarFilled
                    className="collectionIcon hoverIcon"
                    style={{ color: "#FEC054" }}
                    onClick={() => {
                      collectHandle && collectHandle(false, cardData);
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="关注" arrowPointAtCenter>
                  <StarOutlined
                    className="collectionIcon hoverIcon"
                    onClick={() => {
                      collectHandle && collectHandle(true, cardData);
                    }}
                  />
                </Tooltip>
              ))}

            {rightTopIcon && <span>{rightTopIcon}</span>}
          </div>
          <div className="itemBox">
            {(columns || []).map((item) => (
              <div
                className="valueItem"
                key={item.key}
                style={{ width: item.width || "100%", ...(item.style || {}) }}
              >
                <div className="label" style={{ ...(item.style || {}) }}>
                  {item.label}
                </div>
                {item.render ? (
                  item.render(cardData[item.key], cardData)
                ) : (
                  <div className="value">
                    {initValue(item.type, item.key, item.format)}
                  </div>
                )}
              </div>
            ))}
          </div>
          {showProgress ? (
            <div
              className="progressBox"
              style={{ width: wholeLine ? "100%" : "85%" }}
            >
              <Popover
                // open={true}
                content={
                  <ProgressPopoverContent
                    progressInfo={progressInfo}
                  ></ProgressPopoverContent>
                }
                placement="bottom"
                overlayClassName="progressPopover"
              >
                <div className="progress">
                  {progressList.map((item, index) => (
                    <div
                      key={item.dic_item_code}
                      className={
                        progressIndex >= index
                          ? progressIndex === index
                            ? "progressItem active"
                            : "progressItem success"
                          : "progressItem"
                      }
                      style={{
                        width: 100 / progressList.length + "%",
                      }}
                    ></div>
                  ))}
                </div>
              </Popover>
              {/* style={{color:progressIndex === -1?'green':'#FE545F'}} */}
              <div className="progressTag">{progressName}</div>
            </div>
          ) : null}
        </div>
      </div>

      {btnList?.length > 0 ? <Divider></Divider> : null}
      {btnList?.length > 0 ? (
        <div className="infoButtonBox">
          <StatusTag status={projectStatus}></StatusTag>
          <div className="btnBox">
            {(btnList || []).map((node) => (
              <i key={Math.random()}>{node}</i>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MyCard;
