import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoadingPage from "../LoadingPage";
import { moduleCodeMap } from "../../../../setting";
const ProgressRouter = (props) => {
  const { children, menu_name } = props;
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(true);

  const projectProgressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );

  const projectProgressShowCode = useSelector(
    (state) => state.Project.projectProgressShowCode
  );

  const getStatus = () => {
    //|| menu_name === '疑似关联方分析' || menu_name === '底稿管理'
    if (!menu_name) {
      return true;
    }
    if (projectProgressCode === "CDD_FIRST_PROCESS_0_WAIT") {
      //尽调排队中
      return [].includes(menu_name);
    } else if (projectProgressCode === "CDD_FIRST_PROCESS_1_START") {
      //尽调主体获取中
      let arr = moduleCodeMap[projectProgressShowCode] || [];
      return arr.includes(menu_name);
    } else if (projectProgressCode === "CDD_FIRST_PROCESS_2_BASICINFO") {
      //工商信息查询中
      let arr = moduleCodeMap[projectProgressShowCode] || [];
      return arr.includes(menu_name);
    } else if (projectProgressCode === "CDD_FIRST_PROCESS_3_PENETRATION") {
      //股权穿透计算中
      return [
        "主要股东",
        "对外投资",
        "主要人员",
        "分支机构",
        "工商信息",
        "商标",
        "专利",
        "作品著作",
        "软件著作",
        "资质证书",
        "行政许可",
        "招投标",
        "行政处罚",
        "动产抵质押",
        "不动产抵押",
        "司法拍卖",
        "司法案件",
        "法律文书",
        "失信被执行人",
        "被执行人",
        "新闻舆情",
        "公告舆情",
        "底稿管理",
        "历史沿革",
        "资本运作情况",
        "经营分析",
        "税务分析",
        "欠税公告",
        "非正常户",
        "监管处罚",
        "诚信处罚"
      ].includes(menu_name);
    } else if (projectProgressCode === "CDD_FIRST_PROCESS_4_RELATED") {
      //关联方计算中
      return [
        "主要股东",
        "对外投资",
        "主要人员",
        "分支机构",
        "工商信息",
        "股权关系",
        "商标",
        "专利",
        "作品著作",
        "软件著作",
        "资质证书",
        "行政许可",
        "招投标",
        "行政处罚",
        "动产抵质押",
        "不动产抵押",
        "司法拍卖",
        "司法案件",
        "法律文书",
        "失信被执行人",
        "被执行人",
        "新闻舆情",
        "公告舆情",
        "底稿管理",
        "历史沿革",
        "资本运作情况",
        "经营分析",
        "税务分析",
        "欠税公告",
        "非正常户",
        "监管处罚",
        "诚信处罚"
      ].includes(menu_name);
    } else if (projectProgressCode === "CDD_FIRST_PROCESS_5_DATA") {
      //经营与事件数据查询中
      return [
        "主要股东",
        "对外投资",
        "主要人员",
        "分支机构",
        "工商信息",
        "股权关系",
        "关联方管理",
        "商标",
        "专利",
        "作品著作",
        "软件著作",
        "资质证书",
        "行政许可",
        "招投标",
        "行政处罚",
        "动产抵质押",
        "不动产抵押",
        "司法拍卖",
        "司法案件",
        "法律文书",
        "失信被执行人",
        "被执行人",
        "新闻舆情",
        "公告舆情",
        "底稿管理",
        "历史沿革",
        "资本运作情况",
        "经营分析",
        "税务分析",
        "欠税公告",
        "非正常户",
        "监管处罚",
        "诚信处罚"
      ].includes(menu_name);
    } else if (projectProgressCode === "CDD_FIRST_PROCESS_6_FINISH") {
      //首次尽调完成
      return true;
    }
    return true;
  };

  const getSuccessFunc = () => {
    // setLoading(true);
    setSuccess(getStatus());
    setTimeout(() => {
      // setLoading(false);
    }, 50);
  };

  useEffect(() => {
    getSuccessFunc();
  }, [menu_name, projectProgressCode, projectProgressShowCode]);

  // if (loading) {
  //   return (
  //     <div style={{ width: "100%", height: "100%", background: "#fff" }}></div>
  //   );
  // }

  if (!success) {
    return <LoadingPage menu_name={menu_name}></LoadingPage>;
  }
  return <>{children}</>;
};

export default ProgressRouter;
