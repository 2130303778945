import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  isCurrentYear,
  saveFile,
} from "../../../../../../unit/unit";
import { Popover, message } from "antd";
import { useGetState } from "ahooks";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import IconBar from "../../../../../../components/IconBar";

const SalesAndCustomerAnalysisPopulationContent = (props) => {
  const { data = {}, dataRange } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [type, setType, getType] = useGetState("H1");

  const myChart1 = useRef(null);
  const myChart2 = useRef(null);
  const colorList = useRef(["#0068B2", "#61D4AD", "#FEC054", "#6CAEFF"]);

  const initEcharts = (echartsData) => {
    if (myChart1.current) {
      myChart1.current.dispose();
      myChart1.current = null;
    }
    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    const dom1 = document.getElementById(
      "salesAndCustomerAnalysisPopulationBox1"
    );
    const dom2 = document.getElementById(
      "salesAndCustomerAnalysisPopulationBox2"
    );
    if (!dom1 || !dom2) {
      return;
    }
    myChart1.current = echarts.init(dom1);
    myChart2.current = echarts.init(dom2);

    const amountObj = echartsData?.total_amount || {};
    // amountObj['2023'] = 123123.23;
    // amountObj['2024'] = 23233.23;
    const amountKeys = Object.keys(amountObj)
      ?.filter((_) => !_?.includes("H1") && !_?.includes("H2"))
      ?.sort((a, b) => b - a);
    const numberObj = echartsData?.total_custom || {};
    // numberObj['2023'] = 4;
    // numberObj['2024'] = 8;
    const numberKeys = Object.keys(numberObj)
      ?.filter((_) => !_?.includes("H1") && !_?.includes("H2"))
      ?.sort((a, b) => b - a);

    const option1 = {
      legend: {
        data: amountKeys,
        show: false,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
        formatter: (info) => {
          let str = "<div>销项开票金额</div>";
          info?.forEach((item) => {
            str += `<div>${item?.marker} ${item.seriesName}年   ${Number(
              item.value || 0
            ).toLocaleString("en-US")}元</div>`;
          });
          return str;
        },
      },
      grid: {
        containLabel: true,
        left: 0,
        top: 0,
        right: "80px",
        bottom: 0,
      },
      yAxis: {
        data: ["金额"],
        show: false,
        inverse: false,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          margin: 0,
          fontSize: 0,
        },
        axisPointer: {
          label: {
            show: false,
            margin: 0,
          },
        },
      },
      xAxis: {
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
      },
      series: amountKeys?.map((key, index) => ({
        name: key,
        type: "pictorialBar",
        symbolRepeat: true,
        barGap: "28px",
        label: {
          show: true,
          position: "right",
          align: "center",
          // offset: [16, 0],
          distance:
            (Number(amountObj[key] || 0) / 10000)
              .toFixed(2)
              .toLocaleString("en-US")?.length * 5,
          fontSize: 14,
          // width: 1800,
          // borderColor: 'red',
          // borderWidth: 1,
          formatter: (info) => {
            return (Number(info.value || 0) / 10000)
              .toFixed(2)
              .toLocaleString("en-US");
          },
        },
        symbolSize: 22,
        barCategoryGap: "80%",
        // symbolMargin: 2,
        // symbolClip: true,
        // symbolBoundingData: 10000000,
        itemStyle: {
          color: colorList.current[index % 4],
        },
        data: [
          {
            value: amountObj[key],
            symbol:
              "path://M539.428571 539.428571h137.142858a27.428571 27.428571 0 0 1 0 54.857143h-137.142858V731.428571a27.428571 27.428571 0 0 1-54.857142 0v-137.142857H347.428571a27.428571 27.428571 0 0 1 0-54.857143h137.142858v-54.857142h-109.714286a27.428571 27.428571 0 0 1 0-54.857143h70.948571l-66.194285-66.194286a27.794286 27.794286 0 0 1-7.68-26.697143 28.16 28.16 0 0 1 19.748571-19.748571 27.794286 27.794286 0 0 1 26.697143 7.68L512 418.377143l93.622857-93.622857a27.794286 27.794286 0 0 1 26.697143-7.68 28.16 28.16 0 0 1 19.748571 19.748571 27.794286 27.794286 0 0 1-7.68 26.697143l-66.194285 66.194286h70.948571a27.428571 27.428571 0 0 1 0 54.857143h-109.714286zM512 896A384 384 0 1 1 896 512 384 384 0 0 1 512 896z m0-54.857143a329.142857 329.142857 0 1 0-329.142857-329.142857 329.142857 329.142857 0 0 0 329.142857 329.142857z",
          },
        ],
      })),
    };

    const option2 = {
      legend: {
        data: amountKeys,
        show: false,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
      },
      grid: {
        containLabel: true,
        left: 0,
        top: 0,
        right: "80px",
        bottom: 0,
      },
      yAxis: {
        data: ["客户数"],
        show: false,
        inverse: false,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          margin: 0,
          fontSize: 0,
        },
        axisPointer: {
          label: {
            show: false,
            margin: 0,
          },
        },
      },
      xAxis: {
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
      },
      series: numberKeys?.map((key, index) => ({
        name: key,
        type: "pictorialBar",
        symbolRepeat: true,
        barGap: "28px",
        label: {
          show: true,
          position: "right",
          align: "center",
          // offset: [10, 0],
          distance:
            Number(numberObj[key] || 0).toLocaleString("en-US")?.length * 5 +
            10,
          fontSize: 14,
          formatter: (info) => {
            return Number(info.value || 0).toLocaleString("en-US");
          },
        },
        symbolSize: 22,
        // symbolMargin: 5,
        barCategoryGap: "80%",
        // symbolClip: true,
        itemStyle: {
          color: colorList.current[index % 4],
        },
        data: [
          {
            value: numberObj[key],
            symbol:
              "path://M512 464c88 0 160-72 160-160s-72-160-160-160-160 72-160 160 72 160 160 160z m-19.2 153.6c-8-6.4-12.8-14.4-12.8-25.6 0-17.6 14.4-32 32-32s32 14.4 32 32c0 11.2-4.8 19.2-12.8 25.6l43.2 152c3.2 11.2 0 22.4-8 30.4l-43.2 43.2c-6.4 6.4-16 6.4-22.4 0L457.6 800c-8-8-11.2-19.2-8-30.4l43.2-152zM372.8 480C321.6 438.4 288 376 288 304c0-123.2 100.8-224 224-224s224 100.8 224 224c0 70.4-33.6 134.4-84.8 176C811.2 526.4 928 673.6 928 848c0 52.8-43.2 96-96 96H192c-52.8 0-96-43.2-96-96 0-174.4 116.8-321.6 276.8-368zM832 880c17.6 0 32-14.4 32-32 0-176-144-320-320-320h-64c-176 0-320 144-320 320 0 17.6 14.4 32 32 32h640z",
          },
        ],
      })),
    };

    myChart1.current.setOption(option1);
    myChart2.current.setOption(option2);
    window.addEventListener("resize", () => {
      myChart1.current && myChart1.current.resize();
      myChart2.current && myChart2.current.resize();
    });
  };

  const initData = (data) => {
    const time = dataRange?.split("~")?.at(1);
    let diff = null;
    if (time) {
      const time2 = moment(time)?.format("MM-DD");
      diff = moment(time2).diff(moment("06-30"), "days");
    } else {
      message.info("采集数据未获取");
      return;
    }

    let year =
      diff >= 0
        ? moment(time)?.format("YYYY")
        : moment(time).subtract(1, "year")?.format("YYYY");
    setSelectYear(year);
    //数据范围为下半年 取上半年的数据展示
    if (diff >= 0) {
      setType("H1");
    } else {
      //数据范围为上半年 取前一年的数据展示
      setType(null);
    }
    // initEcharts(data);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "总体情况",
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  const initTip = () => {
    if (!data?.total_amount) {
      return;
    }
    const yearKey =
      getSelectYear() +
      (isCurrentYear(getSelectYear()) ? (getType() === "H1" ? "H1" : "") : "");
    const lastYearkey =
      getSelectYear() -
      1 +
      (isCurrentYear(getSelectYear()) ? (getType() === "H1" ? "H1" : "") : "");

    const lostAmount = data?.total_amount[lastYearkey] || 0;
    const amount = data?.total_amount[yearKey] || 0;
    const isLost = lostAmount > amount;

    const commodityObj = data?.year_product_category_amount[yearKey] || {};
    const lostCommodityObj =
      data?.year_product_category_amount[lastYearkey] || {};
    const areaObj = data?.year_area_amount[yearKey] || {};
    const lostAreaObj = data?.year_area_amount[lastYearkey] || {};

    const addCustomerAmount = data?.add_customer_amount[yearKey] || 0;
    const lostAddCustomerAmount = data?.add_customer_amount[lastYearkey] || 0;

    const stockCustomerAmount = data?.stock_customer_amount[yearKey] || 0;
    const lostStockCustomerAmount =
      data?.stock_customer_amount[lastYearkey] || 0;

    const addCustomerAmountDiff = addCustomerAmount - lostAddCustomerAmount;
    const stockCustomerAmountDiff =
      stockCustomerAmount - lostStockCustomerAmount;

    let commodityList = [];
    Object.keys(commodityObj).forEach((key) => {
      commodityList.push({
        name: key,
        amount: commodityObj[key] || 0,
        lostAmount: lostCommodityObj[key] || 0,
        diff: (commodityObj[key] || 0) - (lostCommodityObj[key] || 0),
      });
    });

    let areaList = [];
    Object.keys(areaObj).forEach((key) => {
      if (key === "未知省份") return;
      areaList.push({
        name: key,
        amount: areaObj[key] || 0,
        lostAmount: lostAreaObj[key] || 0,
        diff: (areaObj[key] || 0) - (lostAreaObj[key] || 0),
      });
    });

    let commodityItem =
      commodityList
        ?.sort((a, b) => (isLost ? a.diff - b.diff : b.diff - a.diff))
        ?.at(0) || {};
    let areaItem =
      areaList
        ?.sort((a, b) => (isLost ? a.diff - b.diff : b.diff - a.diff))
        ?.at(0) || {};

    let diffCustomer = {};
    if (addCustomerAmountDiff > stockCustomerAmountDiff) {
      diffCustomer.name = isLost ? "存量" : "增量";
      diffCustomer.amount = isLost ? stockCustomerAmount : addCustomerAmount;
      diffCustomer.lostAmount = isLost
        ? lostStockCustomerAmount
        : lostAddCustomerAmount;
      diffCustomer.diff = isLost
        ? stockCustomerAmountDiff
        : addCustomerAmountDiff;
    } else {
      diffCustomer.name = isLost ? "增量" : "存量";
      diffCustomer.amount = isLost ? addCustomerAmount : stockCustomerAmount;
      diffCustomer.lostAmount = isLost
        ? lostAddCustomerAmount
        : lostStockCustomerAmount;
      diffCustomer.diff = isLost
        ? addCustomerAmountDiff
        : stockCustomerAmountDiff;
    }
    const filterZeroCommodityList = commodityList?.filter(
      (item) => item.amount !== 0
    );
    const filterZeroAreaList = areaList?.filter((item) => item.amount !== 0);

    let areaType = "";
    const areaLen = filterZeroAreaList?.length;
    if (areaLen <= 3) {
      areaType = "单一";
    } else if (3 < areaLen && areaLen <= 5) {
      areaType = "集中";
    } else if (5 < areaLen && areaLen <= 10) {
      areaType = "比较集中";
    } else if (areaLen > 10) {
      areaType = "分布较广";
    }

    const topThreeAreaProportion =
      filterZeroAreaList
        ?.sort((a, b) => b?.amount - a.amount)
        ?.slice(0, 3)
        ?.reduce((a, b) => a + b.amount, 0) / amount;

    return (
      <>
        <div className="salesAndCustomerAnalysisPopulationTipItem">
          <i></i>
          <div>
            {getSelectYear()}年
            {getType() === "H1" && isCurrentYear(getSelectYear())
              ? "上半年"
              : ""}
            销项开票金额为
            <span className="numberSpan">{addMillimeter(amount)}</span>元
            {lostAmount ? (
              <>
                ，同比（较
                {getSelectYear() - 1}年
                {getType() === "H1" && isCurrentYear(getSelectYear())
                  ? "上半年"
                  : ""}
                ）{isLost ? "下降" : "增长"}
                <span
                  style={{
                    color: isLost ? "#FE545F" : "#61D4AD",
                    fontWeight: "bold",
                  }}
                >
                  {addMillimeter(
                    (Math.abs(amount - lostAmount || 0) /
                      Math.abs(lostAmount)) *
                      100
                  )}
                  %
                </span>
                {isLost ? (
                  <>
                    <ArrowDownOutlined
                      style={{ color: "#FE545F", fontSize: "14px" }}
                    />
                  </>
                ) : (
                  <>
                    <ArrowUpOutlined
                      style={{ color: "#61D4AD", fontSize: "14px" }}
                    />
                  </>
                )}
              </>
            ) : null}
            。其中，{commodityItem?.name}（按开票项目）
            {commodityItem?.diff > 0 ? "增加" : "减少"}
            <span className="numberSpan">
              {addMillimeter(Math.abs(commodityItem?.diff))}
            </span>
            元
            {commodityItem?.lostAmount ? (
              <>
                ，{commodityItem?.diff > 0 ? "增幅" : "降幅"}
                <span className="numberSpan">
                  {commodityItem?.lostAmount
                    ? addMillimeter(
                        (Math.abs(commodityItem?.diff) /
                          Math.abs(commodityItem?.lostAmount)) *
                          100
                      )
                    : "-"}
                  %
                </span>
              </>
            ) : null}
            ；
            {areaItem.name ? (
              <>
                {areaItem?.name}
                {areaItem?.diff > 0 ? "增加" : "减少"}
                <span className="numberSpan">
                  {addMillimeter(Math.abs(areaItem?.diff))}
                </span>
                元
                {areaItem?.lostAmount ? (
                  <>
                    ，{areaItem?.diff > 0 ? "增幅" : "降幅"}
                    <span className="numberSpan">
                      {areaItem?.lostAmount
                        ? addMillimeter(
                            (Math.abs(areaItem?.diff) /
                              Math.abs(areaItem?.lostAmount)) *
                              100
                          )
                        : "-"}
                      %
                    </span>
                  </>
                ) : null}
                ；
              </>
            ) : null}
            {diffCustomer?.name}客户
            {diffCustomer?.diff > 0 ? "增加" : "减少"}
            <span className="numberSpan">
              {addMillimeter(Math.abs(diffCustomer?.diff))}
            </span>
            元
            {diffCustomer?.lostAmount ? (
              <>
                ，{diffCustomer?.diff > 0 ? "增幅" : "降幅"}
                <span className="numberSpan">
                  {diffCustomer?.lostAmount
                    ? addMillimeter(
                        (Math.abs(diffCustomer?.diff) /
                          Math.abs(diffCustomer?.lostAmount)) *
                          100
                      )
                    : "-"}
                  %
                </span>
              </>
            ) : null}
            。
          </div>
        </div>
        <div className="salesAndCustomerAnalysisPopulationTipItem">
          <i></i>
          <div>
            {getSelectYear()}年
            {getType() === "H1" && isCurrentYear(getSelectYear())
              ? "上半年"
              : ""}
            公司销售商品（按开票项目）为
            <span className="numberSpan">
              {filterZeroCommodityList?.length}
            </span>
            种 ，其中，
            {filterZeroCommodityList
              ?.sort((a, b) => b?.amount - a.amount)
              ?.slice(0, 3)
              ?.map((item, index) => (
                <span key={item.name}>
                  {index === 0 ? null : "、"}
                  {item.name}开票金额
                  <span className="numberSpan">
                    {addMillimeter(item.amount)}
                  </span>
                  元 （占比{" "}
                  <span className="numberSpan">
                    {((item.amount / Math.abs(amount)) * 100).toFixed(2)}%
                  </span>
                  ）
                </span>
              ))}
            。
            {lostAmount ? (
              <>
                主营商品开票金额同比（较{getSelectYear() - 1}年
                {getType() === "H1" && isCurrentYear(getSelectYear())
                  ? "上半年"
                  : ""}
                ）{amount - lostAmount < 0 ? "下降" : "增长"}
                <span
                  style={{
                    color: amount - lostAmount < 0 ? "#FE545F" : "#61D4AD",
                    fontWeight: "bold",
                  }}
                >
                  {addMillimeter(
                    (Math.abs(amount - lostAmount || 0) /
                      Math.abs(lostAmount)) *
                      100
                  )}
                  %
                </span>
                {amount - lostAmount < 0 ? (
                  <>
                    <ArrowDownOutlined
                      style={{ color: "#FE545F", fontSize: "14px" }}
                    />
                  </>
                ) : (
                  <>
                    <ArrowUpOutlined
                      style={{ color: "#61D4AD", fontSize: "14px" }}
                    />
                  </>
                )}
                。
              </>
            ) : null}
          </div>
        </div>
        <div className="salesAndCustomerAnalysisPopulationTipItem">
          <i></i>
          <div>
            {getSelectYear()}年
            {getType() === "H1" && isCurrentYear(getSelectYear())
              ? "上半年"
              : ""}
            公司商品在<span className="numberSpan">{areaLen}</span>
            个省份销售，市场<span className="numberSpan">{areaType}</span>
            {filterZeroAreaList?.length > 3 ? (
              <>
                ，其中，
                {filterZeroAreaList
                  ?.sort((a, b) => b?.amount - a.amount)
                  ?.slice(0, 3)
                  ?.map((item, index) => (
                    <span key={item.name}>
                      {index === 0 ? null : "、"}
                      {item.name}开票金额
                      <span className="numberSpan">
                        {addMillimeter(item.amount)}
                      </span>
                      元 （占比
                      <span className="numberSpan">
                        {((item.amount / Math.abs(amount)) * 100).toFixed(2)}%{" "}
                      </span>
                      ）
                    </span>
                  ))}
                。开票金额前三的省份占全部开票金额
                <span className="numberSpan">
                  {(topThreeAreaProportion * 100)?.toFixed(2)}%
                </span>
                ，开票金额分布
                <span className="numberSpan">
                  {topThreeAreaProportion > 0.5 ? "较均衡" : "不平衡"}
                </span>
              </>
            ) : null}
            。
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="salesAndCustomerAnalysisPopulationContent">
      <div className="headerBox">
        总体情况
      </div>

      {!noData && (
        <div className="salesAndCustomerAnalysisPopulationContentBox">
          <div className="salesAndCustomerAnalysisPopulationBox">
            <div className="salesAndCustomerAnalysisPopulationItem">
              <div className="titleBox">销项开票金额（万元）</div>
              {/* <div id="salesAndCustomerAnalysisPopulationBox1"></div> */}
              <IconBar
                data={data?.total_amount || {}}
                type="amount"
                title="销项开票金额"
                icon={
                  <svg
                    t="1695022393787"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    p-id="4051"
                    width="22"
                    height="22"
                  >
                    <path
                      d="M544 544h160a32 32 0 0 1 0 64h-160v160a32 32 0 0 1-64 0v-160h-160a32 32 0 0 1 0-64h160v-64h-128a32 32 0 0 1 0-64h82.752l-77.248-77.248a32 32 0 1 1 45.248-45.248L512 402.752l109.248-109.248a32 32 0 1 1 45.248 45.248L589.248 416H672a32 32 0 0 1 0 64h-128v64z m-32 416C264.576 960 64 759.424 64 512S264.576 64 512 64s448 200.576 448 448-200.576 448-448 448z m0-64c212.064 0 384-171.936 384-384S724.064 128 512 128 128 299.936 128 512s171.936 384 384 384z"
                      fill="#333333"
                      p-id="4052"
                    ></path>
                  </svg>
                }
              ></IconBar>
            </div>
            <div className="salesAndCustomerAnalysisPopulationItem">
              <div className="titleBox">开票客户数</div>
              {/* <div id="salesAndCustomerAnalysisPopulationBox2"></div> */}
              <IconBar
                data={data?.total_custom || {}}
                title="开票客户数"
                icon={
                  <svg
                    t="1695024901405"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="5327"
                    width="22"
                    height="22"
                  >
                    <path
                      d="M517.818182 465.454545A145.454545 145.454545 0 1 0 372.363636 320 145.92 145.92 0 0 0 517.818182 465.454545z m-17.454546 139.636364a28.392727 28.392727 0 0 1-11.636363-23.272727 29.090909 29.090909 0 0 1 58.181818 0 28.392727 28.392727 0 0 1-11.636364 23.272727l39.330909 138.24a27.694545 27.694545 0 0 1-7.214545 27.461818l-39.330909 39.330909a14.196364 14.196364 0 0 1-20.48 0l-39.330909-39.330909a27.694545 27.694545 0 0 1-7.214546-27.461818z m-109.149091-125.207273A203.869091 203.869091 0 1 1 721.454545 320a206.661818 206.661818 0 0 1-77.032727 159.883636A349.090909 349.090909 0 0 1 896 814.545455a87.505455 87.505455 0 0 1-87.272727 87.272727h-581.818182A87.505455 87.505455 0 0 1 139.636364 814.545455a349.090909 349.090909 0 0 1 251.578181-334.661819z m417.512728 363.752728A29.090909 29.090909 0 0 0 837.818182 814.545455a291.607273 291.607273 0 0 0-290.909091-290.909091H488.727273A291.607273 291.607273 0 0 0 197.818182 814.545455a29.090909 29.090909 0 0 0 29.090909 29.090909z"
                      p-id="5328"
                    ></path>
                  </svg>
                }
              ></IconBar>
            </div>

            <div className="salesAndCustomerAnalysisPopulationItem">
              <div className="titleBox">开票客单价（万元）</div>

              <div className="numberBox">
                <Popover
                  trigger="hover"
                  placement="rightBottom"
                  overlayClassName="salesAndCustomerAnalysisPopulationPopover"
                  content={
                    <div className="salesAndCustomerAnalysisPopulationPopoverBox">
                      <div className="title">开票客单价</div>
                      {Object?.keys(data?.custom_price || {})
                        ?.sort((a, b) => b - a)
                        ?.map((key) => (
                          <div key={key} className="text">
                            <span>{key}</span>
                            <span
                              style={{
                                marginLeft: "6px",
                                fontWeight: "normal",
                              }}
                            >
                              {addMillimeter(data?.custom_price[key]) + "元"}
                            </span>
                          </div>
                        ))}
                    </div>
                  }
                >
                  {addMillimeter(
                    ((data?.custom_price &&
                      data?.custom_price[getSelectYear()]) ||
                      0) / 10000,
                    2
                  )}
                </Popover>
              </div>
            </div>

            <div className="salesAndCustomerAnalysisPopulationItem">
              <div className="titleBox">销售商品种类</div>
              <div className="numberBox">
                <Popover
                  trigger="hover"
                  placement="rightBottom"
                  overlayClassName="salesAndCustomerAnalysisPopulationPopover"
                  content={
                    <div className="salesAndCustomerAnalysisPopulationPopoverBox">
                      <div className="title">销售商品种类</div>
                      {Object?.keys(data?.total_product || {})
                        ?.sort((a, b) => b - a)
                        ?.map((key) => (
                          <div
                            key={key}
                            className="text"
                            style={{ minWidth: "90px" }}
                          >
                            <span>{key}</span>
                            {"  "}
                            {data?.total_product[key] + "种"}
                          </div>
                        ))}
                    </div>
                  }
                >
                  {data?.total_product && data?.total_product[getSelectYear()]}
                </Popover>
              </div>
            </div>
          </div>
          {initTip()}
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default SalesAndCustomerAnalysisPopulationContent;
