import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import { LoadingOutlined } from "@ant-design/icons";
import YearSelect from "../../../../TenderingAndBidding/components/YearSelect";
import DownLoadIcon from "../../../../../../components/Icons/DownLoadIcon";

const QuarterlyDistribution = (props) => {
  const { data = {}, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [yearList, setYearList, getYearList] = useGetState([]);
  const [dataInfo, setDataInfo, getDataInfo] = useGetState({});

  const myChart = useRef(null);
  const colorRef = useRef(["#6CAEFF", "#AAD461", "#FEC054", "#FE545F"]);

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let amountMax = 0;
    const xTypeList = [];
    Object?.keys(echartsData)?.forEach((key) => {
      echartsData[key]?.forEach((item) => {
        if (item?.amount >= amountMax) {
          amountMax = item?.amount;
        }
        if (!xTypeList?.includes(item.product_category)) {
          xTypeList.push(item.product_category);
        }
      });
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("quarterlyDistributionContentBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);

    const option = {
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        // y: "bottom", //可设定图例在上、下、居中
        bottom: "10px",
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        data: xTypeList,
      },
      xAxis: [
        {
          type: "category",
          data: ["第一季度", "第二季度", "第三季度", "第四季度"],
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            interval: 0,
            // rotate: -15,
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
        },
      ],
      yAxis: [
        {
          type: "value",
          max: amountMax <= 5 ? 5 : null,
          interval: amountMax <= 5 ? 1 : null,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            show: false,
          },
          axisLabel: {
            formatter: function (value, index) {
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else if (value <= -10000) {
                return value / 10000 + "万";
              } else if (value <= -100000000) {
                return value / 100000000 + "亿";
              } else {
                return value;
              }
            },
          },
        },
      ],
      grid: {
        top: "20px",
        left: "0%",
        right: "0px",
        bottom: "40px",
        containLabel: true,
      },
      series: xTypeList?.map((key, index) => ({
        name: key,
        type: "bar",
        barGap: 0,
        barMaxWidth: 40,
        tooltip: {
          valueFormatter: function (value) {
            return addMillimeter(value, 2) + "元";
          },
        },
        itemStyle: {
          color: colorRef.current[index % 4],
          barBorderRadius: [4, 4, 0, 0],
        },
        data: ["第一季度", "第二季度", "第三季度", "第四季度"]?.map(
          (_key, index) => {
            const __key = ["Q1", "Q2", "Q3", "Q4"][index];
            return (echartsData[__key] || [])
              ?.filter((item) => item.product_category === key)
              ?.reduce((a, b) => a + (b.amount || 0), 0);
          }
        ),
      })),
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  const initData = (data) => {
    let yearArr = Object?.keys(data)
      ?.filter((_) => _ !== "name")
      ?.map((item) => {
        return item?.slice(0, 4);
      })
      .sort((a, b) => Number(a) - Number(b));
    yearArr = [...new Set(yearArr)];
    const nowYear = yearArr?.at(-1);
    setYearList(yearArr);
    setSelectYear(nowYear);

    const obj = {};
    Object?.keys(data)
      ?.filter((_) => _ !== "name")
      ?.forEach((key) => {
        const year = key?.slice(0, 4);
        const quarter = key?.slice(4);
        if (obj[year]) {
          obj[year][quarter] = data[key];
        } else {
          const _obj = {};
          _obj[quarter] = data[key];
          obj[year] = _obj;
        }
      });

    setDataInfo(obj);
    initEcharts(obj[nowYear]);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "主营商品开票金额季度分布",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="quarterlyDistributionContent">
      <div className="headerBox">
        主营商品开票金额季度分布
        {!noData && (
          <div className="yearSelectBox">
            <YearSelect
              value={selectYear}
              defaultOptions={yearList}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value?.at(0));
                setTimeout(() => {
                  initEcharts(getDataInfo()[value?.at(0)]);
                }, 50);
              }}
            ></YearSelect>
          </div>
        )}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <DownLoadIcon
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                ></DownLoadIcon>
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="quarterlyDistributionContentBox">
          <div id="quarterlyDistributionContentBox"></div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default QuarterlyDistribution;
