import { Tag, Tooltip, Typography } from "antd";
import "./index.scss";
import {
  EyeOutlined,
  PayCircleFilled,
  CloseOutlined,
  EyeInvisibleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import icon1 from "../../../../../assets/中标2.svg";
import { useSelector } from "react-redux";
import { isArray } from "lodash";
import { addMillimeter } from "../../../../../unit/unit";
import TableModal from "../TableModal";
import { useParams } from "react-router-dom";

const { Paragraph } = Typography;

const CompanyCard = (props) => {
  const {
    info,
    index,
    type,
    dataType,
    deleteFunc,
    checkCompanyList,
    setCheckCompanyList,
    dataList,
    noDelete,
    empty,
    loading,
    notShowIndex,
    searchTableFunc,
    maxNumber = 20,
  } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();

  //为空 骨架屏占位
  if (empty) {
    return (
      <div className="companyCardContent">
        <div className="topBox">
          <div className="indexBox">
            {index < 10 ? "0" + index : index}
            <div className="aaa"></div>
            <div className="bbb"></div>
          </div>
          <div
            className="titleBox"
            style={{ width: `100%`, height: "16px", background: "#D8D8D8" }}
          ></div>
        </div>
        <div className="bottomBox" style={{ width: "calc(100% - 40px)" }}>
          <div
            style={{
              width: "48px",
              height: "20px",
              background: "#EFEFEF",
              borderRadius: "10px",
            }}
          ></div>

          <div className="iconTextBox">
            <div
              style={{
                width: "12px",
                height: "12px",
                background: "#D8D8D8",
              }}
            ></div>
            <div
              style={{
                width: "16px",
                height: "12px",
                background: "#0068B2",
                marginLeft: "6px",
              }}
            ></div>
            <div
              style={{
                width: "12px",
                height: "12px",
                background: "#D8D8D8",
                marginLeft: "24px",
              }}
            ></div>
            <div
              style={{
                width: "16px",
                height: "12px",
                background: "#0068B2",
                marginLeft: "6px",
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="companyCardContent">
      <div className="topBox">
        {notShowIndex ? (
          <div
            className="indexBox"
            style={{
              paddingLeft: "4px",
              fontSize: "30px",
            }}
          >
            -
          </div>
        ) : (
          <div
            className="indexBox"
            style={{
              fontSize:
                index >= 100 ? (index >= 1000 ? "14px" : "18px") : "24px",
            }}
          >
            {index < 10 ? "0" + index : index}
            <div className="aaa"></div>
            <div className="bbb"></div>
          </div>
        )}
        <Paragraph
          ellipsis={{
            rows: 1,
            tooltip: {
              title: (
                <div className="tableColumnsParagraphTip">
                  {info?.bid_winner}
                </div>
              ),
              overlayClassName: "tableColumnsParagraphTooltip",
              placement: "top",
            },
          }}
          className="titleBox"
          style={{
            width: `calc(100% - ${!noDelete ? 80 : 60}px)`,
            marginBottom: "0px",
          }}
          onClick={() => {
            let typeName = "投标结果";
            let filterOptions = {};
            let isBid = false;
            if (dataType === "投标及客户分析") {
              typeName = "投标结果";
              filterOptions = {
                供应商: info?.bid_winner,
              };
            } else if (dataType === "招标及供应商分析") {
              typeName = "招标结果";
              filterOptions = {
                采购人: info?.bid_winner,
              };
            } else if (dataType === "竞争对手分析") {
              typeName = "投标结果";
              filterOptions = {
                供应商: info?.bid_winner,
              };
              isBid = true;
              if(projectInfo.company_name === info?.bid_winner){
                isBid = false;
              }
            }
            TableModal({
              title: info?.bid_winner + "-" + typeName,
              typeName: typeName,
              filterOptions: filterOptions,
              onOk: searchTableFunc,
              isBid: isBid,
              projectId: id,
            });
          }}
        >
          {info?.bid_winner}
        </Paragraph>
        <div
          className={
            checkCompanyList?.includes(info?.bid_winner)
              ? "iconsBox activeIconsBox"
              : "iconsBox"
          }
          style={{
            width: !noDelete ? "40px" : "20px",
          }}
        >
          {!notShowIndex &&
            (checkCompanyList?.includes(info?.bid_winner) ? (
              <Tooltip placement="top" title="显示" arrowPointAtCenter>
                <EyeOutlined
                  onClick={() => {
                    setCheckCompanyList((prev) => {
                      const _prev = prev.filter((_) => _ !== info?.bid_winner);
                      if (_prev?.length !== 0) {
                        return [..._prev];
                      } else {
                        return prev;
                      }
                    });
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="隐藏" arrowPointAtCenter>
                <EyeInvisibleOutlined
                  onClick={() => {
                    // if(checkCompanyList?.length >= maxNumber){
                    //   return
                    // }
                    setCheckCompanyList((prev) => {
                      prev = [...prev, info?.bid_winner];
                      prev = prev.sort((a, b) => {
                        const _a = dataList.findIndex(
                          (_) => _.bid_winner === a
                        );
                        const _b = dataList.findIndex(
                          (_) => _.bid_winner === b
                        );
                        return _a - _b;
                      });
                      return [...prev];
                    });
                  }}
                />
              </Tooltip>
            ))}

          {!noDelete &&
            (info?.is_cdd_company ===
            1 ? null : projectInfo?.project_permission_urls.includes(
                "CDD_PRJ_CDD_ITEM_EDIT"
              ) ? (
              <Tooltip placement="top" title="删除" arrowPointAtCenter>
                <CloseOutlined onClick={() => deleteFunc && deleteFunc(info)} />
              </Tooltip>
            ) : null)}
        </div>
      </div>
      <div
        className="bottomBox"
        style={{ width: `calc(100% - ${!noDelete ? 42 : 42}px)` }}
      >
        {isArray(type) ? (
          <div className="tagsBox">
            {type?.map((typeItem) => {
              return (
                <Tooltip
                  key={Math.random()}
                  placement="top"
                  title={typeItem.full_name}
                  arrowPointAtCenter
                >
                  <div>
                    <Tag key={Math.random()}>{typeItem?.short_name}</Tag>
                  </div>
                </Tooltip>
              );
            })}
          </div>
        ) : (
          <Tag>
            {info?.is_cdd_company === 1
              ? "尽调公司"
              : type === "竞争对手"
              ? "竞争对手"
              : type}
          </Tag>
        )}

        <div
          className={
            checkCompanyList?.includes(info?.bid_winner)
              ? "activeIconTextBox iconTextBox"
              : "iconTextBox"
          }
        >
          <Tooltip placement="top" title="项目数" arrowPointAtCenter>
            <ReactSVG className="selectItemIcon" src={icon1}></ReactSVG>
          </Tooltip>
          <div className="text">
            {loading ? <LoadingOutlined /> : info?.competitive_count}
          </div>
          <Tooltip placement="top" title="金额" arrowPointAtCenter>
            <PayCircleFilled style={{ margin: "0 5px 0  10px" }} />
          </Tooltip>
          {loading ? (
            <div className="text">
              <LoadingOutlined />
            </div>
          ) : (
            <div className="text">
              {/* {info?.competitive_amount < 10000
                ? info?.competitive_amount
                : parseFloat(info?.competitive_amount / 10000)?.toFixed(2)} */}

              {addMillimeter(
                parseFloat(
                  info?.competitive_amount < 10000
                    ? info?.competitive_amount
                    : info?.competitive_amount / 10000
                )?.toFixed(2)
              )}
              <span>{info?.competitive_amount < 10000 ? "元" : "万元"}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CompanyCard;
