import "./index.scss";
import projectApi from "../../../../../apis/project";
import { useParams } from "react-router-dom";
import {
  message,
  Modal,
  Button,
  Form,
  Input,
  Radio,
  Select,
  Popover,
  Dropdown,
  Menu,
  notification,
  Tooltip,
  Drawer,
  Typography,
  DatePicker,
} from "antd";
import {
  CloseOutlined,
  LoadingOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";

import { useState, useRef, useEffect } from "react";
import G6 from "@antv/g6";
import peopleImg from "./people.png";
import comImg from "./com.png";
import icon1 from "./icon1.svg";
import icon2 from "./批注.svg";
import icon3 from "./all.svg";
import icon4 from "./筛选.svg";

import addIcon from "./add.svg";
import editIcon from "./edit.svg";
import deleteIcon from "./delete.svg";
import actionPersonIcon from "./actionPersonIcon.svg";
import {
  useUnmountedRef,
  useGetState,
  useFullscreen,
  useUnmount,
} from "ahooks";
import MessageModal from "../../../../../components/MessageModal";
import DebounceSelect from "../../../../../components/DebounceSelect";
import PersonAndCompanySelect from "../../../../../components/PersonAndCompanySelect";

import { toleranceValue } from "../../../../../setting";
import {
  InfoCircleOutlined,
} from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import {
  getLicenseValid,
  guid,
  initWrap,
  isExceedDigit,
} from "../../../../../unit/unit";
import { Content } from "../../../../../components/Icons/CommentsIcon/index";
import { useSelector } from "react-redux";
import { Content as InfoContent } from "../../../../../components/Icons/InfoIcon/index";
import MySpin from "../../../../../components/MySpin";

import RulesDrawer from "../RulesDrawer";
import _, { includes, parseInt } from "lodash";
import HeaderTitle from "../../../../../components/HeaderTitle";
import MyTextCheckBox from "../../../../../components/MyTextCheckBox";
import MyRangePicker from "../../../../../components/MyRangePicker";
import moment from "moment";
import regionJson from "../../../../../json/province.json";
import MyEmpty from "../../../../../components/Empty";
import DownLoadIcon from "../../../../../components/Icons/DownLoadIcon";
const { Paragraph } = Typography;

const { Option } = Select;
const Info = (props) => {
  const {
    onClose,
    getData,
    info,
    dataInfo,
    projectId,
    typeName,
    dataTable,
    mode,
  } = props;
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState(null);
  const [equityNodeData, setEquityNodeData] = useState(null);

  const getCommentsInfo = (data_string) => {
    setLoading(true);
    projectApi.getNodeRemark(projectId, data_string).then((res) => {
      if (res.code === 200) {
        setComments(res.data.comments);
      } else {
        message.error(res.message);
        onClose && onClose();
      }
      setLoading(false);
    });
  };

  const initEquityNodeData = () => {
    const obj = {};
    Object.keys(dataInfo || {})?.forEach((key) => {
      obj[key] = dataInfo[key]?.val;
    });
    setEquityNodeData(JSON.stringify(obj));
    getCommentsInfo(JSON.stringify(obj));
  };

  useEffect(() => {
    initEquityNodeData();
  }, []);

  return loading ? (
    <MySpin></MySpin>
  ) : (
    <InfoContent
      onClose={onClose}
      getData={getData}
      info={{ ...info, comments }}
      dataInfo={dataInfo}
      projectId={projectId}
      typeName={typeName}
      dataTable={dataTable}
      mode={mode}
      equityNodeData={equityNodeData}
    ></InfoContent>
  );
};

const Content2 = (props) => {
  const { info, setRulesDrawerVisible, loading } = props;
  const _info = {
    ...info,
  };
  if (info["穿透层级"]) {
    _info["穿透层级"] = {
      value: [`最多${info["穿透层级"]?.value}层`],
    };
  }
  if (info["终止穿透股东的持股比例"]) {
    _info["终止穿透股东的持股比例"] = {
      value: [`持股比例不低于${info["终止穿透股东的持股比例"]?.value}%`],
    };
  }
  const keyList = Object.keys(info || {}).sort((a, b) => {
    return info[a]?.index - info[b]?.index;
  });
  return (
    <div className="ruleContent">
      {keyList.map((key) => (
        <div key={key} className="ruleItem">
          <div className="spot"></div>
          <div>
            <div className="title">{key}</div>
            <div className="textContent">
              {_info[key]?.value?.map((item) => (
                <div key={Math.random()} className="text">
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      {!loading && (
        <div className="ruleButtonBox">
          <Button
            className="ruleButton"
            onClick={() => {
              getLicenseValid().then((res) => {
                if (res) {
                  setRulesDrawerVisible && setRulesDrawerVisible(true);
                }
              });
            }}
          >
            修改穿透规则
          </Button>
        </div>
      )}
    </div>
  );
};

const Charts = (props) => {
  const { setUploadDate } = props;
  const { id } = useParams();
  const unmountedRef = useUnmountedRef();

  const [groupChange, setGroupChange, getGroupChange] = useGetState(false);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [showTip, setShowTip, getShowTip] = useGetState(false);
  const [dataInfo, setDataInfo] = useState(null);
  const [showTipType, setShowTipType] = useState("批注");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [choiceItem, setChoiceItem] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [handleType, setHandleType] = useState("");
  const [type, setType] = useState("");
  const [form] = Form.useForm();
  const [firstLoading, setFirstLoading, getFirstLoading] = useGetState(false);

  const [groupRoot, setGroupRoot, getGroupRoot] = useGetState(false);

  const [
    concertedActionPersonGroupLoading,
    setConcertedActionPersonGroupLoading,
    getConcertedActionPersonGroupLoading,
  ] = useGetState(false);
  const [
    concertedActionPersonGroupInfo,
    setConcertedActionPersonGroupInfo,
    getConcertedActionPersonGroupInfo,
  ] = useGetState([]);
  const [
    concertedActionPersonList,
    setConcertedActionPersonList,
    getConcertedActionPersonList,
  ] = useGetState([]);
  const [
    editConcertedActionPersonList,
    setEditConcertedActionPersonList,
    getEditConcertedActionPersonList,
  ] = useGetState([]);
  const [
    concertedActionPersonGroupRemarks,
    setConcertedActionPersonGroupRemarks,
    getConcertedActionPersonGroupRemarks,
  ] = useGetState("");
  const [
    concertedActionPersonGroupModalVisible,
    setConcertedActionPersonGroupModalVisible,
  ] = useState(false);
  const [
    concertedActionPersonItem,
    setConcertedActionPersonItem,
    getConcertedActionPersonItem,
  ] = useGetState(null);
  const [
    concertedActionPersonGroup,
    setConcertedActionPersonGroup,
    getConcertedActionPersonGroup,
  ] = useGetState(null);
  const [
    concertedActionPersonGroupModalTitle,
    setConcertedActionPersonGroupModalTitle,
  ] = useState("新建一致行动人");
  const [
    concertedActionPersonGroupModalType,
    setConcertedActionPersonGroupModalType,
    getConcertedActionPersonGroupModalType,
  ] = useGetState("新建一致行动人");
  const [editNodes, setEditNodes] = useState([]);
  const [loading, setLoading, getLoading] = useGetState(false);
  const [maxLevel, setMaxLevel, getMaxLevel] = useGetState(0);
  const [hasController, setHasController, getHasController] =
    useGetState(false);
  const [expandOptions, setExpandOptions, getExpandOptions] = useGetState([]);

  const [percentWaring, setPercentWaring] = useState(false);

  const graph = useRef(null);
  const timer = useRef(null);
  const downloadGraph = useRef(null);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [percentProps, setPercentProps] = useState({});

  const [shareholderType, setShareholderType, getShareholderType] =
    useGetState(null);

  const [editLoading, setEditLoading] = useState(false);
  const ref = useRef(null);
  const [isFullscreen, { enterFullscreen, exitFullscreen, toggleFullscreen }] =
    useFullscreen(ref);

  const getDataTimer = useRef(null);

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  const [rulesDrawerVisible, setRulesDrawerVisible] = useState(false);

  const [rulesInfo, setRulesInfo] = useState(null);

  const selectKey = useRef(Math.random());
  const menu = (
    <Menu
      items={[
        {
          key: "当前页面",
          label: (
            <div
              onClick={() => {
                getLicenseValid().then((res) => {
                  if (res) {
                    downloadPicHandle("当前页面");
                  }
                });
              }}
            >
              当前页面
            </div>
          ),
        },
        {
          key: "疑似关联方",
          label: (
            <div
              onClick={() => {
                getLicenseValid().then((res) => {
                  if (res) {
                    downloadPicHandle("仅主要股东和实际控制人");
                  }
                });
              }}
            >
              仅主要股东和实际控制人
            </div>
          ),
        },
        // {
        //   key: "自定义",
        //   label: (
        //     <div
        //       onClick={() => {
        //         downloadPicHandle("自定义");
        //       }}
        //     >
        //       自定义
        //     </div>
        //   ),
        // },
      ]}
    />
  );

  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [filterLevel, setFilterLevel, getFilterLevel] = useGetState(2);
  const [filterDirection, setFilterDirection, getFilterDirection] =
    useGetState("全部");
  const [directionOptions, setDirectionOptions] = useState([
    {
      value: "全部",
      label: "双向展开",
    },
    {
      value: "top",
      label: "向上展开 (仅股东)",
    },
    {
      value: "bottom",
      label: "向下展开(仅投资)",
    },
  ]);
  const [
    filterEnterpriseStatus,
    setFilterEnterpriseStatus,
    getFilterEnterpriseStatus,
  ] = useGetState(["存续", "开业", "吊销", "注销", "迁出", "其他"]);
  const [enterpriseStatusOptions, setEnterpriseStatusOptions] = useState([
    {
      value: "存续",
      label: "存续",
      disabled: true,
    },
    {
      value: "开业",
      label: "开业",
    },
    {
      value: "吊销",
      label: "吊销",
    },
    {
      value: "注销",
      label: "注销",
    },
    {
      value: "迁出",
      label: "迁出",
    },
    {
      value: "其他",
      label: "其他",
    },
  ]);
  const [
    filterEnterpriseType,
    setFilterEnterpriseType,
    getFilterEnterpriseType,
  ] = useGetState([
    "集体企业及社会团体",
    "上市公司",
    "基金公司",
    "股份制有限公司（非上市）",
  ]);
  const [enterpriseTypeOptions, setEnterpriseTypeOptions] = useState([
    {
      value: "集体企业及社会团体",
      label: "集体企业及社会团体",
      // disabled: true,
    },
    {
      value: "上市公司",
      label: "上市公司",
    },
    {
      value: "基金公司",
      label: "基金公司",
    },
    {
      value: "股份制有限公司（非上市）",
      label: "股份制有限公司（非上市）",
    },
    // {
    //   value: "其他",
    //   label: "其他",
    // },
  ]);
  const [
    minShareholdingRatio,
    setMinShareholdingRatio,
    getMinShareholdingRatio,
  ] = useGetState(null);
  const [
    maxShareholdingRatio,
    setMaxShareholdingRatio,
    getMaxShareholdingRatio,
  ] = useGetState(null);

  const [
    minRegisteredCapital,
    setMinRegisteredCapital,
    getMinRegisteredCapital,
  ] = useGetState(null);
  const [
    maxRegisteredCapital,
    setMaxRegisteredCapital,
    getMaxRegisteredCapital,
  ] = useGetState(null);
  const [registrationTime, setRegistrationTime, getRegistrationTime] =
    useGetState([]);
  const [
    registrationStartTime,
    setRegistrationStartTime,
    getRegistrationStartTime,
  ] = useGetState(null);
  const [registrationEndTime, setRegistrationEndTime, getRegistrationEndTime] =
    useGetState(null);
  const [filterRegion, setFilterRegion, getFilterRegion] = useGetState([
    "全部",
  ]);
  const [filterLoading, setFilterLoading, getFilterLoading] =
    useGetState(false);

  const getGroupTimer = useRef();

  // 组件卸载清除定时器
  useUnmount(() => {
    clearInterval(getDataTimer.current);
    clearInterval(timer.current);
    clearTimeout(getGroupTimer.current);
  });

  // 初次进入获取穿透数据接口 ==> 若穿透中则不能编辑,定时器轮训获取最新数据.
  const getDataFunc = (first) => {
    if (first) {
      setFirstLoading(true);
    }
    projectApi
      .getPenetrationInfo(id)
      .then((res) => {
        // 拿到上一次的穿透规则 setRulesInfo()
        setGroupChange(!!res.data?.group_change);
        if (res.message === "穿透计算未完成") {
          message.info("穿透计算未完成");
          return;
        }
        const { down, up, update_time } = res.data;
        if (res.message === "旧版本穿透数据，等待更新") {
          setLoading(true);
          if (!timer.current) {
            timer.current = setInterval(() => {
              console.log("获取");
              getDataFunc();
            }, 10000);
          }
        } else {
          setLoading(false);
          if (timer.current) {
            console.log("清除定时器");
            clearInterval(timer.current);
            timer.current = null;
          }
          if (!first) {
            initData(up || {}, down || {}, true);
          }
        }
        setRulesInfo(res.data?.penetration_rule);
        setUploadDate(update_time);
        if (first) {
          setFirstLoading(false);
          initData(up || {}, down || {}, true);
        }
      })
      .catch((sea) => {
        console.log("获取数据失败", sea);
        message.error("获取数据失败");
      });
  };

  //定时器轮询获取 最新的一致行动人分组数据
  const getConcertedActionPersonGroupInfoFunc = (group) => {
    projectApi.getConcertedActionPersonGroup(id).then((res) => {
      if (res.code === 200) {
        setConcertedActionPersonGroupInfo(res.data);
        if (group) {
          setTimeout(() => {
            choiceGroup(group, true);
          }, 50);
        }
      } else {
        if (group) {
          message.error(res.message);
        }
      }
    });
    if (getGroupTimer.current) {
      clearTimeout(getGroupTimer.current);
      getGroupTimer.current = null;
    }
    getGroupTimer.current = setTimeout(() => {
      getConcertedActionPersonGroupInfoFunc();
    }, 5000);
  };

  useEffect(() => {
    getDataFunc(true);
    getConcertedActionPersonGroupInfoFunc();
  }, []);

  //展开隐藏的节点
  const expandHideNodes = (node) => {
    //停止动画
    graph.current.set("animate", false);
    let item = graph.current.find("node", (item) => {
      const model = item?.getModel();
      return model.id === node.id;
    });
    let parentItem = graph.current.find("node", (item) => {
      const model = item?.getModel();
      return model._id === node.parent_id && model.id === node.parent_key;
    });

    const parentModel = parentItem?.getModel();
    const model = item?.getModel();
    const ellipsisNodeList = JSON.parse(
      JSON.stringify(model?.ellipsisNodeList || [])
    );
    // let children = parentModel.children.filter((_) => _.id !== node.id);
    // children = children.concat(ellipsisNodeList);
    let _children = parentModel._children.filter((_) => _.id !== node.id);
    _children = _children.concat(ellipsisNodeList);
    graph.current.removeChild(node.id);
    (ellipsisNodeList || [])?.forEach((node) => {
      delete node.isEllipsisNode;
      graph.current.addChild(node, parentItem);
    });

    parentItem.update({
      ...parentModel,
      _children: _children,
    });
    setTimeout(() => {
      graph.current.layout();
      graph.current.set("animate", true);
    }, 300);
  };

  // 格式化节点数据 并获取change有值的节点
  const initIdAndGetEditNodes = (
    list,
    direction,
    level,
    parent,
    filterParams
  ) => {
    let editNodes = [];
    let maxLevel = 0;
    let hasController = false;
    let remarkEditNodes = [];
    let concertedActionPersonNodes = [];

    const initId = (list, direction, level, parent) => {
      list = list.map((item, index) => {
        item.level = level;

        item._id = item.id;
        item._label = item.label;
        item.label = null;
        item.parent_key = parent?.id;
        item.parent_id = parent?._id;
        item.parent_type = parent?._label;

        // if (item.percent < 0.02 && level > 1) {
        //   item.isEllipsisNode = true;
        // }

        if (level > maxLevel) {
          maxLevel = level;
        }
        if (item.controller === 1) {
          hasController = true;
        }
        if (direction) {
          item.direction = direction;
        }
        if (!item.percent) {
          item.percent = 0;
        }
        item.id =
          (parent.id || "") + item.id + item._label + (item.direction || "");

        //判断是否隐藏
        //公司层面
        if (item._label === "Company") {
          //判断企业状态
          if (filterParams?.filterEnterpriseStatus?.length > 0) {
            let flag = false;
            filterParams?.filterEnterpriseStatus?.forEach((_) => {
              if ((item.company_status || "")?.includes(_)) {
                flag = true;
              }
            });
            if (!flag) {
              item.isEllipsisNode = true;
            }
          }

          //判断企业类型
          if (filterParams?.filterEnterpriseType?.length > 0) {
            let flag = false;
            filterParams?.filterEnterpriseType?.forEach((_) => {
              if ((item.company_type || "")?.includes(_)) {
                flag = true;
              }
            });
            if (!flag) {
              item.isEllipsisNode = true;
            }
          }

          //判断地区
          if (filterParams?.filterRegion?.length > 0) {
            if (!filterParams?.filterRegion?.includes("全部")) {
              let flag = false;
              filterParams?.filterRegion?.forEach((_) => {
                if ((item.company_base || "")?.includes(_)) {
                  flag = true;
                }
              });
              if (!flag) {
                item.isEllipsisNode = true;
              }
            }
          }

          //判断注册资本
          if (Number(filterParams?.minRegisteredCapital || 0)) {
            if (
              item.company_capital <
              Number(filterParams?.minRegisteredCapital) * 10000
            ) {
              item.isEllipsisNode = true;
            }
          }
          if (Number(filterParams?.maxRegisteredCapital || 0)) {
            if (
              item.company_capital >
              Number(filterParams?.maxRegisteredCapital) * 10000
            ) {
              item.isEllipsisNode = true;
            }
          }

          //判断注册时间
          if (filterParams?.registrationStartTime) {
            if (
              new Date(item.company_estiblish_date).getTime() <
              new Date(filterParams?.registrationStartTime).getTime()
            ) {
              item.isEllipsisNode = true;
            }
          }
          if (filterParams?.registrationEndTime) {
            if (
              new Date(item.company_estiblish_date).getTime() >
              new Date(filterParams?.registrationEndTime).getTime()
            ) {
              item.isEllipsisNode = true;
            }
          }
        }

        //判断持股比例
        if (Number(filterParams?.minShareholdingRatio || 0)) {
          if (
            Number(item.percent || 0) * 100 <
            Number(filterParams?.minShareholdingRatio)
          ) {
            item.isEllipsisNode = true;
          }
        }
        if (Number(filterParams?.maxShareholdingRatio || 0)) {
          if (
            Number(item.percent || 0) * 100 >
            Number(filterParams?.maxShareholdingRatio)
          ) {
            item.isEllipsisNode = true;
          }
        }

        //TODO
        if (item.children) {
          let nodes =
            initId(item.children, item.direction, level + 1, item) || [];
          const _children = nodes.filter(
            (_) => _.change !== "del" && !_.isEllipsisNode
          );

          if (level === 1) {
            const topEllipsisNodeList =
              nodes.filter(
                (_) =>
                  _.change !== "del" &&
                  _.isEllipsisNode &&
                  _.direction === "top"
              ) || [];
            const bottomEllipsisNodeList =
              nodes.filter(
                (_) =>
                  _.change !== "del" &&
                  _.isEllipsisNode &&
                  _.direction === "bottom"
              ) || [];

            if (topEllipsisNodeList?.length > 0) {
              _children.push({
                isEllipsisNode: true,
                direction: "top",
                level: level + 1,
                ellipsisNodeList: JSON.parse(
                  JSON.stringify(topEllipsisNodeList)
                ),
                id: level + 1 + "" + item._id + "topEllipsisNode",
                children: [],
                label: "",
                percent: topEllipsisNodeList?.reduce(
                  (a, b) => b?.percent + a,
                  0
                ),
                parent_key: item.id,
                parent_id: item._id,
                parent_type: item._label,
                open: false,
              });
            }
            if (bottomEllipsisNodeList?.length > 0) {
              _children.push({
                isEllipsisNode: true,
                direction: "bottom",
                level: level + 1,
                ellipsisNodeList: JSON.parse(
                  JSON.stringify(bottomEllipsisNodeList)
                ),
                id: level + 1 + "" + item._id + "bottomEllipsisNode",
                children: [],
                label: "",
                percent: bottomEllipsisNodeList?.reduce(
                  (a, b) => b?.percent + a,
                  0
                ),
                parent_key: item.id,
                parent_id: item._id,
                parent_type: item._label,
                open: false,
              });
            }
          } else {
            const ellipsisNodeList =
              nodes.filter((_) => _.change !== "del" && _.isEllipsisNode) || [];
            if (ellipsisNodeList?.length > 0) {
              _children.push({
                isEllipsisNode: true,
                direction: item.direction,
                name: "",
                level: level + 1,
                ellipsisNodeList: JSON.parse(JSON.stringify(ellipsisNodeList)),
                id: level + 1 + "" + item._id + item.direction + "EllipsisNode",
                children: [],
                label: "",
                percent: ellipsisNodeList?.reduce((a, b) => b?.percent + a, 0),
                parent_key: item.id,
                parent_id: item._id,
                parent_type: item._label,
                open: false,
              });
            }
          }
          item._children = JSON.parse(JSON.stringify(_children));
          //判断子集是否有 实际控制人
          if (
            item._children.some(
              (_) => _.controller === 1 || _.childrenHasController === true
            )
          ) {
            item.childrenHasController = true;
          }
          //展示层级
          if (filterParams?.filterLevel === "controller") {
            item.children =
              item._children.some(
                (_) => _.controller === 1 || _.childrenHasController === true
              ) || level <= 1
                ? item._children
                : [];
          } else {
            let _level =
              filterParams?.filterLevel === "全部"
                ? 100
                : filterParams?.filterLevel || 2;
            item.children = _level > level ? item._children : [];
          }
        }
        //展示方向
        if (filterParams?.filterDirection && item.level === 1) {
          if (filterParams?.filterDirection === "top") {
            item.children = item.children?.filter((_) => _.direction === "top");
          } else if (filterParams?.filterDirection === "bottom") {
            item.children = item.children?.filter(
              (_) => _.direction === "bottom"
            );
          }
        }

        item.open = item?.children?.length > 0;

        if (item.change) {
          item.isEdit = true;
          item.parent_cpercent = parent.cpercent || 0;
          const _item = JSON.parse(JSON.stringify(item));
          editNodes?.push(_item);
        }
        if (item.remark_change) {
          const __item = JSON.parse(JSON.stringify(item));
          remarkEditNodes?.push(__item);
        }
        if (item.group) {
          const __item = JSON.parse(JSON.stringify(item));
          concertedActionPersonNodes?.push(__item);
        }
        return item;
      });
      return list;
    };
    const nodes = initId(list, direction, level, parent);
    return {
      nodes,
      editNodes,
      maxLevel,
      hasController,
      remarkEditNodes,
      concertedActionPersonNodes,
    };
  };

  //输出日志
  const consoleLog = (type, item, _item, line) => {
    const logList = [];
    const cssList = [];
    switch (type) {
      case "cpercentChange":
        logList.push("%c ID为");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${item._id}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c 的");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${item.level - 1}级股东:`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push(`%c ${item.name}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c 股份合计发生,");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${(item.cpercent * 100).toFixed(2)}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c =>");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${(_item * 100).toFixed(2)}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c ,节点ID为");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${item.id}`);
        cssList.push("font-size:14px;font-weight:400;color:red");

        break;
      case "percentChange":
        logList.push("%c ID为");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${item._id}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c 的");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${item.level - 1}级股东:`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push(`%c ${item.name}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c 股份发生,");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${(item.percent * 100).toFixed(2)}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c =>");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${(_item * 100).toFixed(2)}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c ,节点ID为");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${item.id}`);
        cssList.push("font-size:14px;font-weight:400;color:red");

        break;
      case "addChild":
        logList.push("%c ID为");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${item._id}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c 的");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(
          `%c ${item.level - 1}级${
            _item.direction === "top" ? "股东" : "投资"
          }:`
        );
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push(`%c ${item.name}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c  添加 ");
        cssList.push("font-size:16px;font-weight:400;color:red");

        logList.push("%c  了一个 ");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(
          `%c ${_item.level - 1}级${
            _item.direction === "top" ? "股东" : "投资"
          }:`
        );
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push(`%c ${_item.name}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push(
          `%c 新${_item.direction === "top" ? "股东" : "投资"}的ID为`
        );
        cssList.push("font-size:16px;font-weight:500;");

        logList.push(`%c ${_item._id}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c ,节点ID为");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${_item.id}`);
        cssList.push("font-size:14px;font-weight:400;color:red");

        break;
      case "removeChild":
        logList.push("%c ID为");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${item._id}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c 的");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(
          `%c ${item.level - 1}级${
            _item.direction === "top" ? "股东" : "投资"
          }:`
        );
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push(`%c ${item.name}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c  删除 ");
        cssList.push("font-size:16px;font-weight:400;color:red");

        logList.push("%c  了一个 ");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(
          `%c ${_item.level - 1}级${
            _item.direction === "top" ? "股东" : "投资"
          }:`
        );
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push(`%c ${_item.name}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push(
          `%c 删除的${_item.direction === "top" ? "股东" : "投资"}的ID为`
        );
        cssList.push("font-size:16px;font-weight:500;");

        logList.push(`%c ${_item.id}`);
        cssList.push("font-size:16px;font-weight:500;color:blue");

        logList.push("%c ,节点ID为");
        cssList.push("font-size:14px;font-weight:400;");

        logList.push(`%c ${_item.key}`);
        cssList.push("font-size:14px;font-weight:400;color:red");

        break;
      default:
    }
    try {
      console.log(
        "%c ------------------------------------------------",
        "font-size:14px;font-weight:400;"
      );
      console.log(logList.join("") + " ----" + line + "行", ...cssList);
    } catch (err) {
      console.error(err);
    }
  };

  // 更新修改过的节点
  const updateNodes = (editNodes) => {
    editNodes.forEach(async (node) => {
      let item = graph.current.find("node", (item) => {
        const model = item?.getModel();
        return model._id === node._id && model.id === node.id;
      });
      let parentItem = graph.current.find("node", (item) => {
        const model = item?.getModel();
        return model._id === node.parent_id && model.id === node.parent_key;
      });
      let type = null;
      let _node = {};
      //修改
      if (node.change === "mod") {
        //没在界面展示的不更新
        if (!item) {
          if (parentItem) {
            const parentModel = parentItem?.getModel();
            const flag = parentModel._children?.some(
              (_) => _.id === node.id && _.percent !== node.percent
            );
            if (parentModel.cpercent !== node.parent_cpercent || flag) {
              //添加输出日志
              consoleLog(
                "cpercentChange",
                parentModel,
                node.parent_cpercent,
                369
              );
              let _children = JSON.parse(
                JSON.stringify(parentModel._children || [])
              );
              //判断是否再隐藏节点里
              let _ellipsisNode =
                parentModel.children?.find(
                  (_) => _.isEllipsisNode && node?.direction === _.direction
                ) || null;
              //在隐藏节点里  修改隐藏节点
              if (_ellipsisNode) {
                let ellipsisNode = graph.current.find("node", (item) => {
                  const model = item?.getModel();
                  return model.id === _ellipsisNode.id;
                });
                if (ellipsisNode) {
                  const ellipsisNodeModel = ellipsisNode?.getModel();
                  const list = ellipsisNodeModel?.ellipsisNodeList?.map((_) => {
                    if (_._id === node._id && _.id === node.id) {
                      _.percent = Number(node.percent);
                      _.change = _?.change === "add" ? "add" : "mod";
                      _.isEdit = true;
                    }
                    return _;
                  });
                  ellipsisNode.update({
                    ...ellipsisNodeModel,
                    percent: list?.reduce((a, b) => b?.percent + a, 0),
                    ellipsisNodeList: JSON.parse(JSON.stringify(list)),
                  });
                }
              }
              //
              _children = _children.map((_) => {
                if (_._id === node._id && _.id === node.id) {
                  _.percent = Number(node.percent);
                  _.change = _?.change === "add" ? "add" : "mod";
                  _.isEdit = true;
                }
                return _;
              });
              parentItem.update({
                ...parentModel,
                _children: JSON.parse(JSON.stringify(_children)),
                cpercent: node.parent_cpercent,
              });
            }
          }
          return;
        }
        //股权没变的不更新
        if (item.getModel().percent !== node.percent) {
          _node = {
            id: node._id,
            key: node.id,
            parent_cpercent: node.parent_cpercent,
            type: node._label === "Company" ? 1 : 2,
            parent_id: node.parent_id,
            parent_key: node.parent_key,
            parent_type: node.parent_type === "Company" ? 1 : 2,
            up: node.direction === "top" ? 1 : -1,
            percent: node.percent,
          };
          type = "编辑";
        }
      } else if (node.change === "add") {
        //新增
        //父节点没在界面展示的不更新
        if (!parentItem) {
          return;
        }

        //如果本身节点存在
        if (item) {
          //股权没变的不更新
          if (item.getModel().percent !== node.percent) {
            _node = {
              id: node._id,
              key: node.id,
              parent_cpercent: node.parent_cpercent,
              type: node._label === "Company" ? 1 : 2,
              parent_id: node.parent_id,
              parent_key: node.parent_key,
              parent_type: node.parent_type === "Company" ? 1 : 2,
              up: node.direction === "top" ? 1 : -1,
              percent: node.percent,
            };
            type = "编辑";
          }
        } else if (parentItem) {
          let parentItemModel = parentItem.getModel();
          let _childrenLen = parentItemModel?._children?.length;
          let childrenLen = parentItemModel?.children?.length;
          const flag = parentItemModel._children?.some(
            (_) => _.id === node.id && _.percent !== node.percent
          );
          let has = parentItemModel._children?.some((_) => _.id === node.id);
          if (childrenLen > 0 && _childrenLen > 0) {
            //判断是否再隐藏节点里
            let _ellipsisNode =
              parentItemModel.children?.find(
                (_) => _.isEllipsisNode && node?.direction === _.direction
              ) || null;
            //在隐藏节点里  修改隐藏节点
            if (_ellipsisNode) {
              let ellipsisNode = graph.current.find("node", (item) => {
                const model = item?.getModel();
                return model.id === _ellipsisNode.id;
              });
              if (ellipsisNode) {
                const ellipsisNodeModel = ellipsisNode?.getModel();
                const __item = ellipsisNodeModel?.ellipsisNodeList?.find(
                  (_) => _._id === node._id && _.id === node.id
                );
                if (__item) {
                  has = true;
                }
                if (__item?.percent !== node.percent) {
                  const list = ellipsisNodeModel?.ellipsisNodeList?.map((_) => {
                    if (_._id === node._id && _.id === node.id) {
                      _.percent = Number(node.percent);
                      _.change = _?.change === "add" ? "add" : "mod";
                      _.isEdit = true;
                    }
                    return _;
                  });
                  ellipsisNode.update({
                    ...ellipsisNodeModel,
                    percent: list?.reduce((a, b) => b?.percent + a, 0),
                    ellipsisNodeList: JSON.parse(JSON.stringify(list)),
                  });
                }
              }
            }
            if (has) {
              return;
            }
            _node = {
              oper: "add",
              id: node._id,
              name: node.name,
              key: node.id,
              type: node._label === "Company" ? 1 : 2,
              parent_id: node.parent_id,
              parent_key: node.parent_key,
              parent_type: node.parent_type === "Company" ? 1 : 2,
              percent: node.percent,
              up: node.direction === "top" ? 1 : -1,
              direction: node.direction,
              _id: node._id,
              parent_cpercent: node.parent_cpercent,
              level: node.level,
            };
            type = "添加";
          } else if (flag) {
            //添加输出日志
            consoleLog(
              "cpercentChange",
              parentItemModel,
              node.parent_cpercent,
              464
            );
            //
            let _children = JSON.parse(
              JSON.stringify(parentItemModel._children || [])
            );
            _children = _children.map((_) => {
              if (_._id === node._id && _.id === node.id) {
                consoleLog("percentChange", _, node.percent, 475);
                _.percent = Number(node.percent);
                _.change = _?.change === "add" ? "add" : "mod";
                _.isEdit = true;
                //添加输出日志
              }
              return _;
            });
            parentItem.update({
              ...parentItemModel,
              _children: JSON.parse(JSON.stringify(_children)),
              cpercent: node.parent_cpercent,
            });
          } else {
            if (!parentItemModel._children?.some((_) => _.id === node.id)) {
              //添加输出日志
              consoleLog("addChild", parentItemModel, node, 491);
              let _children = JSON.parse(
                JSON.stringify(parentItemModel._children)
              );
              _children.push({
                change: "add",
                isEdit: true,
                children: [],
                cpercent: 0,
                direction: node.direction,
                fpercent: null,
                id: node.id,
                _id: node._id,
                _label: node._label,
                level: node.level,
                name: node.name,
                open: false,
                parent_id: node.parent_id,
                parent_key: node.parent_key,
                parent_type: node.parent_type,
                percent: node.percent,
                _children: [],
              });
              parentItem.update({
                ...parentItemModel,
                _children: JSON.parse(JSON.stringify(_children)),
                cpercent: node.parent_cpercent,
              });
            }
          }
        }
      } else if (node.change === "del") {
        // 删除
        //本身节点在界面展示的才更新
        if (item) {
          _node = {
            oper: "del",
            id: node._id,
            key: node.id,
            type: node._label === "Company" ? 1 : 2,
            parent_id: node.parent_id,
            parent_key: node.parent_key,
            parent_type: node.parent_type === "Company" ? 1 : 2,
            up: node.direction === "top" ? 1 : -1,
            percent: node.percent,
            parent_cpercent: node.parent_cpercent,
            level: node.level,
            name: node.name,
          };
          type = "删除";
        } else if (parentItem) {
          const parentItemModel = parentItem.getModel();
          let flag = parentItemModel._children?.some((_) => _.id === node.id);
          //判断是否再隐藏节点里
          let _ellipsisNode =
            parentItemModel.children?.find(
              (_) => _.isEllipsisNode && node?.direction === _.direction
            ) || null;
          if (_ellipsisNode) {
            let ellipsisNode = graph.current.find("node", (item) => {
              const model = item?.getModel();
              return model.id === _ellipsisNode.id;
            });
            if (ellipsisNode) {
              const ellipsisNodeModel = ellipsisNode?.getModel();
              if (
                ellipsisNodeModel?.ellipsisNodeList?.some(
                  (_) => _.id === node.id
                )
              ) {
                flag = true;
              }
            }
          }
          if (parentItemModel.cpercent !== node.parent_cpercent || flag) {
            //添加输出日志
            consoleLog(
              "cpercentChange",
              parentItemModel,
              node.parent_cpercent,
              546
            );
            flag && consoleLog("removeChild", parentItemModel, node, 552);
            let _children = JSON.parse(
              JSON.stringify(parentItemModel._children)
            );
            _children = _children.filter((_) => _.id !== node.id);

            //在隐藏节点里  修改隐藏节点
            if (_ellipsisNode) {
              let ellipsisNode = graph.current.find("node", (item) => {
                const model = item?.getModel();
                return model.id === _ellipsisNode.id;
              });
              if (ellipsisNode) {
                const ellipsisNodeModel = ellipsisNode?.getModel();
                if (
                  ellipsisNodeModel?.ellipsisNodeList?.some(
                    (_) => _.id === node.id
                  )
                ) {
                  const list = ellipsisNodeModel?.ellipsisNodeList?.filter(
                    (_) => _.id !== node.id
                  );
                  if (list?.length === 0) {
                    graph.current.removeChild(ellipsisNodeModel.id);
                    _children = _children.filter(
                      (_) => _.id !== ellipsisNodeModel.id
                    );
                  } else {
                    ellipsisNode.update({
                      ...ellipsisNodeModel,
                      percent: ellipsisNodeModel?.percent - node.percent,
                      ellipsisNodeList: JSON.parse(JSON.stringify(list)),
                    });
                  }
                }
              }
            }
            //

            parentItem.update({
              ...parentItemModel,
              _children: JSON.parse(JSON.stringify(_children)),
              cpercent: node.parent_cpercent,
            });
          }
        }
      }
      type && editNode(_node, type);
    });
  };

  // 更新批注修改过的节点
  const updateRemarkNodes = (remarkEditNodes) => {
    remarkEditNodes.forEach(async (node) => {
      let item = graph.current.find("node", (item) => {
        const model = item?.getModel();
        return (
          model._id === node._id &&
          model.id === node.id &&
          node.remark !== model.remark
        );
      });
      if (!item) {
        return;
      }
      const model = item?.getModel() || {};
      item.update({
        ...model,
        remark: node.remark,
        remark_change: node.remark_change,
      });
    });
  };

  const choiceGroup = (group, isRoot) => {
    if (group === "新建") {
      let _group = 0;
      for (let i = 1; i < 100; i++) {
        if (!getConcertedActionPersonGroupInfo()?.some((_) => _.group === i)) {
          _group = i;
          break;
        }
      }
      setConcertedActionPersonGroup(_group);
      setConcertedActionPersonList([]);
      setConcertedActionPersonGroupRemarks(null);
      setEditConcertedActionPersonList([]);
      return;
    }
    const item =
      getConcertedActionPersonGroupInfo()?.find((_) => _.group === group) || {};
    setConcertedActionPersonGroup(group);
    setConcertedActionPersonList(item.members);
    setConcertedActionPersonGroupRemarks(item.desc);
    setEditConcertedActionPersonList([]);
    if (isRoot) {
      setGroupRoot(true);
      setConcertedActionPersonGroupModalType("新建一致行动人组");
      setConcertedActionPersonGroupModalTitle("修改一致行动人");
      setTimeout(() => {
        setConcertedActionPersonGroupModalVisible(true);
      }, 50);
    }
  };

  //更新一致行动人修改过的节点
  const updateConcertedActionPersonNodes = (concertedActionPersonNodes) => {
    let allNodes = graph.current.findAll("node", (item) => {
      const model = item?.getModel();
      return model?.group;
    });

    concertedActionPersonNodes?.forEach((node) => {
      let item = graph.current.find("node", (item) => {
        const model = item?.getModel();
        return model._id === node._id && model.id === node.id;
      });
      if (!item) {
        return;
      }
      const model = item?.getModel() || {};
      if (model?.group !== node?.group || model?.gpercent !== node?.gpercent) {
        item.update({
          ...model,
          gpercent: node?.gpercent,
          group: node.group,
        });
      }
      allNodes = allNodes?.filter((_) => _.getModel().id !== node.id);
    });
    if (allNodes?.length > 0) {
      allNodes?.forEach((node) => {
        const model = node?.getModel() || {};
        node.update({
          ...model,
          gpercent: undefined,
          group: undefined,
        });
      });
    }
  };

  //获取序号
  const getSerialNumber = (isRoot) => {
    //最外层展示
    if (isRoot) {
      const list = getConcertedActionPersonGroupInfo().sort(
        (a, b) => Number(a.group) - Number(b.group)
      );
      const showList = list.slice(0, 5) || [];
      const otherList = list.slice(5) || [];
      let menu = null;
      if (otherList?.length > 0) {
        menu = (
          <Menu
            items={otherList?.map((_) => ({
              key: _.group,
              label: (
                <span
                  onClick={() => {
                    if (getLoading()) {
                      return;
                    }
                    getLicenseValid().then((res) => {
                      if (res) {
                        getConcertedActionPersonGroupInfoFunc(_.group);
                      }
                    });
                  }}
                >
                  {_.group}
                </span>
              ),
            }))}
          ></Menu>
        );
      }
      return (
        <>
          {showList?.map((_, index) => {
            return (
              _ && (
                <span
                  key={index}
                  style={{ marginTop: "4px" }}
                  className="activeSpan"
                  onClick={() => {
                    if (getLoading()) {
                      return;
                    }
                    getLicenseValid().then((res) => {
                      if (res) {
                        getConcertedActionPersonGroupInfoFunc(_.group);
                      }
                    });
                  }}
                >
                  {_.group}
                </span>
              )
            );
          })}
          {otherList?.length > 0 && (
            <Dropdown
              overlay={menu}
              placement="bottom"
              overlayClassName="dropdownItemC"
            >
              <div>...</div>
            </Dropdown>
          )}
        </>
      );
    }

    if (getConcertedActionPersonGroupModalType() === "新建一致行动人组") {
      return <span>{getConcertedActionPersonGroup()}</span>;
    } else if (getConcertedActionPersonGroupModalType() === "移除一致行动人") {
      return null;
    } else {
      const list = getConcertedActionPersonGroupInfo()
        ?.filter((_) => _.group !== getConcertedActionPersonItem()?.group)
        .sort((a, b) => Number(a.group) - Number(b.group));
      let max = list?.length - 1;

      let index = list.findIndex(
        (_) => _.group === getConcertedActionPersonGroup()
      );
      index = index === -1 ? 0 : index;
      if (max < 2) {
        max = 2;
      }
      const previousItem = list?.at(
        index === 0 ? 0 : index === max ? index - 2 : index - 1
      );
      const nextItem = list?.at(
        index === 0 ? 2 : index === max ? index : index + 1
      );
      const item = list?.at(
        index === 0 ? 1 : index === max ? index - 1 : index
      );

      let menu = null;
      if (list?.length > 3) {
        menu = (
          <Menu
            items={list
              ?.filter(
                (_) =>
                  _.group !== previousItem.group &&
                  _.group !== item.group &&
                  _.group !== nextItem.group
              )
              ?.map((_) => ({
                key: _.group,
                label: (
                  <span
                    onClick={() => {
                      if (_.group === getConcertedActionPersonGroup()) {
                        return;
                      }
                      choiceGroup(_.group);
                    }}
                  >
                    {_.group}
                  </span>
                ),
              }))}
          ></Menu>
        );
      }
      return (
        <>
          {[previousItem, item, nextItem]?.map((_, index) => {
            return (
              _ && (
                <span
                  key={index}
                  style={{ marginRight: "4px" }}
                  className={
                    _.group === getConcertedActionPersonGroup()
                      ? "activeSpan"
                      : null
                  }
                  onClick={() => {
                    if (_.group === getConcertedActionPersonGroup()) {
                      return;
                    }
                    choiceGroup(_.group);
                  }}
                >
                  {_.group}
                </span>
              )
            );
          })}
          {list?.length > 3 && (
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              overlayClassName="dropdownItemB"
            >
              <div>...</div>
            </Dropdown>
          )}
        </>
      );
    }
  };

  const concertedActionPersonGroupHandleCancel = () => {
    setGroupRoot(false);
    setConcertedActionPersonGroupModalVisible(false);
  };

  //获取一致行动人组
  const getConcertedActionPersonGroupFunc = (group) => {
    setConcertedActionPersonGroupLoading(true);
    projectApi.getConcertedActionPersonGroup(id).then((res) => {
      if (res.code === 200) {
        setConcertedActionPersonGroupInfo(res.data);
        let concertedActionPersonList = [];
        let concertedActionPersonGroupRemarks = null;
        if (group) {
          const item = res.data?.find((_) => _.group === group);
          concertedActionPersonList = item?.members || [];
          concertedActionPersonGroupRemarks = item?.desc || null;
        }
        setConcertedActionPersonList(concertedActionPersonList);
        setConcertedActionPersonGroupRemarks(concertedActionPersonGroupRemarks);
        setEditConcertedActionPersonList([]);
        if (group) {
          setConcertedActionPersonGroup(group);
        } else {
          for (let i = 1; i < 100; i++) {
            if (!res.data?.some((_) => _.group === i)) {
              setConcertedActionPersonGroup(i);
              break;
            }
          }
        }
      } else {
        setConcertedActionPersonGroupModalVisible(false);
        message.error(res.message);
      }
      setConcertedActionPersonGroupLoading(false);
    });
  };

  //添加一致行动人到分组
  const addActionPersonGroupSuccessFunc = (item) => {
    let oldGroup = 0;
    getConcertedActionPersonGroupInfo()?.forEach((_) => {
      _?.members?.forEach((__) => {
        if (
          __?.entity_id === item?.entity_id &&
          __?.name === item?.name &&
          __?.type === item?.type
        ) {
          oldGroup = _.group;
        }
      });
    });
    if (oldGroup) {
      MessageModal({
        message: `${item.name}已经存在于${oldGroup}组内，是否还要添加到该组？`,
        title: "提示",
        onOk: () => {
          setEditConcertedActionPersonList([
            ...getEditConcertedActionPersonList(),
            `${item?.entity_id}_${
              item?.type
            }_${oldGroup}_${getConcertedActionPersonGroup()}`,
          ]);
          setConcertedActionPersonList([
            ...(getConcertedActionPersonList() || []),
            item,
          ]);
        },
      });
      return;
    }
    setEditConcertedActionPersonList([
      ...getEditConcertedActionPersonList(),
      `${item?.entity_id}_${
        item?.type
      }_${0}_${getConcertedActionPersonGroup()}`,
    ]);
    setConcertedActionPersonList([
      ...(getConcertedActionPersonList() || []),
      item,
    ]);
  };

  //修改一致行动人组
  const concertedActionPersonGroupSuccessFunc = () => {
    if (
      getConcertedActionPersonGroupModalType() === "新建一致行动人组" &&
      !getGroupRoot() &&
      getConcertedActionPersonList()?.length < 1
    ) {
      message.error("新建一致行动人组至少需要额外添加一个企业/自然人");
      return;
    }

    let editList = [];
    const info = getConcertedActionPersonItem() || {};

    let code = ``;
    if (getConcertedActionPersonGroupModalType() === "加入一致行动人组") {
      code = `${info?._id}_${
        info?._label === "Person" ? 2 : 1
      }_${0}_${getConcertedActionPersonGroup()}`;
    } else if (
      getConcertedActionPersonGroupModalType() === "移动到一致行动人组"
    ) {
      if (info.group === getConcertedActionPersonGroup()) {
        message.info("请选择需要移动到的一致行动人组序号");
        return;
      }
      code = `${info?._id}_${info?._label === "Person" ? 2 : 1}_${
        info.group || 0
      }_${getConcertedActionPersonGroup()}`;
    } else if (getConcertedActionPersonGroupModalType() === "移除一致行动人") {
      code = `${info?._id}_${info?._label === "Person" ? 2 : 1}_${
        info.group || 0
      }_${0}`;
    }
    editList.push(code);

    if (getConcertedActionPersonGroupModalType() === "新建一致行动人组") {
      editList = getEditConcertedActionPersonList() || [];
      if (!getGroupRoot()) {
        editList.push(
          `${info?._id}_${
            info?._label === "Person" ? 2 : 1
          }_${0}_${getConcertedActionPersonGroup()}`
        );
      }
    }

    projectApi
      .handleConcertedActionPersonGroup(
        id,
        editList?.join(","),
        `${getConcertedActionPersonGroup()}_${
          getConcertedActionPersonGroupRemarks() || ""
        }`,
        getGroupRoot() ? 1 : undefined
      )
      .then((res) => {
        if (res.code === 200) {
          concertedActionPersonGroupHandleCancel();
          getNewData();
        } else {
          message.error(res.message);
        }
      });
  };

  // 获取最新穿透数据并进行合并操作
  const getNewData = (onlyUpdateRemarkNodes) => {
    projectApi.getPenetrationInfo(id).then(async (res) => {
      setGroupChange(!!res.data?.group_change);
      const { up, down, update_time } = res.data;
      const { data, editNodes, remarkEditNodes, concertedActionPersonNodes } =
        initData(up || {}, down || {});
      // console.log("获取最新数据,更新的节点有:", editNodes,getMaxLevel(),getHasController());
      if (!onlyUpdateRemarkNodes) {
        await updateNodes(editNodes || []);
      }
      await updateConcertedActionPersonNodes(concertedActionPersonNodes || []);
      await updateRemarkNodes(remarkEditNodes || []);
      if (res.message === "旧版本穿透数据，等待更新") {
        setLoading(true);
        if (!timer.current) {
          timer.current = setInterval(() => {
            console.log("获取");
            getDataFunc();
          }, 10000);
        }
        clearInterval(getDataTimer.current);
        getDataTimer.current = null;
      }
      setUploadDate(update_time);
    });
  };

  // 格式化穿透数据并渲染图表
  const initData = (
    shareholderData,
    investmentData,
    isRender,
    filterParams
  ) => {
    if (unmountedRef.current) {
      console.log("组件已经卸载");
      return;
    }
    let data = {
      level: 1,
      open: true,
    };
    let keys = Object.keys(shareholderData);
    if (keys.length === 0) {
      keys = Object.keys(investmentData);
    }
    keys.forEach((key) => {
      if (key !== "children") {
        data[key] = shareholderData[key] || investmentData[key];
      } else {
        let investmentChildren = (investmentData?.children || []).map((_) => {
          _.direction = "bottom";
          return _;
        });
        let shareholderChildren = (shareholderData?.children || []).map((_) => {
          _.direction = "top";
          return _;
        });
        data.children = [...investmentChildren, ...shareholderChildren];
      }
    });
    const {
      nodes,
      editNodes,
      maxLevel,
      hasController,
      remarkEditNodes,
      concertedActionPersonNodes,
    } = initIdAndGetEditNodes([data], null, 1, {}, filterParams);

    data = nodes?.at(0);
    setMaxLevel(maxLevel);
    setHasController(hasController);
    setEditNodes(editNodes);
    //需要渲染
    if (isRender) {
      if (keys.length !== 0) {
        initCharts(data);
      }
      if (getDataTimer.current) {
        clearInterval(getDataTimer.current);
        getDataTimer.current = null;
      }
      getDataTimer.current = setInterval(() => {
        getNewData();
      }, 5000);
    }
    return {
      data,
      editNodes,
      remarkEditNodes,
      concertedActionPersonNodes,
    };
  };

  //递归边 改变样式
  const edgeUpdateFunc = (node) => {
    const model = node.getModel();
    //截止到一级节点(尽调公司)
    if (model?.level === 1) {
      return;
    }
    const edges = node.getInEdges();
    edges.forEach((edge) => {
      graph.current.setItemState(edge, "hover", true);
      edge.toFront();
      const newNode = edge.getSource();
      edgeUpdateFunc(newNode);
    });
  };

  //边 添加 取消样式的方法
  const edgeActiveFunc = (node, flag) => {
    if (flag) {
      edgeUpdateFunc(node);
    } else {
      const allEdges = graph.current.getEdges();
      allEdges.forEach((edge) => {
        graph.current.setItemState(edge, "hover", false);
        edge.toBack();
      });
    }
  };

  // 渲染图表的函数
  const initCharts = (data, isDownLoad) => {
    if (unmountedRef.current) {
      console.log("组件已经卸载");
      return;
    }
    const tooltip = new G6.Tooltip({
      offsetX: 10,
      offsetY: 10,
      // the types of items that allow the tooltip show up
      // 允许出现 tooltip 的 item 类型
      itemTypes: ["node", "edge"],
      // custom the tooltip's content
      // 自定义 tooltip 内容
      getContent: (e) => {
        const outDiv = document.createElement("div");
        outDiv.style.width = "fit-content";
        if (
          [
            "编辑",
            "添加",
            "删除",
            "行动",
            "批注列表",
            "批注",
            "collapse-icon",
          ]?.includes(e.target?.cfg?.name)
        ) {
          let type = "";
          if (e.target?.cfg?.name === "编辑") {
            type = "修改";
          } else if (e.target?.cfg?.name === "添加") {
            type = "新增";
          } else if (e.target?.cfg?.name === "删除") {
            type = "删除";
          } else if (e.target?.cfg?.name === "批注列表") {
            type = "历史批注";
          } else if (e.target?.cfg?.name === "行动") {
            type = "一致行动人";
          } else if (e.target?.cfg?.name === "批注") {
            type = "批注";
          } else if (e.target?.cfg?.name === "collapse-icon") {
            let model = e.item?.getModel();
            type = model?.children?.length > 0 ? "收起" : "展开";
          }
          outDiv.innerHTML = `${type}`;
          return outDiv;
        }
        let model = e.item?.getModel();
        if (e.item.getType() === "edge") {
          let item = graph.current?.find("node", (item) => {
            const model = item?.getModel();
            return model.id === e.item?.getModel().target;
          });
          model = item?.getModel();
        }
        if (
          e.target?.cfg?.name === "action-circle" ||
          e.target?.cfg?.name === "action-text"
        ) {
          outDiv.style.margin = "0px";
          // outDiv.style.background = "#000";
          outDiv.userSelect = "none";
          outDiv.style.borderRadius = "4px";
          // outDiv.style.color = "#000";
          outDiv.style.padding = "4px";
          outDiv.style.display = "flex";
          outDiv.style.alignItems = "center";
          outDiv.innerHTML = `
          一致行动人组 <span style='margin-left:4px;display: flex;align-items: center;justify-content: center; width: 16px;height: 16px;border: 1px solid #313131;border-radius: 50%;font-size: 12px;font-weight: 700;transform:scale(0.8)'>${
            model.group
          }</span>（${
            (model?.gpercent || 0) * 100
              ? ((model?.gpercent || 0) * 100)?.toFixed(2)
              : 0
          }%）`;
          return outDiv;
        }

        //outDiv.style.padding = '0px 0px 20px 0px';
        outDiv.innerHTML = `
          <div>
            <li>${
              e.item.getType() === "edge" ? "持股比例" : "最终收益股份"
            } ${parseFloat(
          (
            Number(
              (e.item.getType() === "edge" ? model.percent : model.fpercent) ||
                0
            ) * 100
          ).toPrecision(14)
        )}%</li>
          </div>`;
        return outDiv;
      },
      shouldBegin: (e) => {
        const percent =
          e.target?.attrs?.text?.toString()?.replace(/[^\d.]/g, "") || 0;
        let res = false;
        switch (e.target.get("name")) {
          case "批注列表":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "批注":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "编辑":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "添加":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "删除":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "行动":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "collapse-icon":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;

          case "action-circle":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "action-text":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "text1":
            res = e.target?.attrs?.text?.includes("-")
              ? false
              : !parseFloat(percent);
            break;
          case "percentText":
            res = !parseFloat(percent);
            break;
          default:
            res = false;
            break;
        }
        return res;
      },
    });

    const COLLAPSE_ICON = function COLLAPSE_ICON(x, y, r) {
      return [
        ["M", x - r, y],
        ["a", r, r, 0, 1, 0, r * 2, 0],
        ["a", r, r, 0, 1, 0, -r * 2, 0],
        ["M", x + 4, y],
        ["L", x - 4, y],
      ];
    };
    const EXPAND_ICON = function EXPAND_ICON(x, y, r) {
      return [
        ["M", x - r, y],
        ["a", r, r, 0, 1, 0, r * 2, 0],
        ["a", r, r, 0, 1, 0, -r * 2, 0],
        ["M", x + 4, y],
        ["L", x - 4, y],
        ["M", x, y + 4],
        ["L", x, y - 4],
      ];
    };
    G6.registerNode(
      "icon-node",
      {
        options: {
          size: [120, 20],
          stroke: "#91d5ff",
          fill: "#91d5ff",
        },
        draw(cfg, group) {
          const styles = this.getShapeStyle(cfg);
          const { labelCfg = {} } = cfg;
          const notTop = cfg.direction === "bottom";
          const notRoot = cfg.level !== 1;
          const w = styles.width;
          const h = styles.height;
          if (!cfg.name) {
            cfg.name = "       ";
          }
          const keyShape = group.addShape("rect", {
            attrs: {
              ...styles,
              x: -w / 2,
              y: -h / 2,
              // stroke: "black",
            },
            name: "key-shape",
          });

          // 整体区域
          const rectGroup = group.addGroup({
            name: "rect-box",
          });

          if (cfg?.isEllipsisNode && notRoot) {
            rectGroup.addShape("circle", {
              attrs: {
                x: 0,
                y: (notTop ? 30 : 40) - h / 2,
                r: 20,
                fill: "#0068B2",
                cursor: "pointer",
              },
              name: "image-circle",
            });

            rectGroup.addShape("text", {
              attrs: {
                fill: "#fff",
                fontSize: 16,
                textAlign: "center",
                text: `+${cfg?.ellipsisNodeList?.length}`,
                y: notTop ? -16 : -6,
                cursor: "pointer",
              },
              name: "ellipsis-number",
            });

            rectGroup.addShape("text", {
              attrs: {
                fill: "#000",
                fontSize: 14,
                textAlign: "center",
                text: `...`,
                x: 0,
                y: (notTop ? 67 : 77) - h / 2 + 5 + (notTop ? 10 : 0),
                cursor: "pointer",
              },
            });

            return keyShape;
          }
          rectGroup.addShape("rect", {
            attrs: {
              ...styles,
              fill: "#fff",
              stroke: "#fff",
              x: -w / 2,
              y: -h / 2,
            },
          });

          if (!isDownLoad) {
            if (notRoot) {
              //一级以为的元素 展开关闭按钮
              rectGroup.addShape("marker", {
                attrs: {
                  x: 0,
                  y: notTop ? h / 2 - 6 : 15 - h / 2 + 2 - 6,
                  r: 6,
                  stroke: "#0068B2",
                  cursor: cfg._children?.length > 0 ? "pointer" : null,
                  opacity: cfg._children?.length > 0 ? 1 : 0,
                  symbol:
                    cfg.children?.length > 0 ? COLLAPSE_ICON : EXPAND_ICON,
                },
                name: "collapse-icon",
              });
            } else {
              const topAllChildren = cfg._children?.filter(
                (_) => _.direction === "top"
              );
              const bottomAllChildren = cfg._children?.filter(
                (_) => _.direction === "bottom"
              );
              const topChildren = cfg.children?.filter(
                (_) => _.direction === "top"
              );
              const bottomChildren = cfg.children?.filter(
                (_) => _.direction === "bottom"
              );
              //一级元素 上半部分展开关闭按钮
              rectGroup.addShape("marker", {
                attrs: {
                  x: 0,
                  y: 16 - h / 2 - 6,
                  r: 6,
                  stroke: "#0068B2",
                  cursor: topAllChildren?.length > 0 ? "pointer" : null,
                  opacity: topAllChildren?.length > 0 ? 1 : 0,
                  symbol: topChildren?.length > 0 ? COLLAPSE_ICON : EXPAND_ICON,
                },
                name: "root-top-collapse-icon",
              });
              //一级元素 下半部分展开关闭按钮
              rectGroup.addShape("marker", {
                attrs: {
                  x: 0,
                  y: h / 2 - 5 - 6,
                  r: 6,
                  stroke: "#0068B2",
                  cursor: bottomAllChildren?.length > 0 ? "pointer" : null,
                  opacity: bottomAllChildren?.length > 0 ? 1 : 0,
                  symbol:
                    bottomChildren?.length > 0 ? COLLAPSE_ICON : EXPAND_ICON,
                },
                name: "root-bottom-collapse-icon",
              });
            }
          }

          //图像logo
          let imgFill = cfg._label === "Company" ? "#AAD461" : "#FEC054";
          if (cfg.controller === 1) {
            imgFill = "#FE545F";
          }
          // if (cfg.isEdit) {
          //   imgFill = "red";
          // }
          rectGroup.addShape("circle", {
            attrs: {
              name: "image-circle",
              x: 0,
              y: (notTop ? 30 : 42) - h / 2,
              r: notRoot ? 20 : 22,
              fill: notRoot ? imgFill : "#0068B2",
            },
            name: "image-circle",
          });
          rectGroup.addShape("image", {
            attrs: {
              x: notRoot ? -13 : -15,
              y: (notTop ? 16 : notRoot ? 26 : 25) - h / 2,
              width: notRoot ? 26 : 30,
              height: notRoot ? 26 : 30,
              img: cfg._label === "Company" ? comImg : peopleImg,
            },
            name: "image-shape",
          });
          //名称是否换行
          let isLineFeed = false;
          //公司名称
          if (cfg.name) {
            let max = 12;
            if (cfg.name?.length > 24) {
              max = Math.round(cfg.name?.length / 2);
            }
            isLineFeed = cfg.name?.length > max;
            const name = isLineFeed
              ? cfg.name?.slice(0, max) + "\n" + cfg.name?.slice(max)
              : cfg.name;
            const len = isLineFeed ? max : cfg.name?.length;
            if (notRoot) {
              rectGroup.addShape("text", {
                attrs: {
                  ...labelCfg.style,
                  text: name,
                  textAlign: "center",
                  x: 0,
                  y:
                    (notTop ? 67 : 77) -
                    h / 2 +
                    (isLineFeed ? 11 : 5) +
                    (notTop ? 10 : 0),
                },
              });
            } else {
              rectGroup.addShape("text", {
                attrs: {
                  fill: "#0068B2",
                  fontSize: 14,
                  text: cfg.name,
                  textAlign: "center",
                  x: 0,
                  y: 84 - h / 2,
                  // stroke: 'black'
                },
              });
            }
          }

          if (notRoot && !notTop) {
            //最终收益股份
            rectGroup.addShape("text", {
              attrs: {
                fill: "#0068B2",
                fontSize: 12,
                text:
                  cfg.fpercent === null || cfg.fpercent === undefined
                    ? "最终收益股份-%"
                    : `最终收益股份${Number(cfg.fpercent * 100).toFixed(2)}%`,
                x:
                  0 -
                  (cfg.fpercent === null || cfg.fpercent === undefined
                    ? 7
                    : 9) *
                    6,
                y: (notTop ? 85 : 95) - h / 2 + 10,
              },
              name: "text1",
            });
          }
          //一致行动人
          // const actionGroup = group.addGroup({
          //   name: "action-box",
          // });
          rectGroup.addShape("circle", {
            attrs: {
              name: "action-circle",
              x: 30,
              y: notTop ? -40 : -30,
              r: 7,
              stroke: cfg.controller === 1 ? "#FE545F" : "#0068B2",
              opacity: cfg.group ? 1 : 0,
              fill: "#fff",
            },
            name: "action-circle",
          });
          rectGroup.addShape("text", {
            attrs: {
              fill: cfg.controller === 1 ? "#FE545F" : "#0068B2",
              fontSize: 10,
              textAlign: "center",
              text: cfg.group,
              x: 30,
              y: notTop ? -35 : -25,
              opacity: cfg.group ? 1 : 0,
            },
            name: "action-text",
          });
          //
          let iconList = ["编辑", "添加", "删除", "行动"]; // "批注列表", ,
          if (
            cfg._label !== "Company" ||
            cfg?.change === "add" ||
            cfg.stop ||
            cfg.level >= 11
          ) {
            iconList = ["编辑", "删除", "行动"];
          }
          if (!["add", "delete"].includes(cfg.change)) {
            iconList.push("批注");
            iconList.unshift("批注列表");
          }
          // if(cfg.has1){
          //   iconList.unshift('批注列表');
          //   iconList.push('批注')
          // }
          if (!notRoot) {
            iconList = ["添加"]; //cfg.stop?[]:["添加"];
          }
          if (
            !projectInfo?.project_permission_urls.includes("CDD_PRJ_COMMENT")
          ) {
            iconList = iconList.filter((_) => _ !== "批注");
          }
          if (
            !projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EDIT"
            )
          ) {
            iconList = iconList.filter(
              (_) =>
                _ !== "编辑" && _ !== "删除" && _ !== "添加" && _ !== "行动"
            );
          }
          iconList.forEach((iconItem, index) => {
            if (
              !["add", "delete"].includes(cfg.change) &&
              notRoot &&
              !cfg.remark
            ) {
              index = index - 1;
            }
            if (iconItem === "批注列表") {
              rectGroup.addShape("image", {
                attrs: {
                  x: (notRoot ? 25 : 35) + index * 15,
                  y: (notTop ? 30 : 42) - h / 2 - 6,
                  width: 12,
                  height: 12,
                  opacity: cfg.remark ? 1 : 0,
                  cursor: cfg.remark ? "pointer" : "default",
                  img: icon1,
                },
                zIndex: 999,
                name: "批注列表",
              });
            } else if (iconItem === "批注") {
              rectGroup.addShape("image", {
                attrs: {
                  x: (notRoot ? 25 : 35) + index * 15,
                  y: (notTop ? 30 : 42) - h / 2 - 5,
                  width: 10,
                  height: 10,
                  opacity: 0,
                  cursor: "pointer",
                  img: icon2,
                },
                zIndex: 999,
                name: "批注",
              });
            } else if (iconItem === "编辑") {
              rectGroup.addShape("image", {
                attrs: {
                  x: (notRoot ? 25 : 35) + index * 15,
                  y: (notTop ? 30 : 42) - h / 2 - 6,
                  width: 12,
                  height: 12,
                  opacity: 0,
                  cursor: "pointer",
                  img: editIcon,
                },
                zIndex: 990,
                name: "编辑",
              });
            } else if (iconItem === "添加") {
              rectGroup.addShape("image", {
                attrs: {
                  x: (notRoot ? 25 : 35) + index * 15,
                  y: (notTop ? 30 : 42) - h / 2 - 6,
                  width: 12,
                  height: 12,
                  opacity: 0,
                  cursor: "pointer",
                  img: addIcon,
                },
                zIndex: 999,
                name: "添加",
              });
            } else if (iconItem === "删除") {
              rectGroup.addShape("image", {
                attrs: {
                  x: (notRoot ? 25 : 35) + index * 15,
                  y: (notTop ? 30 : 42) - h / 2 - 6,
                  width: 12,
                  height: 12,
                  opacity: 0,
                  cursor: "pointer",
                  img: deleteIcon,
                },
                zIndex: 999,
                name: "删除",
              });
            } else if (iconItem === "行动") {
              rectGroup.addShape("image", {
                attrs: {
                  x: (notRoot ? 25 : 35) + index * 15,
                  y: (notTop ? 30 : 42) - h / 2 - 6,
                  width: 12,
                  height: 12,
                  opacity: 0,
                  cursor: "pointer",
                  img: actionPersonIcon,
                },
                zIndex: 999,
                name: "行动",
              });
            }
          });

          rectGroup.addShape("rect", {
            attrs: {
              height: 12,
              width: 12 * iconList?.length,
              fill: "#ffffff",
              x: notRoot ? 25 : 35,
              y: (notTop ? 30 : 42) - h / 2 - 6,
              opacity: 0,
              radius: [1],
            },
            zIndex: 888,
            name: "icon-bg-rect",
          });
          // 疑似实际控制人
          if (cfg.controller === 1) {
            const nameLen = cfg.name.length > 2 ? cfg.name.length : 3;
            rectGroup.addShape("rect", {
              attrs: {
                fill: "#6CAEFF",
                stroke: "#6CAEFF",
                radius: 2,
                zIndex: 999,
                x: -110,
                y: (notTop ? 32 : 42) - h / 2 - 15 + 5,
                width: 80,
                height: 15,
              },
            });
            rectGroup.addShape("polygon", {
              attrs: {
                points: [
                  [-30, (notTop ? 32 : 42) - h / 2 + 2 - 7 + 5],
                  [-30, (notTop ? 32 : 42) - h / 2 - 2 - 7 + 5],
                  [-30 + 6, (notTop ? 32 : 42) - h / 2 - 7 + 5],
                ],
                fill: "#6CAEFF",
              },
            });
            rectGroup.addShape("text", {
              attrs: {
                fill: "#fff",
                fontSize: 10,
                text: "疑似实际控制人",
                x: -110 + 5,
                y: (notTop ? 32 : 42) - h / 2 - 2 + 5,
              },
            });
          }
          //公司性质
          if (cfg.company_tag) {
            const max = 7;
            const _isLineFeed = cfg.company_tag?.length > max;
            const len = _isLineFeed ? max : cfg.company_tag?.length;
            const width = len * 12;
            const { str, line } = initWrap(cfg.company_tag, max);
            const company_tag = str;
            let _x = isLineFeed ? 75 : (cfg.name?.length / 2) * 12;
            if (_x < 24) {
              _x = 24;
            }
            rectGroup.addShape("rect", {
              attrs: {
                fill: "#FE545F",
                stroke: "#FE545F",
                radius: 2,
                x: _x,
                y:
                  (notTop ? 72 : 77) -
                  h / 2 -
                  (isLineFeed ? 35 : 30) -
                  (line - 1) * 15,
                width: width,
                height: line * 15,
              },
              zIndex: 11,
            });

            rectGroup.addShape("polygon", {
              attrs: {
                points: [
                  [
                    _x + 10 - 0.5,
                    (notTop ? 72 : 77) - h / 2 - (isLineFeed ? 35 : 30) + 3 + 8,
                  ],
                  [
                    _x - 0.5 - 2,
                    (notTop ? 72 : 77) - h / 2 - (isLineFeed ? 35 : 30) + 9 + 8,
                  ],
                  [
                    _x - 0.5,
                    (notTop ? 72 : 77) - h / 2 - (isLineFeed ? 35 : 30) + 3 + 8,
                  ],
                ],
                fill: "#FE545F",
              },
              zIndex: 11,
            });

            rectGroup.addShape("text", {
              attrs: {
                fill: "#fff",
                fontSize: 10,
                text: company_tag,
                x: _x + len,
                y:
                  (notTop ? 72 : 77) -
                  h / 2 -
                  (isLineFeed ? 35 : 30) +
                  line * 13 -
                  (line - 1) * 15,
              },
              zIndex: 11,
            });
          }

          //公司状态
          // if (cfg.name.includes("公司") && notRoot) {
          //   const max = 14;
          //   const isLineFeed = cfg.name?.length > max;
          //   const len = isLineFeed ? max : cfg.name?.length;

          //   const type = "已注销";
          //   const width = type.length * 15;
          //   rectGroup.addShape("rect", {
          //     attrs: {
          //       fill: "#FE545F",
          //       stroke: "#FE545F",
          //       radius: 2,
          //       x: (len / 2) * 6 + width,
          //       y: (notTop ? 67 : 77) - h / 2 - 30,
          //       width: width,
          //       height: 15,
          //     },
          //   });

          //   rectGroup.addShape("polygon", {
          //     attrs: {
          //       points: [
          //         [
          //           (len / 2) * 6 + width + 10 - 0.5,
          //           (notTop ? 67 : 77) - h / 2 - 30 + 3 + 9,
          //         ],
          //         [
          //           (len / 2) * 6 + width - 0.5 - 2,
          //           (notTop ? 67 : 77) - h / 2 - 30 + 9 + 9,
          //         ],
          //         [
          //           (len / 2) * 6 + width - 0.5,
          //           (notTop ? 67 : 77) - h / 2 - 30 + 3 + 9,
          //         ],
          //       ],
          //       fill: "#FE545F",
          //     },
          //   });

          //   rectGroup.addShape("text", {
          //     attrs: {
          //       fill: "#fff",
          //       fontSize: 10,
          //       text: type,
          //       x: (len / 2) * 6 + width + 5,
          //       y: (notTop ? 67 : 77) - h / 2 - 17,
          //     },
          //   });
          // }
          //股份合计不等于100%
          // if (notRoot) {
          rectGroup.addShape("rect", {
            attrs: {
              fill: "#FE545F",
              stroke: "#FE545F",
              radius: 2,
              x: notRoot ? 15 : 30,
              y: notTop ? h / 2 + 2 - 15 : 15 - h / 2 - 15,
              width: 110,
              height: 15,
              opacity:
                cfg._children?.length > 0 &&
                (cfg.cpercent > 1 + toleranceValue ||
                  cfg.cpercent < 1 - toleranceValue) &&
                (cfg.direction === "top" || cfg.level === 1)
                  ? 1
                  : 0,
            },
            name: "shares-rect",
          });
          rectGroup.addShape("polygon", {
            attrs: {
              points: [
                [
                  notRoot ? 15 : 30,
                  (notTop ? h / 2 + 2 - 15 : 15 - h / 2 - 15) + 2 + 7,
                ],
                [
                  notRoot ? 15 : 30,
                  (notTop ? h / 2 + 2 - 15 : 15 - h / 2 - 15) - 2 + 7,
                ],
                [
                  (notRoot ? 15 : 30) - 6,
                  (notTop ? h / 2 + 2 - 15 : 15 - h / 2 - 15) + 7,
                ],
              ],
              fill: "#FE545F",
              opacity:
                cfg._children?.length > 0 &&
                (cfg.cpercent > 1 + toleranceValue ||
                  cfg.cpercent < 1 - toleranceValue) &&
                (cfg.direction === "top" || cfg.level === 1)
                  ? 1
                  : 0,
            },
            name: "shares-polygon",
          });

          rectGroup.addShape("text", {
            attrs: {
              fill: "#fff",
              fontSize: 10,
              text: `当前股份合计${Number(cfg.cpercent * 100).toFixed(2)}%`,
              x: notRoot ? 20 : 35,
              y: notTop ? h / 2 + 2 - 2 : 15 - h / 2 - 2,
              opacity:
                cfg._children?.length > 0 &&
                (cfg.cpercent > 1 + toleranceValue ||
                  cfg.cpercent < 1 - toleranceValue) &&
                (cfg.direction === "top" || cfg.level === 1)
                  ? 1
                  : 0,
            },
            name: "shares-text",
          });

          rectGroup.sort();
          // }
          return keyShape;
        },
        update(cfg, node) {
          const model = node?.getModel();
          if (model.isEllipsisNode) {
            const edges = node.getInEdges();
            const keyShape = edges?.at(0)?.getKeyShape();
            keyShape?.attr("fill", "#A342B6");
            const percent = model?.ellipsisNodeList?.reduce(
              (a, b) => b?.percent + a,
              0
            );
            keyShape?.attr("text", Number(percent * 100).toFixed(2) + "%");

            const group = node.getContainer();
            const sharesRect = group.find(
              (e) => e.get("name") === "ellipsis-number"
            );
            sharesRect?.attr("text", `+${model?.ellipsisNodeList?.length}`);
            return;
          }
          if (model.level === 1) {
            const group = node.getContainer();
            const shareholderList = model.children?.filter(
              (_) => _.direction === "top"
            );
            if (
              shareholderList?.length > 0 &&
              (model.cpercent > 1 + toleranceValue ||
                model.cpercent < 1 - toleranceValue)
            ) {
              const sharesRect = group.find(
                (e) => e.get("name") === "shares-rect"
              );
              sharesRect.attr("opacity", 1);
              const sharesPolygon = group.find(
                (e) => e.get("name") === "shares-polygon"
              );
              sharesPolygon.attr("opacity", 1);
              const sharesText = group.find(
                (e) => e.get("name") === "shares-text"
              );
              sharesText.attr(
                "text",
                `当前股份合计${Number(model.cpercent * 100).toFixed(2)}%`
              );
              sharesText.attr("opacity", 1);
            } else {
              const sharesRect = group.find(
                (e) => e.get("name") === "shares-rect"
              );
              sharesRect.attr("opacity", 0);
              const sharesPolygon = group.find(
                (e) => e.get("name") === "shares-polygon"
              );
              sharesPolygon.attr("opacity", 0);
              const sharesText = group.find(
                (e) => e.get("name") === "shares-text"
              );
              sharesText.attr("opacity", 0);
            }
            return;
          }
          //一致行动人
          const _group = node.getContainer();
          const actionCircle = _group.find(
            (e) => e.get("name") === "action-circle"
          );
          const actionText = _group.find(
            (e) => e.get("name") === "action-text"
          );
          if (model.group) {
            if (actionText?.attr("text") !== model.group) {
              actionText?.attr("text", model.group);
            }
            if (actionCircle?.attr("opacity") === 0) {
              actionCircle?.attr("opacity", 1);
              actionText?.attr("opacity", 1);
            }
          } else {
            if (actionCircle?.attr("opacity") === 1) {
              actionCircle?.attr("opacity", 0);
              actionText?.attr("opacity", 0);
            }
          }
          //
          if (model.isEdit) {
            const edges = node.getInEdges();
            const keyShape = edges?.at(0)?.getKeyShape();
            keyShape?.attr("fill", "#A342B6");
            keyShape?.attr(
              "text",
              Number(model.percent * 100).toFixed(2) + "%"
            );
            // const container = node.getContainer();
            // const imageCircle = container.find(
            //   (_) => _?.attr("name") === "image-circle"
            // );
            // imageCircle?.attr("fill", "red");
          }
          if (model.children?.length > 0) {
            const group = node.getContainer();
            const icon = group.find((e) => e.get("name") === "collapse-icon");
            icon.attr("opacity", 1);
            icon.attr("cursor", "pointer");
            if (model.open) {
              icon.attr("symbol", COLLAPSE_ICON);
            } else {
              icon.attr("symbol", EXPAND_ICON);
            }
          }
          if (model.direction === "top") {
            const group = node.getContainer();
            if (
              (model.cpercent > 1 + toleranceValue ||
                model.cpercent < 1 - toleranceValue) &&
              model.cpercent > 0
            ) {
              const sharesRect = group.find(
                (e) => e.get("name") === "shares-rect"
              );
              sharesRect.attr("opacity", 1);
              const sharesPolygon = group.find(
                (e) => e.get("name") === "shares-polygon"
              );
              sharesPolygon.attr("opacity", 1);
              const sharesText = group.find(
                (e) => e.get("name") === "shares-text"
              );
              sharesText.attr(
                "text",
                `当前股份合计${Number(model.cpercent * 100).toFixed(2)}%`
              );
              sharesText.attr("opacity", 1);
            } else {
              const sharesRect = group.find(
                (e) => e.get("name") === "shares-rect"
              );
              sharesRect.attr("opacity", 0);
              const sharesPolygon = group.find(
                (e) => e.get("name") === "shares-polygon"
              );
              sharesPolygon.attr("opacity", 0);
              const sharesText = group.find(
                (e) => e.get("name") === "shares-text"
              );
              sharesText.attr("opacity", 0);
            }
          }
          if (model._children?.length === 0) {
            const group = node.getContainer();
            const icon = group.find((e) => e.get("name") === "collapse-icon");
            icon?.attr("opacity", 0);
            const sharesRect = group.find(
              (e) => e.get("name") === "shares-rect"
            );
            sharesRect?.attr("opacity", 0);
            const sharesPolygon = group.find(
              (e) => e.get("name") === "shares-polygon"
            );
            sharesPolygon?.attr("opacity", 0);
            const sharesText = group.find(
              (e) => e.get("name") === "shares-text"
            );
            sharesText?.attr("opacity", 0);
          } else {
            const group = node.getContainer();
            const icon = group.find((e) => e.get("name") === "collapse-icon");
            icon.attr("opacity", 1);
            if (model.children?.length == 0) {
              icon.attr("symbol", EXPAND_ICON);
            } else {
              icon.attr("symbol", COLLAPSE_ICON);
            }
          }

          // console.log(model);
          if (
            model.level !== 1 &&
            !["add", "delete"].includes(model.change) &&
            model.remark_change
          ) {
            const group = node.getContainer();
            const sharesRect = group.find((e) => e.get("name") === "批注列表");
            if (!sharesRect) {
              return;
            }
            const has = sharesRect?.attr("opacity") === 1;
            if (model.remark !== has) {
              let list = ["编辑", "添加", "删除", "行动"]; // "批注列表", ,
              if (
                model._label !== "Company" ||
                model?.change === "add" ||
                model.stop ||
                model.level >= 11
              ) {
                list = ["编辑", "删除", "行动"];
              }
              list.push("批注");
              list.unshift("批注列表");
              list.forEach(async (_, index) => {
                if (!model.remark) {
                  index = index - 1;
                }
                const _sharesRect = group.find((e) => e.get("name") === _);
                await _sharesRect.attr("x", 25 + index * 15);
                if (_ === "批注列表") {
                  await _sharesRect.attr("opacity", model.remark ? 1 : 0);
                  await _sharesRect.attr(
                    "cursor",
                    model.remark ? "pointer" : "default"
                  );
                }
              });
              const iconRect = group.find(
                (e) => e.get("name") === "icon-bg-rect"
              );
              iconRect?.attr({
                cursor: "pointer",
                width: list?.length * 14 + 1,
              });
            }
          }
        },
        setState(name, value, item) {
          const group = item.get("group");
          const model = item.getModel();
          if (model.isEllipsisNode) {
            return;
          }
          if (name === "hover") {
            let iconList = ["编辑", "添加", "删除", "行动", "批注"];
            let len = 0;
            iconList.forEach((key) => {
              if (
                getLoading() &&
                ["编辑", "添加", "删除", "行动", "批注"].includes(key)
              ) {
                const img = group.find((e) => e.get("name") === key);
                img?.attr({
                  cursor: "default",
                });
                return;
              }
              const img = group.find((e) => e.get("name") === key);
              if (img) {
                len = len + 1;
              }
              img?.attr({
                cursor: "pointer",
                opacity: value ? 1 : 0,
              });
            });

            const listIcon = group.find((e) => e.get("name") === "批注列表");
            if (listIcon && listIcon?.attr("opacity") === 1) {
              len = len + 1;
            }

            const iconRect = group.find(
              (e) => e.get("name") === "icon-bg-rect"
            );
            iconRect?.attr({
              cursor: "pointer",
              width: len * 14 + 1,
              opacity: value ? 1 : 0,
            });
          }
        },
      },
      "rect"
    );

    G6.registerEdge("flow-line", {
      draw(cfg, group) {
        const startPoint = cfg.startPoint;
        const endPoint = cfg.endPoint;
        // if (
        //   endPoint?.includes("EllipsisNode") ||
        //   startPoint?.includes("EllipsisNode")
        // ) {
        //   console.log(cfg);
        // }
        const targetModel = cfg.targetNode.getModel();
        // const sourceModel = cfg.sourceNode.getModel();
        const notTop = targetModel.direction === "bottom";
        // const isRoot = sourceModel.level === 1;
        endPoint.y = endPoint.y + (notTop ? -55 : 55);
        const { style } = cfg;
        let shape = null;
        shape = group.addShape("path", {
          attrs: {
            stroke: style.stroke,
            endArrow: style.endArrow,
            label: "triangleRect arrow",
            path: [
              ["M", startPoint.x, startPoint.y + (notTop ? 66 : -63)],
              [
                "L",
                startPoint.x,
                (startPoint.y + endPoint.y) / 2 + (notTop ? 20 : -20),
              ],
              [
                "L",
                endPoint.x,
                (startPoint.y + endPoint.y) / 2 + (notTop ? 20 : -20),
              ],
              ["L", endPoint.x, endPoint.y],
            ],
          },
          name: "flowLinePath",
        });
        if (targetModel.percent !== null && targetModel.percent !== undefined) {
          shape = group.addShape("text", {
            attrs: {
              fill: targetModel.isEdit ? "#A342B6" : "#6CAEFF",
              fontSize: 12,
              text: Number(targetModel.percent * 100).toFixed(2) + "%",
              x: endPoint.x + 5,
              y: endPoint.y - (notTop ? 25 : -40),
            },
            name: "percentText",
          });
        }
        return shape;
      },
      setState(name, value, item) {
        const group = item.get("group");
        if (name === "hover") {
          const path = group.find((e) => e.get("name") === "flowLinePath");
          path?.attr({
            stroke: value ? "#006AB2" : "#D8D8D8",
            lineWidth: value ? 2 : 1,
            endArrow: {
              fill: value ? "#006AB2" : "#D8D8D8",
              path: G6.Arrow.circle(5, 2),
              d: 2,
            },
          });
        }
      },
    });

    const defaultStateStyles = {
      hover: {
        stroke: "#1890ff",
        lineWidth: 2,
      },
    };

    const defaultNodeStyle = {
      // fill: "#91d5ff",
      // stroke: "#40a9ff",
      radius: 5,
    };

    const defaultEdgeStyle = {
      stroke: "#D8D8D8",
      endArrow: {
        fill: "#D8D8D8",
        path: G6.Arrow.circle(5, 2),
        d: 2,
      },
    };

    const defaultLayout = {
      type: "compactBox",
      direction: "V",
      getSide: function getSide(d) {
        if (d.data.direction === "bottom") return "right";
        return "left";
      },
      getId: function getId(d) {
        return d.id;
      },
      getHeight: function getHeight() {
        return 110;
      },
      getWidth: function getWidth() {
        return 120;
      },
      getVGap: function getVGap() {
        return 40;
      },
      getHGap: function getHGap() {
        return 70;
      },
    };

    const defaultLabelCfg = {
      style: {
        fill: "#000",
        fontSize: 12,
      },
    };

    const container = document.documentElement.querySelector(
      isDownLoad ? "#downloadPicCanvasBox" : "#stockRightCharts"
    );
    const width = container.scrollWidth - 10;
    const height = container.scrollHeight - 10;
    if (!isDownLoad) {
      if (graph.current) {
        graph.current.destroy();
        graph.current = null;
      }
      graph.current = new G6.TreeGraph({
        container: "stockRightCharts",
        width,
        height,
        linkCenter: true,
        animate: true, // Boolean，切换布局时是否使用动画过度，默认为 false
        animateCfg: {
          duration: 300, // Number，一次动画的时长
          easing: "linearEasing", // String，动画函数
        },
        // animate: false,
        modes: {
          default: ["drag-canvas", "zoom-canvas"],
        },
        defaultNode: {
          type: "icon-node",
          size: [200, 110],
          style: defaultNodeStyle,
          labelCfg: defaultLabelCfg,
        },
        defaultEdge: {
          type: "flow-line",
          style: defaultEdgeStyle,
        },
        nodeStateStyles: defaultStateStyles,
        edgeStateStyles: defaultStateStyles,
        layout: defaultLayout,
        plugins: [tooltip],
      });
      graph.current.data(data);
      graph.current.render();
      // graph.current.zoomTo(0.6);
      graph.current.fitCenter();

      graph.current.on("rect-box:mouseenter", (evt) => {
        if (getShowTip()) {
          return;
        }
        const { item } = evt;
        graph.current.setItemState(item, "hover", true);
        edgeActiveFunc(item, true);
      });

      graph.current.on("rect-box:mouseleave", (evt) => {
        if (getShowTip()) {
          return;
        }
        const { item } = evt;
        graph.current.setItemState(item, "hover", false);
        edgeActiveFunc(item, false);
      });

      graph.current.on("node:click", (evt) => {
        if (getShowTip()) {
          return;
        }
        const { item, target } = evt;
        const model = item.getModel();
        //展开隐藏
        if (model?.isEllipsisNode && model.level !== 1) {
          expandHideNodes(model);
          return;
        }
        const targetType = target.get("type");
        const name = target.get("name");
        // 增加元素
        if (targetType === "marker") {
          const model = item.getModel();
          if (name === "collapse-icon") {
            const group = item.getContainer();
            const icon = group.find((e) => e.get("name") === "collapse-icon");
            const opacity = icon.attr("opacity");
            if (!opacity) {
              return;
            }
            if (model.open) {
              graph.current.updateChildren([], model.id);
              item.update({
                ...model,
                open: false,
                // children: [],
              });
              icon.attr("symbol", EXPAND_ICON);
            } else {
              graph.current.updateChildren(model._children, model.id);
              item.update({
                ...model,
                open: true,
                // children: model._children,
              });
              icon.attr("symbol", COLLAPSE_ICON);
            }
            graph.current.setItemState(item, "hover", true);
          }
          if (name === "root-top-collapse-icon") {
            const group = item.getContainer();
            const icon = group.find(
              (e) => e.get("name") === "root-top-collapse-icon"
            );
            const opacity = icon.attr("opacity");
            if (!opacity) {
              return;
            }
            const topChildren =
              model?.children?.filter((_) => _.direction === "top") || [];
            if (topChildren?.length > 0) {
              const otherChildren =
                model?.children?.filter((_) => _.direction === "bottom") || [];
              graph.current.updateChildren(otherChildren, model.id);
              item.update({
                ...model,
                // open: false,
                // children: [],
              });
              icon.attr("symbol", EXPAND_ICON);
            } else {
              const otherChildren =
                model?._children?.filter((_) => _.direction === "top") || [];

              graph.current.updateChildren(
                [...model.children, ...otherChildren],
                model.id
              );
              item.update({
                ...model,
                // open: true,
                // children: model._children,
              });
              icon.attr("symbol", COLLAPSE_ICON);
            }
            graph.current.setItemState(item, "hover", true);
          }
          if (name === "root-bottom-collapse-icon") {
            const group = item.getContainer();
            const icon = group.find(
              (e) => e.get("name") === "root-bottom-collapse-icon"
            );
            const opacity = icon.attr("opacity");
            if (!opacity) {
              return;
            }
            const bottomChildren =
              model?.children?.filter((_) => _.direction === "bottom") || [];
            if (bottomChildren?.length > 0) {
              const otherChildren =
                model?.children?.filter((_) => _.direction === "top") || [];
              graph.current.updateChildren(otherChildren, model.id);
              item.update({
                ...model,
                // open: false,
                // children: [],
              });
              icon.attr("symbol", EXPAND_ICON);
            } else {
              const otherChildren =
                model?._children?.filter((_) => _.direction === "bottom") || [];

              graph.current.updateChildren(
                [...model.children, ...otherChildren],
                model.id
              );
              item.update({
                ...model,
                // open: true,
                // children: model._children,
              });
              icon.attr("symbol", COLLAPSE_ICON);
            }
            graph.current.setItemState(item, "hover", true);
          }
        }
        if (targetType === "image") {
          clickHandle(name, item.getModel());
        }
        // setShowTip(false);
      });
      graph.current.on("canvas:click", (evt) => {
        setShowTip(false);
      });
      if (typeof window !== "undefined")
        window.onresize = () => {
          if (!graph.current || graph.current?.get("destroyed")) return;
          const container = document.getElementById("stockRightCharts");
          if (!container || !container.clientWidth || !container.clientHeight)
            return;
          graph.current.changeSize(
            container.clientWidth - 10,
            container.clientHeight - 10
          );
        };
    } else {
      downloadGraph.current = new G6.TreeGraph({
        container: "downloadPicCanvasBox",
        width,
        height,
        linkCenter: true,
        animate: true, // Boolean，切换布局时是否使用动画过度，默认为 false
        animateCfg: {
          duration: 300, // Number，一次动画的时长
          easing: "linearEasing", // String，动画函数
        },
        // animate: false,
        modes: {
          default: ["drag-canvas", "zoom-canvas"],
        },
        defaultNode: {
          type: "icon-node",
          size: [200, 110],
          style: defaultNodeStyle,
          labelCfg: defaultLabelCfg,
        },
        defaultEdge: {
          type: "flow-line",
          style: defaultEdgeStyle,
        },
        nodeStateStyles: defaultStateStyles,
        edgeStateStyles: defaultStateStyles,
        layout: defaultLayout,
      });
      downloadGraph.current.data(data);
      downloadGraph.current.render();
    }
  };

  // 部分点击操作执行的函数
  const clickHandle = (type, item) => {
    if (getLoading()) {
      return;
    }
    setHandleType(type);
    let _type = "";
    if (item.level === 1) {
      setType("股东");
    } else {
      _type =
        item.direction === "top"
          ? "股东"
          : item.direction === "bottom"
          ? "投资"
          : "";
      setType(_type);
    }
    setShareholderType(null);
    setChoiceItem(item);
    if (type === "编辑") {
      getLicenseValid().then((res) => {
        if (res) {
          setEditLoading(false);
          setIsModalVisible(true);
          setModalTitle("修改" + _type);
          form.setFieldsValue({
            companyName: item.name,
            percent: parseFloat((Number(item.percent) * 100).toPrecision(14)),
          });
        }
      });
    } else if (type === "添加") {
      getLicenseValid().then((res) => {
        if (res) {
          setEditLoading(false);
          setIsModalVisible(true);
          setModalTitle("新增" + _type);
          // if(_type !== '投资'){
          //   setPercentWaring(true)
          // }
          form.setFieldsValue({
            shareholderType: 1,
            type: "股东",
          });
          setShareholderType(1);
        }
      });
    } else if (type === "删除") {
      getLicenseValid().then((res) => {
        if (res) {
          MessageModal({
            type: "warning",
            description: `确定要删除${_type === "股东" ? "股东" : "投资企业"}“${
              item.name
            }”吗？`,
            // message: "删除项目后将不再更新尽调信息和推送订阅信息",
            title: `删除${_type}`,
            onOk: () => {
              const parentItem = graph.current.find("node", (_item) => {
                const model = _item?.getModel();
                return (
                  model._id === item.parent_id && model.id === item.parent_key
                );
              });
              const parentModel = parentItem?.getModel();
              const parent_cpercent =
                _type === "股东"
                  ? (parentModel.cpercent || 0) - Number(item.percent)
                  : parentModel.cpercent || 0;
              const node = {
                oper: "del",
                id: item._id,
                key: item.id,
                type: item._label === "Company" ? 1 : 2,
                parent_id: item.parent_id,
                parent_key: item.parent_key,
                parent_type: item.parent_type === "Company" ? 1 : 2,
                up: _type === "股东" ? 1 : -1,
                percent: item.percent,
                parent_cpercent: parent_cpercent,
                level: item.level,
                name: item.name,
              };
              handleNodes(node, "删除");
            },
          });
        }
      });
    } else if (type === "批注") {
      getLicenseValid().then((res) => {
        if (res) {
          let point = graph.current.getClientByPoint(item.x, item.y);
          let parentItem = graph.current.find("node", (_item) => {
            const model = _item?.getModel();
            return model.id === item.parent_key;
          });
          const parentModel = parentItem?.getModel();
          let info = {
            origin_name: { val: parentModel?.name },
            origin_label: { val: parentModel?._label },
            origin_id: { val: parentModel?._id },
            name: { val: item.name },
            label: { val: item._label },
            id: { val: item._id },
            data_id: { val: item.data_id },
            percent: { val: item.percent },
            fpercent: { val: item.fpercent },
            type: { val: item.direction === "top" ? "up" : "down" },
          };
          // console.log("批注", info, item, parentModel);
          setDataInfo(info);
          setX(point.x);
          setY(point.y);
          setShowTipType("批注");
          setShowTip(true);
        }
      });
    } else if (type === "批注列表") {
      if (!item.remark) {
        return;
      }
      let point = graph.current.getClientByPoint(item.x, item.y);
      let parentItem = graph.current.find("node", (_item) => {
        const model = _item?.getModel();
        return model.id === item.parent_key;
      });
      const parentModel = parentItem?.getModel();
      let info = {
        origin_name: { val: parentModel?.name },
        origin_label: { val: parentModel?._label },
        origin_id: { val: parentModel?._id },
        name: { val: item.name },
        label: { val: item._label },
        id: { val: item._id },
        data_id: { val: item.data_id },
        percent: { val: item.percent },
        fpercent: { val: item.fpercent },
        type: { val: item.direction === "top" ? "up" : "down" },
      };
      // console.log("批注", info, item, parentModel);
      setDataInfo(info);
      setX(point.x);
      setY(point.y);
      setShowTipType("批注列表");
      setShowTip(true);
    } else if (type === "行动") {
      getLicenseValid().then((res) => {
        if (res) {
          let _item = graph.current.find("node", (_item) => {
            const model = _item?.getModel();
            return model._id === item._id && model.id === item.id;
          });
          if (!_item) {
            return;
          }

          getConcertedActionPersonGroupFunc(item.group);
          setConcertedActionPersonGroupModalTitle(
            item.group ? "修改一致行动人" : "新建一致行动人"
          );
          setConcertedActionPersonGroupModalType(
            item.group ? "移除一致行动人" : "新建一致行动人组"
          );
          setConcertedActionPersonItem(item);

          setTimeout(() => {
            setConcertedActionPersonGroupModalVisible(true);
          }, 50);
          // _item.update({
          //   ...item,
          //   group: (item?.group || 0) + 1,
          // });
        }
      });
    }
  };

  const cancelTip = () => {
    setShowTip(false);
    const item = graph.current.find("node", (_item) => {
      const model = _item?.getModel();
      return choiceItem._id === model._id && choiceItem.id === model.id;
    });
    graph.current.setItemState(item, "hover", false);
  };

  // 添加/修改触发函数
  const handleOk = () => {
    form.validateFields().then((res) => {
      setEditLoading(true);
      if (handleType === "添加") {
        let name = res.info.name;
        //校验同级是否有相同的元素
        const item = graph.current.find("node", (item) => {
          const model = item?.getModel();
          return model._id === choiceItem._id && model.id === choiceItem.id;
        });
        const model = item?.getModel();

        let node = {};
        const _id =
          res.info.data_type === 2 ? res.info.person_id : res.info.company_id;
        const _type = res.info.data_type || 1;
        if (type === "股东") {
          node = {
            oper: "add",
            id: _id,
            key: model.id + _id + (_type === 1 ? "Company" : "Person") + "top",
            name: name,
            type: _type,
            parent_id: choiceItem._id,
            parent_key: choiceItem.id,
            parent_type: choiceItem._label === "Company" ? 1 : 2,
            percent: Number(res.percent) / 100,
            up: 1,
            _id: _id,
            direction: "top",
            parent_cpercent:
              (choiceItem.cpercent || 0) + Number(res.percent) / 100,
            level: choiceItem.level + 1,
          };
        } else {
          node = {
            oper: "add",
            id: _id,
            name: name,
            key: model.id + _id + "Company" + "bottom",
            type: 1,
            parent_id: choiceItem._id,
            parent_key: choiceItem.id,
            parent_type: choiceItem._label === "Company" ? 1 : 2,
            percent: Number(res.percent) / 100,
            up: -1,
            direction: "bottom",
            _id: _id,
            parent_cpercent: choiceItem.cpercent || 0,
            level: choiceItem.level + 1,
          };
        }
        handleNodes(node, "添加");
      } else if (handleType === "编辑") {
        const parentItem = graph.current.find("node", (item) => {
          const model = item?.getModel();
          return (
            model._id === choiceItem.parent_id &&
            model.id === choiceItem.parent_key
          );
        });
        const parentModel = parentItem?.getModel();

        const parent_cpercent =
          type === "股东"
            ? (parentModel?.cpercent || 0) -
              Number(choiceItem.percent || 0) +
              Number(res.percent) / 100
            : parentModel.cpercent || 0;

        const node = {
          oper: "mod",
          id: choiceItem._id,
          key: choiceItem.id,
          type: choiceItem._label === "Company" ? 1 : 2,
          parent_id: choiceItem.parent_id,
          parent_key: choiceItem.parent_key,
          parent_type: choiceItem.parent_type === "Company" ? 1 : 2,
          up: type === "股东" ? 1 : -1,
          percent: Number(res.percent) / 100,
          old_percent: choiceItem.percent,
          parent_cpercent: parent_cpercent,
        };
        handleNodes(node, "编辑");
      }
    });
  };

  // 添加/修改成功回调
  const handleNodes = async (node, type) => {
    // console.log("%c ------------" + type + "------------", "font-size:14px;");
    // if (node.type === 2 && type === "添加") {
    //   const { data } = await projectApi.getPersonId(id, {
    //     name: node.name,
    //     company_id: node.id,
    //   });
    //   node.id = data.person_id;
    //   node._id = data.person_id;
    //   node.key = node.parent_key + data.person_id + "Person" + "top";
    // }
    //校验同级是否有相同的元素
    if (type === "添加") {
      const item = graph.current.find("node", (item) => {
        const model = item?.getModel();
        return model._id === choiceItem._id && model.id === choiceItem.id;
      });
      const model = item?.getModel();

      let flag = model.children?.some(
        (_) =>
          _._id === node.id &&
          _.name === node.name &&
          _.direction === node.direction
      );
      if (model._id === node.id && model.name === node.name) {
        message.error(
          `当前公司无法添加公司本身为${
            node.up === 1 ? "股东" : "对外投资公司"
          }，请重新输入`
        );
        setEditLoading(false);
        return;
      }

      if (flag) {
        if (node.up === 1) {
          message.error(
            `当前公司已存在相同${
              node.type === 1 ? "股东公司" : "关联公司的相同自然人股东"
            }，请重新输入`
          );
        } else {
          message.error("当前公司已存在相同对外投资公司，请重新输入");
        }
        setEditLoading(false);
        return;
      }
      if (flag) {
        return;
      }
    }

    // console.log(node);
    projectApi
      .editPenetrationNode(id, {
        content: JSON.stringify([node]),
      })
      .then((res) => {
        if (res.code === 200) {
          setIsModalVisible(false);
          editNode(node, type);
        } else {
          message.error(res.message);
        }
        setEditLoading(false);
      });
  };

  // 节点发生变化触发函数
  const editNode = (node, type) => {
    if (type === "编辑") {
      const item = graph.current.find("node", (item) => {
        const model = item?.getModel();
        return model._id === node.id && model.id === node.key;
      });
      const parentItem = graph.current.find("node", (item) => {
        const model = item?.getModel();
        return model._id === node.parent_id && model.id === node.parent_key;
      });
      const model = item?.getModel();
      const parentModel = parentItem?.getModel();
      let cpercent = parentModel?.cpercent || 0;
      if (node.up === 1) {
        cpercent = node.parent_cpercent;
      }

      // if (parentModel.cpercent !== cpercent) {
      let _children = JSON.parse(JSON.stringify(parentModel._children || []));
      _children = _children.map((_) => {
        if (_._id === node.id && _.id === node.key) {
          _.percent = Number(node.percent);
          _.change = _?.change === "add" ? "add" : "mod";
          _.isEdit = true;
          //添加输出日志
        }
        return _;
      });
      //添加输出日志
      consoleLog("cpercentChange", parentModel, cpercent, 1742);
      parentItem.update({
        ...parentModel,
        cpercent: cpercent,
        _children: JSON.parse(JSON.stringify(_children)),
      });
      // }

      //添加输出日志
      consoleLog("percentChange", model, node.percent, 1751);
      item.update({
        ...model,
        change: model?.change === "add" ? "add" : "mod",
        isEdit: true,
        percent: Number(node.percent),
      });
      // const edges = item.getInEdges();
      // const keyShape = edges?.at(0)?.getKeyShape();
      // keyShape?.attr("text", Number(node.percent * 100).toFixed(2)  + "%");
    } else if (type === "添加") {
      const item = graph.current.find("node", (item) => {
        const model = item?.getModel();
        return model._id === node.parent_id && model.id === node.parent_key;
      });
      const model = item?.getModel();
      const _node = {
        change: "add",
        isEdit: true,
        children: [],
        cpercent: 0,
        direction: node.up === 1 ? "top" : "bottom",
        fpercent: null,
        id: node.key,
        _id: node.id,
        _label: node.type === 1 ? "Company" : "Person",
        level: node.level,
        name: node.name,
        open: false,
        parent_id: node.parent_id,
        parent_key: node.parent_key,
        parent_type: node.parent_type === 1 ? "Company" : "Person",
        percent: node.percent,
        _children: [],
      };
      const _children = [...model._children, _node];
      if (model.open) {
        graph.current.addChild(_node, item);
      }
      //添加输出日志
      consoleLog("addChild", model, _node, 1789);
      let cpercent = model.cpercent;
      if (node.parent_cpercent) {
        cpercent = node.parent_cpercent;
      }
      // if (model.cpercent !== cpercent) {
      //添加输出日志
      consoleLog("cpercentChange", model, cpercent, 1796);
      item.update({
        ...model,
        open: model.open,
        cpercent: cpercent,
        _children: _children,
      });
      // }
    } else if (type === "删除") {
      const item = graph.current.find("node", (item) => {
        const model = item?.getModel();
        return model._id === node.parent_id && model.id === node.parent_key;
      });
      const model = item?.getModel();
      const children = model.children.filter((_) => _.id !== node.key);
      const flag = model.children.some((_) => _.id === node.key);
      // graph.current.updateChild(children, model.id);
      graph.current.removeChild(node.key);
      //添加输出日志
      consoleLog("removeChild", model, node, 1815);
      if (flag) {
        let cpercent = node.parent_cpercent;
        // if (model.level === 1) {
        //   cpercent = (model?.cpercent || 0) - (node.up === 1 ? node.percent : 0);
        // }
        //添加输出日志
        consoleLog("cpercentChange", model, cpercent, 1822);
        item.update({
          ...model,
          cpercent: cpercent,
          _children: children,
        });
      }
    }
  };

  // 保存修改,重新穿透回调
  const handleEdit = (rules) => {
    return projectApi
      .editPenetrationNodes(id, {
        content: JSON.stringify(editNodes),
        penetration_rule: JSON.stringify(rules || {}),
      })
      .then((res) => {
        if (res.code === 200) {
          setRulesDrawerVisible(false);
          setEditNodes([]);
          setLoading(true);
          getDataFunc();
        } else {
          message.error(res.message);
        }
      });
  };

  //取消
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //模糊搜索公司接口
  async function fetchComList(username) {
    if (!username) {
      return [];
    }
    return projectApi.getCompanyInfo(username).then((res) => {
      if (res.code !== 200) {
        message.error(res.message);
        return [];
      }
      return res.data.map((item) => ({
        ...item,
        label: item.name,
        value: item.company_id,
      }));
    });
  }

  async function fetchComList2(username) {
    if (!username || username?.length < 2) {
      return [];
    }
    return projectApi.getCompanyAndPerson(username, id).then((res) => {
      if (res.code !== 200) {
        message.error(res.message);
        return [];
      }
      return res.data.map((item) => ({
        ...item,
        label: item.name,
        value: item.company_id,
      }));
    });
  }

  // 股东类型变化回调
  const shareholderTypeChange = (value) => {
    setShareholderType(value);
  };

  useEffect(() => {
    if (!isModalVisible) {
      form?.resetFields();
    }
    setPercentWaring(false);
  }, [isModalVisible]);

  // 比例变化
  const percentChange = (value) => {
    if (handleType === "添加" && type === "股东") {
      if (
        (choiceItem.cpercent || 0) + Number(value / 100) !== 1 &&
        Number(value / 100) <= 1 &&
        Number(value / 100) > 0
      ) {
        setPercentWaring(true);
        return;
      }
    }
    setPercentWaring(false);
  };

  useEffect(() => {
    if (
      percentWaring &&
      choiceItem.cpercent !== null &&
      choiceItem.cpercent !== undefined
    ) {
      setPercentProps({
        validateStatus: "warning",
        help: `当前已录入股份合计${Number(choiceItem.cpercent * 100).toFixed(
          2
        )}%`,
      });
    } else {
      setPercentProps({});
    }
  }, [percentWaring]);

  // 下载数据格式化
  const initDownLoadData = (data) => {
    data = JSON.parse(JSON.stringify(data));
    let size = 0;
    const addChildren = (item) => {
      if (item._children.length > 0) {
        item._children = item._children.map((_) => addChildren(_));
      }
      item.children = item._children;
      size += item.children.length || 0;
      return item;
    };
    data = addChildren(data);
    return {
      data,
      size,
    };
  };

  //导出图片
  const downloadPicHandle = async (type) => {
    setDownloadLoading(true);
    let downloadData = [];
    let nodesNum = 0;
    if (type === "当前页面") {
      downloadData = graph.current.save();
      nodesNum = graph.current.getNodes()?.length;
    } else if (type === "仅主要股东和实际控制人") {
      await projectApi.getPenetrationInfo(id).then((res) => {
        const { up, down, update_time } = res.data;
        const { data, editNodes } = initData(up || {}, down || {}, false, {
          filterLevel: "controller",
        });
        downloadData = {
          ...data,
          children: data.children.filter((_) => _.direction === "top"),
        };
        nodesNum = 151;
      });
    } else if (type === "自定义") {
      const { data, size } = initDownLoadData(graph.current.save());
      downloadData = data;
      nodesNum = size;
    }
    await initCharts(downloadData, true);
    let zoomToSize = 2;
    if (nodesNum > 1000) {
      zoomToSize = 0.05;
    } else if (nodesNum > 600) {
      zoomToSize = 0.1;
    } else if (nodesNum > 400) {
      zoomToSize = 0.25;
    } else if (nodesNum > 250) {
      zoomToSize = 0.3;
    } else if (nodesNum > 150) {
      zoomToSize = 0.4;
    } else if (nodesNum > 100) {
      zoomToSize = 1;
    } else if (nodesNum > 60) {
      zoomToSize = 1.2;
    }
    if (nodesNum > 250) {
      notification.warning({
        message: "提示",
        description: "当前下载的节点过多,图片可能不清晰!",
      });
    }
    console.log(`下载图片=========>节点数量:${nodesNum}缩放比例:${zoomToSize}`);
    downloadGraph.current.zoomTo(zoomToSize);
    downloadGraph.current.get("canvas").set("pixelRatio", 2);
    setTimeout(async () => {
      const a = await downloadGraph.current.downloadFullImage(
        `${projectInfo?.project_name}股权关系`,
        "image/png",
        {
          backgroundColor: "#fff",
          padding: [15, 15, 15, 15],
        }
      );
      await downloadGraph.current.destroy();
      downloadGraph.current = null;
      setDownloadLoading(false);
    }, 300);
  };

  useEffect(() => {
    initExpandOption();
  }, [maxLevel, hasController]);

  //初始化展开选项
  const initExpandOption = () => {
    selectKey.current = Math.random();
    if (getMaxLevel() === 2) {
      setExpandOptions([
        {
          label: "全部",
          value: "全部",
        },
        {
          label: "一级",
          value: 2,
        },
      ]);
      return;
    }
    let arr = [
      { label: "一级", value: 2 },
      { label: "二级", value: 3 },
      { label: "三级", value: 4 },
      { label: "四级", value: 5 },
      { label: "五级", value: 6 },
      { label: "六级", value: 7 },
      { label: "七级", value: 8 },
      { label: "八级", value: 9 },
      { label: "九级", value: 10 },
      { label: "十级", value: 11 },
    ];
    arr = arr.filter((_) => _.value <= getMaxLevel());
    if (getHasController()) {
      arr.push({ label: "实际控制人", value: "controller" });
    }
    arr.unshift({ label: "全部", value: "全部" });
    setExpandOptions(arr);
  };

  //展开发生变化
  const expandChange = (level) => {
    if (!level) {
      return;
    }
    projectApi.getPenetrationInfo(id).then((res) => {
      const { up, down, update_time } = res.data;
      const { data, editNodes } = initData(up || {}, down || {}, false, level);
      graph.current.changeData(data);
      graph.current.fitView();
      setUploadDate(update_time);
    });
  };

  const handleFilter = () => {
    if (getMinShareholdingRatio() && getMaxShareholdingRatio()) {
      if (
        Number(getMaxShareholdingRatio()) < Number(getMinShareholdingRatio())
      ) {
        message.info("持股比例最大值不能小于最小值");
        return;
      }
    }
    if (getMinRegisteredCapital() && getMaxRegisteredCapital()) {
      if (
        Number(getMaxRegisteredCapital()) < Number(getMinRegisteredCapital())
      ) {
        message.info("注册资本最大值不能小于最小值");
        return;
      }
    }
    if (getRegistrationStartTime() && getRegistrationEndTime()) {
      if (
        new Date(getRegistrationEndTime())?.getTime() <
        new Date(getRegistrationStartTime())?.getTime()
      ) {
        message.info("注册结束时间不能小于开始时间");
        return;
      }
    }
    setFilterLoading(true);
    projectApi.getPenetrationInfo(id).then((res) => {
      const { up, down, update_time } = res.data;
      const params = {
        filterLevel: getFilterLevel(),
        filterDirection: getFilterDirection(),
        filterEnterpriseStatus: getFilterEnterpriseStatus(),
        filterEnterpriseType: [...getFilterEnterpriseType(), "其他"],
        minShareholdingRatio: getMinShareholdingRatio(),
        maxShareholdingRatio: getMaxShareholdingRatio(),
        minRegisteredCapital: getMinRegisteredCapital(),
        maxRegisteredCapital: getMaxRegisteredCapital(),
        // registrationTime: getRegistrationTime(),
        registrationStartTime: getRegistrationStartTime(),
        registrationEndTime: getRegistrationEndTime(),
        filterRegion: getFilterRegion(),
      };
      const { data, editNodes } = initData(up || {}, down || {}, false, params);
      graph.current.changeData(data);
      graph.current.fitView();
      setUploadDate(update_time);
      setFilterLoading(false);
      setFilterModalVisible(false);
    });
  };

  const handleFilterCancel = () => {
    setFilterModalVisible(false);
  };

  const clearFilter = () => {
    setFilterLevel(2);
    setFilterDirection("全部");
    setFilterEnterpriseStatus(["存续", "开业", "吊销", "注销", "迁出", "其他"]);
    setFilterEnterpriseType([
      "集体企业及社会团体",
      "上市公司",
      "基金公司",
      "股份制有限公司（非上市）",
    ]);
    setMinShareholdingRatio(null);
    setMaxShareholdingRatio(null);
    setMinRegisteredCapital(null);
    setMaxRegisteredCapital(null);
    setRegistrationTime([]);
    setRegistrationStartTime(null);
    setRegistrationEndTime(null);
    setFilterRegion(["全部"]);
  };

  return (
    <div className="charts" id="stockRightCharts" ref={ref}>
      {projectInfo?.project_permission_urls.includes("CDD_PRJ_CDD_ITEM_EDIT") &&
        (editNodes.length !== 0 || loading || getGroupChange() ? (
          <div
            style={{ position: "absolute", top: 30, left: 150 }}
            className="rePenetrationBtnBox"
          >
            <Popover
              open={!loading}
              placement="topLeft"
              getPopupContainer={(e) => e?.parentNode}
              content={
                <div className="editText">股权关系已修改，请重新穿透</div>
              }
            >
              <Button
                type="primary"
                onClick={() => {
                  getLicenseValid().then((res) => {
                    if (res) {
                      handleEdit(rulesInfo);
                    }
                  });
                }}
                loading={loading}
              >
                {loading ? "正在穿透中" : "重新穿透"}
              </Button>
            </Popover>
          </div>
        ) : null)}

      {rulesInfo && (
        <div style={{ position: "absolute", top: 30, left: 30 }}>
          <Tooltip
            title={
              <Content2
                info={rulesInfo}
                loading={
                  projectInfo?.project_permission_urls.includes(
                    "CDD_PRJ_CDD_ITEM_EDIT"
                  )
                    ? loading
                    : true
                }
                setRulesDrawerVisible={setRulesDrawerVisible}
              ></Content2>
            }
            placement="bottom"
            overlayClassName="rulesTooltip"
            getPopupContainer={(e) => e?.parentNode}
          >
            <Button icon={<InfoCircleOutlined />} type="primary">
              穿透规则
            </Button>
          </Tooltip>
        </div>
      )}

      {showTip ? (
        <Popover
          trigger="click"
          placement="bottom"
          overlayClassName="myCardPopover remarkTip"
          visible={showTip}
          destroyTooltipOnHide={true}
          content={
            showTipType === "批注" ? (
              <Content
                onClose={() => {
                  cancelTip();
                }}
                getData={() => {
                  cancelTip();
                  getNewData();
                }}
                info={{ id: choiceItem.data_id }}
                dataInfo={dataInfo || {}}
                projectId={id}
                typeName="股权关系"
                dataTable="company_investment_information"
                mode={20}
              ></Content>
            ) : (
              <Info
                onClose={() => {
                  cancelTip();
                }}
                getData={() => {
                  cancelTip();
                  getNewData();
                }}
                info={{ id: choiceItem.data_id }}
                dataInfo={dataInfo || {}}
                projectId={id}
                typeName="股权关系"
                dataTable="company_investment_information"
                mode={20}
              ></Info>
            )
          }
        >
          <div
            className="tipBox"
            style={{ top: y + "px", left: x + "px" }}
          ></div>
        </Popover>
      ) : null}

      <Modal
        title={modalTitle}
        wrapClassName="chartsModal"
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <div className="drawerFooterBtnBox">
            <Button
              type="primary"
              loading={editLoading}
              onClick={() => {
                handleOk();
              }}
            >
              确定
            </Button>
            <Button
              className="noBg"
              onClick={() => {
                handleCancel();
              }}
            >
              关闭
            </Button>
          </div>
        }
      >
        <div className="formContent">
          <Form autoComplete="off" form={form}>
            {handleType === "添加" && choiceItem.level === 1 ? (
              <Form.Item
                label="类型"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "请选择类型",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setType(e.target.value);
                    // setPercentWaring(e.target.value === "股东");
                    form.resetFields(["percent", "info"]);
                  }}
                >
                  <Radio value="股东">股东</Radio>
                  <Radio value="投资">投资</Radio>
                </Radio.Group>
              </Form.Item>
            ) : null}

            {handleType === "添加" && type === "股东" ? (
              <Form.Item
                label="股东名称"
                name="info"
                rules={[
                  {
                    required: true,
                    message: "请填写股东名称",
                  },
                ]}
              >
                <PersonAndCompanySelect
                  showSearch={true}
                  placeholder="请输入股东名称搜索"
                  fetchOptions={fetchComList2}
                  changeClear={true}
                  allowClear
                  style={{
                    width: "100%",
                  }}
                />
                {/* <Radio.Group
                  onChange={(e) => {
                    shareholderTypeChange(e.target.value);
                    form.resetFields([
                      "percent",
                      "companyName",
                      "naturalPerson",
                    ]);
                  }}
                >
                  <Radio value={1}>企业</Radio>
                  <Radio value={2}>自然人</Radio>
                </Radio.Group> */}
              </Form.Item>
            ) : null}

            {/* {handleType === "添加" &&
            type === "股东" &&
            shareholderType === 2 ? (
              <Form.Item
                label="  自然人"
                name="naturalPerson"
                rules={[
                  {
                    required: true,
                    message: "请输入自然人",
                  },
                ]}
              >
                <Input
                  maxLength={50}
                  placeholder="请输入自然人且不超过50个字符"
                />
              </Form.Item>
            ) : null} */}

            {handleType === "添加" && type !== "股东" ? (
              <Form.Item
                label="企业名称"
                name="info"
                rules={[
                  {
                    required: true,
                    message: "请填写企业名称",
                  },
                ]}
              >
                <DebounceSelect
                  showSearch={true}
                  placeholder="请输入企业名称搜索"
                  fetchOptions={fetchComList}
                  changeClear={true}
                  allowClear
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            ) : null}

            {handleType === "编辑" ? (
              <Form.Item
                label={type === "股东" ? "股东名称" : "公司名称"}
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            ) : null}

            <Form.Item
              label="持股比例"
              name="percent"
              rules={[
                {
                  required: true,
                  message: "请输入持股比例",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value === "" || value === undefined || value === null) {
                      return Promise.resolve();
                    }
                    let reg = new RegExp(
                      /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/
                    );
                    if (!reg.test(value)) {
                      return Promise.reject(
                        new Error("请输入大于0不超过100的数字")
                      );
                    }
                    if (isExceedDigit(value, 5)) {
                      return Promise.reject(
                        new Error(`输入的数字小数位不超过${5}位`)
                      );
                    }
                    value = Number(value);
                    if (value <= 100 && value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("请输入大于0不超过100的数字")
                    );
                  },
                }),
              ]}
              onFocus={() => {
                if (type === "股东" && handleType === "添加") {
                  setPercentWaring(true);
                }
              }}
              onBlur={() => {
                setPercentWaring(false);
              }}
              onChange={(e) => {
                percentChange(e.target.value);
              }}
              {...percentProps}
            >
              <Input
                placeholder="请输入大于0不超过100的数字"
                type="text"
                suffix="%"
                // onKeyDown={(e) => {
                //   const prohibitInput = ["-", "e", "+", "E"];
                //   if (prohibitInput.includes(e.key)) {
                //     e.preventDefault();
                //     return false
                //   }
                // }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Drawer
        title={"股权关系筛选"}
        placement="right"
        width="600px"
        visible={filterModalVisible}
        push={false}
        closable={false}
        onOk={handleFilter}
        onClose={handleFilterCancel}
        className="filterDrawer"
        getContainer={isFullscreen ? false : document.body}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              handleFilterCancel();
            }}
          />
        }
        footer={
          <div className="drawerFooterBtnBox">
            <div className="btnBox11">
              <Button
                type="primary"
                loading={filterLoading}
                onClick={() => {
                  handleFilter();
                }}
              >
                确定
              </Button>
              <Button
                className="noBg"
                onClick={() => {
                  handleFilterCancel();
                }}
              >
                取消
              </Button>
            </div>
            <Button
              type="primary"
              loading={filterLoading}
              style={{}}
              onClick={() => {
                clearFilter();
              }}
            >
              恢复默认
            </Button>
          </div>
        }
      >
        <div className="formContent">
          {/* 展示层级 */}
          <div className="formItemBox">
            <HeaderTitle title="展示层级"></HeaderTitle>
            <div>
              <MyTextCheckBox
                value={filterLevel}
                options={getExpandOptions()}
                onChange={(value) => {
                  setFilterLevel(value);
                }}
              ></MyTextCheckBox>
            </div>
          </div>
          {/* 展示方向 */}
          <div className="formItemBox">
            <HeaderTitle title="展示方向"></HeaderTitle>
            <div>
              <MyTextCheckBox
                value={filterDirection}
                options={directionOptions}
                onChange={(value) => {
                  setFilterDirection(value);
                }}
              ></MyTextCheckBox>
            </div>
          </div>
          {/* 企业状态 */}
          <div className="formItemBox">
            <HeaderTitle title="企业状态"></HeaderTitle>
            <div>
              <MyTextCheckBox
                multiple={true}
                value={filterEnterpriseStatus}
                options={enterpriseStatusOptions}
                onChange={(value) => {
                  setFilterEnterpriseStatus([...new Set([...value, "存续"])]);
                }}
              ></MyTextCheckBox>
            </div>
          </div>
          {/* 企业类型 */}
          <div className="formItemBox">
            <HeaderTitle title="企业类型"></HeaderTitle>
            <div>
              <MyTextCheckBox
                multiple={true}
                value={filterEnterpriseType}
                options={enterpriseTypeOptions}
                onChange={(value) => {
                  setFilterEnterpriseType([...new Set([...value])]);
                }}
              ></MyTextCheckBox>
            </div>
          </div>
          {/* 持股比例 */}
          <div className="formItemBox">
            <HeaderTitle title="持股比例"></HeaderTitle>
            <div className="formInputItemBox">
              <Input
                min={0}
                max={100}
                placeholder="最小值"
                style={{ width: "200px", marginRight: "10px" }}
                value={minShareholdingRatio}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    setMinShareholdingRatio(null);
                    return;
                  }
                  let min = e.target.value
                    .replace(/[^\d\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".");
                  let [int, decimal] = min?.split(".");
                  int = parseInt(int || 0) + "";
                  if (Number(min) >= 100) {
                    int = "100";
                    setMinShareholdingRatio(int);
                    return;
                  }
                  if (decimal?.length > 2) {
                    decimal = decimal?.slice(0, 2);
                  }

                  min =
                    int +
                    (decimal ? "." + decimal : min.includes(".") ? "." : "");

                  setMinShareholdingRatio(min);
                }}
              />
              <div className="unitBox">%</div>
              <span className="separateSpan">~</span>
              <Input
                min={0}
                max={100}
                placeholder="最大值"
                style={{ width: "200px", marginRight: "10px" }}
                value={maxShareholdingRatio}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    setMaxShareholdingRatio(null);
                    return;
                  }
                  let max = e.target.value
                    .replace(/[^\d\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".");
                  let [int, decimal] = max?.split(".");
                  int = parseInt(int || 0) + "";
                  if (Number(max) >= 100) {
                    int = "100";
                    setMaxShareholdingRatio(int);
                    return;
                  }
                  if (decimal?.length > 2) {
                    decimal = decimal?.slice(0, 2);
                  }

                  max =
                    int +
                    (decimal ? "." + decimal : max.includes(".") ? "." : "");
                  setMaxShareholdingRatio(max);
                }}
              />
              <div className="unitBox">%</div>
            </div>
          </div>
          {/* 注册资本 */}
          <div className="formItemBox">
            <HeaderTitle title="注册资本"></HeaderTitle>
            <div className="formInputItemBox">
              <Input
                min={0}
                placeholder="最小值"
                style={{ width: "200px", marginRight: "10px" }}
                value={minRegisteredCapital}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    setMinRegisteredCapital(null);
                    return;
                  }
                  let min = e.target.value
                    .replace(/[^\d\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".");
                  let [int, decimal] = min?.split(".");
                  int = parseInt(int || 0) + "";
                  if (int?.length > 10) {
                    int = int?.slice(0, 10);
                  }
                  if (decimal?.length > 6) {
                    decimal = decimal?.slice(0, 6);
                  }

                  min =
                    int +
                    (decimal ? "." + decimal : min.includes(".") ? "." : "");
                  setMinRegisteredCapital(min);
                }}
              />
              <div className="unitBox">万元</div>
              <span className="separateSpan">~</span>
              <Input
                min={0}
                placeholder="最大值"
                style={{ width: "200px", marginRight: "10px" }}
                value={maxRegisteredCapital}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    setMaxRegisteredCapital(null);
                    return;
                  }
                  let max = e.target.value
                    .replace(/[^\d\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".");
                  let [int, decimal] = max?.split(".");
                  int = parseInt(int || 0) + "";
                  if (int?.length > 10) {
                    int = int?.slice(0, 10);
                  }
                  if (decimal?.length > 6) {
                    decimal = decimal?.slice(0, 6);
                  }
                  max =
                    int +
                    (decimal ? "." + decimal : max.includes(".") ? "." : "");
                  setMaxRegisteredCapital(max);
                }}
              />
              <div className="unitBox">万元 </div>
            </div>
          </div>
          {/* 注册时间 */}
          <div className="formItemBox">
            <HeaderTitle title="注册时间"></HeaderTitle>
            <div className="formInputItemBox">
              <DatePicker
                onChange={(date) => {
                  setRegistrationStartTime(date);
                }}
                placeholder="开始时间"
                value={registrationStartTime}
                style={{ width: "215px", marginRight: "10px" }}
                disabledDate={(currentDate) => {
                  let max = moment(new Date());
                  if (getRegistrationEndTime()) {
                    max = moment.min(
                      moment(new Date()),
                      moment(getRegistrationEndTime())
                    );
                  }
                  return moment(currentDate).diff(moment(max)) > 0;
                }}
              />
              <span className="separateSpan">~</span>
              <DatePicker
                onChange={(date) => {
                  setRegistrationEndTime(date);
                }}
                disabledDate={(currentDate) => {
                  let max = moment(new Date());
                  if (getRegistrationStartTime()) {
                    return (
                      moment(currentDate).diff(moment(max)) > 0 ||
                      moment(currentDate).diff(
                        moment(getRegistrationStartTime())
                      ) < 0
                    );
                  }
                  return moment(currentDate).diff(moment(max)) > 0;
                }}
                placeholder="结束时间"
                value={registrationEndTime}
                style={{ width: "215px", marginRight: "10px" }}
              />
              {/* <MyRangePicker
                allowClear={true}
                format="YYYY-MM-DD"
                style={{ width: "486px" }}
                value={registrationTime.map((_) => {
                  if (_) {
                    return moment(_);
                  }
                  return null;
                })}
                onChange={(date, dateString) => {
                  setRegistrationTime(dateString);
                }}
              ></MyRangePicker> */}
            </div>
          </div>
          {/* 地区 */}
          <div className="formItemBox">
            <HeaderTitle title="地区"></HeaderTitle>
            <div>
              <MyTextCheckBox
                multiple={true}
                value={filterRegion}
                options={[{ label: "全部", value: "全部" }, ...regionJson]}
                onClick={(value) => {
                  setFilterRegion((prev) => {
                    if (value === "全部") {
                      return ["全部"];
                    } else {
                      let list = prev;
                      if (list?.includes("全部")) {
                        list = list?.filter((_) => _ !== "全部");
                      }
                      if (list?.includes(value)) {
                        list = list?.filter((_) => _ !== value);
                      } else {
                        list = [...list, value];
                      }
                      if (list?.length === 0) {
                        list = [value];
                      }
                      return [...list];
                    }
                  });
                }}
              ></MyTextCheckBox>
            </div>
          </div>
        </div>
      </Drawer>

      <RulesDrawer
        visible={rulesDrawerVisible}
        onClose={() => setRulesDrawerVisible(false)}
        successFunc={handleEdit}
        defaultRules={rulesInfo || {}}
      ></RulesDrawer>

      {!firstLoading && (
        <div
          className="chartHandleBox"
          style={{ position: "absolute", top: 10, right: 10 }}
        >
          {/* {getExpandOptions()?.length > 0 && (
          <div className="openBox">
            展开
            <Select
              style={{
                width: "calc(100% - 40px)",
                marginLeft: "5px",
              }}
              placeholder="请选择"
              // allowClear
              onChange={(value) => {
                expandChange(value);
              }}
              key={selectKey.current}
              defaultValue={2}
              getPopupContainer={() =>
                document.documentElement.querySelector("#stockRightCharts")
              }
            >
              {getExpandOptions().map((_) => (
                <Option key={_.label} value={_.value}>
                  {_.label}
                </Option>
              ))}
            </Select>
          </div>
        )} */}

          <ReactSVG
            className="allIcon"
            src={icon4}
            onClick={() => {
              setFilterModalVisible(true);
            }}
          ></ReactSVG>

          <ReactSVG
            className="allIcon"
            src={icon3}
            onClick={toggleFullscreen}
          ></ReactSVG>

          {downloadLoading ? (
            <LoadingOutlined className="downIcon" />
          ) : (
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              overlayClassName="dropdownItemA"
            >
              <div>
                <DownLoadIcon
                  style={{ cursor: "pointer" }}
                  className="downIcon hoverIcon"
                ></DownLoadIcon>
              </div>
            </Dropdown>
          )}
        </div>
      )}

      <Modal
        title={concertedActionPersonGroupModalTitle}
        wrapClassName="concertedActionPersonGroupModal"
        centered
        visible={concertedActionPersonGroupModalVisible}
        onOk={concertedActionPersonGroupSuccessFunc}
        onCancel={() => {
          concertedActionPersonGroupHandleCancel();
        }}
        footer={null}
      >
        {getConcertedActionPersonGroupLoading() ? (
          <div style={{ width: "100%", height: "420px" }}>
            <MySpin></MySpin>
          </div>
        ) : (
          <div className="concertedActionPersonGroupBox">
            <div className="concertedActionPersonGroupTitleBox">
              <div className="concertedActionPersonGroupTitle">
                {!getGroupRoot() ? (
                  concertedActionPersonItem?.name
                ) : (
                  <>
                    一致行动人组<span>{getConcertedActionPersonGroup()}</span>
                  </>
                )}
              </div>
              {!getGroupRoot() && (
                <div className="concertedActionPersonGroupInputBox">
                  {(getConcertedActionPersonGroupModalType() ===
                    "移动到一致行动人组" ||
                    getConcertedActionPersonGroupModalType() ===
                      "移除一致行动人") && (
                    <div className="groupBox">
                      <span
                        className={
                          getConcertedActionPersonItem()?.group ===
                          getConcertedActionPersonGroup()
                            ? "activeSpan"
                            : null
                        }
                        onClick={() => {
                          if (
                            getConcertedActionPersonItem()?.group ===
                            getConcertedActionPersonGroup()
                          ) {
                            return;
                          }
                          choiceGroup(getConcertedActionPersonItem()?.group);
                        }}
                      >
                        {getConcertedActionPersonItem()?.group}
                      </span>
                    </div>
                  )}
                  <Select
                    style={{
                      width: 160,
                      height: "28px",
                    }}
                    bordered={false}
                    onChange={(value) => {
                      setConcertedActionPersonGroupModalType(value);
                      if (value === "新建一致行动人组") {
                        setConcertedActionPersonGroupModalTitle(
                          "新建一致行动人"
                        );
                        choiceGroup("新建");
                        return;
                      } else if (value === "加入一致行动人组") {
                        setConcertedActionPersonGroupModalTitle(
                          "添加一致行动人"
                        );
                      }
                      if (getConcertedActionPersonItem()?.group) {
                        choiceGroup(getConcertedActionPersonItem()?.group);
                      } else {
                        choiceGroup(
                          getConcertedActionPersonGroupInfo()?.at(0)?.group
                        );
                      }
                    }}
                    value={concertedActionPersonGroupModalType}
                    options={
                      concertedActionPersonItem?.group
                        ? getConcertedActionPersonGroupInfo()?.length > 1
                          ? [
                              {
                                value: "移动到一致行动人组",
                                label: "移动到一致行动人组",
                              },
                              {
                                value: "移除一致行动人",
                                label: "移除一致行动人",
                              },
                            ]
                          : [
                              {
                                value: "移除一致行动人",
                                label: "移除一致行动人",
                              },
                            ]
                        : getConcertedActionPersonGroupInfo()?.length > 0
                        ? [
                            {
                              value: "新建一致行动人组",
                              label: "新建一致行动人组",
                            },
                            {
                              value: "加入一致行动人组",
                              label: "加入一致行动人组",
                            },
                          ]
                        : [
                            {
                              value: "新建一致行动人组",
                              label: "新建一致行动人组",
                            },
                          ]
                    }
                  />

                  {<div className="groupBox">{getSerialNumber()}</div>}
                </div>
              )}
            </div>
            <div className="remarksBox">
              备注
              {!(
                getConcertedActionPersonGroupModalType() ===
                  "新建一致行动人组" || getGroupRoot()
              ) ? (
                <div className="remarkContent">
                  <Paragraph
                    ellipsis={{
                      rows: 1,
                      tooltip: {
                        title: concertedActionPersonGroupRemarks,
                      },
                    }}
                  >
                    {concertedActionPersonGroupRemarks}
                  </Paragraph>
                </div>
              ) : (
                <Input
                  style={{ width: "calc(100% - 40px)" }}
                  bordered={false}
                  placeholder="一致行动人组形成原因"
                  value={concertedActionPersonGroupRemarks}
                  onChange={(e) => {
                    let value = e.target.value || null;
                    if (value?.length > 100) {
                      value = value?.slice(0, 100);
                    }
                    setConcertedActionPersonGroupRemarks(value);
                  }}
                />
              )}
            </div>

            <div className="concertedActionPersonGroupListBox">
              <div className="titleBox">
                {getConcertedActionPersonGroupModalType() === "新建一致行动人组"
                  ? "添加其他一致行动人:"
                  : "该组中其他一致行动人:"}
              </div>
              {concertedActionPersonGroupModalType === "新建一致行动人组" && (
                <PersonAndCompanySelect
                  showSearch={true}
                  placeholder="输入一致行动人名称"
                  fetchOptions={fetchComList2}
                  changeClear={true}
                  notChangeValue={true}
                  value={null}
                  onChange={(info) => {
                    const item = {
                      entity_id:
                        info?.data_type === 2
                          ? info?.person_id
                          : info?.company_id,
                      type: info?.data_type === 2 ? 2 : 1,
                      name: info.name,
                    };

                    if (
                      getConcertedActionPersonList()?.some(
                        (_) =>
                          _.entity_id === item.entity_id &&
                          _.type === item.type &&
                          _.name === item.name
                      )
                    ) {
                      return;
                    }
                    addActionPersonGroupSuccessFunc(item);
                  }}
                  style={{
                    width: "100%",
                    marginTop: "12px",
                  }}
                />
              )}
              <div className="concertedActionPersonGroupListContent">
                {concertedActionPersonList?.length > 0 ? (
                  (getGroupRoot()
                    ? concertedActionPersonList
                    : concertedActionPersonList?.filter((_) => {
                        const itemInfo = getConcertedActionPersonItem() || {};
                        return !(
                          _.entity_id === itemInfo._id &&
                          _.type === (itemInfo?._label === "Person" ? 2 : 1) &&
                          _.name === itemInfo.name
                        );
                      })
                  )?.map((item, index) => (
                    <div className="concertedActionPersonItem" key={index}>
                      <span
                        style={{ color: item.name ? "#0068B2" : "#ff4d4f" }}
                      >
                        {item.name || "（工商公示异常）"}
                      </span>
                      {getConcertedActionPersonGroupModalType() ===
                        "新建一致行动人组" && (
                        <CloseCircleFilled
                          onClick={() => {
                            setConcertedActionPersonList((prev) => {
                              prev = prev?.filter(
                                (_) =>
                                  _.entity_id !== item.entity_id ||
                                  _.type !== item.type ||
                                  _.name !== item.name
                              );
                              return [...prev];
                            });

                            setEditConcertedActionPersonList((prev) => {
                              const addCode = `${item?.entity_id}_${
                                item?.type
                              }_${0}_${getConcertedActionPersonGroup()}`;
                              //之前新增过 删除新增记录
                              if (prev.some((_) => _ === addCode)) {
                                prev = prev?.filter((_) => _ !== addCode);
                              } else {
                                //不是新增的
                                prev = [
                                  ...prev,
                                  `${item?.entity_id}_${
                                    item?.type
                                  }_${getConcertedActionPersonGroup()}_${0}`,
                                ];
                              }
                              return [...prev];
                            });
                          }}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <MyEmpty description="请至少添加一个行动人"></MyEmpty>
                )}
              </div>
            </div>

            <div className="concertedActionPersonGroupBtnBox">
              <Button
                className="noBg"
                onClick={() => {
                  concertedActionPersonGroupHandleCancel();
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  concertedActionPersonGroupSuccessFunc();
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )}
      </Modal>

      {!firstLoading && (
        <div className="groupShowBox">一致行动人组{getSerialNumber(true)}</div>
      )}

      {firstLoading ? (
        <div className="firstLoadingBox">
          <MySpin></MySpin>
        </div>
      ) : null}

      <div id="downloadPicCanvasBox"></div>
    </div>
  );
};
export default Charts;
