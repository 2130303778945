import "./index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import MyEmpty from "../../../components/Empty";
import projectApi from "../../../apis/project";
import {
  message,
  Timeline,
  Button,
  Modal,
  Checkbox,
  Tooltip,
  Popover,
  Select,
} from "antd";
import MySpin from "../../../components/MySpin";
import { useGetState, useUnmountedRef } from "ahooks";
import MessageModal from "../../../components/MessageModal";
import { guid, downloadFile, getLicenseValid } from "../../../unit/unit";
import ReportCard from "./components/ReportCard";
import wordIcon from "../../../assets/word.svg";
import icon1 from "../../../assets/01.svg";
import icon2 from "../../../assets/02.svg";
import filterIcon from "../../../assets/筛选.svg";
import CreateReport from "./components/createReport";
import deleteIcon from "../../../assets/delete.svg";
import MyRangePicker from "../../../components/MyRangePicker";
import moment from "moment";
import Slider from "react-slick";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import HeadPortrait from "../../../components/HeadPortrait";
import HeaderTitle from "../../../components/HeaderTitle";
import DownLoadIcon from "../../../components/Icons/DownLoadIcon";
const Content = (props) => {
  const { typeList, creatorList, onFilter, filterOptions, data } = props;
  const [date, setDate, getDate] = useGetState([]);
  const [type, setType, getType] = useGetState([]);
  const [creators, setCreators, getCreators] = useGetState([]);

  useEffect(() => {
    setDate(filterOptions?.date || []);
    setType(filterOptions?.type || []);
    setCreators(filterOptions?.creators || []);
  }, [filterOptions]);

  const filterHandle = () => {
    let params = {
      date: getDate(),
      type: getType(),
      creators: getCreators(),
    };
    onFilter && onFilter(params, data);
  };

  return (
    <div className="filterIconContent">
      <div className="itemBox">
        <div className="keyBox">生成时间</div>
        <div className="valueBox">
          <MyRangePicker
            notInitValue={true}
            allowClear={true}
            format="YYYY-MM-DD"
            value={date.map((_) => {
              if (_) {
                return moment(_);
              }
              return null;
            })}
            onChange={async (date, dateString) => {
              await setDate(dateString);
              filterHandle();
            }}
          ></MyRangePicker>
        </div>
      </div>
      <div className="itemBox">
        <div className="keyBox">报告类型</div>
        <div className="valueBox">
          <Select
            style={{ width: "100%" }}
            mode="multiple"
            value={type}
            allowClear
            placeholder="全部"
            maxTagCount="responsive"
            onChange={async (value) => {
              await setType(value);
              filterHandle();
            }}
          >
            {typeList.map((item, index) => (
              <Select.Option key={item} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="itemBox">
        <div className="keyBox">创建人</div>
        <div className="valueBox">
          <Select
            style={{ width: "100%" }}
            mode="multiple"
            value={creators}
            allowClear
            placeholder="全部"
            maxTagCount="responsive"
            onChange={async (value) => {
              await setCreators(value);
              filterHandle();
            }}
          >
            {creatorList.map((item, index) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};
const BondReportManagement = (props) => {
  const { id } = useParams();
  const bgColor = ["#829DFF", "#C88EED", "#FF9E82", "#00A5AF"];

  const [isCreate, setIsCreate] = useState(false);
  const [showData, setShowData] = useState([]);
  // const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const userInfo = useSelector((state) => state.User.userInfo) || {};
  const [noData, setNoData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData, getData] = useGetState([]);
  const [downloadVisible, setDownloadVisible] = useState(false);
  const [downloadList, setDownloadList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [processInfo, setProcessInfo] = useState({});

  const [filterOptions, setFilterOptions, getFilterOptions] = useGetState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [creatorList, setCreatorList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  const timer = useRef(null);
  const unmountedRef = useUnmountedRef();
  const carouselRef = useRef(null);
  const [previewVisible, setPreviewVisible] = useState(false);

  const [notHasPrev, setNotHasPrev] = useState(true);
  const [notHasNext, setNotHasNext] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [reportTypeList, setReportTypeList] = useState([]);
  const [downloadKeys, setDownloadKeys, getDownloadKeys] = useGetState([]);

  useEffect(() => {
    if (isCreate) {
      nProgress.start();
    }
    nProgress.done();
  }, [isCreate]);

  const initData = (data) => {
    let incompleteItems = [];
    let incompleteMenuItems = [];
    data.forEach((item, index) => {
      if (item.detail) {
        item.detail?.forEach((_item) => {
          //正在生成
          if (_item.status === 1) {
            incompleteItems.push(_item.id);
            incompleteMenuItems.push(_item.manu_id);
          }
        });
      }
    });
    setData(data);
    if (incompleteMenuItems.length > 0) {
      getReportProcess(incompleteItems, incompleteMenuItems);
    }
  };
  //获取进度
  const getReportProcess = (incompleteItems, incompleteMenuItems) => {
    projectApi
      .getBondReportProcess(
        incompleteItems.join(","),
        incompleteMenuItems.join(","),
        id
      )
      .then((res) => {
        console.log("获取进度", res.data);
        if (res.code === 200) {
          initProcessItems(res.data);
        } else {
          message.error(res.message);
        }
      });
  };
  //处理进度项
  const initProcessItems = (processObj) => {
    const _data = getData().map((item) => {
      item.detail = item.detail.map((_item) => {
        if (processObj[_item.manu_id] !== undefined) {
          if (processObj[_item.manu_id]?.process === 100) {
            _item.size = processObj[_item.manu_id]?.size;
            _item.status = 2;
          }
        }
        return _item;
      });
      return item;
    });
    setData(_data);
    let obj = {};
    let incompleteItems = [];
    let incompleteManuItems = [];
    for (let key in processObj) {
      if (processObj[key]?.process !== 100) {
        incompleteManuItems.push(key);
        obj[key] = processObj[key]?.process;
      }
    }
    setProcessInfo(obj);
    if (incompleteManuItems.length > 0) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        if (unmountedRef.current) {
          console.log("已经卸载");
          return;
        }
        getReportProcess(incompleteItems, incompleteManuItems);
      }, 1500);
    }
  };

  const getDataFunc = () => {
    setLoading(true);
    const params = {
      project_id: 0,
      company_id: id,
    };
    projectApi.getBondGenerationList(params).then((res) => {
      if (res.code === 200) {
        if (res.data?.length > 0) {
          initData(res.data);
          setNoData(false);
        } else {
          setNoData(true);
        }
      } else {
        message.error(res.message);
        setNoData(true);
      }
      setTimeout(() => {
        setLoading(false);
      }, 100);
    });
  };

  const getReportTypes = () => {
    projectApi.getBondReportTypes({}).then((res) => {
      if (res.code === 200) {
        console.log(res);
        setReportTypeList(res.data || []);
      } else {
        message.error(res.message);
      }
    });
  };
  useEffect(() => {
    getDataFunc();
    getReportTypes();
  }, []);

  useEffect(() => {
    const creatorList = [];
    const typeList = [];
    data?.forEach((item) => {
      if (!creatorList?.some((_) => _.value === item.creator_id)) {
        creatorList.push({
          value: item.creator_id,
          label: item.creator,
        });
      }
      item?.detail?.forEach((reportItem) => {
        if (!typeList?.some((_) => _ === reportItem.type)) {
          typeList.push(reportItem.type);
        }
      });
    });
    setCreatorList(creatorList);
    setTypeList(typeList);
    filterDataFunc(getFilterOptions(), getData());
  }, [data]);

  useEffect(() => {
    let isFilter = false;
    if (filterOptions?.date) {
      if (filterOptions?.date?.at(0) && filterOptions?.date?.at(1)) {
        isFilter = true;
      }
    }
    if (filterOptions?.type?.length > 0) {
      isFilter = true;
    }
    if (filterOptions?.creators?.length > 0) {
      isFilter = true;
    }
    setIsFilter(isFilter);
  }, [filterOptions]);

  const filterDataFunc = (filterOptions, data) => {
    let filterData = JSON.parse(JSON.stringify(data));
    setFilterOptions(filterOptions);
    let keys = Object.keys(filterOptions);
    keys.forEach((key) => {
      if (filterOptions[key]?.length === 0) {
        return;
      }
      if (key === "date") {
        filterData = filterData.filter((item) => {
          return (
            moment(filterOptions[key]?.at(0) + " 00:00:00").isBefore(
              item.create_time
            ) &&
            moment(filterOptions[key]?.at(1) + " 23:59:59").isAfter(
              item.create_time
            )
          );
        });
      } else if (key === "type") {
        filterData = filterData.map((item) => {
          item.detail = item.detail?.filter((_) =>
            filterOptions[key].includes(_.type)
          );
          return item;
        });
        filterData = filterData.filter((_) => _?.detail?.length > 0);
      } else if (key === "creators") {
        filterData = filterData.filter((item) => {
          return filterOptions[key].includes(item.creator_id);
        });
      }
    });
    setShowData(filterData);
    // setLoading(false);
  };
  //删除
  const deleteItem = (item, reportItem) => {
    MessageModal({
      type: "warning",
      description: "确定要删除底稿吗？",
      title: "删除",
      onOk: () => {
        // statusChangeHandle(status, data);
        let params = {
          project_id: 0,
          generation_id: item.generation_id,
          company_id: id,
        };
        if (reportItem) {
          params.report_id = reportItem.id;
        }
        projectApi.deleteBondGeneration(params).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getDataFunc();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const downloadAllDis = (item) => {
    let dis = false;
    item.detail?.forEach((_) => {
      if (_.status !== 2) {
        dis = true;
      }
    });
    return dis;
  };

  //点击下载
  const downloadItem = (item, reportItem) => {
    // const path = "/save/20221011120546.txt";
    // const token = getToken()
    // window.open(
    //   `http://10.244.184.95:9001/cdd/pm/info/rpt/download_file?token=${token}&path=${path}&key=${Math.random()}`,
    //   "Download"
    // );

    let params = {
      project_id: 0,
      generation_id: item.generation_id,
      company_id: id,
    };
    if (reportItem) {
      params.report_id = reportItem.id;
    }
    projectApi.downloadBondGeneration(params).then((res) => {
      if (res.code === 200) {
        downloadGeneration(res.data.file_name, res.data.url);
      } else {
        message.error(res.message);
      }
      setDownloadKeys((prev) => {
        prev = prev?.filter((_) => _ !== item?.generation_id);
        return [...prev];
      });
    });
  };
  //下载单个
  const downloadGeneration = (file_name, file_list) => {
    if (file_list.length === 1) {
      downloadFile(file_list?.at(0), id, true);
      return;
    }
    setDownloadVisible(true);
    let pdfList = file_list.filter((_) => _?.slice(-3) === "pdf");
    let arr = file_list.filter((_) => _?.slice(-3) !== "pdf");
    let len = arr.length;
    arr = arr.map((item, index) => {
      const obj = {};
      obj.url = item;
      obj.fileName = item?.slice(item?.lastIndexOf("/") + 1); //len > 1 ? file_name + `${len}_${index + 1}` : file_name;
      return obj;
    });
    pdfList = pdfList.map((item, index) => {
      const obj = {};
      obj.url = item;
      obj.fileName = item?.slice(item?.lastIndexOf("/") + 1);
      return obj;
    });
    arr = arr.concat(pdfList);
    setDownloadList(arr);
  };
  //下载多个
  const downloadItems = () => {
    if (selectedRowKeys.length === 0) {
      message.info("请至少选择一项进行下载");
      return;
    }
    selectedRowKeys.forEach((url, index) => {
      const item = downloadList.find((_) => _.url === url);
      setTimeout(() => {
        downloadFile(item.url, id, true);
      }, index * 100);
    });
  };
  //预览
  const previewFile = async (file) => {
    await setPdfUrl(file.pdfUrl);
    setTimeout(() => {
      setPreviewVisible(true);
    }, 1000);
  };

  //新增报告
  if (isCreate) {
    return (
      <CreateReport
        setIsCreate={setIsCreate}
        onClose={() => {
          setIsCreate(false);
          setNotHasPrev(true);
          setNotHasNext(false);
          setPdfUrl(null);
        }}
        getData={() => {
          setFilterOptions([]);
          setIsFilter(false);
          getDataFunc();
        }}
      ></CreateReport>
    );
  }
  //报告列表
  return (
    <div className="reportManagementContent">
      <HeaderTitle title="底稿管理"></HeaderTitle>
      <div className="content">
        {/* 生成报告按钮 */}
        <div
          className="createBtnBox"
          onClick={() => {
            getLicenseValid(false, true).then((res) => {
              if (res) {
                setIsCreate(true);
              }
            });
          }}
        >
          <div className="btnContent">
            <ReactSVG className="wordIcon" src={wordIcon}></ReactSVG> 生成报告
          </div>
          <div className="btnIcon">
            <PlusOutlined />
          </div>
        </div>

        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: `calc(100% - 100px)`,
            width: "100%",
            marginTop: "16px",
            zIndex: 55,
          }}
        >
          <div style={{ paddingRight: "20px" }}>
            {/* 报告模板标题 */}
            <div
              className="orderTitle"
              style={{
                marginTop: "8px",
              }}
            >
              <ReactSVG className="icon" src={icon1}></ReactSVG>报告模板
            </div>
            {/* 报告模板介绍 */}
            <div className="introduceBox">
              <div style={{ display: "block", height: "100%", width: "100%" }}>
                <Slider
                  dots={true}
                  infinite={false}
                  speed={500}
                  slidesToShow={4}
                  slidesToScroll={1}
                  // autoplay={true}
                  // autoplaySpeed={5000}
                  ref={carouselRef}
                  beforeChange={(current, next) => {
                    setNotHasPrev(next === 0);
                    setNotHasNext(next + 4 === reportTypeList?.length);
                  }}
                >
                  {reportTypeList.map((item, _index) => (
                    <Tooltip
                      key={_index}
                      title={item.details}
                      arrowPointAtCenter
                    >
                      <div
                        onClick={() => {
                          previewFile(item);
                        }}
                        key={_index}
                        className="introduceReportBox"
                        style={{ backgroundColor: bgColor[_index % 4] }}
                      >
                        <div className="introduceReportItem">
                          <div className="reportType">{item.type}</div>
                          <div className="reportContent">
                            <img src={item.img} />
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  ))}
                </Slider>
              </div>

              {/* <Carousel ref={carouselRef}>
            {new Array(Math.ceil(reportTypeList?.length / 4))
              .fill(null)
              .map((item, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    {reportTypeList
                      ?.slice(index * 4, index * 4 + 4)
                      .map((item, _index) => (
                        <Tooltip
                          key={_index}
                          title={item.details}
                          arrowPointAtCenter
                        >
                          <div
                            onClick={() => {
                              previewFile(item);
                            }}
                            key={_index}
                            className="introduceReportBox"
                            style={{ backgroundColor: bgColor[_index % 4] }}
                          >
                            <div className="introduceReportItem">
                              <div className="reportType">{item.type}</div>
                              <div className="reportContent">
                                <img src={item.img} />
                              </div>
                            </div>
                          </div>
                        </Tooltip>
                      ))}
                  </div>
                </div>
              ))}
          </Carousel> */}

              {reportTypeList?.length > 4 && !notHasPrev && (
                <LeftOutlined
                  className="carouselIcon LeftOutlined"
                  onClick={() => carouselRef.current?.slickPrev()}
                />
              )}

              {reportTypeList?.length > 4 && !notHasNext && (
                <RightOutlined
                  className="carouselIcon RightOutlined"
                  onClick={() => carouselRef.current?.slickNext()}
                />
              )}
            </div>
            {/* 全部报告标题 */}
            <div className="orderTitle">
              <ReactSVG className="icon" src={icon2}></ReactSVG>全部报告
              {/* 筛选 */}
              {data?.length > 0 && (
                <div className="filterIcon">
                  <Popover
                    placement="bottomLeft"
                    trigger="click"
                    open={filterOpen}
                    overlayClassName="myCardPopover noPaddingPopover filterIconPopover"
                    content={
                      <Content
                        creatorList={creatorList}
                        typeList={typeList}
                        filterOptions={filterOptions}
                        onFilter={(filterOptions, data) => {
                          setLoading(true);
                          setTimeout(() => {
                            filterDataFunc(filterOptions, data);
                            setLoading(false);
                          }, 100);
                        }}
                        data={data}
                      ></Content>
                    }
                    onOpenChange={(newOpen) => setFilterOpen(newOpen)}
                  >
                    <ReactSVG
                      src={filterIcon}
                      className={isFilter ? "activeFilterIcon" : ""}
                    ></ReactSVG>
                  </Popover>
                </div>
              )}
            </div>
            {/* 报告列表 */}
            <div
              className="reportListBox"
              style={{
                minHeight: `calc(100% - 430px)`,
                height: noData || loading ? `calc(100% - 430px)` : "auto",
              }}
            >
              {loading ? (
                <MySpin></MySpin>
              ) : noData ? (
                <div className="noData">
                  <MyEmpty
                    description={"还没有生成底稿，现在立即生成？"}
                  ></MyEmpty>
                </div>
              ) : (
                // <Scrollbars
                //   autoHide={true}
                //   autoHideTimeout={5000}
                //   className="reportListContent"
                //   style={{ height: "100%", width: "100%" }}
                // >
                <div className="reportListContent">
                  {showData?.length > 0 ? (
                    <Timeline>
                      {showData?.map((item, index) => (
                        <Timeline.Item
                          key={item.generation_id}
                          dot={
                            <HeadPortrait
                              size={36}
                              src={item.pf_photo}
                              title={
                                item.show_name +
                                (item.assist_name ? "/" + item.assist_name : "")
                              }
                            ></HeadPortrait>
                          }
                        >
                          <div className="timelineItem">
                            <div className="timelineItemTitle">
                              <span>{item.create_time}</span>
                              <span
                                style={{
                                  marginLeft: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.show_name}
                              </span>
                              {getDownloadKeys()?.some(
                                (_) => _ === item?.generation_id
                              ) ? (
                                <LoadingOutlined className="downloadIcon" />
                              ) : (
                                <>
                                  <Tooltip
                                    placement="top"
                                    title="全部下载"
                                    arrowPointAtCenter
                                  >
                                    <DownLoadIcon
                                      className={
                                        downloadAllDis(item)
                                          ? "disIcon downloadIcon"
                                          : "downloadIcon"
                                      }
                                      onClick={() => {
                                        if (downloadAllDis(item)) {
                                          return;
                                        }
                                        setDownloadKeys((prev) => {
                                          return [...prev, item?.generation_id];
                                        });
                                        downloadItem(item);
                                      }}
                                    ></DownLoadIcon>
                                  </Tooltip>
                                </>
                              )}
                              {item.creator_id === userInfo.co_user_id && (
                                // <Button
                                //   size="small"
                                //   type="primary"
                                //   className="btn"
                                //   style={{ marginLeft: "8px" }}
                                //   onClick={() => {
                                //     deleteItem(item);
                                //   }}
                                // >
                                //   全部删除
                                // </Button>
                                <Tooltip
                                  placement="top"
                                  title="全部删除"
                                  arrowPointAtCenter
                                >
                                  <ReactSVG
                                    src={deleteIcon}
                                    className={
                                      getDownloadKeys()?.some(
                                        (_) => _ === item?.generation_id
                                      )
                                        ? "deleteIcon disDeleteIcon"
                                        : "deleteIcon"
                                    }
                                    onClick={() => {
                                      if (
                                        getDownloadKeys()?.some(
                                          (_) => _ === item?.generation_id
                                        )
                                      ) {
                                        return;
                                      }
                                      deleteItem(item);
                                    }}
                                  ></ReactSVG>
                                </Tooltip>
                              )}
                            </div>

                            <div className="timelineItemContent">
                              {item?.detail?.map((reportItem) => (
                                <ReportCard
                                  processInfo={processInfo}
                                  deleteItem={deleteItem}
                                  item={item}
                                  downloadItem={downloadItem}
                                  key={reportItem.id}
                                  reportInfo={reportItem}
                                  getData={getDataFunc}
                                ></ReportCard>
                              ))}
                            </div>
                          </div>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  ) : (
                    <div className="noData">
                      <MyEmpty description="暂无数据"></MyEmpty>
                    </div>
                  )}
                </div>
                // </Scrollbars>
              )}
            </div>
          </div>
        </Scrollbars>
      </div>

      <Modal
        wrapClassName="downloadModal"
        visible={downloadVisible}
        title={
          <div>
            提示
            <span
              style={{ fontSize: "12px", marginLeft: "6px", color: "#FE545F" }}
            >
              {selectedRowKeys?.length > 5
                ? "受浏览器性能限制，一次性下载选择过多文件可能会影响其他操作！"
                : ""}
            </span>
          </div>
        }
        onCancel={() => {
          setDownloadVisible(false);
          setSelectedRowKeys([]);
        }}
        destroyOnClose={true}
        footer={false}
        centered
      >
        <div className="checkBox">
          <Checkbox
            checked={selectedRowKeys?.length === downloadList?.length}
            indeterminate={
              selectedRowKeys?.length !== 0 &&
              selectedRowKeys?.length !== downloadList?.length
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRowKeys(downloadList.map((item) => item.url));
              } else {
                setSelectedRowKeys([]);
              }
            }}
          >
            全选
          </Checkbox>
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            onChange={(value) => {
              setSelectedRowKeys(value);
            }}
            value={selectedRowKeys}
          >
            {downloadList.map((item) => (
              <Checkbox value={item.url}>{item.fileName}</Checkbox>
            ))}
          </Checkbox.Group>
        </div>

        <div className="tipBox">
          <ExclamationCircleOutlined />{" "}
          报告内容较多，为方便您下载和阅读，系统将文件拆分为多个小文件，建议您每次下载不超过3个
        </div>
        <div className="modalBtnBox">
          <Button
            style={{ marginRight: "24px" }}
            onClick={() => {
              setDownloadVisible(false);
              setSelectedRowKeys([]);
            }}
          >
            取消
          </Button>
          <Button
            type="primary"
            onClick={() => {
              downloadItems();
            }}
          >
            下载
          </Button>
        </div>
      </Modal>

      <Modal
        wrapClassName="previewModal"
        visible={previewVisible}
        title="报告模板预览"
        onCancel={() => {
          setPreviewVisible(false);
          setPdfUrl(null);
        }}
        destroyOnClose={true}
        footer={false}
        centered
      >
        <div id="previewBox">
          <embed
            src={pdfUrl + "#toolbar=0"}
            style={{ width: "100%", height: "100%" }}
            type="application/pdf"
          />
        </div>
      </Modal>
    </div>
  );
};
export default BondReportManagement;
