import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import { useState, useEffect, useRef } from "react";
import MyDiv from "../../../components/MyDiv/index";
import { guid, showTipFunc } from "../../../unit/unit.js";
import { Tabs, message } from "antd";
import MySpin from "../../../components/MySpin";
import useUrlState from "@ahooksjs/use-url-state";
import BiddingAnalysis from "./components/BiddingAnalysis";
import CompetitivenessAnalysis from "./components/CompetitivenessAnalysis";
import projectApi from "../../../apis/project";
import { useGetState } from "ahooks";
import CompetitorsTable from "./components/CompetitorsTable";
import DialogDrawer from "../../../components/DialogDrawer";
import { useSelector } from "react-redux";
import TenderingAndSupplierAnalysis from "./components/TenderingAndSupplierAnalysis/index.jsx";

const { TabPane } = Tabs;

const TenderingAndBidding = (props) => {
  const { id } = useParams();

  const [type, setType, getType] = useGetState("");
  const [state, setState] = useUrlState({});
  const [dataInfo, setDataInfo] = useState({});
  const [showHeader, setShowHeader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [tableType, setTableType, getTableType] =
    useGetState("投标结果");

  const [drawerTitle, setDrawerTitle] = useState(null);
  const [defaultData, setDefaultData] = useState(null);
  const [formList, setFormList] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [okLoading, setOkLoading] = useState(false);
  const ref = useRef();
  const [updateTime, setUpdateTime] = useState(null);

  const projectProgressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );

  const [
    defaultFilterCriteria,
    setDefaultFilterCriteria,
    getDefaultFilterCriteria,
  ] = useGetState(null);

  const tableRef = useRef(null);
  const tabs = useRef({
    投标及客户分析: "listed_bid_info",
    招标及供应商分析: "listed_bid_info",
    竞争对手分析: "listed_bid_info",
    // 中标列表: "listed_bid_winner",
    尽调对象及关联方招投标明细: "listed_bid_info",
    竞争对手招投标明细: "listed_bid_info",
  });

  const eventTypeCodeList = {
    招标及供应商分析: "CDD_CONCERN_TEND_SUP_ANA",
    投标及客户分析: "CDD_CONCERN_DLP_ANA",
    竞争对手分析: "CDD_CONCERN_CTT_ANA",
  };

  const typeNameList = {
    招标及供应商分析: "招标及供应商分析",
    投标及客户分析: "投标及客户分析",
    竞争对手分析: "竞争对手分析",
    // 中标列表: "中标列表",
    尽调对象及关联方招投标明细: "尽调对象及关联方招投标明细",
    竞争对手招投标明细: "竞争对手招投标明细",
  };

  const tableTypeNameList = useRef({
    招标公告: "listed_invite_bid_pubic",
    招标结果: "listed_invite_bid_res",
    投标结果: "listed_hit_bid_res",
  });

  const eventTypeKeyList = {
    招标公告: "listed_invite_bid_pubic",
    招标结果: "listed_invite_bid_res",
    投标结果: "listed_hit_bid_res",
  };

  useEffect(() => {
    let _type = "投标及客户分析";
    if (state.type) {
      _type = Object.keys(typeNameList)?.find(
        (_) => typeNameList[_] === state.type
      );
    }
    tabChange(_type);
  }, []);

  const tabChange = (key) => {
    console.log(key, tableType);
    setDefaultFilterCriteria(null);
    setType(key);
    if (
      key === "投标及客户分析" ||
      key === "竞争对手分析" ||
      key === "招标及供应商分析"
    ) {
      getSpecialFocusRemark(key);
    } else if (key === "尽调对象及关联方招投标明细") {
      setDataInfo({});
      setTableType("投标结果");
    } else if (key === "竞争对手招投标明细") {
      setDataInfo({});
      setTableType("投标结果");
      setTimeout(() => {
        ref.current?.onPageChange(1);
      }, 50);
    }
  };

  const getSpecialFocusRemark = (type) => {
    projectApi
      .getSpecialFocusRemark(id, {
        table_name: "listed_bid_info",
        event_type_code: eventTypeCodeList[type],
      })
      .then((res) => {
        if (res.code === 200) {
          setDataInfo({
            event_type_remark: res.data?.event_type_remark,
          });
        } else {
          message.error(res.message);
          setDataInfo({});
        }
      });
  };

  const addOrEditCompetitors = (type, item) => {
    setDrawerTitle(
      `${type === "add" ? "新增" : "修改"}竞争对手${getTableType()}`
    );
    projectApi
      .getTableStyle(tableTypeNameList.current[getTableType()], id, 1)
      .then((res) => {
        if (res.code === 200) {
          setFormList(res.data);
          if (type === "edit") {
            setDefaultData(JSON.parse(JSON.stringify(item)));
          } else {
            setDefaultData(null);
          }
          setTimeout(() => {
            setDrawerVisible(true);
          }, 100);
        } else {
          message.error(res.message);
        }
      });
  };

  const success = (obj) => {
    setOkLoading(true);
    let params = {
      name: tableTypeNameList.current[getTableType()],
      project_id: id,
      data_string: JSON.stringify(obj),
      is_bid: 1,
    };
    if (defaultData) {
      params.id = defaultData.id?.val;
    }
    projectApi.setTableItem(params).then(async (res) => {
      if (res.code === 200) {
        await setDrawerVisible(false);
        await ref.current?.onPageChange(1);
        message.success(res.message);
      } else {
        message.error(res.message);
      }
      setOkLoading(false);
    });
  };

  const searchTableFunc = (filterCriteria, isBid, tableType) => {
    if (filterCriteria?.length > 0) {
      setDefaultFilterCriteria(filterCriteria);
    } else {
      setDefaultFilterCriteria(null);
    }
    setTableType(tableType || "投标结果");
    setTimeout(() => {
      setType(isBid ? "竞争对手招投标明细" : "尽调对象及关联方招投标明细");
      if (isBid) {
        setTimeout(() => {
          if (filterCriteria) {
            ref.current?.setGroupByFields(filterCriteria);
          }
          ref.current?.onPageChange(1);
        }, 50);
      }
    }, 50);
  };

  const showTip = showTipFunc(projectProgressCode, "招投标");
  return (
    <div className="tenderingAndBiddingContent">
      {/* <HeaderTitle
        title="招投标"
        showTip={showTip}
      ></HeaderTitle> */}
      <div className="biddingTitleDiv">
        <span className="biddingTitle">招投标</span>
        {["投标及客户分析", "招标及供应商分析", "竞争对手分析"]?.includes(
          getType()
        ) &&
          (Object.prototype.toString.call(updateTime) === "[object String]" ? (
            <span style={{ color: "#9E9E9E" }}>
              {"更新时间：" + (updateTime || "-")}
            </span>
          ) : (
            updateTime
          ))}
      </div>

      {loading ? (
        <MySpin></MySpin>
      ) : (
        <div className="tabBox">
          <Tabs
            className="myTabs"
            onChange={tabChange}
            activeKey={type}
            destroyInactiveTabPane={true}
            // tabBarExtraContent={<div className="typeSreach">事项类型 <CaretDownOutlined /></div>}
          >
            {Object.keys(tabs.current).map((key) => (
              <TabPane
                tab={
                  type === key && showHeader ? (
                    <MyDiv
                      info={{
                        ...dataInfo,
                        comments: dataInfo?.event_type_remark,
                        function: ["comments", "remark"],
                        id: guid(),
                      }}
                      dataTable={tabs.current[key]}
                      typeName={typeNameList[key]}
                      getData={() => {
                        if (getType() === "竞争对手招投标明细") {
                          ref.current?.getCompetitiveHitData();
                        } else if (getType() === "尽调对象及关联方招投标明细") {
                          tableRef.current?.getData();
                        } else {
                          getSpecialFocusRemark(key);
                        }

                        setShowHeader(false);
                        setTimeout(() => {
                          setShowHeader(true);
                        }, 100);
                      }}
                      mode={30}
                      dataInfo={{}}
                    >
                      {key}
                    </MyDiv>
                  ) : (
                    key
                  )
                }
                key={key}
              >
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="infoBox">
                    {key === "投标及客户分析" && (
                      <BiddingAnalysis
                        searchTableFunc={searchTableFunc}
                        updateTimeCallBack={setUpdateTime}
                      ></BiddingAnalysis>
                    )}
                    {key === "招标及供应商分析" && (
                      <TenderingAndSupplierAnalysis
                        searchTableFunc={searchTableFunc}
                        updateTimeCallBack={setUpdateTime}
                      ></TenderingAndSupplierAnalysis>
                    )}
                    {key === "竞争对手分析" && (
                      <CompetitivenessAnalysis
                        searchTableFunc={searchTableFunc}
                        updateTimeCallBack={setUpdateTime}
                      ></CompetitivenessAnalysis>
                    )}
                    {key === "尽调对象及关联方招投标明细" && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <div className="tableTypeBox">
                          {["投标结果", "招标公告", "招标结果"]?.map((_) => (
                            <div
                              key={_}
                              className={tableType === _ ? "activeItem" : null}
                              onClick={() => {
                                setDefaultFilterCriteria(null);
                                setTableType(_);
                              }}
                            >
                              {_}{" "}
                              <span>
                                {(dataInfo?.cbc_map || {})[eventTypeKeyList[_]]}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div className="tableTypeContent">
                          {
                            <MyTable
                              key={tableType}
                              defaultFilterCriteria={defaultFilterCriteria}
                              typeName={"尽调对象及关联方招投标明细"}
                              tableName={"尽调对象及关联方" + getTableType()}
                              dataTable={
                                tableTypeNameList.current[getTableType()]
                              }
                              dataChange={(info) => {
                                if (
                                  getType() !== "尽调对象及关联方招投标明细"
                                ) {
                                  return;
                                }
                                setDataInfo(info);
                              }}
                              ref={tableRef}
                            ></MyTable>
                          }
                        </div>
                      </div>
                    )}
                    {key === "竞争对手招投标明细" && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <div className="tableTypeBox">
                          {["投标结果", "招标公告", "招标结果"]?.map((_) => (
                            <div
                              key={_}
                              className={tableType === _ ? "activeItem" : null}
                              onClick={() => {
                                setDefaultFilterCriteria(null);
                                setTableType(_);
                                setTimeout(() => {
                                  ref.current?.onPageChange(1);
                                }, 50);
                              }}
                            >
                              {_}{" "}
                              <span>
                                {(dataInfo?.cbc_map || {})[eventTypeKeyList[_]]}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div className="tableTypeContent">
                          {
                            <CompetitorsTable
                              key={tableType}
                              tableType={tableType}
                              dataTable={
                                tableTypeNameList.current[getTableType()]
                              }
                              typeName={"竞争对手招投标明细"}
                              defaultFilterCriteria={defaultFilterCriteria}
                              setIsDownFunc={() => {}}
                              ref={ref}
                              dataChange={(info) => {
                                if (getType() !== "竞争对手招投标明细") {
                                  return;
                                }
                                setDataInfo(info);
                              }}
                              addOrEditCompetitors={addOrEditCompetitors}
                            ></CompetitorsTable>
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </Scrollbars>
              </TabPane>
            ))}
          </Tabs>
        </div>
      )}
      <DialogDrawer
        okLoading={okLoading}
        title={drawerTitle}
        okText="确定"
        cancelText="取消"
        defaultData={defaultData}
        formList={formList}
        onOk={success}
        visible={drawerVisible}
        typeName="中标列表"
        onCancel={() => {
          setDrawerVisible(false);
        }}
      ></DialogDrawer>
    </div>
  );
};
export default TenderingAndBidding;
