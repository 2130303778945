import "./index.scss";
import {
  Button,
  Drawer,
  Input,
  Form,
  Select,
  Table,
  message,
  Tooltip,
  Tag,
  Avatar,
  Popover,
} from "antd";
import { CloseOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useEffect, useRef, useState } from "react";
import ContentMap from "./component/ContentMap";
import projectApi from "../../apis/project/index";
import DebounceSelect from "../../components/DebounceSelect";
import { useSelector } from "react-redux";
import { useGetState } from "ahooks";
import { ReactSVG } from "react-svg";
import deleteIcon from "../../assets/删除.svg";
import {
  getAdminsDom,
  getContactBusinessDom,
  guid,
  verifyAdmin,
} from "../../unit/unit";
const { Option } = Select;

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        loading={props.creatLoading}
        onClick={() => {
          props.onFinish();
        }}
        type="primary"
      >
        创建
      </Button>
      <Button
        className="noBg"
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};

const CreatProjectDrawer = (props) => {
  const { projectType, successFunc } = props;
  const [loading, setLoading] = useState(false);
  const [creatLoading, setCreatLoading] = useState(false);
  const [form] = Form.useForm();
  const mapRef = useRef();
  const [userList, setUserList] = useState([]);
  const [sceneOptions, setSceneOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  const [mapType, setMapType, getMapType] = useGetState("");
  const [licenseNums, setLicenseNums, getLicenseNums] = useGetState({});

  const [isArtificial, setIsArtificial, getIsArtificial] = useGetState(false);

  const myInfo = useSelector((state) => state.User.userInfo);
  const admins = useSelector((state) => state.User.admins);

  // useEffect(() => {
  //   if (form && projectType) {
  //     form?.setFieldsValue({ scene_code: projectType });
  //   }
  //   setMapType(projectType);
  //   console.log(projectType);
  // }, []);

  const getUsers = () => {
    return projectApi.getUsers().then((res) => {
      let arr = [];
      if (res.code === 200) {
        Object.values(res.data).forEach((item) => {
          arr = arr.concat(item);
        });
      }
      setUserOptions(arr.filter((item) => item.user_id !== myInfo.co_user_id));
      return arr;
    });
  };

  const getRole = () => {
    return projectApi.getDicCodeInfo("CDD_PRJ_ROLE").then((res) => {
      let arr = [];
      if (res.code === 200) {
        arr = res.data;
      }
      arr = arr.filter((item) => item.dic_item_code !== "CDD_PRJ_ROLE_CREATOR");
      setRoleOptions(arr);
      return res.data;
    });
  };

  const clearAll = () => {
    setUserList([]);
    setSceneOptions([]);
    setUserOptions([]);
    setRoleOptions([]);
    setMapType("");
    setLicenseNums({});
    setIsArtificial(false);
    form?.resetFields();
  };

  const setDefProjectType = () => {
    if (form && projectType) {
      form?.setFieldsValue({ scene_code: projectType });
      setMapType(projectType);
    }
  };

  //判断尽调数量
  const calcQuantity = (projectType, licenseObj) => {
    let licenseArr = [];
    if (
      projectType === "CDD_SCENE_TYPE_CUS" ||
      projectType === "CDD_SCENE_TYPE_IPO"
    ) {
      //自定义和ipo项目
      licenseArr = licenseObj["CDD_NUM_ADV"];
    } else if (projectType === "CDD_SCENE_TYPE_PRE") {
      //初期尽调
      licenseArr = licenseObj["CDD_NUM_PRE"];
    } else {
      return;
    }
    if (licenseArr?.at(1) === -1) {
      return;
    }
    const remaining = (licenseArr?.at(1) || 0) - (licenseArr?.at(0) || 0);
    if (remaining <= 0) {
      if (licenseArr?.at(1) === 0) {
        message.warning({
          content: verifyAdmin(myInfo) ? (
            <span>
              当前产品授权不支持该尽调场景，请
              {getContactBusinessDom()}
              升级订阅方案
            </span>
          ) : (
            <span>
              当前产品授权不支持该尽调场景，可联系
              {getAdminsDom()}
              升级订阅方案
            </span>
          ),
        });
      } else {
        message.warning({
          content: verifyAdmin(myInfo) ? (
            <span>
              尽调次数已达上限（已尽调{licenseArr?.at(0) || 0}次 / 授权
              {licenseArr?.at(1) || 0}次），如需继续使用请
              {getContactBusinessDom()}
              升级订阅方案
            </span>
          ) : (
            <span>
              尽调次数已达上限（已尽调{licenseArr?.at(0) || 0}次 / 授权
              {licenseArr?.at(1) || 0}次），如需继续使用请联系
              {getAdminsDom()}
              升级订阅方案
            </span>
          ),
        });
      }
      // props.onClose && props.onClose();
    } else if (remaining <= (licenseArr?.at(2) || 0)) {
      message.warning({
        content: verifyAdmin(myInfo) ? (
          <span>
            尽调次数即将达到上限（已尽调{licenseArr?.at(0) || 0}次 / 授权
            {licenseArr?.at(1) || 0}次），请
            {getContactBusinessDom()}
            升级订阅方案
          </span>
        ) : (
          <span>
            尽调次数即将达到上限（已尽调{licenseArr?.at(0) || 0}次 / 授权
            {licenseArr?.at(1) || 0}次），可联系
            {getAdminsDom()}
            升级订阅方案
          </span>
        ),
      });
    }
    // else {
    //   message.info(`剩余可尽调次数${remaining}次`);
    // }
  };

  useEffect(() => {
    if (!props.visible) {
      clearAll();
    } else {
      projectApi
        .getLicenseNums('["CDD_NUM_ADV", "CDD_NUM_PRE"]')
        .then((res) => {
          if (res.code === 200) {
            setLicenseNums(res.data);
            calcQuantity(projectType, res.data);
          } else {
            props.onClose && props.onClose();
            message.error(res.msg);
          }
        })
        .catch((res) => {
          props.onClose && props.onClose();
        });
      setDefProjectType();
      setLoading(true);
      projectApi.getScenes().then((res) => {
        let list = [];
        if (res.code === 200) {
          list = JSON.parse(JSON.stringify(res.data));
        }
        // list.push({
        //   id: 9999,
        //   scene_name: "自定义",
        //   scene_code: "auto",
        // });
        setSceneOptions(list);
      });
      const userPromise = getUsers();
      const rolePromise = getRole();
      Promise.all([userPromise, rolePromise]).then((res) => {
        const [users, roles] = res;
        let userInfo =
          users.find((item) => item.user_id === myInfo.co_user_id) || {};
        let roleInfo = roles.find(
          (item) => item.dic_item_code === "CDD_PRJ_ROLE_CREATOR"
        );
        setUserList([
          {
            key: userInfo.user_id,
            id: userInfo.user_id,
            user_code: userInfo.assist_name,
            user_name: userInfo.username,
            user_role: roleInfo.dic_item_name,
            role_code: "CDD_PRJ_ROLE_CREATOR",
            pf_photo: userInfo.pf_photo,
            disable: true,
          },
        ]);
        setLoading(false);
      });
    }
  }, [props.visible]);

  const deleteUser = (item) => {
    setUserList((prev) => {
      let result = prev.filter((_item) => item.id !== _item.id);
      return [...result];
    });
  };

  const userRoleChange = (key, item) => {
    setUserList((prev) => {
      let result = prev.map((_item) => {
        if (item.id === _item.id) {
          let roleInfo = roleOptions.find((item) => item.dic_item_code === key);
          _item.role_code = key;
          _item.user_role = roleInfo.dic_item_name;
        }
        return _item;
      });
      return [...result];
    });
  };

  const columns = [
    {
      title: "用户名",
      dataIndex: "user_name",
      key: "user_name",
      align: "left",
      minWidth: "150px",
      render: (text, item) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              whiteSpace: "break-spaces",
            }}
          >
            <Avatar
              size={32}
              src={item.pf_photo}
              style={{ marginRight: "8px", flex: "none" }}
            ></Avatar>
            {text}
          </div>
        );
      },
    },
    {
      title: "姓名",
      dataIndex: "user_code",
      key: "user_code",
      align: "center",
    },
    {
      title: "角色",
      dataIndex: "user_role",
      key: "user_role",
      width: "150px",
      render: (text, item) => {
        if (item.disable) {
          return <div>{item.user_role}</div>;
        }
        return (
          <Select
            style={{ width: "150px" }}
            defaultValue={item.role_code}
            bordered={false}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode || document.body
            }
            onChange={(key) => {
              userRoleChange(key, item);
            }}
          >
            {roleOptions.map((item) => (
              <Option key={item.dic_item_code} value={item.dic_item_code}>
                {item.dic_item_name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: "40px",
      render: (text, item) =>
        item.disable ? (
          <></>
        ) : (
          <Tooltip placement="top" title="删除" arrowPointAtCenter>
            <ReactSVG
              src={deleteIcon}
              className="deleteIcon"
              onClick={() => {
                deleteUser(item);
              }}
            ></ReactSVG>
          </Tooltip>
        ),
    },
  ];

  const addUser = () => {
    const { userName, userRole } = form.getFieldsValue([
      "userName",
      "userRole",
    ]);

    let fields = [];

    if (!userName || userName?.length === 0) {
      fields.push({ name: "userName", errors: ["请选择用户"] });
    }
    if (!userRole) {
      fields.push({ name: "userRole", errors: ["请选择角色"] });
    }
    if (fields.length > 0) {
      return form.setFields(fields);
    }
    // let hasUser = userList.some((item) => item.id === userName);
    // if (hasUser) {
    //   message.info("不能添加重复用户");
    //   return;
    // }
    let roleInfo = roleOptions.find((item) => item.dic_item_code === userRole);
    let userList = [];
    userName.forEach((name) => {
      let userInfo = userOptions.find((item) => item.user_id === name);
      userList.push({
        key: userInfo.user_id,
        id: userInfo.user_id,
        user_code: userInfo.assist_name,
        user_name: userInfo.username,
        user_role: roleInfo.dic_item_name,
        role_code: userRole,
        pf_photo: userInfo.pf_photo,
      });
    });
    setUserList((prev) => {
      return [...prev, ...userList];
    });
    form.setFieldsValue({ userName: [], userRole: undefined });
  };

  const onFinish = () => {
    // successFunc && successFunc();
    // return
    form.validateFields().then((values) => {
      const { scene_code, company_info, project_name } = values;
      if (!project_name) {
        message.warning("项目名称不能为空!");
        return;
      }
      let _function_list = (mapRef.current.getCheckDate() || []).map(
        (item) => ({
          function_code: item.function_code,
          parent_function_code: item.parent_function_code,
        })
      );
      let _userList = userList.map((item) => ({
        user_id: item.id,
        role_code: item.role_code,
      }));
      if (_userList?.length === 0) {
        message.warning("项目成员不能为空!");
        return;
      }
      let params = {
        scene_code,
        company_name: company_info.label,
        company_id: company_info.value,
        project_name,
        user_list: JSON.stringify(_userList),
        function_list: JSON.stringify(_function_list),
      };
      setCreatLoading(true);
      projectApi.creatProject(params).then((res) => {
        if (res.code === 200) {
          message.success(res.message);
          let url = window.location.origin + "/company/" + res.data.project_id;
          window.open(url, guid());
          successFunc && successFunc();
        } else {
          message.error(res.message);
        }
        setCreatLoading(false);
      });
    });
  };

  const setDefProjectName = () => {
    if (getIsArtificial()) {
      return;
    }
    const sceneItem = sceneOptions.find((_) => _.scene_code === getMapType());
    const company_name = form.getFieldValue("company_info")?.label;
    if (sceneItem && company_name) {
      projectApi
        .getProjectName(sceneItem.scene_name, company_name)
        .then((res) => {
          if (res.code === 200) {
            form.setFieldsValue({ project_name: res.data.res_name });
          }
        });
    }
  };

  const sceneChange = async (key) => {
    await setMapType(key);
    await setDefProjectName();
    calcQuantity(key, getLicenseNums());
  };
  const companyNameChange = (key) => {
    setDefProjectName();
  };

  const userNameChange = (key) => {
    form.resetFields(["userName"]);
    form.setFieldsValue({ userName: key });
  };

  const roleChange = (key) => {
    form.resetFields(["userRole"]);
    form.setFieldsValue({ userRole: key });
  };

  async function fetchUserList(username) {
    if (!username) {
      return [];
    }
    return projectApi.getCompanyInfo(username).then((res) => {
      if (res.code !== 200) {
        message.error(res.message);
        return [];
      }
      return res.data.map((item) => ({
        ...item,
        label: item.name,
        value: item.company_id,
      }));
    });
  }

  const filterUserOptions = () => {
    let _userOptions = [];
    _userOptions = userOptions.filter((item) => {
      return !userList.some((_item) => _item.id === item.user_id);
    });
    return _userOptions;
  };

  return (
    <Drawer
      className="creatProjectDrawer"
      width="600px"
      closable={false}
      title="创建尽调项目"
      placement="right"
      push={false}
      onClose={props.onClose}
      visible={props.visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={
        <BtnBox
          onClose={props.onClose}
          creatLoading={creatLoading}
          onFinish={onFinish}
        ></BtnBox>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="creatProjectForm"
        >
          <Form.Item
            style={{ width: "calc(50% - 10px)" }}
            label="尽调场景"
            name="scene_code"
            rules={[{ required: true, message: "请选择尽调场景" }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="请选择尽调场景"
              onChange={sceneChange}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              // allowClear
            >
              {sceneOptions.map((item) => (
                <Option key={item.id} value={item.scene_code}>
                  {item.scene_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: "calc(50% - 10px)" }}
            label="尽调对象"
            name="company_info"
            rules={[{ required: true, message: "请选择尽调对象" }]}
          >
            <DebounceSelect
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              showSearch={true}
              placeholder="请选择尽调对象"
              fetchOptions={fetchUserList}
              onChange={companyNameChange}
              changeClear={true}
              allowClear
              style={{
                width: "100%",
              }}
            />

            {/* <Select
              style={{ width: "100%" }}
              placeholder="请选择尽调对象"
              onChange={companyNameChange}
              allowClear
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select> */}
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            label="项目名称"
            name="project_name"
          >
            <Input
              maxLength={100}
              allowClear
              onChange={(e) => {
                setIsArtificial(!!e.target.value);
                form.setFieldsValue({
                  project_name: e.target.value ? e.target.value?.trim() : null,
                });
              }}
              // onKeyUp={(e) => {
              //   console.log(e);
              // }}
            />
          </Form.Item>
          {myInfo?.co_type_code !== "INDIV_ORG" ? (
            <Form.Item
              style={{ width: "100%" }}
              label="项目成员"
              name="user_list"
            >
              <div className="userListBox">
                <div className="searchBox">
                  {/* 用户名或姓名 */}
                  <Form.Item
                    style={{ width: "calc(50% - 60px)" }}
                    name="userName"
                  >
                    <Select
                      mode="multiple"
                      maxTagCount="responsive"
                      style={{ width: "100%" }}
                      placeholder="请选择用户名或姓名"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode || document.body
                      }
                      // onChange={userNameChange}
                      tagRender={(props) => {
                        const { label, value, closable } = props;
                        const item = (filterUserOptions() || []).find(
                          (_) => _.user_id === value
                        );
                        return (
                          <Tag
                            style={{
                              marginRight: 3,
                            }}
                            className="optionUserTag"
                          >
                            <Avatar
                              size={26}
                              src={item.pf_photo}
                              style={{ marginRight: "8px" }}
                            ></Avatar>
                            {item.username}
                          </Tag>
                        );
                      }}
                      allowClear
                      showSearch
                      filterOption={(input, option) => {
                        const item = (filterUserOptions() || []).find(
                          (_) => _.user_id === option.value
                        );
                        return (
                          item?.username
                            .toLowerCase()
                            .includes(input.toLowerCase()) ||
                          item?.assist_name
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        );
                      }}
                      dropdownMatchSelectWidth={false}
                    >
                      {(filterUserOptions() || []).map((item) => (
                        <Option
                          key={item.user_id}
                          value={item.user_id}
                          userName={item.name}
                          info
                        >
                          <div className="userOptionsItemBox">
                            <div>
                              <Avatar
                                size={26}
                                src={item.pf_photo}
                                style={{ marginRight: "8px" }}
                              ></Avatar>
                              {item.username}
                            </div>
                            <div className="userOptionsItemBoxSpan">
                              {item.assist_name}
                            </div>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* 角色 */}
                  <Form.Item
                    style={{ width: "calc(50% - 60px)" }}
                    name="userRole"
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="请选择角色"
                      onChange={roleChange}
                      allowClear
                      showSearch
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode || document.body
                      }
                    >
                      {roleOptions.map((item) => (
                        <Option
                          key={item.dic_item_code}
                          value={item.dic_item_code}
                        >
                          {item.dic_item_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* 添加按钮 */}
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      addUser();
                    }}
                  >
                    添加
                  </Button>
                </div>
                <Table
                  showHeader={false}
                  scroll={
                    userList.length > 5
                      ? {
                          y: 240,
                        }
                      : null
                  }
                  pagination={false}
                  columns={columns}
                  dataSource={userList}
                  className="myTable userTable"
                  loading={loading}
                />
              </div>
            </Form.Item>
          ) : null}

          <Form.Item style={{ width: "100%" }} label="尽调内容" name="content">
            <div className="contentMap">
              <ContentMap mapType={mapType} ref={mapRef}></ContentMap>
            </div>
          </Form.Item>
        </Form>
      </Scrollbars>
    </Drawer>
  );
};

export default CreatProjectDrawer;
