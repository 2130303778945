import { useState } from "react";
import "./index.scss";
import moment from "moment";
import { Select } from "antd";
import { FilterOutlined } from "@ant-design/icons";

const YearSelect = (props) => {
  const { value, onChange, defaultOptions = undefined } = props;
  const [options, setOptions] = useState([
    moment(new Date()).subtract(4, "years")?.format("YYYY"),
    moment(new Date()).subtract(3, "years")?.format("YYYY"),
    moment(new Date()).subtract(2, "years")?.format("YYYY"),
    moment(new Date()).subtract(1, "years")?.format("YYYY"),
    moment(new Date())?.format("YYYY"),
    "all",
  ]);
  return (
    <div className="yearSelect">
      {/* {(defaultOptions || options)?.map((year) => {
        return (
          <div
            className={
              value?.includes(year) ? "yearItem activeYearItem" : "yearItem"
            }
            key={Math.random()}
            onClick={() => {
              // let newValue = [...value];
              // if (value?.includes(year)) {
              //   newValue = newValue?.filter((_) => _ !== year);
              // } else {
              //   newValue.push(year);
              // }
              onChange && onChange([year]);
            }}
          >
            <span></span>
            {year === 'all' ? '最近5年' : (year + '年')}
          </div>
        );
      })} */}
      <FilterOutlined />
      <Select
        value={(value || [])?.at(0)}
        // style={{
        //   width: 50,
        // }}
        placement="bottomRight"
        dropdownMatchSelectWidth={70}
        size="small"
        onChange={(year) => {
          onChange && onChange([year]);
        }}
        showArrow={false}
        bordered={false}
        popupClassName="chartYearSelectPopup"
        options={options?.map((_) => ({
          value: _,
          label: _ === "all" ? "最近5年" : _ + "年",
        }))}
      />
    </div>
  );
};
export default YearSelect;
