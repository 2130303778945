import "./index.scss";
import { message, Typography, Tag } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Subscribe from "../../Subscribe/index";
import HeaderTitle from "../../../components/HeaderTitle";
import MyCard from "../../../components/MyCard/index";
import Scrollbars from "react-custom-scrollbars";
import Follow from "../../Follow";
import { useSize, useLongPress, useMouse, useEventListener } from "ahooks";
import PieMenu from "../components/PieMenu/index";
import projectApi from "../../../apis/project";
import UserDrawer from "../../ProjectManagement/components/UserDrawer";
import { useDispatch, useSelector } from "react-redux";
import { setProjectInfo, setMenusList } from "../../../store/project/project";
import { ReactSVG } from "react-svg";
import StatusText from "../../../components/StatusText";
import HeadPortrait from "../../../components/HeadPortrait";
import HeadPortraitLine from "../../../components/HeadPortraitLine";
import MessageModal from "../../../components/MessageModal";
import RiskVerification from "../RiskVerification";

const { Paragraph } = Typography;

const RightBox = (props) => {
  const { id } = useParams();
  const moveRef = useRef(null);
  const contentRef = useRef(null);
  const [isMove, setIsMove] = useState(false);
  const [y, setY] = useState(365);
  const { projectInfo } = props;
  const mouse = useMouse(contentRef.current);
  const userInfo = useSelector((state) => state.User.userInfo);

  useLongPress(
    () => {
      setIsMove(true);
    },
    moveRef,
    {
      delay: 0,
    }
  );

  useEventListener("mouseup", () => {
    setIsMove(false);
  });

  useEffect(() => {
    if (!isMove) {
      return;
    }
    const minH = 250;
    if (
      mouse.elementY > minH + 10 &&
      mouse.elementH - mouse.elementY > minH + 10
    ) {
      setY(mouse.elementY);
    }
  }, [mouse]);

  return (
    <div className="rightBox" ref={contentRef}>
      {/* 关注 */}
      <div className="rightTop" style={{ height: y - 10 + "px" }}>
        <Follow
          hasHeader={true}
          projectId={id}
          defaultType={"我的"}
          isHome={userInfo?.co_type_code === "INDIV_ORG"}
        ></Follow>
      </div>
      <div className="line">
        <div
          className={isMove ? "moveBox activeMove" : "moveBox"}
          ref={moveRef}
          style={{ cursor: isMove ? "move" : "pointer" }}
        ></div>
      </div>
      {/* 订阅 */}
      <div
        className="rightBottom"
        style={{ height: `calc(100% - ${y - 10 + 20}px)` }}
      >
        <Subscribe
          hasHeader={true}
          projectInfo={projectInfo}
          projectId={id}
        ></Subscribe>
      </div>
    </div>
  );
};

const ProjectInfo = (props) => {
  let menuList = useSelector((state) => state.Project.menuList) || [];

  const { id } = useParams();
  const navigate = useNavigate();
  const columns = useRef([]);
  // const [data, setData] = useState({});
  const data = useSelector((state) => state.Project.projectInfo);
  const dispatch = useDispatch();

  const colorList = ["#0068B2", "#FEC054", "#FE545F", "#6CAEFF", "#AAD461"];

  const mapRef = useRef();
  const size = useSize(mapRef);

  const [choiceMenu, setChoiceMenu] = useState();

  const [userVisible, setUserVisible] = useState(false);

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const userInfo = useSelector((state) => state.User.userInfo);

  const first = useSelector((state) => state.Project.first);

  const progressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );

  useEffect(() => {
    let list = [
      {
        label: "尽调场景",
        key: "scene_code_name",
        width: "40%",
        type: "text",
      },
      {
        label: "项目状态",
        key: "status_name",
        width: "60%",
        type: "tag",
      },
      {
        label: "创建人",
        key: "name",
        width: "40%",
        type: "text",
        format: (data, info) => {
          if (localStorage.getItem("show_project_type_tag")) {
            if (info?.project_type === "team_project") {
              return "系统导入";
            }
          }

          return (
            <Paragraph
              style={{
                width: `100%`,
                color: "#535353",
              }}
              ellipsis={{
                tooltip: (
                  <span style={{ fontSize: "12px" }}>{info?.show_name}</span>
                ),
              }}
            >
              <HeadPortrait
                size={20}
                title={
                  info.show_name +
                  (info.assist_name ? "/" + info.assist_name : "")
                }
                src={info?.pf_photo}
                style={{ marginRight: "3px" }}
              ></HeadPortrait>
              {info?.show_name}
            </Paragraph>
          );
        },
      },
      {
        label: "创建时间",
        key: "create_time",
        width: "60%",
        type: "text",
      },
      {
        label: "尽调对象",
        key: "company_name",
        width: "100%",
        type: "text",
        render: (text, item) => {
          return (
            <div
              className="value"
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <span style={{ marginRight: "9px" }}>{text}</span>
              {item.is_listed_company === 1 && item.stock_block && (
                <Tag className="companyTypeTag">{item.stock_block}</Tag>
              )}
              {item.is_listed_company === 1 && item.stock_code && (
                <Tag className="companyTypeTag">{item.stock_code}</Tag>
              )}
            </div>
          );
        },
      },
      {
        label: "尽调成功",
        key: "cdd_success_count",
        width: "40%",
        type: "text",
        render: (text, item) => {
          return (
            <div className="value">
              <span
                style={{
                  marginRight: "2px",
                  color: "#0068b2",
                  fontWeight: "bold",
                }}
              >
                {text}
              </span>
              次
            </div>
          );
        },
      },
      {
        label: "项目成员",
        key: "member_list",
        width: "60%",
        type: "text",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        // format: (data) => data?.map((item) => item.name).join("、"),
        format: (data, info) => {
          let str = data?.map((item) => item.name).join("、");
          return (
            // <Paragraph
            //   style={{ width: "100%", marginBottom: "0" }}
            //   ellipsis={{
            //     // tooltip: {
            //     //   title: <div className="tableColumnsParagraphTip">{str}</div>,
            //     //   overlayClassName: "tableColumnsParagraphTooltip",
            //     // },
            //     rows: 1,
            //     expandable: false,
            //   }}
            // >
            //   {data?.map((item) => (
            //     <HeadPortrait
            //       size={20}
            //       title={item?.name}
            //       src={item?.pf_photo}
            //       onClick={() => editUser()}
            //       style={{ marginRight: "3px", cursor: "pointer" }}
            //     ></HeadPortrait>
            //   ))}
            // </Paragraph>
            <HeadPortraitLine
              data={data}
              size={20}
              num={6}
              inBody={true}
              // onClick={() => userChange(info)}
            ></HeadPortraitLine>
          );
        },
      },
    ];
    if (userInfo?.co_type_code === "INDIV_ORG") {
      list = list?.filter((_) => _.label !== "项目成员");
    }
    columns.current = list;
  }, [userInfo]);
  // useEffect(() => {
  //   if (id === undefined) {
  //     // navigate("/projectManagement");
  //     return;
  //   }
  //   getProjectInfo();
  // }, [id]);

  useEffect(() => {
    //首次进入界面 此模块不需要加载项目详情. home主页面会调一次
    if (first) {
      return;
    }
    getProjectInfo();
  }, []);

  const sizeNumber = Math.min(size?.width, size?.height);
  const mapStyle = {
    width: sizeNumber > 600 ? "600px" : sizeNumber + "px",
    height: sizeNumber > 600 ? "600px" : sizeNumber + "px",
    "--icon-width": sizeNumber / 24 + "px",
  };

  const [relevantProjectList, setRelevantProjectList] = useState([]);

  const relevantProjectColumns = useRef([
    {
      label: "尽调场景",
      key: "scene_code_name",
      width: "calc(100% - 120px)",
      type: "text",
    },
    {
      label: "项目状态",
      key: "status_name",
      width: "120px",
      type: "tag",
    },
    {
      label: "尽调对象",
      key: "company_name",
      width: "calc(100% - 120px)",
      type: "text",
      format: (companyName) => {
        const suffixCount = 4;
        let str = companyName;
        const start = str.slice(0, str.length - suffixCount).trim();
        const suffix = str.slice(-suffixCount).trim();
        return (
          <Paragraph
            style={{
              width: `100%`,
              color: "#535353",
            }}
            ellipsis={{
              suffix,
              tooltip: companyName,
            }}
          >
            {start}
          </Paragraph>
        );
      },
    },
    {
      label: "创建人",
      key: "name",
      width: "120px",
      type: "text",
      format: (data, info) => {
        if (localStorage.getItem("show_project_type_tag")) {
          if (info?.project_type === "team_project") {
            return "系统导入";
          }
        }
        return (
          <Paragraph
            style={{
              width: `100%`,
              color: "#535353",
            }}
            ellipsis={{
              tooltip: (
                <span style={{ fontSize: "12px" }}>{info?.show_name}</span>
              ),
            }}
          >
            <HeadPortrait
              size={20}
              title={
                info.show_name +
                (info.assist_name ? "/" + info.assist_name : "")
              }
              src={info?.pf_photo}
              style={{ marginRight: "3px" }}
            ></HeadPortrait>
            {info?.show_name}
          </Paragraph>
        );
      },
    },
  ]);

  const initValue = (cardData, type, key, format) => {
    if (type === "text") {
      if (format) {
        return format(cardData[key], cardData);
      }
      return cardData[key] || "";
    }
    if (type === "tag") {
      return (
        <StatusText
          status={cardData[key]}
          errorTips={cardData?.fail_msg}
        ></StatusText>
      );
    }
  };

  const initMenu = (_menuList) => {
    const menuListCopy = JSON.parse(JSON.stringify(menuList));
    return menuListCopy.map((item) => {
      const _item = _menuList.find(
        (_) => _.function_code === item.function_code
      );
      if (_item) {
        item.children = item.children.map((_) => {
          const __item = _item.children?.find(
            (__) => __.function_code === _.function_code
          );
          if (__item) {
            _ = { ..._, ...__item };
          }
          return _;
        });
      }
      return item;
    });
  };

  const getProjectInfo = () => {
    projectApi.getProjectInfo(id).then((res) => {
      if (res.code === 200) {
        // setData(res.data.data[0]);
        dispatch(setProjectInfo(res.data.data[0]));
        dispatch(
          setMenusList(
            JSON.parse(
              JSON.stringify(initMenu(res.data.data[0]?.function_list || []))
            )
          )
        );
      } else {
        message.error(res.message);
      }
    });
  };

  const menuChange = (menuId) => {
    let obj = menuList.find((item) => item.id === menuId);
    setChoiceMenu(obj);
  };

  const editUser = () => {
    setUserVisible(true);
  };

  const creatSuccess = () => {
    setUserVisible(false);
    getProjectInfo();
  };

  const goUrl = (item) => {
    navigate(item.menu_url);
  };

  const initInfoContent = () => {
    if (choiceMenu?.function_name === "概览") {
      return (
        <div className="comContent">
          <div className="comTitle">
            公司简介
            <div className="line"></div>
          </div>
          <div className="comInfo">
            <Paragraph
              ellipsis={{
                rows: 10,
                expandable: true,
                symbol: (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      lookUp(data.describe);
                    }}
                  >
                    更多
                  </span>
                ),
              }}
            >
              {data.describe}
            </Paragraph>
          </div>
          {/* {data.describe} */}
        </div>
      );
    }

    return (
      <div className="childrenBox">
        {/* <div className="title">{choiceMenu?.function_name}</div> */}
        {choiceMenu?.children &&
          choiceMenu?.children
            ?.filter((_) => !_.disable)
            ?.map((item, index) => (
              <div className="childrenItem" key={item?.id}>
                <div
                  className="iconBox"
                  style={{
                    color: colorList[index % 4],
                    border: `1px solid ${colorList[index % 4]}`,
                  }}
                >
                  <ReactSVG src={item?.full_logo_url}></ReactSVG>
                </div>
                <div className="content">
                  <div
                    className="title"
                    onClick={() => {
                      goUrl(item);
                    }}
                  >
                    {item?.function_name}
                  </div>

                  <div className="childrenContent">
                    <Paragraph
                      // ellipsis={{
                      //   rows: 2,
                      //   tooltip: item?.function_describe_tips,
                      // }}
                      ellipsis={{
                        tooltip: {
                          title: (
                            <div className="tableColumnsParagraphTip">
                              {item?.function_describe_tips}
                            </div>
                          ),
                          overlayClassName: "tableColumnsParagraphTooltip",
                        },
                        rows: 2,
                        expandable: false,
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      {/* {progressCode === 'CDD_FIRST_PROCESS_6_FINISH'?<span
                        style={{ color: "#535353" }}
                        dangerouslySetInnerHTML={{
                          __html: item?.function_describe,
                        }}
                      ></span>:<span>尽调数据获取中...</span>} */}
                      <span
                        style={{ color: "#535353" }}
                        dangerouslySetInnerHTML={{
                          __html: item?.function_describe,
                        }}
                      ></span>
                    </Paragraph>
                  </div>
                </div>
              </div>
            ))}
      </div>
    );
  };

  const getProjectRelevant = () => {
    projectApi
      .getProjectRelevant(projectInfo.company_id, projectInfo.id)
      .then((res) => {
        if (res.code === 200) {
          setRelevantProjectList(res.data);
        } else {
          setRelevantProjectList([]);
        }
      });
  };

  useEffect(() => {
    getProjectRelevant();
  }, []);

  const lookUp = (value) => {
    MessageModal({
      maskClosable: true,
      noIcon: true,
      noBtn: true,
      infoText: value,
      title: "公司简介",
    });
  };

  const projectTagColorObject = {
    personal_project: "#9E9E9E",
    team_project: "#0068B2",
  };
  return (
    <div className="projectInfoContent">
      <div className="leftBox">
        {/* 项目信息 */}
        <div className="topBox">
          <div
            className="leftTop"
            style={{
              width: `calc(${
                relevantProjectList?.length > 0 ? "66% - 20px" : "100%"
              })`,
              marginRight: relevantProjectList?.length > 0 ? "20px" : "0",
            }}
          >
            <HeaderTitle title="项目信息"></HeaderTitle>
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "calc(100% - 26px)", width: "100%" }}
            >
              <div className="scrollBox">
                <div className="projectInfoBox">
                  <MyCard
                    getProjectInfo={getProjectInfo}
                    projectInfo={projectInfo}
                    titleIcon={true}
                    editUser={editUser}
                    marginBottom="0px"
                    width="100%"
                    key={data.id}
                    title={data.project_name}
                    pic={data.logo_url}
                    cardData={data}
                    columns={columns.current}
                    hasBorder={false}
                    showCollection={false}
                    progress={true}
                    wholeLine={relevantProjectList?.length > 0}
                  ></MyCard>
                </div>
                <div className="chart">{/* <RadarChart></RadarChart> */}</div>
              </div>
            </Scrollbars>
          </div>
          {relevantProjectList?.length > 0 && (
            <div className="relevantProjectBox">
              <HeaderTitle title="相关项目"></HeaderTitle>
              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{
                  height: "calc(100% - 36px)",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <div className="relevantProjectContent">
                  {relevantProjectList.map((item) => (
                    <div className="relevantProjectItem" key={item.id}>
                      <div
                        className="relevantProjectTitle"
                        onClick={() => {
                          let url =
                            window.location.origin + "/company/" + item.id;
                          window.open(url, "_blank");
                        }}
                      >
                        {item.project_name}
                        {localStorage.getItem("show_project_type_tag") && (
                          <Tag
                            style={{
                              color: projectTagColorObject[item?.project_type],
                              borderColor:
                                projectTagColorObject[item?.project_type],
                              background: "#ffffff",
                              height: "18px",
                              lineHeight: "16px",
                              textAlign: "center",
                              fontSize: "10px",
                              borderRadius: "20px",
                              padding: "0 8px",
                              boxSizing: "border-box",
                              marginLeft: "5px",
                            }}
                          >
                            {item?.project_type_name}
                          </Tag>
                        )}
                      </div>
                      {relevantProjectColumns.current?.map((column) => (
                        <div
                          className="valueItem"
                          key={column.key}
                          style={{ width: column.width || "100%" }}
                        >
                          <div className="label">{column.label}</div>
                          <div className="value">
                            {initValue(
                              item,
                              column.type,
                              column.key,
                              column.format
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </Scrollbars>
            </div>
          )}
        </div>

        {/* left_bottom_display: 1 风险核查  2 尽调地图 */}
        {projectInfo?.left_bottom_display === 1 ? (
          <div className="leftBottom">
            <HeaderTitle title="尽调对象风险核查"></HeaderTitle>
            <div className="leftBottomContent">
              <RiskVerification></RiskVerification>
            </div>
          </div>
        ) : (
          <div className="leftBottom">
            <HeaderTitle title="尽调地图"></HeaderTitle>
            <div className="leftBottomContent">
              <div className="mapBox" ref={mapRef}>
                <div className="map" style={mapStyle}>
                  <div className="mapBg"></div>
                  <PieMenu onChange={menuChange} menuList={menuList}></PieMenu>
                </div>
              </div>
              <div className="menuInfoBox">{initInfoContent()}</div>
            </div>
          </div>
        )}
      </div>
      <RightBox projectInfo={projectInfo}></RightBox>

      <UserDrawer
        visible={userVisible}
        onClose={() => setUserVisible(false)}
        successFunc={creatSuccess}
        info={data}
      ></UserDrawer>
    </div>
  );
};
export default ProjectInfo;
