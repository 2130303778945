import "./index.scss";
import { ReactSVG } from "react-svg";
import LoadingSvg from "../../assets/loading.svg";
const StatusDescTag = (props) => {
  const { text } = props;
  let style = {};

  if (text === "核查完成") {
    style.color = "#61D4AD";
  } else if (text === "核查失败") {
    style.color = "#FE545F";
  } else if (text === "核查中") {
    style.color = "#0068B2";
  }else if (text === "等待核查") {
    style.color = "#FEC054";
  }
  return (
    <span style={style}>
      {text}{" "}
      {text === "核查中" && (
        <ReactSVG
          src={LoadingSvg}
          className="anticon-spin anticon myLoadingIcon myLoadingIcon222"
        />
      )}
    </span>
  );
};
export default StatusDescTag;
