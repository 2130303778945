import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  calcCompanyNameFunc,
  saveFile,
  unifyNumber,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { innerCircleMap, outerCircleMap } from "../../../../../../setting";
import DownLoadIcon from "../../../../../../components/Icons/DownLoadIcon";

const TaxSituation = (props) => {
  const { yearsData = {}, qsData = {}, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);

  const [circleSelectKey, setCircleSelectKey, getCircleSelectKey] =
    useGetState("");

  const [initType, setInitType, getInitType] = useGetState("年");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [yearList, setYearList, getYearList] = useGetState([]);
  const [dataInfo, setDataInfo, getDataInfo] = useGetState([]);

  const myChart = useRef(null);
  const myChart2 = useRef(null);

  const colorRef = useRef([
    "#0068B2",
    "#AAD461",
    "#FEC054",
    "#FE545F",
    "#6CAEFF",
  ]);
  const colorRef2 = useRef(["#6CAEFF", "#AAD461", "#FEC054", "#FE545F"]);

  const initCircleEcharts = (key) => {
    // if (getCircleSelectKey() === key) {
    //   return;
    // }

    setCircleSelectKey(key);

    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    const dom = document.getElementById("taxSituationContentBox2");
    if (!dom) {
      return;
    }
    const data = getInitType() === "年" ? yearsData : qsData;
    let _key = key;

    if (_key?.includes("第一季度")) {
      _key = _key?.slice(0, 4) + "Q1";
    }
    if (_key?.includes("第二季度")) {
      _key = _key?.slice(0, 4) + "Q2";
    }
    if (_key?.includes("第三季度")) {
      _key = _key?.slice(0, 4) + "Q3";
    }
    if (_key?.includes("第四季度")) {
      _key = _key?.slice(0, 4) + "Q4";
    }

    const list = data[_key];

    const obj = {};
    let sum = 0;

    list?.forEach((item) => {
      if (obj[item.zsxm]) {
        obj[item.zsxm].amount = obj[item.zsxm].amount + item.sjsk;
        obj[item.zsxm].items.push(item);
      } else {
        const _ = {
          amount: item.sjsk,
          items: [item],
        };
        obj[item.zsxm] = _;
      }
      sum += item.sjsk;
    });
    const keys = Object?.keys(obj)?.sort(
      (a, b) => obj[b]?.amount - obj[a]?.amount
    );

    const data1 = [];
    const data2 = [];
    let count = 0;
    (keys || [])?.forEach((key, index) => {
      data1.push({
        value: obj[key].amount,
        name: key,
        itemStyle: {
          color: innerCircleMap[index % innerCircleMap?.length],
        },
      });

      (obj[key]?.items || [])?.forEach((item) => {
        data2.push({
          value: item.sjsk,
          name: item.zspm,
          itemStyle: {
            color: outerCircleMap[count % outerCircleMap?.length],
          },
        });
        count = count + 1;
      });
    });

    myChart2.current = echarts.init(dom);

    const option = {
      title: {
        text: `${key}纳税情况`,
        left: "center",
      },
      tooltip: {
        trigger: "item",
        formatter: (info) => {
          let per = ((info?.value / sum) * 100)?.toFixed(2);
          if (sum === 0 && info?.value === 0) {
            per = "100.00";
          }
          return `<div style='font-weight:bold'>${
            info?.name
          }</div> <div>收入: ${addMillimeterAndInit(
            info?.value + "元"
          )} </div><div>占比: ${per === "0.00" ? "小于0.01" : per}%</div>`;
        },
      },
      legend: {
        show: false,
      },
      grid: {
        top: "60px",
        left: "0%",
        right: "0%",
        bottom: "0px",
        containLabel: true,
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          // selectedMode: "single",
          // selectedOffset: 2,
          radius: [0, "40%"],
          startAngle: 0,
          label: {
            position: "inner",
            fontSize: 12,
            color: "#000",
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: data1,
        },
        {
          name: "Access From",
          type: "pie",
          radius: ["55%", "70%"],
          labelLine: {
            length: 10,
          },
          startAngle: 0,
          label: {
            formatter: (info) => {
              let name = info?.name;
              if (name?.length > 5) {
                name = name?.slice(0, 5) + "...";
              }
              let per = ((info?.value / sum) * 100)?.toFixed(2);
              if (sum === 0 && info?.value === 0) {
                per = "100.00";
              }
              return `{b|${name}：}{per|${per === "0.00" ? "小于0.01" : per}%}`;
            },
            // width: 200,
            // backgroundColor: "#F6F8FC",
            // borderColor: "#8C8D8E",
            // borderWidth: 1,
            // borderRadius: 4,
            rich: {
              b: {
                color: "#4C5058",
                fontSize: 12,
                fontWeight: "bold",
                // lineHeight: 33,
              },
              c: {
                formatter: (value) => {
                  return value + "元";
                },
              },
              per: {
                color: "#4C5058",
                fontSize: 12,
              },
            },
          },
          data: data2,
        },
      ],
    };

    myChart2.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart2.current && myChart2.current.resize();
    });
  };

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    const dom = document.getElementById("taxSituationContentBox");
    if (!dom) {
      return;
    }
    let amountMax = 0;
    echartsData?.forEach((item) => {
      if (item?.amount >= amountMax) {
        amountMax = item?.amount;
      }
    });
    myChart.current = echarts.init(dom);
    let option = {
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        bottom: "0px",
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        data: ["销售收入", "预测销售收入"],
      },
      xAxis: [
        {
          type: "category",
          data: echartsData?.map((item) => item?.name),
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            interval: 0,
            rotate: getInitType() === "年" ? 0 : -30,
            formatter: function (value) {
              let name = value;
              if (name?.includes("第一季度")) {
                name = name?.slice(0, 4) + "Q1";
              }
              if (name?.includes("第二季度")) {
                name = name?.slice(0, 4) + "Q2";
              }
              if (name?.includes("第三季度")) {
                name = name?.slice(0, 4) + "Q3";
              }
              if (name?.includes("第四季度")) {
                name = name?.slice(0, 4) + "Q4";
              }
              return name;
              // return calcCompanyNameFunc(
              //   value,
              //   echartsData?.length,
              //   "taxSituationContentBox"
              // );
            },
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
        },
      ],
      yAxis: [
        {
          type: "value",
          max: amountMax <= 5 ? 5 : null,
          interval: amountMax <= 5 ? 1 : null,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            show: false,
          },
          axisLabel: {
            formatter: function (value, index) {
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else {
                return value;
              }
            },
          },
        },
      ],
      grid: {
        top: "20px",
        left: "0%",
        right: getInitType() === "年" ? 0 : "25px",
        bottom: "0px",
        containLabel: true,
      },
      series: [
        {
          name: "纳税金额",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },
          itemStyle: {
            color: "#0068B2",
            barBorderRadius: [4, 4, 0, 0],
          },
          barMaxWidth: "40px",
          data: echartsData?.map((item) => item?.amount),
        },
      ],
    };

    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (params) => {
      initCircleEcharts(params.name);
    });

    initCircleEcharts(echartsData?.at(-1).name);
  };

  const initData = () => {
    const data = getInitType() === "年" ? yearsData : qsData;
    const keys = Object?.keys(data)
      ?.filter((_) => _ !== "name")
      .sort((a, b) => Number(a) - Number(b));
    let arr = [];
    keys?.forEach((key) => {
      const items = data[key];
      let name = key;
      if (getInitType() === "季") {
        if (name?.includes("Q1")) {
          name = name?.slice(0, 4) + "第一季度";
        }
        if (name?.includes("Q2")) {
          name = name?.slice(0, 4) + "第二季度";
        }
        if (name?.includes("Q3")) {
          name = name?.slice(0, 4) + "第三季度";
        }
        if (name?.includes("Q4")) {
          name = name?.slice(0, 4) + "第四季度";
        }
      }
      arr.push({
        name: name,
        amount: items?.reduce((a, b) => a + b?.sjsk, 0),
        items: items,
      });
    });
    setTimeout(() => {
      initEcharts(arr);
    }, 100);
  };

  useEffect(() => {
    if (
      Object?.keys(yearsData)?.length === 0 &&
      Object?.keys(qsData)?.length === 0
    ) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(yearsData, qsData);
  }, [yearsData, qsData]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: `纳税情况（${getInitType()}度）`,
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="taxSituationContent">
      <div className="headerBox">
        纳税情况
        {!noData && (
          <div className="selectBox2">
            <div
              className={
                initType === "年"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "年") {
                  return;
                }
                setInitType("年");
                setTimeout(() => {
                  initData();
                }, 50);
              }}
            >
              年
            </div>
            <div
              className={
                initType === "季"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "季") {
                  return;
                }
                setInitType("季");
                setTimeout(() => {
                  initData();
                }, 50);
              }}
            >
              季
            </div>
          </div>
        )}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <DownLoadIcon
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                ></DownLoadIcon>
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="taxSituationContentBox">
          <div id="taxSituationContentBox"></div>
          <div id="taxSituationContentBox2"></div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default TaxSituation;
