const DownLoadIcon = (props) => {
  let className = props?.className || "";
  if (className) {
    className = className + " " + "anticon";
  } else {
    className = "anticon";
  }
  return (
    <span {...props} className={className}>
      <svg
        fill="none"
        version="1.1"
        width="1em"
        height="1em"
        viewBox="0 0 19 20"
      >
        <g>
          <path
            d="M18.0354,18.0889L0.964581,18.0889C0.42621,18.0889,0,18.5111,0,19.0444C0,19.5778,0.42621,20,0.964581,20L18.0354,20C18.5738,20,19,19.5778,19,19.0444C19,18.5333,18.5738,18.0889,18.0354,18.0889ZM8.86068,16.4444C9.04014,16.6222,9.28689,16.7111,9.51122,16.6889C9.73554,16.6889,9.98229,16.6222,10.1617,16.4444L15.9941,10.6667C16.3306,10.3333,16.3306,9.77778,15.9941,9.44445C15.6576,9.11111,15.0968,9.11111,14.7603,9.44445L10.3412,13.8222L10.3412,0.866667C10.3412,0.4,9.95986,0,9.46635,0C8.97284,0,8.5915,0.377778,8.5915,0.866667L8.5915,13.7333L4.2621,9.44444C3.92562,9.11111,3.36482,9.11111,3.02834,9.44444C2.69185,9.77778,2.69185,10.3333,3.02834,10.6667L8.86068,16.4444Z"
            fill="currentColor"
            fill-opacity="1"
          />
        </g>
      </svg>
    </span>
  );
};
export default DownLoadIcon;
