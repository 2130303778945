import request from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, setToken, getRedirectUrl } from "../../unit/index";
import userApi from "../../apis/user/index";
const Login = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    let url = document.location.href;
    //获取ticket
    let ticket = "";
    if (/ticket=(ST-[^#/?]+)/.test(url)) {
      ticket = /ticket=(ST-[^#/?]+)/.exec(url)[1];
    }
    //获取上次退出记录的网址
    let next_url = "";
    if (url?.indexOf('next_url') !== -1) {
      let startIndex = url?.indexOf('next_url');
      let endIndex = url?.indexOf('?ticket');
      if(endIndex === -1){
        endIndex = url?.indexOf('&ticket');
      }
      if(endIndex === -1){
        endIndex = url.length
      }
      
      next_url = url.slice(startIndex + 9, endIndex);
      console.log(url,next_url,endIndex);
    }
    //如果有token则直接跳转
    if (getToken()) {
      if (next_url) {
        window.location.href = next_url//getRedirectUrl();
      } else {
        navigate("/home");
      }
      return;
    }
    //没有token 登录
    userApi.login(ticket, url).then((res) => {
      if (res.data.status === "redirect") {
        window.location.href = res.data.login_url;
      } else if (res.data.status === "login") {
        const token = res.data.token;
        setToken(token);
        //getRedirectUrl()
        if (next_url) {
          window.location.href = next_url//getRedirectUrl();
        } else {
          navigate("/home");
        }
      }
    });
  }, []);
  return <div></div>;
};

export default Login;
