import "./index.scss";
import {
  Select,
  Tag,
  Button,
  Divider,
  Modal,
  Form,
  Input,
  message,
} from "antd";
import debounce from "lodash/debounce";
import React, { useEffect, useMemo, useRef, useState } from "react";
import MySpin from "../MySpin";
import DebounceSelect from "../DebounceSelect";
import projectApi from "../../apis/project";
import { useParams } from "react-router-dom";
import MyEmpty from "../Empty";
import { useGetState } from "ahooks";

const { Option } = Select;

function PersonAndCompanySelect({
  fetchOptions,
  selectChange,
  debounceTimeout = 800,
  changeClear = false,
  notInForm,
  notChangeValue = false,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const [searchValue, setSearchValue] = useState("");
  const [value, setValue, getValue] = useGetState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [positionInformation, setPositionInformation] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [form] = Form.useForm();

  const { id } = useParams();

  const loadOptions = (value) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    if (value?.length < 2) {
      setOptions([]);
      return;
    }
    setOptions([]);
    setFetching(true);
    fetchOptions(value).then((newOptions) => {
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }
      setOptions(newOptions);
      setFetching(false);
    });
  };

  const debounceFetcher = useMemo(() => {
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const valueChange = (info) => {
    const [id, name, type] = info?.value?.split("&&&&&&&") || [];
    if (!notChangeValue) {
      setTimeout(() => {
        setValue(name);
        setSearchValue(name);
      }, 50);
    }
    const item = options.find((_) => {
      if (Number(type) === 2) {
        return _.person_id === Number(id) && _.name === name;
      } else {
        return _.company_id === Number(id) && _.name === name;
      }
    });
    props.onChange && props.onChange(item);
    selectChange && selectChange(item);
    setIsSearch(false);
    if (changeClear) {
      setOptions([]);
    }
  };
  const showCode = (row) => {
    if (row.data_type === 2) {
      return (
        row?.company_name_list?.join("、") +
        (row?.company_name_list.length > 1 ? "等" : "")
      );
    } else if (row.credit_code) {
      return (
        "统一信用号:" +
        row.credit_code +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.org_number) {
      return (
        "统一信用号:" +
        row.org_number +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.reg_number) {
      return (
        "统一信用号:" +
        row.reg_number +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.legal_person_name) {
      return "法人:" + (row.legal_person_name ? row.legal_person_name : "");
    } else {
      return "无详细信息";
    }
  };

  const initTag = (row) => {
    const color = row.data_type === 2 ? "#52c41a" : "#096dd9",
      text = row.data_type === 2 ? "自然人" : "公司";
    return (
      <Tag className="nameTag" color={color}>
        {text}
      </Tag>
    );
  };

  const dropdownRender = (menu) => {
    return (
      <>
        {menu}
        <Divider
          style={{
            margin: "2px 0",
          }}
        />
        <Button
          type="link"
          onClick={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
            setTimeout(() => {
              let _searchValue =
                searchValue?.length > 50
                  ? searchValue?.slice(0, 50)
                  : searchValue;
              form.setFieldValue("naturalPerson", _searchValue);
            }, 100);
          }}
        >
          没有找到（点击手工添加人员）
        </Button>
      </>
    );
  };

  const handleOk = () => {
    form.validateFields().then((res) => {
      setAddLoading(true);
      projectApi
        .getPersonId(id, {
          name: res.naturalPerson,
          company_id: res.companyInfo?.company_id,
        })
        .then((res1) => {
          if (res1.code === 200) {
            const item = {
              company_id: res.companyInfo?.company_id,
              name: res.naturalPerson,
              person_id: res1.data.person_id,
              data_type: 2,
              company_name_list: [res.companyInfo?.name],
              value: res1.data.person_id,
              label: res.naturalPerson,
            };
            if (!notChangeValue) {
              setTimeout(() => {
                setValue(res.naturalPerson);
                setSearchValue(res.naturalPerson);
              }, 50);
            }
            props.onChange && props.onChange(item);
            selectChange && selectChange(item);
            setIsModalOpen(false);
          } else {
            message.error("添加失败");
          }
          setAddLoading(false);
        });
    });
  };

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields(["naturalPerson", "companyInfo"]);
    }
    setPositionInformation(null);
  }, [isModalOpen]);

  //模糊搜索公司接口
  async function fetchComList(username) {
    if (!username) {
      return [];
    }
    return projectApi.getCompanyInfo(username).then((res) => {
      if(res.code !== 200) {
        message.error(res.message)
        return []
      }
      return res.data.map((item) => ({
        ...item,
        label: item.name,
        value: item.company_id,
      }));
    });
  }

  //验证职位
  const verificationPositionDebounce = useMemo(() => {
    const verificationPosition = () => {
      const naturalPerson = form.getFieldValue("naturalPerson");
      const companyInfo = form.getFieldValue("companyInfo");
      if (!companyInfo || !naturalPerson) {
        setPositionInformation(null);
        return;
      }
      projectApi
        .getPersonRole(id, naturalPerson, companyInfo.company_id)
        .then((res) => {
          if (res.code === 200) {
            let tip = "";
            if (!res.data.roles) {
              tip = `暂未查询到 ${naturalPerson} 在该公司任职，是否继续添加？`;
            } else {
              tip = `${naturalPerson} 担任 ${res.data.roles}`;
            }
            setPositionInformation(tip);
          } else {
            message.error(res.message);
            setPositionInformation(null);
          }
        });
    };
    return debounce(verificationPosition, debounceTimeout);
  }, [debounceTimeout]);

  // useEffect(() => {
  //   if (searchValue?.length >= 2) {
  //     setIsSearch(true);
  //   } else {
  //     setIsSearch(false);
  //   }
  // }, [searchValue]);
  if (notInForm) {
    props.value = value;
  }
  return (
    <>
      <Select
        labelInValue
        filterOption={false}
        onSearch={(_value) => {
          setIsSearch(_value?.length >= 2);
          setSearchValue(_value);
          debounceFetcher(_value);
        }}
        notFoundContent={
          fetching ? <MySpin /> : isSearch ? <MyEmpty></MyEmpty> : null
        }
        {...props}
        onChange={valueChange}
        searchValue={searchValue}
        onFocus={(e) => {
          setSearchValue(value);
          setIsSearch(false);
        }}
        onBlur={(e) => {
          setTimeout(() => {
            setOptions([]);
            setIsSearch(false);
          }, 300);
        }}
        dropdownRender={dropdownRender}
      >
        {options.map((item) => {
          const _id = item.person_id || item.company_id,
            type = item.data_type || 1;
          return (
            <Option
              value={_id + "&&&&&&&" + item.name + "&&&&&&&" + type}
              key={_id + item.name}
              className="PersonAndCompanySelectItem"
            >
              <div className="addComp">
                <a className="name" title={item.label}>
                  {initTag(item)}
                  <span className="nameText">{item.label}</span>
                </a>
                <a className="info" title={showCode(item)}>
                  {showCode(item)}
                </a>
              </div>
            </Option>
          );
        })}
      </Select>
      <Modal
        title="添加自然人"
        wrapClassName="PersonAndCompanyModal"
        centered
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        maskStyle={{ zIndex: 1050 }}
        maskClosable={false}
        footer={
          <div className="drawerFooterBtnBox">
            <Button
              type="primary"
              loading={addLoading}
              onClick={() => {
                handleOk();
              }}
            >
              确定
            </Button>
            <Button
              className="noBg"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              取消
            </Button>
          </div>
        }
      >
        <div className="formContent">
          <Form autoComplete="off" form={form}>
            <Form.Item
              label="姓名"
              name="naturalPerson"
              rules={[
                {
                  required: true,
                  message: "请输入姓名",
                },
              ]}
            >
              <Input
                maxLength={50}
                placeholder="请输入姓名且不超过50个字符"
                onChange={(e) => {
                  verificationPositionDebounce();
                }}
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "0px" }}
              label="关联公司"
              name="companyInfo"
              rules={[
                {
                  required: true,
                  message: "请填写关联公司",
                },
              ]}
            >
              <DebounceSelect
                selectChange={(value) => {
                  verificationPositionDebounce();
                }}
                showSearch={true}
                placeholder="请输入企业名称搜索"
                fetchOptions={fetchComList}
                changeClear={true}
                allowClear
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <div className="tip">{positionInformation}</div>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default PersonAndCompanySelect;
