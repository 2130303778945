import "./indes.scss";
import MyEmpty from "../../../../../../components/Empty";
import { useGetState } from "ahooks";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import projectApi from "../../../../../../apis/project";
import { useParams } from "react-router-dom";
import { Table, message } from "antd";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
} from "../../../../../../unit/unit";
import DownLoadIcon from "../../../../../../components/Icons/DownLoadIcon";

const ValueAddedTax = (props) => {
  const { data, selectCompany } = props;
  const [noData, setNoData] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [downLoading, setDownLoading] = useState(false);
  const { id } = useParams();
  const [tableData, setTableData, getTableData] = useGetState([]);
  const [columns, setColumns, getColumns] = useGetState([]);

  const initData = (data) => {
    const keys = Object.keys(data)
      ?.filter((item) => item !== "name")
      ?.sort((a, b) => b - a);
    const columns = [
      {
        title: "项目",
        dataIndex: "xmmc",
        key: "xmmc",
        align: "center",
      },
    ];
    let companysObj = {};
    keys.forEach((year) => {
      columns.push({
        title: year,
        dataIndex: year,
        key: year,
        align: "center",
        render: (text, info) => (
          <span>
            {text
              ? text > 0
                ? addMillimeter(text, 2)
                : "-" + addMillimeter(Math.abs(text), 2)
              : "-"}
          </span>
        ),
      });

      data[year]?.forEach((item) => {
        if (companysObj[item.xmmc]) {
          if (companysObj[item.xmmc][year]) {
            companysObj[item.xmmc][year] =
              companysObj[item.xmmc][year] + item.sum_bys;
          } else {
            companysObj[item.xmmc][year] = item.sum_bys;
          }
        } else {
          let _ = {
            index: item.xmmc_index,
          };
          _[year] = item.sum_bys;
          companysObj[item.xmmc] = _;
        }
      });
    });
    setColumns(columns);
    const list = Object.keys(companysObj)
      ?.map((key) => ({
        ...(companysObj[key] || {}),
        xmmc: key,
      }))
      ?.sort((a, b) => a.index - b.index);
    setTableData(list);
  };

  useEffect(() => {
    if (Object.keys(data || {})?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "增值税申报表",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="valueAddedTaxContent">
      <div className="headerBox">
        增值税申报表
        {!noData && <div className="unitBox">单位：元</div>}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <DownLoadIcon
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                ></DownLoadIcon>
              ))}
          </div>
        )}
      </div>
      {!noData && (
        <div className="valueAddedTaxTableBox">
          <Table
            // scroll={{
            //   y: tableData?.length > 0 ? "274px" : undefined,
            // }}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
            rowKey={() => Math.random()}
            className="myFormTable"
            bordered={true}
            pagination={false}
            columns={columns}
            dataSource={tableData}
          ></Table>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default ValueAddedTax;
