import "./index.scss";
import { useState, useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import bondApi from "../../../../../apis/bond";
import { message, Tag, Radio, Tooltip, Popover } from "antd";
import { useParams } from "react-router-dom";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { toleranceValue } from "../../../../../setting";
import { useSelector } from "react-redux";
import { downloadFile, getLicenseValid, guid } from "../../../../../unit/unit";
import MySpin from "../../../../../components/MySpin";
import MyEmpty from "../../../../../components/Empty";
import DownLoadIcon from "../../../../../components/Icons/DownLoadIcon";

const CellBox = (props) => {
  const { item, type, hidden, getData } = props;
  // console.log(item,type);
  const ref = useRef(null);
  const { id } = useParams();

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  return (
    <div className="cellBox" ref={ref}>
      <div className="titleBox">
        {item.data_id ? (
          item.name ? (
            item.name
          ) : (
            <span style={{ color: "red" }}>(工商公示异常企业)</span>
          )
        ) : (
          ""
        )}
      </div>
      <div className="tagBox">
        {item.percent !== undefined && item.percent !== null ? (
          parseFloat((item.percent * 100).toFixed(2)) <= 0 ? (
            <Tooltip
              key={Math.random()}
              placement="top"
              title={
                parseFloat((Number(item.percent) * 100).toPrecision(14)) + "%"
              }
              arrowPointAtCenter
            >
              <Tag className="percent">
                持股比例{(item.percent * 100).toFixed(2) + "%"}
              </Tag>
            </Tooltip>
          ) : (
            <Tag className="percent">
              持股比例{(item.percent * 100).toFixed(2) + "%"}
            </Tag>
          )
        ) : null}
        {item.fpercent !== undefined &&
        item.fpercent !== null &&
        type === "股东" ? (
          parseFloat((item.fpercent * 100).toFixed(2)) <= 0 ? (
            <Tooltip
              key={Math.random()}
              placement="top"
              title={
                parseFloat((Number(item.fpercent) * 100).toPrecision(14)) + "%"
              }
              arrowPointAtCenter
            >
              <Tag className="ultimateBenefit">
                最终收益股份{(item.fpercent * 100).toFixed(2) + "%"}
              </Tag>
            </Tooltip>
          ) : (
            <Tag className="ultimateBenefit">
              最终收益股份{(item.fpercent * 100).toFixed(2) + "%"}
            </Tag>
          )
        ) : null}
        {item.controller === 1 ? (
          <Tag className="controller">疑似实际控制人</Tag>
        ) : null}
        {(item.cpercent > 1 + toleranceValue ||
          item.cpercent < 1 - toleranceValue) &&
        item.children?.length > 0 &&
        type === "股东" ? (
          <Tag className="cpercent">{`当前股份合计${Number(
            item.cpercent * 100
          ).toFixed(2)}%`}</Tag>
        ) : null}
      </div>
    </div>
  );
};
const StockRightTable = (props) => {
  const { setUploadDate } = props;
  const { id } = useParams();

  const [type, setType] = useState("股东");

  const [shareholderData, setShareholderData] = useState({});
  const [investmentData, setInvestmentData] = useState({});

  const [investmentList, setInvestmentList] = useState([]);
  const [shareholderList, setShareholderList] = useState([]);

  const [investmentLevel, setInvestmentLevel] = useState(0);
  const [shareholderLevel, setShareholderLevel] = useState(0);

  const [data, setData] = useState([]);
  const [level, setLevel] = useState(0);
  const [downLoading, setDownLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const projectInfo = useSelector((state) => state.BondProject.bondProjectInfo);

  const ref = useRef(null);
  // const scroll = useScroll(ref);

  const typeChange = (e) => {
    setType(e.target.value);
  };

  useEffect(() => {
    if (type === "股东") {
      setData(shareholderList);
      setLevel(shareholderLevel);
    } else {
      setData(investmentList);
      setLevel(investmentLevel);
    }
  }, [type, investmentList, investmentList]);

  const initTableData = (list) => {
    let obj = {
      一: [],
      二: [],
      三: [],
      四: [],
    };

    let depth = 0;
    let levelList = ["一", "二", "三", "四"];
    //"五","六","七","八","九","十",
    const defaultH = 80;
    const defaultMaxIndex = 3;
    let init = (_list, index, parent) => {
      if (index + 1 > depth) {
        depth = index + 1;
      }
      return _list.map((item) => {
        item.children = item.children?.filter((_) => _?.change !== "del");
        if (item.children && item.children?.length > 0) {
          let h = defaultH;
          if (index < defaultMaxIndex) {
            item.children = init(item.children, index + 1, item);
            h = 0;
            item.children?.forEach((_) => (h += _.h));
          }
          item.h = h;
        } else {
          item.h = defaultH;
          let _levelList = levelList.filter((_, _index) => _index > index);
          _levelList.forEach((_) => {
            obj[_]?.push({ h: defaultH });
          });
        }
        if (item.level === 2) {
          if (shareholderData.id) {
            parent = shareholderData;
          } else {
            parent = investmentData;
          }
        }
        item.parentId = parent?.id;
        item.parentLabel = parent?.label;
        item.parentName = parent?.name;
        item.data_id = item.id + "-" + item.level;
        obj[levelList[index]]?.push(JSON.parse(JSON.stringify(item)));
        return item;
      });
    };
    list = list?.filter((_) => _?.change !== "del") || [];
    list = init(list, 0);
    return {
      obj,
      depth,
    };
  };

  const initShareholderList = (shareholderData) => {
    setLoading(true);
    const { obj, depth } = initTableData(shareholderData?.children || []);
    setShareholderList(obj);
    setShareholderLevel(depth);
    setLoading(false);
  };

  const initInvestmentList = (investmentData) => {
    setLoading(true);
    const { obj, depth } = initTableData(investmentData?.children || []);
    setInvestmentList(obj);
    setInvestmentLevel(depth);
    setLoading(false);
  };

  useEffect(() => {
    initShareholderList(shareholderData);
  }, [shareholderData]);

  useEffect(() => {
    initInvestmentList(investmentData);
  }, [investmentData]);

  const getDataFunc = () => {
    setLoading(true);
    bondApi.getPenetrationInfo(id).then((res) => {
      if (res.code === 200) {
        const { down, up, update_time } = res.data;
        setShareholderData(up);
        setInvestmentData(down);
        setUploadDate(update_time);
      } else {
        message.error(res.message);
        setShareholderData({});
        setInvestmentData({});
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getDataFunc();
  }, []);

  const downLoadFile = () => {
    setDownLoading(true);
    bondApi.downLoadBasicExcel(id).then((res) => {
      if (res.code === 200) {
        var hiddenIFrameID = "hiddenDownloader" + guid();
        var iframe = document.createElement("iframe");
        iframe.id = hiddenIFrameID;
        iframe.style.display = "none";
        iframe.src = res.data;
        document.body.appendChild(iframe);
        setTimeout(() => {
          iframe.remove();
        }, 30 * 1000);
      } else {
        message.error(res.message);
      }
      setDownLoading(false);
    });
  };
  const initDom = (data) => {
    let empty = true;
    const dom = Object.keys(data).map((key) => {
      if (data[key]?.length > 0) {
        empty = false;
      }
      return (
        <div className="column" key={key}>
          {data[key].map((item) => (
            <div
              key={Math.random()}
              className="cell"
              style={{ height: item.h + "px" }}
            >
              <CellBox
                item={item}
                type={type}
                getData={getDataFunc}
                hidden={
                  item.h >
                  document.documentElement.querySelector(".stockRightTableBox")
                    ?.clientHeight *
                    1.5
                }
              ></CellBox>
            </div>
          ))}
        </div>
      );
    });
    return empty ? (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MyEmpty></MyEmpty>
      </div>
    ) : (
      dom
    );
  };

  return (
    <div className="stockRightTable">
      <div className="typeBox">
        <Radio.Group
          value={type}
          className="myRadioGroup"
          onChange={(e) => {
            typeChange(e);
          }}
        >
          <Radio.Button value="股东">股东</Radio.Button>
          <Radio.Button value="投资">投资</Radio.Button>
        </Radio.Group>
        {loading ? null : downLoading ? (
          <LoadingOutlined />
        ) : (
          <Tooltip placement="top" title="下载" arrowPointAtCenter>
            <DownLoadIcon
              onClick={() => {
                getLicenseValid(false, true).then((res) => {
                  if (res) {
                    downLoadFile();
                  }
                });
              }}
              className="downIcon hoverIcon"
            ></DownLoadIcon>
          </Tooltip>
        )}
      </div>
      {loading ? (
        <div className="stockRightTableBox">
          <MySpin></MySpin>
        </div>
      ) : (
        <div className="stockRightTableBox">
          <div className="stockRightTableHeader">
            {/* style={{left: 0 - scroll?.left + 'px'}} */}
            {["一", "二", "三", "四"].map((item) => (
              <div className="headerCell" key={item}>
                {item + "级" + type}
              </div>
            ))}
          </div>

          <div className="stockRightTableContent" ref={ref}>
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "100%", width: "100%" }}
            >
              <div style={{ height: "100%", width: "100%", display: "flex" }}>
                {initDom(data)}
              </div>
            </Scrollbars>
          </div>
        </div>
      )}
    </div>
  );
};
export default StockRightTable;
