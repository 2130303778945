const EditIcon = (props) => {
  let className = props?.className || "";
  if (className) {
    className = className + " " + "anticon";
  } else {
    className = "anticon";
  }
  return (
    <span {...props} className={className}>
      <svg
        fill="none"
        version="1.1"
        width="1em"
        height="1em"
        viewBox="0 0 12 12"
      >
        <g>
          <g>
            <path
              d="M5.4823589226904295,6.2134C5.6097338826904295,5.74465,5.80578188269043,5.02264,5.91321988269043,4.63387C5.92429688269043,4.59277,5.957523882690429,4.53612,5.98742988269043,4.50613C7.05627788269043,3.43311,7.79061788269043,2.7,8.86057788269043,1.62921C8.86500788269043,1.62476,8.871647882690429,1.62143,8.88604788269043,1.61254C9.39333788269043,2.10684,9.90283788269043,2.60336,10.41676788269043,3.10321C10.38575788269043,3.13542,10.36249788269043,3.16208,10.337017882690429,3.18763C9.28921788269043,4.23843,8.57702788269043,4.95044,7.52700788269043,5.99901C7.47716788269043,6.049,7.4162478826904294,6.08454,7.348687882690429,6.10343C6.94551788269043,6.21895,6.25436388269043,6.41,5.80245988269043,6.53441C5.60751988269043,6.58662,5.43030248269043,6.40778,5.48235958269043,6.2134M10.88306788269043,2.62446C10.37578788269043,2.13239,9.86849788269043,1.6392,9.35567788269043,1.14157C9.377827882690429,1.11714,9.39443788269043,1.09603,9.41216788269043,1.07826C9.663587882690429,0.825001,9.91169788269043,0.570633,10.16644788269043,0.320708C10.623887882690429,-0.128047,11.32611788269043,-0.102499,11.74479788269043,0.376247C12.100337882690429,0.782792,12.08261788269043,1.43371,11.70270788269043,1.81582C11.43576788269043,2.08463,11.162187882690429,2.34899,10.88306788269043,2.62446Z"
              fill="currentColor"
              fill-opacity="1"
            />
          </g>
          <g>
            <path
              d="M8.18746,6.671583204364777C8.0056,6.855823204364777,7.77237,6.9805232043647765,7.51847,7.029253204364776L5.02302,7.512443204364777C4.70513,7.573543204364777,4.42823,7.290293204364777,4.49579,6.973723204364776L5.03742,4.427813204364776C5.09058,4.177883204364777,5.21574,3.9501732043647766,5.3985,3.7713432043647765L8.75235,0.4989832043647766L2.04908,0.4989832043647766C0.917103,0.4989832043647766,0,1.4187082043647767,0,2.553923204364777L0,9.945053204364777C0,11.080283204364777,0.917103,11.999983204364776,2.04908,11.999983204364776L9.59634,11.999983204364776C10.7283,11.999983204364776,11.6454,11.080283204364777,11.6454,9.945063204364777L11.6454,3.1781832043647764L8.18746,6.671583204364777ZM6.48174,9.902843204364776L2.54972,9.902843204364776C2.18758,9.902843204364776,1.89401,9.608433204364777,1.89401,9.245263204364777C1.89401,8.882093204364777,2.18758,8.587683204364776,2.54972,8.587683204364776L6.48174,8.587683204364776C6.84388,8.587683204364776,7.13745,8.882093204364777,7.13745,9.245263204364777C7.13745,9.608433204364777,6.84388,9.902843204364776,6.48174,9.902843204364776Z"
              fill="currentColor"
              fill-opacity="1"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};
export default EditIcon;
