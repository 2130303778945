import { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useGetState } from "ahooks";
import { Button, Popover } from "antd";
import moment from "moment";
import { addMillimeter, umberFormats } from "../../../../../../../unit/unit";
import { useSelector } from "react-redux";
import projectApi from "../../../../../../../apis/project";
import { useParams } from "react-router-dom";
import png from "../../../../../../../assets/logo-w.png";
import TableModal from "../../../TableModal";

const SummaryAssembly = (props) => {
  const { data, checkCompanyList, keyList } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();

  const [winningCount, setWinningCount, getWinningCount] = useGetState(0);
  const [winningAmount, setWinningAmount, getWinningAmount] = useGetState(0);
  const [customerCount, setCustomerCount, getCustomerCount] = useGetState(0);
  const [biddingCount, setBiddingCount, getBiddingCount] = useGetState(0);
  const [biddingAmount, setBiddingAmount, getBiddingAmount] = useGetState(0);

  const [biddingCount2, setBiddingCount2, getBiddingCount2] = useGetState(0);
  const [biddingAmount2, setBiddingAmount2, getBiddingAmount2] = useGetState(0);

  const [customerAmountSum, setCustomerAmountSum, getCustomerAmountSum] =
    useGetState(0);

  const [yearObj, setYearObj, getYearObj] = useGetState({});
  const [customObj, setCustomObj, getCustomObj] = useGetState({});

  const [winningObj, setWinningObj, getWinningObj] = useGetState({});

  const initNumberFunc = (data) => {
    let winningCount = 0;
    let winningAmount = 0;

    let competitorWinningCount = 0;
    let competitorWinningAmount = 0;

    let dueAdjustmentWinningCount = 0;
    let dueAdjustmentWinningAmount = 0;

    let customerCount = 0;
    let biddingCount = 0;
    let biddingAmount = 0;
    let biddingCount2 = 0;
    let biddingAmount2 = 0;
    let yearObj = {};
    let customObj = {};

    let bidIds = [];

    (data?.competitive_history_bid_count || []).forEach((_) => {
      if (_?.bid_winner !== projectInfo.company_name) {
        if (yearObj[_?.anc_date]) {
          yearObj[_?.anc_date].amount =
            yearObj[_?.anc_date].amount + (_?.bid_winning_amo || 0);
        } else {
          yearObj[_?.anc_date] = {
            amount: _?.bid_winning_amo || 0,
          };
        }

        //筛除掉多个公司中一个项目
        if (!bidIds.some((__) => __ === _?.bid_id)) {
          bidIds.push(_?.bid_id);
          yearObj[_?.anc_date].count =
            (yearObj[_?.anc_date].count || 0) + (_?.bidding_count || 0);
          winningCount = winningCount + (_?.bidding_count || 0);
        }
        winningAmount = winningAmount + (_?.bid_winning_amo || 0);
      }

      if (_?.bid_winner === projectInfo.company_name) {
        dueAdjustmentWinningCount =
          dueAdjustmentWinningCount + (_?.bidding_count || 0);
        dueAdjustmentWinningAmount =
          dueAdjustmentWinningAmount + (_?.bid_winning_amo || 0);
      } else {
        competitorWinningCount =
          competitorWinningCount + (_?.bidding_count || 0);
        competitorWinningAmount =
          competitorWinningAmount + (_?.bid_winning_amo || 0);

        if (customObj[_?.bid_winner]) {
          customObj[_?.bid_winner] =
            customObj[_?.bid_winner] + (_?.bid_winning_amo || 0);
        } else {
          customObj[_?.bid_winner] = _?.bid_winning_amo || 0;
        }
      }
    });

    Object.keys(data.competition_data || {}).forEach((year) => {
      if (yearObj[year]) {
        yearObj[year].biddingCount =
          (yearObj[year].biddingCount || 0) +
          (((data.competition_data || {})[year] || {})?.project_count || 0);
        yearObj[year].biddingAmount =
          (yearObj[year].biddingAmount || 0) +
          (((data.competition_data || {})[year] || {})?.total_amount || 0);
      } else {
        yearObj[year] = {
          biddingCount:
            ((data.competition_data || {})[year] || {})?.project_count || 0,
          biddingAmount:
            ((data.competition_data || {})[year] || {})?.total_amount || 0,
        };
      }

      biddingCount =
        biddingCount +
        (((data.competition_data || {})[year] || {})?.project_count || 0);
      biddingAmount =
        biddingAmount +
        (((data.competition_data || {})[year] || {})?.total_amount || 0);

      biddingCount2 =
        biddingCount2 +
        (((data.competition_data || {})[year] || {})?.total_self_count || 0);
      biddingAmount2 =
        biddingAmount2 +
        (((data.competition_data || {})[year] || {})?.total_self_amount || 0);
    });

    setWinningCount(winningCount);
    setWinningAmount(winningAmount);
    setCustomerCount(customerCount);
    setBiddingCount(biddingCount);
    setBiddingAmount(biddingAmount);
    setBiddingCount2(biddingCount2);
    setBiddingAmount2(biddingAmount2);
    setYearObj(yearObj);
    setCustomObj(customObj);
    setWinningObj({
      dueAdjustmentWinningCount,
      dueAdjustmentWinningAmount,
      competitorWinningCount,
      competitorWinningAmount,
    });
  };

  const initPopoverContentDom = (type) => {
    let yearArr = [
      moment(new Date())?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
    ];

    let yearData = {};
    let suffix = "";
    let key = "";
    let isMoney = false;

    if (type === "竞争对手累计中标项目数") {
      yearData = getYearObj();
      suffix = "个";
      key = "count";
    } else if (type === "竞争对手累计中标金额") {
      yearData = getYearObj();
      suffix = "万元";
      key = "amount";
      isMoney = true;
    } else if (type === "共同竞标项目数") {
      yearData = getYearObj();
      suffix = "个";
      key = "biddingCount";
    } else if (type === "共同竞标项目金额") {
      yearData = getYearObj();
      suffix = "万元";
      key = "biddingAmount";
      isMoney = true;
    }

    return (
      <div className="popoverContentDom">
        <div className="popoverContentDomTitle">{type}</div>
        {yearArr?.map((year) => {
          return (
            <div className="popoverContentDomText">
              <span>{year}</span>
              <span>
                {(yearData[year] || {})[key]
                  ? (isMoney
                      ? addMillimeter(yearData[year][key] / 10000)
                      : yearData[year][key]) + suffix
                  : "-"}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const initDescribeDom = () => {
    let isHasDueDiligenceCompany = checkCompanyList?.some(
      (_) => _ === projectInfo?.company_name
    );

    let customList = Object.keys(customObj)
      .filter((_) => _)
      .sort((a, b) => customObj[b] - customObj[a]);

    let topCustomList = customList?.slice(0, 3);

    return (
      <>
        <div className="describeItem">
          <i></i>
          <div>
            当前添加的竞争对手近5年与尽调公司共同参与竞标项目数为
            <span className="numberSpan">{getBiddingCount()}</span>个
            {getBiddingCount() ? (
              <>
                ，中标金额
                <span className="numberSpan">
                  {umberFormats(getBiddingAmount() / 10000)}
                </span>
                万元。其中，尽调公司中标项目数
                <span className="numberSpan">{getBiddingCount2()}</span>
                个，金额
                <span className="numberSpan">
                  {umberFormats(getBiddingAmount2() / 10000)}
                </span>
                万元
                {
                  <>
                    （占比
                    <span className="numberSpan">
                      {getBiddingAmount()
                        ? (
                            Number(getBiddingAmount2() / getBiddingAmount()) *
                            100
                          ).toFixed(2)
                        : "-"}
                      %
                    </span>
                    ）
                  </>
                }
                。
              </>
            ) : (
              <>。</>
            )}
          </div>
        </div>
        <div className="describeItem">
          <i></i>
          <div>
            近5年， 竞争对手累计中标项目数为
            <span className="numberSpan">{getWinningCount()}</span>个，中标金额
            <span className="numberSpan">
              {umberFormats(getWinningAmount() / 10000)}
            </span>
            万元。尽调公司累计中标项目数
            <span className="numberSpan">
              {winningObj?.dueAdjustmentWinningCount}
            </span>
            个，中标金额
            <span className="numberSpan">
              {umberFormats(winningObj?.dueAdjustmentWinningAmount / 10000)}
            </span>
            万元。
          </div>
        </div>
        {topCustomList?.length > 0 && (
          <div className="describeItem">
            <i></i>
            <div>
              近5年，中标金额Top3的竞争对手分别是：
              {topCustomList?.map((_, index) => (
                <>
                  <span className="companySpan">{_}</span>（中标金额
                  <span className="numberSpan">
                    {umberFormats(customObj[_] / 10000)}
                  </span>
                  万元）
                  {topCustomList.length === index + 1 ? "。" : "、"}
                </>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    initNumberFunc(data);
  }, [data]);

  if (keyList?.length === 0) {
    return (
      <div className="bidWinningSituationContent">
        <div className="headerBox">
          <span className="headerBoxTitle">
            <span className="block"></span>
            概述
          </span>
        </div>
        <div
          style={{
            width: "calc(100% - 24px)",
            // aspectRatio: "1.4 / 1",
            minHeight: "200px",
            background: "#efefef",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "12px",
          }}
        >
          <img
            style={{ display: "block", height: "44px", width: "auto" }}
            src={png}
            alt="iWudao魔方全景查"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="summaryAssemblyContent3">
      <div className="headerBox">
        <span className="headerBoxTitle">
          <span className="block"></span>
          概述
        </span>
      </div>
      <div className="numberContentBox">
        <div
          className="numberItem"
          style={{ cursor: "pointer" }}
          onClick={() => {
            TableModal({
              title: "共同竞标项目",
              filterOptions: {
                ids: data?.competition_data?.bid_ids || [],
              },
              isBid: true,
              notOk: true,
              projectId: id,
            });
          }}
        >
          <Popover
            overlayClassName="summaryNumberItemPopover"
            placement="right"
            title={null}
            content={initPopoverContentDom("共同竞标项目数")}
          >
            <div className="numberItemTitle">共同竞标项目数</div>
            <div className="numberItemText">
              {biddingCount}
              <span>个</span>
            </div>
          </Popover>
        </div>
        <span className="line"></span>

        <div
          className="numberItem"
          style={{ cursor: "pointer" }}
          onClick={() => {
            TableModal({
              title: "共同竞标项目",
              filterOptions: {
                ids: data?.competition_data?.bid_ids || [],
              },
              notOk: true,
              isBid: true,
              projectId: id,
            });
          }}
        >
          <Popover
            overlayClassName="summaryNumberItemPopover"
            placement="right"
            title={null}
            content={initPopoverContentDom("共同竞标项目金额")}
          >
            <div className="numberItemTitle">共同竞标项目金额</div>
            <div className="numberItemText">
              {umberFormats(biddingAmount / 10000)}
              <span>万元</span>
            </div>
          </Popover>
        </div>
        <span className="line"></span>

        <div className="numberItem">
          <Popover
            overlayClassName="summaryNumberItemPopover"
            placement="left"
            title={null}
            content={initPopoverContentDom("竞争对手累计中标项目数")}
          >
            <div className="numberItemTitle">竞争对手累计中标项目数</div>
            <div className="numberItemText">
              {winningCount}
              <span>个</span>
            </div>
          </Popover>
        </div>
        <span className="line"></span>

        <div className="numberItem">
          <Popover
            overlayClassName="summaryNumberItemPopover"
            placement="left"
            title={null}
            content={initPopoverContentDom("竞争对手累计中标金额")}
          >
            <div className="numberItemTitle">竞争对手累计中标金额</div>
            <div className="numberItemText">
              {umberFormats(winningAmount / 10000)}
              <span>万元</span>
            </div>
          </Popover>
        </div>
      </div>
      <div className="describeContentBox">{initDescribeDom()}</div>

      <div className="annotationDiv">注：统计时间为近五年数据</div>
    </div>
  );
};
export default SummaryAssembly;
