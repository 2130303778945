import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import { Select, message, Tooltip, Spin } from "antd";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { useGetState } from "ahooks";
import {
  addMillimeter,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import MyEmpty from "../../../../../../../components/Empty";
import { useSelector } from "react-redux";
import moment from "moment";
import TableModal from "../../../TableModal";
import { chartColorMap } from "../../../../../../../setting";
import DownLoadIcon from "../../../../../../../components/Icons/DownLoadIcon";

const { Option } = Select;

const AmountTrend = (props) => {
  const {
    data,
    checkCompanyList,
    searchTableFunc,
    notHasData,
    chartsLoading,
    getCompanyList,
    checkAllCompanyList,
  } = props;
  const { id } = useParams();
  const [noData, setNoData] = useState(false);
  const [allData, setAllData, getAllData] = useGetState({});
  const [yearsObj, setYearsObj] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectYear, setSelectYear, getSelectYear] = useGetState([]);
  const [selectTop, setSelectTop, getSelectTop] = useGetState("top5");
  const [downLoading, setDownLoading] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [chartType, setChartType, getChartType] = useGetState("招标项目");

  const myChart = useRef(null);
  const initEchartsData = () => {
    //默认五年
    let yearArr = [
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date())?.format("YYYY"),
    ];
    let echartsData = [],
      _data = [];
    let key = "";
    if (getChartType() === "招标项目") {
      _data = getAllData()["招标项目"] || [];
      key = "count";
    } else if (getChartType() === "中标项目") {
      _data = getAllData()["中标项目"] || [];
      key = "count";
    } else if (getChartType() === "招标金额") {
      _data = getAllData()["招标金额"] || [];
      key = "year_amount_sum";
    }
    let obj = {};

    checkCompanyList.forEach((_, index) => {
      obj[_] = {
        index: index,
      };
    });
    _data.forEach((item) => {
      if (obj[item.bid_winner]) {
        if (obj[item.bid_winner][item.year]) {
          obj[item.bid_winner][item.year] =
            obj[item.bid_winner][item.year] + item[key];
        } else {
          obj[item.bid_winner][item.year] = item[key];
        }
      }
      // else {
      //   let index = checkCompanyList?.findIndex((_) => _ === item.bid_winner);
      //   let __ = { index: index };
      //   __[item.year] = item[key];
      //   obj[item.bid_winner] = __;
      // }
    });
    checkCompanyList?.forEach((name) => {
      echartsData.push({
        name,
        data: yearArr.map((_year) => obj[name][_year] || 0),
      });
    });
    initECharts(yearArr, echartsData);
  };

  const initECharts = (data, seriesData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let maxNum = 0;
    seriesData.forEach((item) => {
      item?.data?.forEach((_) => {
        if (_ > maxNum) {
          maxNum = _;
        }
      });
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("amountTrendBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    const option = {
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: (list) => {
          let str = `<div>${list?.at(0)?.axisValue}</div>`;
          list?.forEach((item) => {
            let name = item.seriesName;
            if (name.length > 30) {
              name = name?.slice(0, 30) + "...";
            }
            str += `<div>${
              item.marker
            } ${name} <span style='float:right;margin-left: 20px;font-weight: bold;'>${
              getChartType() === "招标金额"
                ? addMillimeter(item.value, 2) + "元"
                : item.value + "个"
            }</span></div>`;
          });
          return str;
        },
      },
      legend: {
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        data: seriesData?.map((_) => _.name),
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        top: "12px",
        // left: "90px",
        type: "scroll", // 显示分页
        selectedMode: true,
        height: 24,
        textStyle: {
          rich: {
            title: {
              fontSize: 14,
              lineHeight: 22,
              color: "#595959",
              width: 80,
            },
            value: {
              fontSize: 14,
              lineHeight: 22,
              color: "rgba(0,0,0,0.45)",
              width: 30,
              align: "center",
            },
          },
        },
      },
      grid: {
        top: "46px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        data: data,
        axisLabel: {
          interval: 0,
        },
        axisTick: {
          alignWithLabel: true,
          interval: 0,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        axisPointer: {
          type: "shadow",
        },
      },
      // dataZoom: [
      //   {
      //     type: "inside",
      //   },
      // ],
      yAxis: {
        type: "value",
        // name: maxNum < 10000 ? "(元)" : "(万元)",
        nameTextStyle: {
          align: "center",
          // padding: [0, 50, 0, 0],
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        max: maxNum < 5 ? 5 : null,
        nameGap: 10,
        axisLabel: {
          formatter: function (value, index) {
            // let num = parseInt(value / 10000).toLocaleString("en-US");
            // if (maxNum < 10000) {
            //   num = parseInt(value).toLocaleString("en-US");
            // }
            // return num;
            if (value >= 100000000) {
              return value / 100000000 + "亿";
            } else if (value >= 10000) {
              return value / 10000 + "万";
            } else {
              return value;
            }
          },
        },
      },
      series: seriesData?.map((item, index) => ({
        ...item,
        type: "line",
        // tooltip: {
        //   valueFormatter: function (value) {
        //     return addMillimeter(value, 2) + "元";
        //   },
        // },
        itemStyle: {
          color: chartColorMap[index],
        },
      })),
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (a) => {
      let filterOptions = {
        采购人: a?.seriesName,
        年份: a?.name,
      };
      TableModal({
        title: "中标详情",
        typeName: getChartType() === "招标项目" ? "招标公告" : "招标结果",
        filterOptions,
        onOk: searchTableFunc,
        projectId: id,
      });
    });
  };

  const initData = (data) => {
    const _data = {
      招标项目: data?.invite_bit_list || [],
      中标项目: data?.hit_bit_list || [],
      招标金额: data?.hit_bit_amount_list || [],
    };
    setAllData(_data);
    setTimeout(() => {
      initEchartsData();
    }, 50);
  };

  useEffect(() => {
    if (notHasData) {
      return;
    }
    initData(data);
  }, [data]);

  useEffect(() => {
    setNoData(notHasData);
  }, [notHasData]);

  const downloadImg = () => {
    // myChart.current.dispose()
    let src = myChart.current.getDataURL({
      pixelRatio: 2,
      backgroundColor: "#fff",
    });

    downloadBase64Img(src, "历年招标及公布中标情况趋势.png");
  };

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "历年招标及公布中标情况趋势",
        bid_list: checkAllCompanyList,
        company_ids: JSON.stringify(
          checkAllCompanyList?.map((_) => {
            let item = getCompanyList()?.find((__) => __.bid_winner === _);
            return item.winner_id;
          })
        ),
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="amountTrendContent2">
      <div className="headerBox">
        <span className="headerBoxTitle">
          <span className="block"></span>
          历年招标及公布中标情况趋势
        </span>
        {!noData && !chartsLoading && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <DownLoadIcon
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  ></DownLoadIcon>
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      {!noData && (
        <div className="selectBox">
          {/* {[
            new Date().getFullYear(),
            moment().subtract(1, "years")?.format("YYYY"),
          ]?.map((year) => (
            <div
              className={getSelectYear() === year ? "activeSelectItem" : null}
              key={year}
              onClick={() => {
                setSelectYear(year);
                setTimeout(() => {
                  initEchartsData(yearsObj, year, getSelectTop());
                }, 50);
              }}
            >
              {year + "年TOP5客户"}
            </div>
          ))} */}
          <div
            className={
              chartType === "招标项目"
                ? "selectItem activeSelectItem"
                : "selectItem"
            }
            onClick={() => {
              if (getChartType() === "招标项目") {
                return;
              }
              setChartType("招标项目");
              setTimeout(() => {
                initEchartsData();
              }, 50);
            }}
          >
            招标项目数
          </div>
          <div
            className={
              chartType === "中标项目"
                ? "selectItem activeSelectItem"
                : "selectItem"
            }
            onClick={() => {
              if (getChartType() === "中标项目") {
                return;
              }
              setChartType("中标项目");
              setTimeout(() => {
                initEchartsData();
              }, 50);
            }}
          >
            已公布中标项目数
          </div>

          <div
            className={
              chartType === "招标金额"
                ? "selectItem activeSelectItem"
                : "selectItem"
            }
            onClick={() => {
              if (getChartType() === "招标金额") {
                return;
              }
              setChartType("招标金额");
              setTimeout(() => {
                initEchartsData();
              }, 50);
            }}
          >
            招标金额（已公布中标项目）
          </div>
        </div>
      )}
      <div id="amountTrendBox">{noData && <MyEmpty></MyEmpty>}</div>

      <div className="annotationDiv">
        注：根据公司列表排序展示选择的前10家公司
      </div>
    </div>
  );
};
export default AmountTrend;
