import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import {
  addMillimeter,
  calcCompanyNameFunc,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import { message, Select, Tooltip } from "antd";
import { useGetState } from "ahooks";
import { useDispatch, useSelector } from "react-redux";
import MyEmpty from "../../../../../../../components/Empty";
import TableModal from "../../../TableModal";
import png from "../../../../../../../assets/logo-w.png";
import { chartColorMap } from "../../../../../../../setting";
import DownLoadIcon from "../../../../../../../components/Icons/DownLoadIcon";
const { Option } = Select;

const ViscosityAnalysis = (props) => {
  const { data, keyList, checkCompanyList, searchTableFunc, bidCompanyName } =
    props;
  const { id } = useParams();
  const [yearsObj, setYearsObj] = useState({});
  const [noData, setNoData] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectType, setSelectType, getSelectType] = useGetState([]);
  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const myChart = useRef(null);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  useEffect(() => {
    setSelectType(checkCompanyList);
    if (!checkCompanyList || checkCompanyList?.length === 0) {
      setNoData(true);
    } else {
      setNoData(false);
    }
    setTimeout(() => {
      initEchartsData(yearsObj, getSelectYear(), checkCompanyList);
    }, 100);
  }, [checkCompanyList]);

  const initECharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let maxNum = 0;
    echartsData?.keyList.forEach((item, index) => {
      let num = 0;
      [
        "中标1次客户数",
        "中标2~5次客户数",
        "中标5~10次客户数",
        "中标10次以上客户数",
      ].forEach((_) => {
        num = echartsData[_]?.at(index) + num;
      });
      if (num > maxNum) {
        maxNum = num;
      }
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("viscosityAnalysisBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    const typeColor = {
      中标1次客户数: "#6CAEFF",
      "中标2~5次客户数": "#FE545F",
      "中标5~10次客户数": "#FEC054",
      中标10次以上客户数: "#AAD461",
    };
    const series = [
      "中标10次以上客户数",
      "中标5~10次客户数",
      "中标2~5次客户数",
      "中标1次客户数",
    ].map((item, _index) => {
      let index = 3 - _index;
      return {
        name: item,
        type: "bar",
        stack: "total",
        label: {
          show: false,
        },
        barMaxWidth: 40,
        itemStyle: {
          color: chartColorMap[index],
          barBorderRadius: index !== 0 ? [0, 0, 0, 0] : [4, 4, 0, 0],
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0,0,0,0.3)",
          },
        },
        tooltip: {
          valueFormatter: function (value) {
            return addMillimeter(value, 2) + "元";
          },
        },
        data: echartsData[item],
      };
    });
    const option = {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: (params, ticket, callback) => {
          let name = params?.at(0)?.name;
          if (name.length > 30) {
            name = name?.slice(0, 30) + "...";
          }
          let html = `<div>${name}`;
          [
            "中标10次以上客户数",
            "中标5~10次客户数",
            "中标2~5次客户数",
            "中标1次客户数",
          ]
            ?.sort(() => -1)
            ?.forEach((_, _index) => {
              const index = echartsData?.keyList?.findIndex(
                (name) => name === params?.at(0)?.name
              );
              //addMillimeter(echartsData[_]?.at(index),2)
              html += `<div><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
                chartColorMap[_index]
              };"></span> ${_}  <span style='float:right;font-weight:bold;margin-left:20px'>${echartsData[
                _
              ]?.at(index)}个</span></div>`;
            });
          html += `</div>`;
          return html;
        },
      },
      // dataZoom: [
      //   {
      //     type: "inside",
      //   },
      // ],
      legend: {
        x: "center", //可设定图例在左、右、居中
        y: "top", //可设定图例在上、下、居中
        top: "12px",
        itemStyle: {
          borderCap: "round",
        },
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",

        type: "scroll", // 显示分页
        selectedMode: true,
        textStyle: {
          rich: {
            title: {
              fontSize: 14,
              lineHeight: 22,
              color: "#595959",
              width: 80,
            },
            value: {
              fontSize: 14,
              lineHeight: 22,
              color: "rgba(0,0,0,0.45)",
              width: 30,
              align: "center",
            },
          },
        },
        data: [
          "中标1次客户数",
          "中标2~5次客户数",
          "中标5~10次客户数",
          "中标10次以上客户数",
        ],
      },
      grid: {
        top: "50px",
        right: "20px",
        left: "0",
        bottom: "0",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        axisPointer: {
          type: "shadow",
          label: {
            formatter: (params) => {
              let str = params.value;
              if (str.length > 30) {
                str = str?.slice(0, 30) + "...";
              }
              return str;
            },
          },
        },
        axisLabel: {
          interval: 0,
          // width: 60,
          overflow: "truncate",
          // rotate: -20,
          formatter: function (value) {
            return calcCompanyNameFunc(
              value,
              getSelectType()?.length,
              "viscosityAnalysisBox"
            );
          },
        },
        axisTick: {
          alignWithLabel: true,
          interval: 0,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        data: echartsData.keyList,
      },
      yAxis: {
        type: "value",
        // name: maxNum < 10000 ? "(元)" : "(万元)",
        nameLocation: "end",
        nameTextStyle: {
          align: "center",
          // padding: [0, 50, 0, 0],
        },
        nameGap: 10,
        // min: 0,
        max: maxNum < 5 ? 5 : null,
        // interval: 50,
        axisLabel: {
          formatter: function (value, index) {
            // let num = parseInt(value / 10000).toLocaleString("en-US");
            // if (maxNum < 10000) {
            //   num = parseInt(value).toLocaleString("en-US");
            // }
            // return num;
            if (value >= 100000000) {
              return value / 100000000 + "亿";
            } else if (value >= 10000) {
              return value / 10000 + "万";
            } else {
              return value;
            }
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        axisPointer: {
          show: false,
        },
      },
      series: series,
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (a) => {
      const list = data
        ?.filter((item) => item.bid_winner === a?.name)
        ?.filter((item) => {
          let flag = false;
          let diff = item.bid_winning_count;
          if (diff >= 0 && diff <= 1) {
            flag = a?.seriesName === "中标1次客户数";
          } else if (diff > 1 && diff <= 5) {
            flag = a?.seriesName === "中标2~5次客户数";
          } else if (diff > 5 && diff <= 10) {
            flag = a?.seriesName === "中标5~10次客户数";
          } else if (diff > 10) {
            flag = a?.seriesName === "中标10次以上客户数";
          }
          return flag;
        });
      // let isBid = true;
      // if (bidCompanyName === a?.name) {
      //   isBid = undefined;
      // }
      // let filterOptions = {
      //   采购人: list?.map((item) => item?.tenderee),
      //   供应商: a?.name,
      // };
      TableModal({
        title: a?.name + a?.seriesName?.slice(0, -1) + "详情",
        notOk: true,
        projectId: id,
        notGetData: true,
        tableData: list || [],
        pageSize: 100,
        tableColumns: [
          {
            index: 1,
            key: "序号",
            title: "序号",
            dataIndex: "_index",
            width: 80,
            align: "center",
            render: (text, record, index) => <>{text}</>,
          },
          {
            index: 2,
            key: "客户",
            title: "客户",
            dataIndex: "tenderee",
            align: "center",
            render: (text, record, index) => <>{text}</>,
          },
          {
            index: 3,
            key: "中标次数",
            title: "中标次数",
            width: 150,
            dataIndex: "bid_winning_count",
            align: "center",
            render: (text, record, index) => <>{text}</>,
          },
        ],
      });
    });
  };

  const initEchartsData = (yearsObj, year, types, onlyData) => {
    //projectInfo.company_name,
    const keyList = [...(getSelectType() || [])];

    const echartsData = {
      keyList: keyList,
      中标1次客户数: [],
      "中标2~5次客户数": [],
      "中标5~10次客户数": [],
      中标10次以上客户数: [],
    };
    keyList.forEach((item) => {
      let arr = data?.filter((_) => _.bid_winner === item);

      let obj = {
        中标1次客户数: 0,
        "中标2~5次客户数": 0,
        "中标5~10次客户数": 0,
        中标10次以上客户数: 0,
      };

      arr.forEach((_) => {
        // let diff = Number(year) - Number(_.anc_date);
        // if (diff >= 0 && diff < 1) {
        //   obj["一年以内"] = obj["一年以内"] + _.bid_winning_amo;
        // } else if (diff >= 1 && diff < 3) {
        //   obj["1-3年"] = obj["1-3年"] + _.bid_winning_amo;
        // } else if (diff >= 3 && diff < 5) {
        //   obj["3-5年"] = obj["3-5年"] + _.bid_winning_amo;
        // } else if (diff >= 5) {
        //   obj["5年以上"] = obj["5年以上"] + _.bid_winning_amo;
        // }
        let diff = _.bid_winning_count;
        if (diff >= 0 && diff <= 1) {
          obj["中标1次客户数"] = obj["中标1次客户数"] + 1;
        } else if (diff > 1 && diff <= 5) {
          obj["中标2~5次客户数"] = obj["中标2~5次客户数"] + 1;
        } else if (diff > 5 && diff <= 10) {
          obj["中标5~10次客户数"] = obj["中标5~10次客户数"] + 1;
        } else if (diff > 10) {
          obj["中标10次以上客户数"] = obj["中标10次以上客户数"] + 1;
        }
      });

      Object.keys(obj).forEach((key) => {
        echartsData[key]?.push(obj[key]);
      });
    });
    if (onlyData) {
      return echartsData;
    }
    initECharts(echartsData);
  };

  const initData = (data) => {
    let yearsObj = {};
    let keyObj = {};
    data.forEach((item) => {
      if (yearsObj[item.anc_date]) {
        yearsObj[item.anc_date]?.push(item);
      } else {
        yearsObj[item.anc_date] = [JSON.parse(JSON.stringify(item))];
      }
      if (!keyObj[item.bid_winner] && item.bid_winner !== bidCompanyName) {
        let index = keyList?.findIndex(
          (_) => _?.bid_winner === item.bid_winner
        );
        if (index !== null && index !== undefined) {
          index = index + 1;
        } else {
          index = 999;
        }
        keyObj[item.bid_winner] = index;
      }
    });
    let keys = Object.keys(yearsObj);
    keys = keys.sort((a, b) => Number(b) - Number(a));
    setYearsObj(yearsObj);
    let objKeys = Object.keys(keyObj);
    objKeys = objKeys.sort((a, b) => Number(keyObj[a]) - Number(keyObj[b]));
    setSelectOptions(objKeys);
    // setSelectType(objKeys?.slice(0, 5) || []);
    setSelectYear(new Date().getFullYear().toString());
    initEchartsData(
      yearsObj,
      new Date().getFullYear().toString(),
      objKeys?.slice(0, 5) || []
    );
  };

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "竞争对手客户粘度对比分析",
        is_bid: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };
  useEffect(() => {
    initData(data || []);
  }, [data]);

  if (keyList?.length === 0) {
    return (
      <div className="viscosityAnalysisContent">
        <div className="headerBox">
          <span className="headerBoxTitle">
            <span className="block"></span>
            竞争对手客户粘度对比分析
          </span>
        </div>
        <div
          style={{
            // aspectRatio: "2.4 / 1",
            width: "calc(100% - 24px)",
            minHeight: "300px",
            background: "#efefef",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "12px",
            marginBottom: "20px",
          }}
        >
          <img
            style={{ display: "block", height: "44px", width: "auto" }}
            src={png}
            alt="iWudao魔方全景查"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="viscosityAnalysisContent">
      <div className="headerBox">
        <span className="headerBoxTitle">
          <span className="block"></span>
          竞争对手客户粘度对比分析
        </span>
        {!noData && (
          <div>
            {/* <Select
            size="small"
            value={selectYear}
            style={{ width: "80px", marginRight: "10px" }}
            onChange={(value) => {
              setSelectYear(value);
              initEchartsData(yearsObj, value, getSelectType());
            }}
          >
            {Object.keys(yearsObj).map((item, index) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select> */}

            {/* <Select
              mode="multiple"
              maxTagCount="responsive"
              // size="small"
              value={selectType}
              style={{ minWidth: "160px", marginRight: "10px" }}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectType(value);
                initEchartsData(yearsObj, getSelectYear(), value);
              }}
            >
              {selectOptions.map((item, index) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select> */}
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <DownLoadIcon
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  ></DownLoadIcon>
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      <div
        id="viscosityAnalysisBox"
        style={{ display: noData ? "none" : "block" }}
      ></div>
      {noData && <MyEmpty></MyEmpty>}

      <div className="annotationDiv">
        注：根据公司列表排序展示选择的前10家公司
      </div>
    </div>
  );
};
export default ViscosityAnalysis;
