import HeaderTitle from "../../components/HeaderTitle";
import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { useGetState, useSize } from "ahooks";
import MyEmpty from "../../components/Empty";
import { ReactSVG } from "react-svg";
import icon1 from "../../assets/上传.svg";
import icon2 from "../../assets/添加.svg";
import { Tooltip, Table, Button, message, Drawer, Form } from "antd";
import MessageModal from "../../components/MessageModal";
import deleteIcon from "../../assets/delete.svg";
import {
  PlusCircleOutlined,
  VerticalAlignTopOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { showInfoFunc } from "../../components/MyTable";
import projectApi from "../../apis/project";
import { guid, judgmentDuplicateArray } from "../../unit/unit";
import UploadFileDrawer from "../../components/UploadFileDrawer";
import Scrollbars from "react-custom-scrollbars";
import DebounceSelect from "../../components/DebounceSelect";
import UploadModal from "../Company/SuspectedRelatedParty/components/UploadMadel";

const CreateCheckCom = (props) => {
  const { setIsCreate, getDate } = props;
  const ref = useRef(null);
  const size = useSize(ref);
  const [verificationList, setVerificationList, getVerificationList] =
    useGetState([]);
  const [fileVisible, setFileVisible] = useState(false);
  const [upLoading, setUpLoading] = useState(false);

  const [addVisible, setAddVisible] = useState(false);
  const [selectItemId, setSelectItemId, getSelectItemId] = useGetState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [upData, setUpData] = useState(null);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [form] = Form.useForm();

  //删除
  const deleteItem = (node) => {
    setSelectItemId(node.key);
    MessageModal({
      type: "warning",
      description: "确定要删除该核查对象吗?",
      okText: "删除",
      title: "删除核查对象",
      onOk: () => {
        setVerificationList((prev) => {
          prev = prev.filter((_) => _.key !== node.key);
          return [...prev];
        });
        setSelectItemId(null);
      },
      onCancel: () => {
        setSelectItemId(null);
      },
    });
  };

  //查看公司 自然人 信息
  const showInfoHandle = async (record) => {
    let id = -1;
    const item = {
      shareholderId: { val: record.id },
      name: { val: record.name },
      s_type: { val: record.type },
      click_all: true,
    };
    if (record.type === 2) {
      const { data } = await projectApi.getPersonId(id, {
        name: record.name,
        company_id: record.id,
      });
      item.shareholderId.val = data.person_id;
    }
    showInfoFunc(item, item, "主要股东", id, "name");
  };

  //点击按钮回调
  const handleFunc = (type) => {
    if (type === "上传") {
      setFileVisible(true);
    } else if (type === "新增") {
      setAddVisible(true);
    }
  };

  const onFinish = () => {
    setIsChecking(true);
    console.log("finish", getVerificationList());
    const params = {
      company_list: getVerificationList(),
    };
    projectApi.addBusinessRecord(params).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setIsChecking(false);
        setIsCreate(false);
        getDate && getDate();
      } else {
        message.error(res.message);
      }
    });
  };

  //下一步
  const nextHandle = () => {
    if (verificationList?.length === 0) {
      message.info("请先添加核查对象");
      return;
    }
    // const isDuplicate = judgmentDuplicateArray(getVerificationList(), [
    //   "company_id",
    //   "company_name",
    // ]);
    // if (isDuplicate) {
    //   message.warning("存在重复核查对象（会占用核查对象数量），建议删除！");
    // }
    onFinish();
  };

  //全部删除
  const clearAllHandle = () => {
    MessageModal({
      type: "warning",
      description: "确定要清除所有核查对象吗?",
      title: "清除",
      onOk: () => {
        setVerificationList([]);
      },
    });
  };

  const imgSrc = (type) => {
    if (type === "上传") {
      return icon1;
    } else if (type === "新增") {
      return icon2;
    }
  };

  //上传成功回调
  const uploadFileSuccess = (files) => {
    setUpLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file[]", file?.originFileObj);
    });
    projectApi.uploadBusinessCheckFiles(formData).then((res) => {
      console.log(res);

      if (res.code === 200) {
        setUpData(res.data);
        setFileVisible(false);
        setUploadVisible(true);
      } else {
        message.error(res.message);
      }
      setUpLoading(false);
    });
  };

  //上传成功点击确定
  const uploadSuccess = () => {
    let nodes =
      upData?.success_data?.map((item) => {
        item.key = guid();
        item.company_name = item.name;
        item.company_id = item.id;
        return item;
      }) || [];
    setVerificationList([...getVerificationList(), ...nodes]);
  };

  const columns = [
    {
      title: "序号",
      dataIndex: "index",
      key: "index",
      width: "80px",
      align: "center",
      render: (text, record, index) => <>{record.index}</>,
    },
    {
      title: "核查对象",
      dataIndex: "company_name",
      key: "company_name",
      align: "center",
      render: (text, record, index) => {
        if (record.is_valid) {
          return (
            <span style={{ color: "red" }}>
              {(text || "") + `（${record.msg}）`}
            </span>
          );
        }
        return text;
        // return (
        //   <a
        //     onClick={(e) => {
        //       e.stopPropagation();
        //       showInfoHandle(record);
        //     }}
        //   >
        //     {text}
        //   </a>
        // );
      },
    },
    {
      title: "操作",
      dataIndex: "caozuo",
      key: "caozuo",
      width: "100px",
      align: "center",
      render: (text, record, index) => (
        <Button
          type="link"
          onClick={() => {
            deleteItem(record);
          }}
        >
          删除
        </Button>
      ),
    },
  ];

  const onAddFinish = () => {
    form.validateFields().then((res) => {
      let obj = {
        company_id: res.companyInfo?.company_id,
        company_name: res.companyInfo.name,
        key: guid(),
      };
      setVerificationList([...getVerificationList(), obj]);
      setTimeout(() => {
        form.resetFields(["companyInfo"]);
        form.setFieldsValue({
          companyInfo: undefined,
        });
      }, 100);
    });
  };

  async function fetchUserList(username) {
    if (!username || username?.length < 2) {
      return [];
    }
    return projectApi.getCompanyInfo(username).then((res) => {
      const filterList = (getVerificationList() || [])
        ?.filter((_) => _.s_type === form.getFieldValue("s_type"))
        ?.map((_) => _.id);
      let list = [];
      if (res.code === 200) {
        list = res.data?.filter((_) => {
          return !filterList.includes(_.company_id);
        });
      } else {
        message.error(res.message);
      }
      return list.map((item) => ({
        ...item,
        label: item.name,
        value: item.company_id,
      }));
    });
  }

  return (
    <div className="createCheckComContent">
      <HeaderTitle title={"新建核查"}></HeaderTitle>
      <div className="createContentBox" ref={ref}>
        {verificationList?.length === 0 ? (
          <div className="noData">
            <MyEmpty description="还没有添加核查对象"></MyEmpty>
            <div className="createBtnBox">
              {["上传", "新增"].map((item) => (
                <div
                  key={item}
                  className="createBtnItem"
                  onClick={() => {
                    handleFunc(item);
                  }}
                >
                  <ReactSVG
                    className="createBtnItemIcon"
                    src={imgSrc(item)}
                  ></ReactSVG>
                  {item}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="tableContentBox">
            <div className="iconBox">
              <Tooltip placement="top" title="全部删除" arrowPointAtCenter>
                <ReactSVG
                  className="clearAll"
                  onClick={() => clearAllHandle(true)}
                  src={deleteIcon}
                ></ReactSVG>
              </Tooltip>

              <Tooltip placement="top" title="上传" arrowPointAtCenter>
                <VerticalAlignTopOutlined
                  onClick={() => setFileVisible(true)}
                />
              </Tooltip>

              <Tooltip placement="top" title="新增" arrowPointAtCenter>
                <PlusCircleOutlined
                  onClick={() => {
                    setAddVisible(true);
                  }}
                />
              </Tooltip>
            </div>
            <Table
              scroll={{
                y: size?.height ? `${size?.height - 100}px` : null,
              }}
              style={{ marginBottom: "15px" }}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              rowClassName={(item) => {
                let className = "";
                if (item.key === getSelectItemId()) {
                  className = "selectItem";
                }
                return className;
              }}
              className="myFormTable"
              bordered={true}
              pagination={false}
              columns={columns}
              dataSource={verificationList.map((_, index) => {
                _.index = index + 1;
                return _;
              })}
            />
          </div>
        )}
      </div>

      <div className="createContentBtnBox">
        <Button
          style={{ marginRight: "24px" }}
          onClick={() => {
            setIsCreate(false);
          }}
        >
          取消
        </Button>
        <Button
          type="primary"
          onClick={() => {
            nextHandle();
          }}
          loading={isChecking}
        >
          开始核查
        </Button>
      </div>

      <UploadFileDrawer
        loading={upLoading}
        visible={fileVisible}
        onClose={() => setFileVisible(false)}
        successFunc={uploadFileSuccess}
        title="上传工商核查对象"
        // multiple={true}
      ></UploadFileDrawer>

      <UploadModal
        upData={upData}
        visible={uploadVisible}
        isBusiness={true}
        onCancel={() => {
          setUploadVisible(false);
        }}
        onOk={() => {
          setUploadVisible(false);
          uploadSuccess();
        }}
      ></UploadModal>
      <Drawer
        className="addDrawer2"
        width="600px"
        closable={false}
        title="手工添加核查对象"
        placement="right"
        push={false}
        maskClosable={false}
        keyboard={false}
        visible={addVisible}
        onClose={() => {
          setAddVisible(false);
        }}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              setAddVisible(false);
            }}
          />
        }
        footer={
          <div className="drawerFooterBtnBox">
            <Button
              onClick={() => {
                onAddFinish();
              }}
              type="primary"
            >
              确定
            </Button>
            <Button
              className="noBg"
              onClick={() => {
                setAddVisible(false);
              }}
            >
              关闭
            </Button>
          </div>
        }
      >
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: `100%`,
            width: "100%",
          }}
        >
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            className="addCheckForm"
          >
            <Form.Item
              name="companyInfo"
              label="核查对象名称"
              key="companyInfo"
              rules={[
                {
                  required: true,
                  message: `请选择核查对象`,
                },
              ]}
              style={{ width: "100%" }}
              className="companyInfoFormItem"
            >
              <DebounceSelect
                showSearch={true}
                placeholder="请输入核查对象名称搜索"
                fetchOptions={fetchUserList}
                changeClear={true}
                allowClear
                style={{
                  width: "100%",
                }}
                notInForm={true}
                onChange={(info) => {
                  form.setFieldsValue({
                    companyInfo: info,
                  });
                }}
              />
            </Form.Item>
          </Form>
        </Scrollbars>
      </Drawer>
    </div>
  );
};
export default CreateCheckCom;
