import { useEffect, useState } from "react";
import "./index.scss";
import useUrlState from "@ahooksjs/use-url-state";
import { Button, Result, Spin } from "antd";
import { useGetState } from "ahooks";
import MySpin from "../../components/MySpin";
import Img404 from "../../assets/404_images/404.png";
import projectApi from "../../apis/project/index";
import moment from "moment";
function ExternalPage() {
  const [state, setState] = useUrlState({});

  const [iframeUrl, setIframeUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [type, setType, getType] = useGetState(false);
  const [link, setLink, getLink] = useGetState(false);

  const loadingFun = () => {
    console.log("iframe加载完成");
    setLoading(false);
  };

  const getIframeUrl = () => {
    setError(false);
    setLoading(true);
    const params = {
      link: getLink(),
      type: getType()
    };
    const nowTime = moment().unix();
    projectApi
      .getThirdPartyUrl(params)
      .then((res) => {
        setLoading(false);
        if (res.code === 200) {
          //先判断有没有登录过
          let tokenUnix = localStorage.getItem("tokenUnix");
          let diff = 60 * 10;
          if (tokenUnix && nowTime - tokenUnix < diff) {
            console.log(
              "拿缓存的token",
              ((diff - (nowTime - tokenUnix)) / 60).toFixed(0) + "分钟后过期"
            );
            setIframeUrl(res.data?.url);
          } else {
            console.log("重新获取token");
            localStorage.setItem("tokenUnix", nowTime);
            setIframeUrl(res.data?.login_url);
          }
        }else{
          setError(true);
        }
      })
      .catch((sea) => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    setType(state.type);
    setLink(state.link);
    setTimeout(() => {
      getIframeUrl();
    }, 50);
  }, []);
  return (
    <div className="externalPageContent">
      {loading ? (
        <MySpin></MySpin>
      ) : error ? (
        <Result
          // status="404"
          title="抱歉，请求错误"
          subTitle="请点击刷新重试!"
          icon={<img src={Img404} className="img_404"></img>}
          extra={
            <Button onClick={getIframeUrl} type="primary">
              刷新
            </Button>
          }
        />
      ) : (
        <iframe
          onLoad={loadingFun}
          className="iframe"
          src={iframeUrl}
          frameBorder="0"
        ></iframe>
      )}
    </div>
  );
}
export default ExternalPage;
