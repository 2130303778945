import "./index.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import TypeComparison from "./components/TypeComparison";
import AmountTrend from "./components/AmountTrend";
import projectApi from "../../../../../apis/project";
import { message, Select, Tag, Spin, Divider, Popover, Input } from "antd";
import { useGetState, useDebounceFn } from "ahooks";
import {
  ArrayIsEqual,
  arrayContainsAnotherArray,
  guid,
} from "../../../../../unit/unit";
import MySpin from "../../../../../components/MySpin";
import CompanyCard from "../CompanyCard";
import Scrollbars from "react-custom-scrollbars";
import { SearchOutlined, CheckOutlined } from "@ant-design/icons";
import MyEmpty from "../../../../../components/Empty";
import {
  CaretDownOutlined,
  PlusCircleFilled,
  DeleteOutlined,
  EyeFilled,
} from "@ant-design/icons";
import SupplierAnalysis from "./components/SupplierAnalysis";
import InfiniteScroll from "react-infinite-scroll-component";
import svg1 from "../../../../../assets/降序.svg";
import svg2 from "../../../../../assets/生序.svg";
import { ReactSVG } from "react-svg";
import HeaderTitle from "../../../../../components/HeaderTitle";
import svg5 from "../../../../../assets/数字降序.svg";
import svg6 from "../../../../../assets/数字升序.svg";
import svg3 from "../../../../../assets/字母降序.svg";
import svg4 from "../../../../../assets/字母生序.svg";
import SummaryAssembly from "./components/SummaryAssembly";
import deleteSvg from "../../../../../assets/清空.svg";
import pinyin from "pinyin";

const { Option } = Select;

const FasterSelectContent = (props) => {
  const {
    subjectScopeOptions,
    checkCompanyList,
    dataList,
    setCheckCompanyList,
  } = props;

  const [subjectScopeList, setSubjectScopeList, getSubjectScopeList] =
    useGetState([]);
  const [
    relatedPartiesCountObj,
    setRelatedPartiesCountObj,
    getRelatedPartiesCountObj,
  ] = useGetState({});

  const initData = (dataList, checkCompanyList) => {
    let obj = {};
    dataList?.forEach((item) => {
      item?.relatedPartiesType?.forEach((codeItem) => {
        if (obj[codeItem?.r_type]) {
          obj[codeItem?.r_type].push(item?.bid_winner);
        } else {
          obj[codeItem?.r_type] = [item?.bid_winner];
        }
      });
    });
    setRelatedPartiesCountObj(obj);
    let arr = [];
    subjectScopeOptions?.forEach((item) => {
      if (
        arrayContainsAnotherArray(
          obj[item?.dic_item_code] || [],
          checkCompanyList
        )
      ) {
        arr.push(item?.dic_item_code);
      }
    });
    setSubjectScopeList(arr);
  };

  useEffect(() => {
    initData(dataList, checkCompanyList);
  }, [checkCompanyList]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {subjectScopeOptions.map((item, index) => (
        <div
          key={item.dic_item_code}
          value={item.dic_item_code}
          className={
            getSubjectScopeList()?.includes(item.dic_item_code)
              ? "select-item-option-content select-active-item-option-content"
              : "select-item-option-content"
          }
          onClick={() => {
            if (!getRelatedPartiesCountObj()[item.dic_item_code]) {
              return;
            }
            let arr = getSubjectScopeList();
            //只能加 不能取消
            if (arr?.includes(item.dic_item_code)) {
              // arr = arr?.filter((_) => _ !== item.dic_item_code);
              return;
            } else {
              arr.push(item.dic_item_code);
            }
            let list = checkCompanyList;
            arr.forEach((code) => {
              list = list.concat(getRelatedPartiesCountObj()[code] || []);
            });
            list = [...new Set(list)];
            if (list?.length === 0) {
              return;
            }
            list = list?.map((_) => {
              let item = dataList?.find((__) => __.bid_winner === _);
              return item;
            });
            setCheckCompanyList(list);
          }}
        >
          <div className="selectOptionBox">
            <a className="textItem" title={item.dic_item_name}>
              {index + ". " + item.dic_item_name}
            </a>
            <Tag
              style={
                !relatedPartiesCountObj[item.dic_item_code]?.length > 0
                  ? {
                      color: "#535353",
                      background: "#EFEFEF",
                      borderColor: "#EFEFEF",
                    }
                  : {
                      color: "#fff",
                      background: "#0068B2",
                      borderColor: "#0068B2",
                    }
              }
            >
              {relatedPartiesCountObj[item.dic_item_code]?.length || 0}
            </Tag>
          </div>

          <CheckOutlined className="selectIcon" />
        </div>
      ))}
    </div>
  );
};

const TenderingAndSupplierAnalysis = (props) => {
  const { searchTableFunc, updateTimeCallBack } = props;
  const { id } = useParams();
  const [updateTime, setUpdateTime] = useState(null);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [data, setData] = useState({});
  const [companyList, setCompanyList, getCompanyList] = useGetState([]);
  const [allCompanyList, setAllCompanyList, getAllCompanyList] = useGetState(
    []
  );
  const [showCompanyList, setShowCompanyList, getShowCompanyList] = useGetState(
    []
  );
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [dicCodeLoading, setDicCodeLoading] = useState(false);
  const [subjectScopeList, setSubjectScopeList, getSubjectScopeList] =
    useGetState([]);
  const [subjectScopeOptions, setSubjectScopeOptions, getSubjectScopeOptions] =
    useGetState([]);
  const [oldSubjectScopeList, setOldSubjectScopeList, getOldSubjectScopeList] =
    useGetState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllIndeterminate, setCheckAllIndeterminate] = useState(false);
  const [relatedPartiesCountObj, setRelatedPartiesCountObj] = useState({});
  const [checkCompanyList, setCheckCompanyList, getCheckCompanyList] =
    useGetState([]);
  const [searchValue, setSearchValue, getSearchValue] = useGetState(null);

  const [showDataPageNumber, setShowDataPageNumber, getShowDataPageNumber] =
    useGetState(1);
  const [showDataPageSize, setShowDataPageSize, getShowDataPageSize] =
    useGetState(50);
  const [dataTotal, setDataTotal, getDataTotal] = useGetState(0);
  const [chartsLoading, setChartsLoading, getChartsLoading] =
    useGetState(false);
  const [identificationToken, setIdentificationToken, getIdentificationToken] =
    useGetState(null);

  const [sortKey, setSortKey, getSortKey] = useGetState("招标金额");
  const [sortOrder, setSortOrder, getSortOrder] = useGetState("decline");

  const sortIconRef = useRef({
    招标金额: {
      rise: <ReactSVG className="sortReactSvg" src={svg1}></ReactSVG>,
      decline: <ReactSVG className="sortReactSvg" src={svg2}></ReactSVG>,
    },
    招标项目数: {
      rise: <ReactSVG className="sortReactSvg" src={svg1}></ReactSVG>,
      decline: <ReactSVG className="sortReactSvg" src={svg2}></ReactSVG>,
    },
    公司名称: {
      rise: <ReactSVG className="sortReactSvg" src={svg3}></ReactSVG>,
      decline: <ReactSVG className="sortReactSvg" src={svg4}></ReactSVG>,
    },
    关联方类型: {
      rise: <ReactSVG className="sortReactSvg" src={svg5}></ReactSVG>,
      decline: <ReactSVG className="sortReactSvg" src={svg6}></ReactSVG>,
    },
  });

  const { run } = useDebounceFn(
    (e) => {
      setSearchValue(e.target.value);
      searchValueChangeToCheck(e.target.value);
    },
    {
      wait: 300,
    }
  );

  //公司筛选
  const searchValueChangeToCheck = (value) => {
    let list = [];
    if (value) {
      list = companyList.filter((_) => _.bid_winner?.includes(value));
    } else {
      list = companyList;
    }
    setShowDataPageNumber(1);
    setDataTotal(list?.length);
    setShowCompanyList(list?.slice(0, 1 * getShowDataPageSize()));
  };

  //获取关联方列表
  const getBiddingRelatedPartiesCompanyList = () => {
    setLoading(true);
    projectApi.getBiddingRelatedPartiesCompanyList(id).then((res) => {
      if (res.code === 200) {
        let list = res.data.data;
        list = list?.map((_) => ({
          ..._,
          bid_winner: _.company_name,
          winner_id: _.company_id,
          type: _?.rpi_type,
          relatedPartiesType: _?.rpi_type,
        }));
        setAllCompanyList(list);
      } else {
        setAllCompanyList([]);
      }
      //初始化 默认选中尽调公司
      initDefaultCompanyList();
      setLoading(false);
    });
  };

  //清空排序规则
  const clearSortingRules = () => {
    setSortKey("招标金额");
    setSortOrder("decline");
  };

  //初始化 默认选中尽调公司
  const initDefaultCompanyList = (notClearSortingRules) => {
    if (!notClearSortingRules) {
      clearSortingRules();
    }
    setTimeout(() => {
      const companyList = [
        {
          company_id: projectInfo?.company_id,
          company_name: projectInfo?.company_name,
          bid_winner: projectInfo.company_name,
          type: [
            {
              full_name: "尽调对象",
              r_type: "CDD_SUB_DD_CORP",
              short_name: "尽调对象",
            },
          ],
        },
      ];
      companyListChangeFunc(companyList);
    }, 50);
  };

  //展示公司列表发生变化
  const companyListChangeFunc = (list) => {
    console.log("公司列表发生变化", list);
    setShowDataPageNumber(1);
    setCompanyList(list);
    setDataTotal(list?.length);
    setShowCompanyList(list?.slice(0, 1 * getShowDataPageSize()));
    setCheckCompanyList(list?.map((_) => _.company_name));
    getData(list);
  };

  //排序规则
  const sortingRules = (itemA, itemB) => {
    const sortKey = getSortKey();
    const sortOrder = getSortOrder(); // -1 小到大 生序 1 大到小 降序
    const subjectScopeList = getSubjectScopeOptions();

    const getValue = (item, key) => {
      switch (key) {
        case "招标金额":
          return item?.competitive_amount;
        case "招标项目数":
          return item?.competitive_count;
        case "公司名称":
          return pinyin(item?.bid_winner, {
            style: pinyin.STYLE_NORMAL,
            segment: true
          }).join("");
        case "关联方类型":
          return (
            1000 -
            (subjectScopeList?.find(
              (_) =>
                _?.dic_item_code ===
                ((item?.relatedPartiesType || [])?.at(0) || {})?.r_type
            )?.seq || 1000)
          );
        default:
          return 0;
      }
    };

    const compareValues = (valueA, valueB, isText) => {
      if (isText) {
        return sortOrder === "rise"
          ? valueB.localeCompare(valueA, "zh-CN")
          : valueA.localeCompare(valueB, "zh-CN");
      }
      return sortOrder === "rise" ? valueA - valueB : valueB - valueA;
    };

    const resolveTie = (itemA, itemB, keys) => {
      for (let key of keys) {
        let valueA = getValue(itemA, key);
        let valueB = getValue(itemB, key);
        let isText = key === "公司名称";
        if (valueA !== valueB) {
          return compareValues(valueA, valueB, isText);
        }
      }
      return 0;
    };

    let valueA = getValue(itemA, sortKey);
    let valueB = getValue(itemB, sortKey);
    let isText = sortKey === "公司名称";

    if (valueA === valueB) {
      const tieBreakerKeys = {
        招标金额: ["招标项目数", "公司名称", "关联方类型"],
        招标项目数: ["招标金额", "公司名称", "关联方类型"],
        公司名称: ["招标金额", "招标项目数", "关联方类型"],
        关联方类型: ["招标金额", "招标项目数", "公司名称"],
      };
      return resolveTie(itemA, itemB, tieBreakerKeys[sortKey]);
    }

    return compareValues(valueA, valueB, isText);
  };

  //更新公司列表数据并排序
  const initCompanyDataAndSort = (companyList) => {
    let dueDiligenceCompanyItem = companyList?.find(
      (_) => _.bid_winner === projectInfo.company_name
    );

    let list = companyList
      ?.filter((_) => _.bid_winner !== projectInfo.company_name)
      ?.sort((a, b) => {
        return sortingRules(a, b);
      });
    list = [dueDiligenceCompanyItem, ...list];

    setCompanyList(list);
    setShowDataPageNumber(1);
    setShowCompanyList(list?.slice(0, 1 * getShowDataPageSize()));
    let checkCompanyList = getCheckCompanyList();
    checkCompanyList = checkCompanyList.sort((a, b) => {
      return (
        list.findIndex((_) => _.bid_winner === a) -
        list.findIndex((_) => _.bid_winner === b)
      );
    });
    setCheckCompanyList(JSON.parse(JSON.stringify(checkCompanyList)));
    console.log(
      "排列顺序后的数据",
      list,
      "排列顺序后的checkCompanyList",
      checkCompanyList
    );
  };

  //更改展示公司列表数字
  const initCompanyListToNum = (list) => {
    let companyList = getCompanyList();
    // let showCompanyList = getShowCompanyList();

    companyList = companyList.map((_) => {
      const item = list?.find((__) => __.bid_winner === _.bid_winner) || {};
      return {
        ..._,
        competitive_amount: item.bid_amount || 0,
        competitive_count: item.bid_count || 0,
      };
    });
    // showCompanyList = showCompanyList.map((_) => {
    //   const item = list?.find((__) => __.bid_winner === _.bid_winner) || {};
    //   return {
    //     ..._,
    //     competitive_amount: item.bid_amount || 0,
    //     competitive_count: item.bid_count || 0,
    //   };
    // });
    initCompanyDataAndSort(companyList);
  };

  //获取公司列表接口
  const getData = (list) => {
    setCompanyLoading(true);
    projectApi
      .getAnalysisCompanyList({
        project_id: id,
        company_id: projectInfo.company_id,
        bid_type: 1,
        company_ids: JSON.stringify(list?.map((_) => _.company_id)),
      })
      .then((res) => {
        if (res.code === 200) {
          console.log("招标及供应商分析----公司", res.data);
          let _list = res.data?.bid_list || [];
          initCompanyListToNum(_list);
          setUpdateTime(res.data.data_update_time);
        } else {
          setUpdateTime(null);
          message.error(res.message);
        }
        setCompanyLoading(false);
      });
  };

  useEffect(() => {
    updateTimeCallBack && updateTimeCallBack(updateTime);
  }, [updateTime]);

  //获取code枚举属性
  const getCodeInfo = () => {
    setDicCodeLoading(true);
    projectApi.getDicCodeInfo("CDD_SUB").then((res) => {
      if (res.code === 200) {
        setSubjectScopeOptions(res.data);
        // getCodeNumber();
      } else {
        setSubjectScopeOptions([]);
      }
      setDicCodeLoading(false);
      // getData();
    });
  };

  //获取图表数据接口
  const getChartDataFunc = () => {
    setChartsLoading(true);
    let _identificationToken = guid();
    setIdentificationToken(_identificationToken);
    projectApi
      .getTenderingAndSupplierAnalysis({
        project_id: id,
        name: "listed_bid_info",
        company_id: projectInfo.company_id,
        company_ids: JSON.stringify(
          getCheckCompanyList()?.map((_) => {
            let item = allCompanyList?.find((__) => __.bid_winner === _);
            return item?.winner_id;
          })
        ),
        sub_type:
          getSubjectScopeList()?.length > 0
            ? JSON.stringify(getSubjectScopeList())
            : null,
      })
      .then((res) => {
        if (_identificationToken !== getIdentificationToken()) {
          return;
        }
        if (res.code === 200) {
          console.log("招标及供应商分析----图表数据", res.data);
          setData(res.data);
        } else {
          setData({});
          message.error(res.message);
        }
        setChartsLoading(false);
      })
      .catch((sea) => {
        setChartsLoading(false);
      });
  };

  //加载更多
  const loadMoreData = () => {
    console.log("----------loadMoreData", getShowDataPageNumber());
    let pageNumber = getShowDataPageNumber() + 1;
    setShowDataPageNumber(pageNumber);
    setShowCompanyList(
      companyList?.slice(0, pageNumber * getShowDataPageSize())
    );
  };

  useEffect(() => {
    getCodeInfo();
    getBiddingRelatedPartiesCompanyList();
  }, []);

  useEffect(() => {
    if (checkCompanyList?.length === 0) {
      return;
    }
    console.log("checkCompanyList变化!!!!!!!!");
    getChartDataFunc();
  }, [checkCompanyList]);

  return loading ? (
    <MySpin></MySpin>
  ) : (
    <div className="tenderingAndSupplierAnalysisContent">
      {/* <div className="dataInput">更新时间：{updateTime || "-"} </div> */}

      <div className="chartsBox">
        <div className="chartsLeftBox">
          {/* 历年中标公司列表 */}
          <div className="chartBox">
            {/* <WinningCompany
              data={data?.history_bid_list || []}
            ></WinningCompany> */}
            <div className="headerTitleBox">
              {/* <div className="imgTitleBox">
                <img src={comSvg}></img> 公司列表
              </div> */}
              <HeaderTitle
                title={
                  <>
                    公司列表
                    {/* <span
                      style={{
                        color: "#535353",
                        fontSize: "12px",
                        marginLeft: "8px",
                        fontWeight: "400",
                      }}
                    >
                      ({companyList?.length}/20)
                    </span> */}
                  </>
                }
                showTip={false}
              ></HeaderTitle>
            </div>
            <div className="tableContentBox">
              {/* <div className="searchBox">
                <div className="searchBoxSelectItem">
                  <SearchOutlined className="searchBoxSelectIcon" />
                  <Select
                    showSearch
                    placeholder="输入公司名称添加到分析列表"
                    onChange={(value) => {
                      let item = allCompanyList?.find(
                        (_) => _?.bid_winner === value
                      );
                      companyListChangeFunc([...getCompanyList(), item]);
                    }}
                    value={null}
                    notFoundContent={<MyEmpty></MyEmpty>}
                    allowClear
                    className="searchBoxSelect"
                    showArrow={false}
                    style={{
                      width: "100%",
                    }}
                    filterOption={(input, option) =>
                      (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={allCompanyList
                      ?.filter(
                        (_) =>
                          !companyList?.some(
                            (__) => __.bid_winner === _?.bid_winner
                          )
                      )
                      ?.map((_) => ({
                        value: _.bid_winner,
                        label: (
                          <div>
                            <div>{_.bid_winner}</div>
                            <div className="searchBoxSelectItemTagBox">
                              {_.relatedPartiesType?.map((__) => (
                                <Tooltip
                                  key={Math.random()}
                                  placement="top"
                                  title={__.full_name}
                                  arrowPointAtCenter
                                >
                                  <div>
                                    <Tag key={Math.random()}>
                                      {__?.short_name}
                                    </Tag>
                                  </div>
                                </Tooltip>
                              ))}
                            </div>
                          </div>
                        ),
                      }))}
                  />
                </div>

                <Popover
                  destroyTooltipOnHide={true}
                  placement="bottomRight"
                  content={
                    <FasterSelectContent
                      subjectScopeOptions={subjectScopeOptions}
                      checkCompanyList={companyList?.map((_) => _.bid_winner)}
                      dataList={allCompanyList}
                      setCheckCompanyList={companyListChangeFunc}
                    ></FasterSelectContent>
                  }
                  trigger="click"
                  overlayClassName="searchOptionsPopover"
                >
                  <div className="searchItemBox">
                    快速添加
                    <CaretDownOutlined />
                  </div>
                </Popover>
              </div> */}
              {/* <div className="tipTextContentBox">
                <span>
                  共添加 <span className="span1">{companyList?.length}</span>{" "}
                  个，已选中{" "}
                  <span className="span2">{checkCompanyList?.length}/20</span>{" "}
                  个展示
                </span>
                <span
                  className="span3"
                  onClick={() => {
                    initDefaultCompanyList();
                  }}
                >
                  清空列表
                </span>
              </div> */}
              <div className="sortContentBox">
                {/* {[
                  {
                    label: "招标金额",
                    ascendingOrderElement: (
                      <ReactSVG className="sortReactSvg" src={svg1}></ReactSVG>
                    ),
                    descendingOrderElement: (
                      <ReactSVG className="sortReactSvg" src={svg2}></ReactSVG>
                    ),
                  },
                  {
                    label: "招标项目数",
                    ascendingOrderElement: (
                      <ReactSVG className="sortReactSvg" src={svg1}></ReactSVG>
                    ),
                    descendingOrderElement: (
                      <ReactSVG className="sortReactSvg" src={svg2}></ReactSVG>
                    ),
                  },
                  {
                    label: "公司名称",
                    ascendingOrderElement: <CaretUpOutlined />,
                    descendingOrderElement: <CaretDownOutlined />,
                  },
                  {
                    label: "关联方类型",
                    ascendingOrderElement: <CaretUpOutlined />,
                    descendingOrderElement: <CaretDownOutlined />,
                  },
                ]?.map((_) => (
                  <span
                    className={
                      getSortKey() === _.label
                        ? "sortIconItem sortIconActiveItem"
                        : "sortIconItem"
                    }
                    onClick={() => {
                      //排序
                      if (getSortKey() === _.label) {
                        setSortOrder(0 - getSortOrder());
                      } else {
                        //切换排序
                        setSortKey(_.label);
                        setSortOrder(1);
                      }
                      setTimeout(() => {
                        initCompanyDataAndSort(getCompanyList());
                      }, 100);
                    }}
                  >
                    {_?.label}{" "}
                    {getSortKey() === _.label
                      ? getSortOrder() === 1
                        ? _.descendingOrderElement
                        : _.ascendingOrderElement
                      : null}
                  </span>
                ))} */}
                <Popover
                  title={null}
                  overlayClassName="sortPopover"
                  placement="bottomLeft"
                  content={
                    <div className="sortPopoverContentBox">
                      {[
                        {
                          label: "招标金额",
                          order: "decline",
                        },
                        {
                          label: "招标金额",
                          order: "rise",
                        },
                        {
                          label: "招标项目数",
                          order: "decline",
                        },
                        {
                          label: "招标项目数",
                          order: "rise",
                        },
                        {
                          label: "公司名称",
                          order: "decline",
                        },
                        {
                          label: "公司名称",
                          order: "rise",
                        },
                        {
                          label: "关联方类型",
                          order: "decline",
                        },
                        {
                          label: "关联方类型",
                          order: "rise",
                        },
                      ]?.map((_) => {
                        return (
                          <div
                            className={
                              getSortKey() === _.label &&
                              getSortOrder() === _.order
                                ? "sortPopoverItem sortPopoverActiveItem"
                                : "sortPopoverItem"
                            }
                            onClick={() => {
                              //排序
                              if (
                                getSortKey() === _.label &&
                                getSortOrder() === _.order
                              ) {
                                return;
                              }
                              //切换排序
                              setSortKey(_.label);
                              setSortOrder(_.order);
                              setTimeout(() => {
                                initCompanyDataAndSort(getCompanyList());
                              }, 100);
                            }}
                          >
                            {_.label}
                            {(sortIconRef.current[_.label] || {})[_.order]}
                          </div>
                        );
                      })}
                    </div>
                  }
                >
                  <div className="sortItem">
                    排序
                    <CaretDownOutlined />
                    <span className="sortItemText">
                      {sortKey}
                      {(sortIconRef.current[sortKey] || {})[sortOrder]}
                    </span>
                  </div>
                </Popover>
                <div className="handleIconBox">
                  <Popover
                    destroyTooltipOnHide={true}
                    placement="bottomRight"
                    content={
                      <FasterSelectContent
                        subjectScopeOptions={subjectScopeOptions}
                        checkCompanyList={companyList?.map((_) => _.bid_winner)}
                        dataList={allCompanyList}
                        setCheckCompanyList={companyListChangeFunc}
                      ></FasterSelectContent>
                    }
                    trigger="click"
                    overlayClassName="searchOptionsPopover"
                  >
                    <div className="searchItemBox">
                      <PlusCircleFilled />
                      添加
                    </div>
                  </Popover>
                  <div
                    className="searchItemBox"
                    onClick={() => {
                      if (
                        getCompanyList()?.length ===
                        getCheckCompanyList()?.length
                      ) {
                        setCheckCompanyList([projectInfo?.company_name]);
                      } else {
                        setCheckCompanyList(
                          getCompanyList()?.map((_) => _.company_name)
                        );
                      }
                    }}
                  >
                    <EyeFilled />
                    显示
                  </div>
                  <div
                    className="searchItemBox"
                    onClick={() => {
                      initDefaultCompanyList(true);
                    }}
                  >
                    {/* <DeleteOutlined /> */}
                    <ReactSVG className="anticon" src={deleteSvg}></ReactSVG>
                    清空
                  </div>
                </div>
              </div>
              <div className="dividerBox">
                <span></span>
              </div>
              <div className="searchCompanyBox">
                <Input
                  placeholder="输入公司名称搜索"
                  onChange={run}
                  suffix={<SearchOutlined />}
                  // bordered={false}
                  allowClear
                  style={{
                    width: "250px",
                    margin: "10px 0px 0px 0px",
                    // borderBottom: "#d9d9d9 1px solid",
                    borderRadius: "4px",
                  }}
                ></Input>
              </div>
              {/* <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "calc(100% - 50px)", width: "100%" }}
              > */}
              <div id="checkBox" className="checkBox">
                {showCompanyList?.length !== 0 ? (
                  <InfiniteScroll
                    dataLength={showCompanyList.length}
                    next={loadMoreData}
                    loader={<Divider className="loadingBox">Loading</Divider>}
                    hasMore={showCompanyList.length < dataTotal}
                    scrollableTarget="checkBox"
                    // endMessage={<Divider className="noDataDivider"></Divider>}
                  >
                    {showCompanyList?.map((item, index) => (
                      <CompanyCard
                        key={Math.random()}
                        info={item}
                        dataType={'招标及供应商分析'}
                        searchTableFunc={searchTableFunc}
                        index={index}
                        type={item.type}
                        noDelete={true}
                        checkCompanyList={checkCompanyList}
                        dataList={companyList}
                        setCheckCompanyList={setCheckCompanyList}
                        loading={companyLoading}
                        notShowIndex={index === 0}
                      ></CompanyCard>
                    ))}
                  </InfiniteScroll>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MyEmpty></MyEmpty>
                  </div>
                )}
              </div>
              {/* </Scrollbars> */}
            </div>
          </div>
        </div>

        <div className="chartsRightBox">
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{ height: "100%", width: "100%", paddingRight: "10px" }}
            className="chartsRightScrollbarsBox"
          >
            {/* 概述 */}
            <Spin spinning={chartsLoading} wrapperClassName="chartsSpin">
              <div className="chartBox" style={{ marginTop: "0px" }}>
                <SummaryAssembly
                  data={data}
                  checkCompanyList={checkCompanyList}
                  searchTableFunc={searchTableFunc}
                  notHasData={noData}
                  getCompanyList={getAllCompanyList}
                ></SummaryAssembly>
              </div>
            </Spin>

            {/* 历年招标及公布中标情况趋势 */}
            <Spin spinning={chartsLoading} wrapperClassName="chartsSpin">
              <div className="chartBox">
                <AmountTrend
                  data={data}
                  checkCompanyList={checkCompanyList?.slice(0, 11)}
                  checkAllCompanyList={checkCompanyList}
                  searchTableFunc={searchTableFunc}
                  notHasData={noData}
                  chartsLoading={chartsLoading}
                  getCompanyList={getAllCompanyList}
                ></AmountTrend>
              </div>
            </Spin>

            {/* 各公司招标及已公布中标情况对比 */}
            <Spin spinning={chartsLoading} wrapperClassName="chartsSpin">
              <div className="chartBox">
                <TypeComparison
                  data={data?.invite_hit_compare_list || []}
                  checkCompanyList={checkCompanyList?.slice(0, 11)}
                  checkAllCompanyList={checkCompanyList}
                  searchTableFunc={searchTableFunc}
                  notHasData={noData}
                  chartsLoading={chartsLoading}
                  getCompanyList={getAllCompanyList}
                ></TypeComparison>
              </div>
            </Spin>

            {/* 供应商分析 */}
            <Spin spinning={chartsLoading} wrapperClassName="chartsSpin">
              <div className="chartBox">
                <SupplierAnalysis
                  data={data?.supplier_list || []}
                  checkCompanyList={checkCompanyList}
                  searchTableFunc={searchTableFunc}
                  notHasData={noData}
                  chartsLoading={chartsLoading}
                  getCompanyList={getAllCompanyList}
                ></SupplierAnalysis>
              </div>
            </Spin>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};
export default TenderingAndSupplierAnalysis;
