import "./index.scss";
import React, { useState, useEffect } from "react";
import {
  Popover,
  Input,
  Checkbox,
  Radio,
  Avatar,
  Select,
  Button,
  message,
  Tooltip,
  Tag,
  AutoComplete,
} from "antd";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import projectApi from "../../../apis/project";
import MySpin from "../../MySpin";
import { ReactSVG } from "react-svg";
import icon from "./批注.svg";
import { getLicenseValid } from "../../../unit/unit";
import { useGetState } from "ahooks";
import { useRef } from "react";

const { TextArea } = Input;
const { Option } = Select;

export const Content = (props) => {
  const {
    info,
    dataTable,
    getData,
    typeName,
    defaultData,
    remarkId,
    dataInfo,
    projectId,
    mode,
    focusContent,
    focusId,
  } = props;
  const [isFollow, setIsFollow, getIsFollow] = useGetState(
    defaultData?.isFollow || false
  );
  const [type, setType, getType] = useGetState(defaultData?.type || []);
  const [typeList, setTypeList] = useState([]);
  const [userId, setUserId] = useState(defaultData?.userId || []);
  const [remark, setRemark] = useState(defaultData?.remark || null);
  const [projectInfo, setProjectInfo] = useState({});
  const myInfo = useSelector((state) => state.User.userInfo);
  const [code, setCode, getCode] = useGetState(defaultData?.code || null);
  const [eventLevelDesc, setEventLevelDesc] = useState(
    defaultData?.codeName || null
  );
  const [codeLoading, setCodeLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [upLoading, setUpLoading, getUpLoading] = useGetState(false);
  const [customTags, setCustomTags] = useState([]);
  const [levelInputPopupOpen, setLevelInputPopupOpen, getLevelInputPopupOpen] =
    useGetState(false);
  const [
    levelInputClickCount,
    setLevelInputClickCount,
    getLevelInputClickCount,
  ] = useGetState(0);

  const levelInputRef = useRef(null);

  const onCheckChange = (e) => {
    setIsFollow(e.target.checked);
    setType(typeList[0]?.dic_item_code);
    setEventLevelDesc(typeList[0]?.dic_item_name);
    const item = (projectInfo?.member_list || []).find(
      (_) => _.user_id === myInfo?.co_user_id
    );
    //成员列表里不包含此人 不默认选择
    if(item){
      setUserId([myInfo?.co_user_id]);
    }else{
      setUserId([])
    }
    
  };

  const onRadioChange = (e) => {
    setType(e.target.value);
    if (e.target.value === "CDD_CONCERN_LEVEL_CUSTOM") {
      let codeName = null;
      if (defaultData?.type === "CDD_CONCERN_LEVEL_CUSTOM") {
        codeName = defaultData?.codeName;
      }
      setEventLevelDesc(codeName);
      return;
    }
    const typeItem = typeList.find((_) => _.dic_item_code === e.target.value);
    setEventLevelDesc(typeItem?.dic_item_name);
  };

  const userChange = (userId) => {
    setUserId(userId);
  };

  const remarkChange = (e) => {
    setRemark(e.target.value);
  };

  const getCodeInfo = () => {
    projectApi.getDicCodeInfo("CDD_CONCERN_TYPE").then((res) => {
      if (res.code === 200) {
        const _code = res.data.find((item) => item.dic_item_name === typeName);
        setCode(_code?.dic_item_code);
      } else {
        setCode("");
      }
    });
  };

  const getCustomTags = () => {
    projectApi.getCustomTags().then((res) => {
      if (res.code === 200) {
        console.log(res.data.data || []);
        setCustomTags(res.data.data || []);
      } else {
        setCustomTags([]);
      }
    });
  };

  useEffect(() => {
    if (typeName) {
      getCodeInfo();
      getCustomTags();
    }
  }, [typeName]);

  useEffect(() => {
    if (myInfo && !defaultData) {
      setUserId([myInfo.co_user_id]);
    }
  }, [myInfo]);

  const onFinish = () => {
    if (!remark) {
      message.error("请填写批注内容");
      return;
    }
    // console.log('dataInfo',dataInfo);
    // console.log('info',info);
    // console.log('defaultData',defaultData);
    // console.log('remarkId',remarkId);
    // console.log('focusId',focusId);
    // console.log('focusContent',focusContent);
    setUpLoading(true);
    let obj = {};

    Object.keys(dataInfo || {})?.forEach((key) => {
      obj[key] = dataInfo[key]?.val;
    });
    // console.log(dataInfo);
    // return
    let params = {
      data_table: dataTable,
      data_id: info.id,
      remark: remark,
      data_string: JSON.stringify(obj),
      focus_status: isFollow ? 1 : "0",
      event_type_code: code,
    };

    if (mode === 20) {
      params.mode = 20;
    } else if (mode === 30) {
      params.mode = 30;
      params.data_id = undefined;
    } else {
      params.mode = 10;
      params.data_field = info.key;
    }

    if (isFollow) {
      if (userId.length === 0) {
        message.error("请至少@一个人");
        setUpLoading(false);
        return;
      }
      if (type === "CDD_CONCERN_LEVEL_CUSTOM" && !eventLevelDesc) {
        message.error("请填写自定义标签");
        setUpLoading(false);
        return;
      }
      let item = null;
      let _type = type;
      if (type === "CDD_CONCERN_LEVEL_CUSTOM") {
        item = typeList.find((_) => _.dic_item_name === eventLevelDesc);
      }
      if (item) {
        _type = item.dic_item_code;
      }
      params.duty_user_ids = JSON.stringify(userId);
      params.event_level_code = _type;
      params.event_level_desc = eventLevelDesc;
    }
    let axiosApi = projectApi.setRemark;
    if (remarkId) {
      params.remark_id = remarkId;
    }
    if (focusContent) {
      params.focus_content = focusContent;
      params.focus_id = focusId;
      axiosApi = projectApi.setFocusRemark;
    }
    // console.log(info, dataTable, params,projectInfo);
    axiosApi(projectId, params).then((res) => {
      if (res.code === 200) {
        getData && getData();
        message.success(res.message);
      } else {
        message.error(res.message);
      }
      setUpLoading(false);
    });
  };

  useEffect(() => {
    projectApi.getDicCodeInfo("CDD_CONCERN_LEVEL").then((res) => {
      if (res.code === 200) {
        setTypeList(res.data);
        if (!defaultData) {
          setType(res.data[0].dic_item_code);
          setEventLevelDesc(res.data[0].dic_item_name);
        }
      } else {
        setTypeList([]);
      }
    });
  }, []);

  const getProjectInfo = () => {
    projectApi.getProjectInfo(projectId).then((res) => {
      setLoading(false);
      if (res.code === 200) {
        setProjectInfo(res.data.data[0]);
      } else {
        message.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (projectId) {
      getProjectInfo();
    }
  }, [projectId]);

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const item = (projectInfo?.member_list || []).find(
      (_) => _.user_id === value
    );
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        <Avatar
          size={26}
          src={item?.pf_photo}
          style={{ marginRight: "8px" }}
        ></Avatar>
        {item?.show_name}
      </Tag>
    );
  };

  const getBtnDis = () => {
    if (!getCode()) {
      return true;
    }
    if (getIsFollow() && !getType()) {
      return true;
    }
    return false;
  };

  return (
    <div
      className="commentsContent"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="headerTitle">批注内容</div>
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <>
          <TextArea
            rows={4}
            showCount
            placeholder="请输入批注内容"
            maxLength={120}
            style={{ marginBottom: "10px", width: "100%" }}
            onChange={remarkChange}
            value={remark}
            onPressEnter={(e) => {
              // e.preventDefault();
              e.stopPropagation();
            }}
          />
          <Checkbox
            disabled={defaultData?.isFollow}
            checked={isFollow}
            onChange={onCheckChange}
          >
            标记为关注事项
          </Checkbox>

          {isFollow ? (
            <div>
              <div className="lineItem">
                <div className="title">标签</div>
                <div className="valueBox">
                  <Radio.Group
                    onChange={onRadioChange}
                    value={type}
                    className="radioGroupBox"
                  >
                    {typeList.map((item) => (
                      <Radio.Button
                        key={item.dic_item_code}
                        value={item.dic_item_code}
                      >
                        {item.dic_item_name}
                      </Radio.Button>
                    ))}
                  </Radio.Group>

                  {type === "CDD_CONCERN_LEVEL_CUSTOM" && (
                    <AutoComplete
                      className="levelInput"
                      bordered={false}
                      maxLength={4}
                      open={levelInputPopupOpen}
                      placeholder="输入标签名称，不超过4个字"
                      popupClassName="levelInputPopup"
                      value={
                        type !== "CDD_CONCERN_LEVEL_CUSTOM"
                          ? null
                          : eventLevelDesc
                      }
                      options={customTags?.map((_) => ({ value: _ }))}
                      onChange={(data) => {
                        setEventLevelDesc(data?.trim());
                      }}
                      ref={levelInputRef}
                      onClick={() => {
                        if (getLevelInputClickCount() >= 1) {
                          setLevelInputPopupOpen(false);
                          levelInputRef.current.blur();
                        } else {
                          setLevelInputClickCount(
                            getLevelInputClickCount() + 1
                          );
                        }
                      }}
                      onFocus={() => {
                        setLevelInputClickCount(0);
                        setLevelInputPopupOpen(true);
                      }}
                      onBlur={() => {
                        setLevelInputPopupOpen(false);
                        levelInputRef.current.blur();
                      }}
                      onSelect={(value) => {
                        setEventLevelDesc(value);
                        setLevelInputPopupOpen(false);
                        levelInputRef.current.blur();
                      }}
                    ></AutoComplete>
                  )}
                </div>
              </div>
              {myInfo?.co_type_code !== "INDIV_ORG" ? (
                <div className="lineItem">
                  <div
                    className="title"
                    style={{ fontSize: "16px", lineHeight: "32px" }}
                  >
                    @
                  </div>

                  <div
                    className="valueBox"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* <Avatar>U</Avatar> */}
                    <Select
                      style={{
                        width: "100%",
                      }}
                      mode="multiple"
                      bordered={false}
                      value={userId}
                      showArrow
                      showSearch
                      onChange={userChange}
                      tagRender={tagRender}
                      dropdownMatchSelectWidth={false}
                      filterOption={(input, option) => {
                        const item = (projectInfo?.member_list || []).find(
                          (_) => _.user_id === option.value
                        );
                        return (
                          item?.show_name
                            .toLowerCase()
                            .includes(input.toLowerCase()) ||
                          item?.assist_name
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        );
                      }}
                    >
                      {(projectInfo?.member_list || []).map((item) => (
                        <Option key={item?.user_id} value={item?.user_id}>
                          <div className="userOptionsItemBox">
                            <div>
                              <Avatar
                                size={26}
                                src={item?.pf_photo}
                                style={{ marginRight: "8px" }}
                              ></Avatar>
                              {item?.show_name}
                            </div>
                            <div className="userOptionsItemBoxSpan">
                              {item?.assist_name}
                            </div>
                          </div>
                        </Option>
                      ))}
                    </Select>
                    <Tooltip
                      title={
                        <div>
                          <div>
                            @其他成员后，则将关注事项推给其他成员，如没有@其他成员，则添加到自己的关注事项中。
                          </div>
                          <div>(关注事项可以在概览页面查看)</div>
                        </div>
                      }
                    >
                      <InfoCircleOutlined
                        style={{
                          color: "#9E9E9E",
                          marginLeft: "5px",
                          cursor: "help",
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="btnBox drawerFooterBtnBox">
            <Button
              className="noBg"
              onClick={() => {
                props.onClose();
              }}
            >
              取消
            </Button>

            <Button
              loading={upLoading}
              disabled={getBtnDis()}
              type="primary"
              onClick={() => {
                onFinish();
              }}
            >
              确定
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const CommentsIcon = (props) => {
  const {
    /* 批注详情对象(内含 comments,id 字段) */
    info,
    /* icon是否显示回调 */
    setShow,
    /* 表名 */
    dataTable,
    /* 回调函数 */
    getData,
    /* 模块中文名称 */
    typeName,
    /* 默认值(修改) */
    defaultData,
    /* 批注id(修改) */
    remarkId,
    /* 单个字段或整条数据的 原数据 */
    dataInfo,
    /* 单个字段还是整条(单个字段为空,整条为20,整个模块为30) */
    mode,
    /* 样式 */
    style,
    /* 批注内容 */
    focusContent,
    /* 关注ID */
    focusId,
    /* 项目id */
    projectId,
    /* 是否提前渲染 */
    forceRender = false,
    /* 鼠标移入后延时多少才显示 */
    mouseEnterDelay = 0.2,
  } = props;

  const [visible, setVisible] = useState(false);

  const iconClick = () => {
    getLicenseValid().then((res) => {
      if (res) {
        setVisible(true);
      }
    });
  };

  const handleVisibleChange = (newVisible) => {
    if (!newVisible) {
      setVisible(newVisible);
    }
  };

  useEffect(() => {
    setShow && setShow(visible);
  }, [visible]);

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      overlayClassName="myCardPopover"
      visible={visible}
      destroyTooltipOnHide={true}
      onVisibleChange={handleVisibleChange}
      content={
        <Content
          onClose={() => {
            setVisible(false);
          }}
          defaultData={defaultData}
          info={info}
          getData={() => {
            getData();
            setVisible(false);
          }}
          dataTable={dataTable}
          typeName={typeName}
          remarkId={remarkId}
          focusId={focusId}
          dataInfo={dataInfo}
          mode={mode}
          projectId={projectId}
          focusContent={focusContent}
        ></Content>
      }
    >
      <Tooltip
        placement="top"
        title="批注"
        arrowPointAtCenter
        forceRender={forceRender}
        mouseEnterDelay={mouseEnterDelay}
      >
        <ReactSVG
          src={icon}
          style={style}
          onClick={(e) => {
            e.stopPropagation();
            iconClick();
          }}
          className="iconSvg cursor-pointer editIcon hoverIcon"
        ></ReactSVG>
        {/* <FileTextOutlined
          style={style}
          onClick={(e) => {
            e.stopPropagation();
            iconClick();
          }}
          className="cursor-pointer editIcon hoverIcon"
        /> */}
      </Tooltip>
    </Popover>
  );
};
export default CommentsIcon;
