import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import { Select, message, Popover, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  addMillimeter,
  amountFormats,
  calcCompanyNameFunc,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import MyEmpty from "../../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useGetState } from "ahooks";
import YearSelect from "../../../YearSelect";
import moment from "moment";
import empPng from "../../../../../../../assets/empC.png";
import TableModal from "../../../TableModal";
import { chartColorMap } from "../../../../../../../setting";
import DownLoadIcon from "../../../../../../../components/Icons/DownLoadIcon";

const { Option } = Select;

const SupplierAnalysis = (props) => {
  const {
    data,
    checkCompanyList,
    searchTableFunc,
    notHasData,
    chartsLoading,
    getCompanyList,
  } = props;
  const { id } = useParams();
  const [noData, setNoData] = useState(false);
  const [yearsObj, setYearsObj, getYearsObj] = useGetState({});
  const [selectYear, setSelectYear, getSelectYear] = useGetState([]);
  const myChart = useRef(null);
  const myChart2 = useRef(null);
  const [downLoading, setDownLoading] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [selectType, setSelectType, getSelectType] = useGetState([]);
  const [topNumber, setTopNumber, getTopNumber] = useGetState(5);
  const [chartType, setChartType, getChartType] = useGetState("金额");

  const [noDataByFilter, setNoDataByFilter, getNoDataByFilter] =
    useGetState(false);

  useEffect(() => {
    setSelectType(checkCompanyList);
    if (notHasData) {
      return;
    }
    // setTimeout(() => {
    //   initECharts(getYearsObj()[getSelectYear()]);
    // }, 100);
  }, [checkCompanyList]);

  const initCircleEchartsData = () => {
    let max = getTopNumber();
    let sortKey = getChartType() === "金额" ? "bid_winning_amo" : "count";
    let sortOtherKey = getChartType() === "金额" ? "count" : "bid_winning_amo";
    const chartData = getYearsObj()[getSelectYear()]?.sort((a, b) => {
      if (Number(a[sortKey]) === Number(b[sortKey])) {
        return Number(b[sortOtherKey]) - Number(a[sortOtherKey]);
      }
      return Number(b[sortKey]) - Number(a[sortKey]);
    });
    const tops = chartData?.slice(0, max);
    const other = chartData?.slice(max);

    const otherSumAmo = other?.reduce((a, b) => a + b.bid_winning_amo, 0) || 0;
    const otherSumCount = other?.reduce((a, b) => a + b.count, 0) || 0;

    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    let circleEchartsData = tops;
    if (otherSumAmo || otherSumCount) {
      circleEchartsData.push({
        bid_winner: "其他",
        bid_winning_amo: otherSumAmo,
        count: otherSumCount,
      });
    }
    setTimeout(() => {
      initCircleEcharts(circleEchartsData);
    }, 50);
  };

  const initCircleEcharts = (echartsData) => {
    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    const dom = document.getElementById("shareProportionBox2");
    if (!dom) {
      return;
    }
    myChart2.current = echarts.init(dom);

    const sumAmo = echartsData?.reduce((a, b) => a + b.bid_winning_amo, 0) || 0;
    const sumCount = echartsData?.reduce((a, b) => a + b.count, 0) || 0;

    const option = {
      tooltip: {
        trigger: "item",
        confine: true,
        appendToBody: true,
        formatter: (a) => {
          return `<div class="pieChartTips222">
          <div class='pieChartTitle'>
            ${a?.name}
          </div>
          <div class='pieChartTip2'>
            <div class='leftBox'>
              <div><span style='display: inline-block; width: 40px'>外环：</span>中标金额</div>
              <div><span style='display: inline-block; width: 40px'></span>中标金额占比</div>
              <div><span style='display: inline-block; width: 40px'>内环：</span>中标项目数</div>
              <div><span style='display: inline-block; width: 40px'></span>中标项目数占比</div>
            </div>
            <div class='rightBox'>
              <div>${
                a?.data?.bid_winning_amo
                  ? addMillimeter(a?.data?.bid_winning_amo, 2) + "元"
                  : "-"
              }</div>
              <div>${
                a?.data?.bid_winning_amo
                  ? (
                      (a?.data?.bid_winning_amo / a?.data?.sumAmo) *
                      100
                    )?.toFixed(2) + "%"
                  : "-"
              }</div>
              <div>${a?.data?.count ? Number(a?.data?.count) + "个" : "-"}</div>
              <div>${
                a?.data?.count
                  ? ((a?.data?.count / a?.data?.sumCount) * 100)?.toFixed(2) +
                    "%"
                  : "-"
              }</div>
          </div>
        </div>
        </div>`;
        },
      },
      legend: {
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        itemStyle: {
          borderCap: "round",
        },
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        bottom: "0px",
        type: echartsData?.length <= 1 ? "plain" : "scroll", // 显示分页
        selectedMode: true,
        textStyle: {
          rich: {
            title: {
              fontSize: 14,
              lineHeight: 22,
              color: "#595959",
              width: 80,
            },
            value: {
              fontSize: 14,
              lineHeight: 22,
              color: "rgba(0,0,0,0.45)",
              width: 30,
              align: "center",
            },
          },
        },
        data: echartsData?.map((_) => _.bid_winner),
      },
      series: [
        {
          name: "中标金额",
          type: "pie",
          radius: ["60%", "80%"],
          top: 0,
          labelLine: {
            show: false,
          },
          label: {
            show: false,
            position: "center",
          },
          minAngle: 1,
          data: echartsData?.map((_, index) => ({
            ..._,
            sumAmo,
            sumCount,
            value: _.bid_winning_amo,
            name: _.bid_winner,
            itemStyle: {
              color: chartColorMap[index],
            },
          })),
        },
        {
          name: "中标项目",
          type: "pie",
          top: 0,
          radius: ["30%", "50%"],
          labelLine: {
            show: false,
          },
          label: {
            show: false,
            position: "center",
          },
          minAngle: 1,
          data: echartsData?.map((_, index) => ({
            ..._,
            sumAmo,
            sumCount,
            value: _.count,
            name: _.bid_winner,
            itemStyle: {
              color: chartColorMap[index],
            },
          })),
        },
      ],
    };
    myChart2.current.setOption(option);
    window.addEventListener("resize", () => {
      myChart2.current && myChart2.current.resize();
    });
  };

  const initECharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    if (echartsData?.length === 0) {
      if (myChart2.current) {
        myChart2.current.dispose();
        myChart2.current = null;
      }
      setNoDataByFilter(true);
      return;
    } else {
      setNoDataByFilter(false);
    }
    let max = getTopNumber();
    let sortKey = getChartType() === "金额" ? "bid_winning_amo" : "count";
    let sortOtherKey = getChartType() === "金额" ? "count" : "bid_winning_amo";
    echartsData = echartsData
      ?.sort((a, b) => {
        if (Number(a[sortKey]) === Number(b[sortKey])) {
          return Number(b[sortOtherKey]) - Number(a[sortOtherKey]);
        }
        return Number(b[sortKey]) - Number(a[sortKey]);
      })
      ?.slice(0, max)
      ?.map((_, index) => ({ ..._, index }));
    let maxNum = 0;
    let countMaxNum = 0;
    echartsData.forEach((item) => {
      if (item["bid_winning_amo"] > maxNum) {
        maxNum = item["bid_winning_amo"];
      }
      if (item["count"] > countMaxNum) {
        countMaxNum = item["count"];
      }
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("shareProportionBox");
    if (!dom) {
      return;
    }
    let resize = false;
    if (dom.style.display === "none") {
      dom.style.display = "block";
      resize = true;
    }
    const typeColor = {
      中标金额: "#006AB2",
      中标项目数: "#61D4AD",
    };
    myChart.current = echarts.init(dom);
    const option = {
      title: {
        text: "TOP供应商排名",
        left: "center",
        textStyle: {
          width: 200,
          fontSize: 14,
          color: "#999999",
          lineHeight: 24,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "axis",
        appendToBody: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      legend: {
        data: ["中标金额", "中标项目数"],
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        itemStyle: {
          borderCap: "round",
        },
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        bottom: "0px",
        type: "scroll", // 显示分页
        selectedMode: true,
        textStyle: {
          rich: {
            title: {
              fontSize: 14,
              lineHeight: 22,
              color: "#595959",
              width: 80,
            },
            value: {
              fontSize: 14,
              lineHeight: 22,
              color: "rgba(0,0,0,0.45)",
              width: 30,
              align: "center",
            },
          },
        },
      },
      grid: {
        top: "40px",
        right: "20px",
        left: "0px",
        bottom: "30",
        containLabel: true,
      },
      xAxis: [
        {
          type: "value",
          nameLocation: "end",
          nameTextStyle: {
            align: "center",
            // padding: [0, 50, 0, 0],
          },
          nameGap: 10,
          // min: 0,
          max: maxNum < 5 ? 5 : null,
          // interval: 50,
          axisLabel: {
            formatter: function (value, index) {
              // let num = parseInt(value / 10000).toLocaleString("en-US");
              // if (maxNum < 10000) {
              //   num = parseInt(value).toLocaleString("en-US");
              // }
              // return num;
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else {
                return value;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            show: false,
          },
        },
        {
          type: "value",

          max: countMaxNum < 5 ? 5 : null,
          min: 0, // 最小为0
          // max: 10, // 最大为100
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            show: false,
          },
          axisPointer: {
            show: false,
          },
        },
      ],
      yAxis: {
        type: "category",
        axisPointer: {
          type: "shadow",
          label: {
            formatter: (params) => {
              let str = params.value;
              if (str.length > 30) {
                str = str?.slice(0, 30) + "...";
              }
              return str;
            },
          },
        },
        axisLabel: {
          interval: 0,
          // width: 60,
          overflow: "truncate",
          // rotate: -20,
          formatter: function (value) {
            let str = value;
            if (str.length > 6) {
              str = str?.slice(0, 2) + "..." + str?.slice(str.length - 2);
            }
            return str;
            // return calcCompanyNameFunc(
            //   value,
            //   echartsData?.length,
            //   "shareProportionBox"
            // );
          },
        },
        axisTick: {
          alignWithLabel: true,
          interval: 0,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        data: echartsData
          ?.sort((a, b) => b?.index - a?.index)
          ?.map((_) => _.bid_winner),
      },
      series: ["中标金额", "中标项目数"]?.map((_item, index) => ({
        name: _item,
        type: "bar",
        label: {
          show: false,
        },
        xAxisIndex: index,
        areaStyle: {
          color: typeColor[_item],
          shadowColor: typeColor[_item],
        },
        barWidth: "20%",
        barMinHeight: 5,

        itemStyle: {
          color: typeColor[_item],
          barBorderRadius: [0, 2, 2, 0],
        },
        emphasis: {
          focus: "series",
        },
        tooltip: {
          valueFormatter: function (value) {
            return _item === "中标金额"
              ? addMillimeter(value, 2) + "元"
              : value + "个";
          },
        },
        data: echartsData?.map((__) => {
          if (_item === "中标金额") {
            return Number(__.bid_winning_amo);
          }
          return Number(__.count);
        }),
      })),
    };
    myChart.current.setOption(option);
    setTimeout(() => {
      initCircleEchartsData();
    }, 50);
    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    if (resize) {
      setTimeout(() => {
        myChart.current.resize();
      }, 100);
    }
    myChart.current.on("click", function (params) {
      let filterOptions = {
        供应商: params?.name,
        年份: getSelectYear(),
      };
      TableModal({
        title: "中标详情",
        filterOptions,
        typeName: "招标结果",
        onOk: searchTableFunc,
        projectId: id,
      });
    });
  };

  const initData = (data) => {
    let yearsObj = {};
    let yearArr = [
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date())?.format("YYYY"),
      "all",
    ];
    yearArr.forEach((year) => {
      yearsObj[year] = [];
    });
    let obj = {};

    data.forEach((_) => {
      if (obj[_?.bid_winner]) {
        if (obj[_?.bid_winner][_?.year]) {
          obj[_?.bid_winner][_?.year].bid_winning_amo =
            obj[_?.bid_winner][_?.year].bid_winning_amo + _.bid_winning_amo;
          obj[_?.bid_winner][_?.year].count =
            obj[_?.bid_winner][_?.year].count + _.count;
        } else {
          obj[_?.bid_winner][_?.year] = {
            bid_winning_amo: _.bid_winning_amo,
            count: _.count,
          };
        }
      } else {
        let _obj = {};
        _obj[_?.year] = {
          bid_winning_amo: _.bid_winning_amo,
          count: _.count,
        };
        obj[_?.bid_winner] = _obj;
      }

      if (obj[_?.bid_winner]) {
        if (obj[_?.bid_winner]["all"]) {
          obj[_?.bid_winner]["all"].bid_winning_amo =
            (obj[_?.bid_winner]["all"].bid_winning_amo || 0) +
            _.bid_winning_amo;
          obj[_?.bid_winner]["all"].count =
            (obj[_?.bid_winner]["all"].count || 0) + _.count;
        } else {
          obj[_?.bid_winner]["all"] = {
            bid_winning_amo: _.bid_winning_amo || 0,
            count: _.count || 0,
          };
        }
      }
    });

    Object.keys(obj).forEach((key) => {
      yearArr.forEach((year) => {
        let item = obj[key][year];
        if (item) {
          yearsObj[year].push({
            bid_winner: key,
            bid_winning_amo: item.bid_winning_amo,
            count: item.count,
            year,
          });
        }
      });
    });

    // data.forEach((item) => {
    //   if (yearsObj[item.year]) {
    //     yearsObj[item.year].push(item);
    //   } else {
    //     yearsObj[item.year] = [item];
    //   }
    // });

    const nowYear = "all"; //moment(new Date())?.format("YYYY");
    setYearsObj(yearsObj);
    setSelectYear(nowYear);
    initECharts(yearsObj[nowYear] || []);
  };

  const topNumberChange = (value) => {
    setTopNumber(value);
    setTimeout(() => {
      initECharts(yearsObj[getSelectYear()]);
    }, 100);
  };

  useEffect(() => {
    if (notHasData) {
      return;
    }
    initData(data);
  }, [data]);

  useEffect(() => {
    setNoData(notHasData);
  }, [notHasData]);

  const downloadImg = () => {
    // myChart.current.dispose()
    let src = myChart.current.getDataURL({
      pixelRatio: 2,
      backgroundColor: "#fff",
    });

    downloadBase64Img(src, "供应商分析.png");
  };

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "供应商分析",
        company_ids: JSON.stringify(
          checkCompanyList?.map((_) => {
            let item = getCompanyList()?.find((__) => __.bid_winner === _);
            return item.winner_id;
          })
        ),
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };
  return (
    <div className="supplierAnalysisContent">
      {/* {!noData && !noDataByFilter && (
        <div className="selectBox">
          <Tooltip placement="top" title="项目数" arrowPointAtCenter>
            <div
              className={
                chartType === "数量"
                  ? "selectItem activeSelectItem"
                  : "selectItem"
              }
              onClick={() => {
                if (getChartType() === "数量") {
                  return;
                }
                setChartType("数量");
                setTimeout(() => {
                  initECharts(getYearsObj()[getSelectYear()]);
                }, 50);
              }}
            >
              <ReactSVG
                className="selectItemIcon"
                src={getChartType() === "数量" ? icon2 : icon1}
              ></ReactSVG>
            </div>
          </Tooltip>
          <Tooltip placement="top" title="金额" arrowPointAtCenter>
            <div
              className={
                chartType === "金额"
                  ? "selectItem activeSelectItem"
                  : "selectItem"
              }
              onClick={() => {
                if (getChartType() === "金额") {
                  return;
                }
                setChartType("金额");
                setTimeout(() => {
                  initECharts(getYearsObj()[getSelectYear()]);
                }, 50);
              }}
            >
              <PayCircleFilled
                style={{
                  color: getChartType() === "金额" ? "#ffffff" : "#999999",
                }}
              />
            </div>
          </Tooltip>
        </div>
      )} */}
      <div className="headerBox">
        <span className="headerBoxTitle">
          <span className="block"></span>
          供应商分析
        </span>
        {!noData && !chartsLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <YearSelect
              value={[selectYear]}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value?.at(0));
                setTimeout(() => {
                  initECharts(yearsObj[value?.at(0)]);
                }, 100);
              }}
            ></YearSelect>

            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <DownLoadIcon
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  ></DownLoadIcon>
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      <div className="shareProportionContentBox">
        {noData && <MyEmpty></MyEmpty>}
        {!noData && !noDataByFilter && (
          <Select
            style={{
              width: 90,
            }}
            size="small"
            value={topNumber}
            onChange={topNumberChange}
            options={[
              {
                label: "TOP5",
                value: 5,
              },
              {
                label: "TOP10",
                value: 10,
              },
              // {
              //   label: "TOP15",
              //   value: 15,
              // },
            ]}
            className="topSelect"
          />
        )}
        {!noData && noDataByFilter && (
          <div
            style={{
              height: " calc(100% - 24px)",
              width: "calc(100% - 280px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MyEmpty></MyEmpty>
          </div>
        )}
        {!noData && (
          <div
            id="shareProportionBox"
            style={{ display: noDataByFilter ? "none" : "block" }}
          ></div>
        )}
        {!noData && (
          <div className="shareProportionBox2">
            <div className="shareProportionTitle">供应商中标项目及金额份额</div>
            {noDataByFilter ? (
              <div className="shareProportionBoxBg2">
                <Popover
                  trigger="hover"
                  placement="topRight"
                  overlayClassName="empPopover"
                  content={<div>暂无数据</div>}
                >
                  <img src={empPng} alt="暂无数据" />
                </Popover>
              </div>
            ) : (
              <div id="shareProportionBox2"></div>
            )}
          </div>
        )}
      </div>

      <div className="annotationDiv">注：统计时间为近五年数据</div>
    </div>
  );
};
export default SupplierAnalysis;
