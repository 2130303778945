import { Menu, Layout } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createFromIconfontCN, FormOutlined } from "@ant-design/icons";
import "./index.scss";
import logo from "../../assets/logo.png";
import { ReactSVG } from "react-svg";

const MyIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_3354122_puxmtsyxun9.js",
});

const { Sider } = Layout;

export default function Sidebar(props) {
  const { userMenus } = props;
  const history = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState("");
  const [openKey, setOpenKey] = useState(null);
  const [allKeys, setAllKeys] = useState(null);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    if (props.routerList?.length === 0) {
      return;
    }
    setAllKeys(
      props.routerList
        ?.filter((_) => _.children?.length > 0)?.map((_) => _.menu_code)
    );
    // setOpenKey(
    //   props.routerList?.find((_) =>
    //     _?.children?.some((__) => __.menu_url === location.pathname)
    //   )?.menu_code
    // );
  }, [props.routerList]);

  const [collapsed, setCollapsed] = useState(false);
  const to = (item) => {
    if (item.menu_url && item.open_mode === "CURRENT_PAGE") {
      history(item.open_url);
    } else if (item.menu_url && item.open_mode === "NEW_PAGE") {
      window.open(item.open_url);
    }else if (item.menu_url && item.open_mode === "IFRAME") {
      history(item.open_url);
    }
  };

  const initMenu = (list, isChildren) => {
    list = list?.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <Menu.SubMenu
            // icon={item.icon}
            icon={isChildren ? null : <ReactSVG src={item.icon}></ReactSVG>}
            title={item.menu_name}
            key={item.menu_url || item.menu_code}
          >
            {initMenu(item.children, true)}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.Item
          key={item.menu_url || item.menu_code}
          icon={
            isChildren ? null : (
              <ReactSVG
                style={{ width: "16px", height: "16px" }}
                src={item.icon}
              ></ReactSVG>
            )
          }
        >
          <a onClick={() => to(item)}>{item.menu_name}</a>
        </Menu.Item>
      );
    });
    return list;
  };
  return (
    <div className="sidebarContainer">
      <Sider
        trigger={null}
        width={216}
        style={{
          background: "linear-gradient(223deg, #006bb3 0%, #15d2d6 100%)",
          height: "100%",
          borderTopRightRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
        className="site-layout-background"
        collapsible
        collapsed={collapsed}
        collapsedWidth={50}
      >
        <div className="logo">
          {!collapsed && (
            <div className="logoBox">
              <img
                src={userMenus?.find((_) => _.menu_code === "CDD_PROD")?.icon}
                alt=""
              />
              <span>
                {userMenus?.find((_) => _.menu_code === "CDD_PROD")?.menu_name}
              </span>
            </div>
          )}
          <span>
            {collapsed ? (
              <MyIcon
                className="anticon-menu-fold"
                type="icon-zhankai"
                onClick={() => setCollapsed(!collapsed)}
              ></MyIcon>
            ) : (
              <MyIcon
                className="anticon-menu-unfold "
                type="icon-shouqi1"
                onClick={() => setCollapsed(!collapsed)}
              ></MyIcon>
            )}
          </span>
        </div>
        <Menu
          mode="inline"
          style={{ height: "100%", borderRight: 0 }}
          selectedKeys={[pathname]}
          onOpenChange={(openKeys) => {
            let openItem = openKeys?.at(-1);
            setOpenKey(openItem);
            setAllKeys(openKeys);
            if (openItem) {
              let item = props.routerList?.find(
                (_item) => _item.menu_code === openItem
              );
              if (
                item?.children?.some((_) => _.menu_url === location.pathname)
              ) {
                return;
              } else {
                if (item?.children?.at(0)) {
                  to(item?.children?.at(0));
                }
              }
            }
          }}
          openKeys={openKey ? [openKey] : allKeys}
        >
          {initMenu(
            props.routerList?.filter((item) => !item.hide),
            false
          )}
        </Menu>
      </Sider>
    </div>
  );
}
