import "./indes.scss";
import MyEmpty from "../../../../../../components/Empty";
import { useGetState } from "ahooks";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import projectApi from "../../../../../../apis/project";
import { useParams } from "react-router-dom";
import { Table, message } from "antd";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
} from "../../../../../../unit/unit";
import DownLoadIcon from "../../../../../../components/Icons/DownLoadIcon";

const TaxArrearsInformation = (props) => {
  const { data, rows, selectCompany } = props;
  const [noData, setNoData] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [downLoading, setDownLoading] = useState(false);
  const { id } = useParams();
  const [tableData, setTableData, getTableData] = useGetState([]);
  const [columns, setColumns, getColumns] = useGetState([]);

  const initData = (data) => {
    setTableData(data);
  };

  const initColumns = (_rows) => {
    const rows = _rows?.rows || {};
    const keys = Object.keys(rows);
    if (keys?.length === 0) {
      return;
    }
    let list = [];
    keys.forEach((key) => {
      let item = rows[key];
      const columnItem = {
        title: item.name,
        dataIndex: key,
        key: key,
        align: "center",
        index: item.index,
        width: item.width,
        render: (text, record, index) => {
          if (text === null || text === undefined || text === "") {
            return "-";
          }
          if (item.need_millimeter) {
            return addMillimeter(text, 2);
          }
          return text;
        },
      };

      list.push(columnItem);
    });
    list = list?.sort((a, b) => a.index - b.index);
    setColumns(list);
  };

  useEffect(() => {
    if (data?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  useEffect(() => {
    initColumns(rows);
  }, [rows]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "欠税信息",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="taxArrearsInformationContent">
      <div className="headerBox">
        欠税信息
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <DownLoadIcon
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                ></DownLoadIcon>
              ))}
          </div>
        )}
      </div>
      {!noData && (
        <div className="taxArrearsInformationTableBox">
          <Table
            // scroll={{
            //   y: tableData?.length > 0 ? "274px" : undefined,
            // }}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
            className="myFormTable"
            rowKey={() => Math.random()}
            bordered={true}
            pagination={false}
            columns={columns}
            dataSource={tableData}
          ></Table>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default TaxArrearsInformation;
