import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import { Select, Tooltip } from "antd";
import {
  LoadingOutlined,
  PayCircleFilled,
} from "@ant-design/icons";
import {
  addMillimeter,
  addMillimeterAndInit,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useGetState } from "ahooks";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import { message } from "antd";
import MyEmpty from "../../../../../../../components/Empty";
import { useSelector } from "react-redux";
import YearSelect from "../../../YearSelect";
import TableModal from "../../../TableModal";
import moment from "moment";
import registerMapJson from "../../../../../../../json/registerMap.json";
import DownLoadIcon from "../../../../../../../components/Icons/DownLoadIcon";
const { Option } = Select;

const RegionMap = (props) => {
  const {
    data,
    searchTableFunc,
    checkCompanyList,
    notHasData,
    chartsLoading,
    getCompanyList,
  } = props;
  const { id } = useParams();
  const [noData, setNoData] = useState(false);
  const [yearsObj, setYearsObj] = useState({});
  const [selectYear, setSelectYear, getSelectYear] = useGetState([]);
  const [selectType, setSelectType, getSelectType] = useGetState("金额");
  const [downLoading, setDownLoading] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  const myChart = useRef(null);

  useEffect(() => {
    setNoData(notHasData);
  }, [notHasData]);

  const initEchartsData = (yearsObj, years, type) => {
    let provinceObj = {};
    let bidIds = [];
    years.forEach((year) => {
      yearsObj[year]?.forEach((item) => {
        let num = type === "金额" ? item.bid_amount : item.bid_count;
        if (provinceObj[item.bidding_area]) {
          //筛除掉多个公司中一个项目
          if (type === "数量") {
            if (!bidIds.some((__) => __ === item?.bid_id)) {
              provinceObj[item.bidding_area] =
                provinceObj[item.bidding_area] + num;
              bidIds.push(item?.bid_id);
            }
          } else {
            provinceObj[item.bidding_area] =
              provinceObj[item.bidding_area] + num;
          }
        } else {
          provinceObj[item.bidding_area] = num;
        }
      });
    });
    initECharts(
      Object.entries(provinceObj).map(([name, value], index) => ({
        name,
        value,
      }))
    );
  };
  const initECharts = (chartData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let max = 5;
    chartData.forEach((item) => {
      if (item.value > max) {
        max = item.value;
      }
    });
    const sumNumber = chartData.reduce((a, b) => a + b?.value, 0);
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("regionMapBox");
    if (!dom) {
      return;
    }
    const abbreviationNameMap = {
      台湾省: "台湾",
      河北省: "河北",
      山西省: "山西",
      内蒙古自治区: "内蒙古",
      辽宁省: "辽宁",
      吉林省: "吉林",
      黑龙江省: "黑龙江",
      江苏省: "江苏",
      浙江省: "浙江",
      安徽省: "安徽",
      福建省: "福建",
      江西省: "江西",
      山东省: "山东",
      河南省: "河南",
      湖北省: "湖北",
      湖南省: "湖南",
      广东省: "广东",
      广西壮族自治区: "广西",
      海南省: "海南",
      四川省: "四川",
      贵州省: "贵州",
      云南省: "云南",
      西藏自治区: "西藏",
      陕西省: "陕西",
      甘肃省: "甘肃",
      青海省: "青海",
      宁夏回族自治区: "宁夏",
      新疆维吾尔自治区: "新疆",
      北京市: "北京",
      天津市: "天津",
      上海市: "上海",
      重庆市: "重庆",
      香港特别行政区: "香港",
      澳门特别行政区: "澳门",
    };
    myChart.current = echarts.init(dom);
    echarts.registerMap("china", registerMapJson);
    const option = {
      series: {
        type: "map",
        name: "合计",
        label: {
          show: true, // 显示省份名称或数量
          formatter: function (params) {
            return abbreviationNameMap[params.name];
          },
          fontSize: 12,
        },
        map: "china",
        data: chartData,
        roam: false,
        aspectScale: 0.75,
        nameProperty: "name",
        selectedMode: false,
        zoom: 1.2,
        nameMap: {
          台湾: "台湾省",
          河北: "河北省",
          山西: "山西省",
          内蒙古: "内蒙古自治区",
          辽宁: "辽宁省",
          吉林: "吉林省",
          黑龙江: "黑龙江省",
          江苏: "江苏省",
          浙江: "浙江省",
          安徽: "安徽省",
          福建: "福建省",
          江西: "江西省",
          山东: "山东省",
          河南: "河南省",
          湖北: "湖北省",
          湖南: "湖南省",
          广东: "广东省",
          广西: "广西壮族自治区",
          海南: "海南省",
          四川: "四川省",
          贵州: "贵州省",
          云南: "云南省",
          西藏: "西藏自治区",
          陕西: "陕西省",
          甘肃: "甘肃省",
          青海: "青海省",
          宁夏: "宁夏回族自治区",
          新疆: "新疆维吾尔自治区",
          北京: "北京市",
          天津: "天津市",
          上海: "上海市",
          重庆: "重庆市",
          香港: "香港特别行政区",
          澳门: "澳门特别行政区",
        },
        mapValueCalculation: "sum",
        showLegendSymbol: false,
        itemStyle: {
          borderColor: "#6BBADB",
          borderWidth: 1,
          areaColor: "#ffffff",
        },
        emphasis: {},
        xAxisIndex: 0,
        yAxisIndex: 0,
      },
      legend: {
        show: false,
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
      },
      tooltip: {
        show: true,
        confine: true,
        trigger: "item",
        triggerOn: "mousemove|click",
        axisPointer: {
          type: "line",
        },
        showContent: true,
        alwaysShowContent: false,
        showDelay: 0,
        hideDelay: 100,
        textStyle: {
          fontSize: 14,
        },
        borderWidth: 0,
        padding: 5,
        position: function (point) {
          return [point[0] + 10, point[1]];
        },
        extraCssText: "z-index: 2",

        formatter: function (data) {
          let value = data.value
            ? getSelectType() === "金额"
              ? addMillimeter(data.value, 2)
              : addMillimeterAndInit(data.value)
            : "-";
          let proportion = data.value / sumNumber;
          proportion = proportion * 100;
          if (
            data.value === null ||
            data.value === undefined ||
            isNaN(data.value)
          ) {
            proportion = "-";
          } else {
            proportion = proportion.toFixed(2);
            proportion = proportion === "0.00" ? "<0.01%" : proportion + "%";
          }
          if (data.value === 0) {
            value = "0";
            proportion = "0%";
          }
          return `<span style='font-weight:bold'>${
            data.name
          }</span>&nbsp;&nbsp;${value}${
            value !== "-" ? (getSelectType() === "金额" ? "元" : "个") : ""
          }<br /><div style='width:100%'><span style='font-weight:bold'>占比</span>&nbsp;&nbsp;<span style='float:right'>${proportion}</span></div>`;
        },
      },
      // title: {
      //   padding: 5,
      //   itemGap: 10,
      // },
      visualMap: {
        show: true,
        type: "piecewise",
        min: 0,
        max: max,
        // categories: [1,2,3,4,5,6],
        selectedMode: false,
        inRange: {
          color:
            // sumNumber === 0
            //     ? ["#f3f3f3"]
            //     :
            ["#F0FBFF", "#DCEFF5", "#6BBADB", "#44A0CF", "#2187C2", "#0068B2"],
        },
        calculable: true,
        inverse: true,
        splitNumber: 5,
        orient: "horizontal",
        showLabel: false,
        itemWidth: 20,
        itemHeight: 20,
        borderWidth: 0,
        itemGap: 0,
        itemSymbol: "rect",
        borderColor: "rgb(0, 104, 178)",
        borderWidth: 2,
        padding: 0,
        // text: ["中标项目（个）"],
        // textGap: 0,
        // textStyle: {
        //   textShadowOffsetY: 40,
        //   color: "#000", // 文字颜色
        //   fontSize: 14, // 文字大小
        // },
      },
      grid: {
        top: "0px",
        left: "0%",
        right: "0%",
        bottom: "5px",
        containLabel: true,
      },
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (a) => {
      if (isNaN(a?.value) || a?.value === null || a?.value === undefined) {
        return;
      }
      let filterOptions = {
        供应商: checkCompanyList,
        区域: a?.name,
        年份: getSelectYear()?.at(0),
      };
      TableModal({
        title: "中标详情",
        filterOptions,
        onOk: searchTableFunc,
        projectId: id,
      });
    });
  };

  const initData = (data) => {
    let yearArr = [
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date())?.format("YYYY"),
      "all",
    ];
    let yearsObj = {};
    yearArr.forEach((year) => {
      yearsObj[year] = [];
    });
    data.forEach((item) => {
      if (yearsObj[item.anc_date]) {
        yearsObj[item.anc_date].push(item);
      } else {
        yearsObj[item.anc_date] = [item];
      }
      yearsObj["all"]?.push(item);
    });
    const nowYear = "all"; //moment(new Date())?.format("YYYY");
    setYearsObj(yearsObj);
    setSelectYear([nowYear]);
    initEchartsData(yearsObj, [nowYear], getSelectType());
  };

  useEffect(() => {
    if (notHasData) {
      return;
    }
    initData(data);
  }, [data]);

  useEffect(() => {
    if (noData) {
      if (myChart.current) {
        myChart.current.dispose();
        myChart.current = null;
      }
    }
  }, [noData]);

  const downloadImg = () => {
    // myChart.current.dispose()
    let src = myChart.current.getDataURL({
      pixelRatio: 2,
      backgroundColor: "#fff",
    });

    downloadBase64Img(src, "中标项目按区域分布.png");
  };

  const downloadFile = () => {
    setDownLoading(true);

    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "中标项目按区域分布",
        company_ids: JSON.stringify(
          checkCompanyList?.map((_) => {
            let item = getCompanyList()?.find((__) => __.bid_winner === _);
            return item.winner_id;
          })
        ),
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="regionMapContent">
      <div className="headerBox">
        <span className="headerBoxTitle">
          <span className="block"></span>
          中标项目按区域分布
        </span>
        {!noData && !chartsLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <YearSelect
              value={selectYear}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value);
                setTimeout(() => {
                  initEchartsData(yearsObj, value, getSelectType());
                }, 50);
              }}
            ></YearSelect>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <DownLoadIcon
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  ></DownLoadIcon>
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      {!noData && (
        <div className="selectBox">
          <div
            className={
              selectType === "金额"
                ? "selectItem activeSelectItem"
                : "selectItem"
            }
            onClick={() => {
              if (getSelectType() === "金额") {
                return;
              }
              setSelectType("金额");
              initEchartsData(yearsObj, getSelectYear(), "金额");
            }}
          >
            金额
          </div>

          <div
            className={
              selectType === "数量"
                ? "selectItem activeSelectItem"
                : "selectItem"
            }
            onClick={() => {
              if (getSelectType() === "数量") {
                return;
              }
              setSelectType("数量");
              initEchartsData(yearsObj, getSelectYear(), "数量");
            }}
          >
            项目数
          </div>
        </div>
      )}
      {!noData && (
        <div className="mapTextDiv">
          {getSelectType() === "金额" ? "中标金额（元）" : "中标项目（个）"}
        </div>
      )}
      <div
        id="regionMapBox"
        style={{ display: noData ? "none" : "block" }}
      ></div>
      {noData && <MyEmpty></MyEmpty>}

      <div className="annotationDiv">注：统计时间为近五年数据</div>
    </div>
  );
};
export default RegionMap;
