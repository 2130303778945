import "./index.scss";
import {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useParams } from "react-router-dom";
import { Table, message, Typography, Tooltip, Tag, Pagination } from "antd";
import {
  PlusCircleOutlined,
  FilterFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  addMillimeter,
  addMillimeterAndInit,
  amountFormats,
  getLicenseValid,
  saveFile,
  umberFormats,
} from "../../../../../unit/unit";
import projectApi from "../../../../../apis/project";
import DetailsDrawer from "../../../../../components/DetailsDrawer";
import MyEmpty from "../../../../../components/Empty";
import LoadingSvg from "../../../../../assets/loading.svg";
import { useGetState, useUnmount, useSize } from "ahooks";
import {
  FilterDropdownContent,
  showInfoFunc,
} from "../../../../../components/MyTable";
import peopleImg from "../../../../../assets/people.png";
import comImg from "../../../../../assets/com.png";
import { FormOutlined } from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import deleteIcon from "../../../../../assets/delete2.svg";
import MessageModal from "../../../../../components/MessageModal";
import { useSelector } from "react-redux";
import MySpin from "../../../../../components/MySpin";
import CompanyInfoDrawer from "../../../../../components/CompanyInfoDrawer";
import PersonInfoDrawer from "../../../../../components/PersonInfoDrawer";
import InfoIcon from "../../../../../components/Icons/InfoIcon";
import DownLoadIcon from "../../../../../components/Icons/DownLoadIcon";

const { Paragraph } = Typography;

const CompetitorsTable = (props, ref) => {
  const {
    addOrEditCompetitors,
    setIsDownFunc,
    dataChange,
    dataTable,
    typeName,
    tableType,
  } = props;
  const { id } = useParams();
  const [selectItemId, setSelectItemId, getSelectItemId] = useGetState(null);

  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [downLoading2, setDownLoading2] = useState(false);
  const [competitiveHitData, setCompetitiveHitData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
  const [choiceItem, setChoiceItem] = useState(null);
  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);

  const [total, setTotal] = useState(0);

  const [groupByFields, setGroupByFields, getGroupByFields] = useGetState([]);
  const [filterOptionsObj, setFilterOptionsObj, getFilterOptionsObj] =
    useGetState({});
  const [filterLoading, setFilterLoading, getFilterLoading] =
    useGetState(false);
  const [subjectScopeList, setSubjectScopeList, getSubjectScopeList] =
    useGetState([]);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  const [isDown, setIsDown, getIsDown] = useGetState(false);
  const tableRef = useRef(null);
  const timer = useRef(null);
  const columns2 = useRef([
    {
      key: "序号",
      title: "序号",
      dataIndex: "序号",
      align: "center",
      width: "80px",
      render: (text, record, index) => <>{index + 1}</>,
    },
  ]);
  const refTable = useRef(null);
  const size = useSize(refTable);

  useEffect(() => {
    getBiddingCompetitivenessStatus();
    timer.current = setInterval(() => {
      getBiddingCompetitivenessStatus();
    }, 5000);
  }, []);

  useUnmount(() => {
    clearInterval(timer.current);
  });

  useEffect(() => {
    setIsDownFunc(isDown);
  }, [isDown]);

  //获取 数据获取状态
  const getBiddingCompetitivenessStatus = () => {
    projectApi.getBiddingCompetitivenessStatus(id).then((res) => {
      console.log("查询状态:", res.data?.complete_flag);
      if (res.code === 200) {
        if ((res.data?.complete_flag === 0) !== getIsDown()) {
          getCompetitiveHitData();
        }
        if (res.data?.complete_flag === 0) {
          setIsDown(true);
        } else {
          setIsDown(false);
        }
      } else {
        setIsDown(false);
        message.error(res.message);
      }
    });
  };

  //点击行
  const clickRow = (item) => {
    setDetailsDrawerVisible(true);
    setChoiceItem(item);
  };
  const onPageChange = (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
    var dom = tableRef.current?.getElementsByClassName("ant-table-body")[0];
    if (dom) {
      dom.scrollTop = 0;
    }
    setTimeout(() => {
      getCompetitiveHitData();
    }, 50);
  };
  //删除的方法
  const deleteItem = (item) => {
    setSelectItemId(item.id.val);
    MessageModal({
      type: "warning",
      description: "确定要删除此条数据吗？",
      // message: "删除项目后将不再更新尽调信息和推送订阅信息",
      title: "删除",
      onOk: () => {
        let params = {
          project_id: id,
          name: dataTable,
          is_bid: 1,
        };

        projectApi.deleteTableItem(item.id.val, params).then(async (res) => {
          if (res.code === 200) {
            message.success(res.message);
            getCompetitiveHitData();
          } else {
            message.error(res.message);
          }
          setSelectItemId(null);
        });
      },
      onCancel: () => {
        setSelectItemId(null);
      },
    });
  };

  //筛选操作
  const filterHandle = (filterInfo) => {
    let isDelete = false;
    if (filterInfo.is_date_select) {
      isDelete = !filterInfo.values?.at(0) && !filterInfo.values?.at(1);
    } else if (filterInfo.is_scope_select) {
      isDelete =
        (filterInfo.values?.at(0) === undefined ||
          filterInfo.values?.at(0) === null ||
          filterInfo.values?.at(0) === "") &&
        (filterInfo.values?.at(1) === undefined ||
          filterInfo.values?.at(1) === null ||
          filterInfo.values?.at(1) === "");
    } else {
      isDelete =
        filterInfo?.values.filter((_) => _ !== null && _ !== undefined)
          ?.length === 0;
    }

    setGroupByFields((prev) => {
      let index = prev.findIndex((item) => {
        return item.key === filterInfo.key;
      });
      if (index === -1) {
        if (!isDelete) {
          prev.push(filterInfo);
        }
      } else {
        if (!isDelete) {
          prev[index] = filterInfo;
        } else {
          prev.splice(index, 1);
        }
      }
      return [...prev];
    });
    onPageChange(1);
  };

  const getColumnSearchProps = (dataIndex, item, filtered) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => {
      if (!visible) {
        return;
      }
      // if(item.is_select){
      //   return
      // }
      const initFilterOptionsObj = () => {
        const item = getGroupByFields()?.find((_) => _.key === dataIndex);
        const values = item?.values || [];
        const arr = JSON.parse(
          JSON.stringify(getFilterOptionsObj()[dataIndex] || [])
        );
        values.forEach((_) => {
          if (!arr.some((__) => __.value === _)) {
            arr.unshift({
              label: _,
              value: _,
            });
          }
        });
        return arr;
      };
      return (
        <FilterDropdownContent
          filterHandle={filterHandle}
          groupByFields={getGroupByFields()}
          filterOptionsObj={initFilterOptionsObj()}
          info={item}
          dataIndex={dataIndex}
          confirm={confirm}
          clearFilters={clearFilters}
          loading={getFilterLoading()}
        ></FilterDropdownContent>
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        if (item.is_date) {
          return;
        }
        setFilterLoading(true);
        const params = {
          name: dataTable,
          select_filed: { key: dataIndex },
          is_bid: 1,
        };

        let group_by_fields =
          JSON.parse(JSON.stringify(getGroupByFields())) || [];
        let index = group_by_fields.findIndex((_) => _.key === dataIndex);
        if (index !== -1) {
          group_by_fields = group_by_fields.slice(0, index);
        }
        group_by_fields.push({
          key: dataIndex,
          values: [],
        });
        params.group_by_fields = JSON.stringify(group_by_fields);

        // console.log("全部", getGroupByFields(),group_by_fields,index);

        if (getSubjectScopeList().length > 0) {
          params.sub_type = JSON.stringify(getSubjectScopeList());
        }
        // console.log("参数", params);
        projectApi.getFiledGroupOptionsList(id, params).then((res) => {
          let result = [];
          if (res.code === 200) {
            result = res.data.data;
          }
          setTimeout(() => {
            setFilterOptionsObj((prev) => {
              prev[dataIndex] = result;
              return { ...prev };
            });
            setFilterLoading(false);
          }, 100);
        });
      }
    },
    filterIcon: () => {
      return (
        <FilterFilled
          style={{
            color: filtered ? "#0068B2" : "#bfbfbf",
          }}
        />
      );
    },
  });

  //公司或者自然人图标
  const initLogo = (item, info, isEspecially, relatedPartiesTypeList) => {
    let isPeople = false;
    if (item.click_legal_request) {
      isPeople = false;
    } else if (item.click_natural_request) {
      isPeople = true;
    } else if (item.click_all) {
    }
    let bgColor = isPeople ? "#FEC054" : "#AAD461";
    //招投标需要特殊处理(不同类型公司展示不同颜色)
    if (relatedPartiesTypeList && !isPeople) {
      if ((relatedPartiesTypeList || [])?.length === 0) {
        bgColor = "#BBBBBB";
      }
      if (
        (relatedPartiesTypeList || [])?.some((_) => _.short_name === "竞争对手")
      ) {
        bgColor = "#B85DDC";
      }
    }
    if (isEspecially) {
      bgColor = isPeople ? "#FE545F" : "#0068B2";
      if (item.is_show_actual_controller_person) {
        bgColor = "#FE545F";
      }
    }
    return (
      <div className="comOrPeopleLogo" style={{ background: bgColor }}>
        <img src={isPeople ? peopleImg : comImg} alt="" />
      </div>
    );
  };
  //格式化 table columns
  const initColumns = (rows, type) => {
    const minWidth = 26;
    let columns = Object.keys(rows)
      .filter((key) => rows[key]?.name !== "最终收益股份")
      .map((key, index) => {
        const item = rows[key];
        let column = {
          key: key,
          title: item.name,
          dataIndex: key,
          // width,
          align: "center",
        };

        column.render = (text, record, index) => {
          if (
            record[key]?.val === null ||
            record[key]?.val === undefined ||
            record[key]?.val === ""
          ) {
            return "-";
          }
          //企业列表
          if (item.is_company_list) {
            if (record[key]?.val?.length === 0) {
              return "-";
            }
            return (
              <div>
                {(record[key]?.val || [])?.map((_item, _index) => (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "center",
                      textAlign: "left",
                      marginTop: _index ? "5px" : null,
                    }}
                  >
                    {initLogo(
                      {
                        click_legal_request: _item?.winner_type === 1,
                        click_natural_request: _item?.winner_type === 2,
                      },
                      record,
                      (_item?.relatedPartiesType || [])?.some(
                        (_) => _.short_name === "尽调对象"
                      ),
                      _item?.relatedPartiesType || []
                    )}
                    <div style={{ width: "calc(100% - 35px)" }}>
                      <div className="tagBox">
                        {(_item?.relatedPartiesType || []).map((tag) => {
                          // const len = (tag.length || 0) * 15;
                          return (
                            <Tooltip
                              key={Math.random()}
                              placement="top"
                              title={tag.full_name}
                              arrowPointAtCenter
                            >
                              <div>
                                <Tag
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "normal",
                                    flex: "none",
                                  }}
                                  key={Math.random()}
                                >
                                  {tag.short_name}
                                </Tag>
                              </div>
                            </Tooltip>
                          );
                        })}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Paragraph
                          ellipsis={{
                            rows: 1,
                            tooltip: {
                              title: _item?.bid_winner,
                            },
                          }}
                          style={{
                            width: `calc(100% - ${
                              item?.is_show_amount ? "120px" : "0"
                            })`,
                          }}
                        >
                          {(_item?.relatedPartiesType || [])?.length > 0 ? (
                            <a
                              onClick={(e) => {
                                e.stopPropagation();
                                if (_item?.winner_type === 1) {
                                  CompanyInfoDrawer({
                                    projectId: id,
                                    companyId: _item?.winner_id,
                                    companyName: _item?.bid_winner,
                                  });
                                } else if (_item?.winner_type === 2) {
                                  PersonInfoDrawer({
                                    projectId: id,
                                    personName: _item?.bid_winner,
                                    params: {
                                      person_id: _item?.winner_id,
                                      companyId: _item?.winner_id,
                                    },
                                  });
                                }
                              }}
                            >
                              {_item?.bid_winner}
                            </a>
                          ) : (
                            _item?.bid_winner
                          )}
                        </Paragraph>
                        {item?.is_show_amount && (
                          <span
                            style={{
                              fontSize: "12px",
                              width: "120px",
                              textAlign: "right",
                            }}
                          >
                            {_item?.bid_winning_amo
                              ? umberFormats(
                                  Number(_item?.bid_winning_amo) / 10000
                                ) + "万元"
                              : "-"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          }
          if (item.is_object) {
            return JSON.stringify(record[key]?.val);
          }
          if (item.need_millimeter) {
            //带单位
            if (item.join_field) {
              return (
                addMillimeterAndInit(record[key]?.val, 2) +
                record[item.join_field]?.val
              );
            }
            return addMillimeter(record[key]?.val, 2);
          }
          //转化为百分比
          if (item.need_percent) {
            return record[key]?.val
              ? (Number(record[key]?.val) * 100).toFixed(2) + "%"
              : "0%";
          }
          //带上主体为范围
          if (item.is_show_related_parties) {
            if (record[key]?.val) {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                    textAlign: "left",
                  }}
                >
                  {initLogo(
                    item,
                    record,
                    (record?.relatedPartiesType?.val || [])?.some(
                      (_) => _.short_name === "尽调对象"
                    )
                  )}
                  <div>
                    <div className="tagBox">
                      {(record?.relatedPartiesType?.val || []).map((tag) => {
                        // const len = (tag.length || 0) * 15;
                        return (
                          <Tooltip
                            key={Math.random()}
                            placement="top"
                            title={tag.full_name}
                            arrowPointAtCenter
                          >
                            <div>
                              <Tag
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "normal",
                                  flex: "none",
                                }}
                                key={Math.random()}
                              >
                                {tag.short_name}
                              </Tag>
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>

                    <Paragraph
                      ellipsis={{
                        rows: 2,
                        tooltip: {
                          title: record[key]?.val,
                        },
                      }}
                    >
                      {record?.winner_id?.val !== -1 ? (
                        <a
                          onClick={(e) => {
                            e.stopPropagation();
                            showInfoFunc(item, record, "中标列表", id, key);
                          }}
                        >
                          {record[key]?.val}
                        </a>
                      ) : (
                        record[key]?.val
                      )}
                    </Paragraph>
                  </div>
                </div>
              );
            }
            return "-";
          }
          //公司名称可查看 自然人可查看
          if (
            item.click_natural_request ||
            item.click_legal_request ||
            item.click_all
          ) {
            return (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <div className="comOrPeopleContent">
                {initLogo(
                  item,
                  record,
                  item.is_show_actual_controller_person &&
                    record?.is_actual_controller_person?.val
                )}
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    tooltip: {
                      title: record[key]?.val,
                    },
                  }}
                >
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      showInfoFunc(item, record, "中标列表", id, key);
                    }}
                  >
                    {record[key]?.val}
                  </a>
                </Paragraph>
                {/* {item.is_show_actual_controller_person &&
              record?.is_actual_controller_person?.val ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                  <Tag className="controller">疑似实际控制人</Tag>
                </div>
              ) : null} */}
              </div>
            );
          }
          //tips显示
          if (item.is_ellipsis || item.name === "采购人") {
            let rows = 2;
            let placement = "top";
            return (
              <Paragraph
                ellipsis={{
                  rows,
                  tooltip: {
                    title: (
                      <div className="tableColumnsParagraphTip">
                        {record[key]?.val}
                      </div>
                    ),
                    overlayClassName: "tableColumnsParagraphTooltip",
                    placement,
                  },
                }}
              >
                <div style={{ textAlign: "left", color: "#535353" }}>
                  {record[key]?.val}
                </div>
              </Paragraph>
            );
          }
          return record[key]?.val;
        };
        if (item.is_select) {
          let _key = key;
          if (item.order_field) {
            _key = item.order_field;
          }
          const filtered = getGroupByFields().some((_) => _.key === _key);
          column.filtered = filtered;
          /* 筛选 */
          const columnSearchProps = getColumnSearchProps(_key, item, filtered);

          Object.keys(columnSearchProps).forEach((key) => {
            column[key] = columnSearchProps[key];
          });
        }
        column.index = item.index + 1;
        if (item.width) {
          column.width = item.width;
        }
        return column;
      });
    //添加序号
    columns.unshift({
      index: 1,
      key: "序号",
      title: "序号",
      dataIndex: "序号",
      // width: "7%",
      width: 80,
      align: "center",
      render: (text, record, index) => (
        <>{(getPageNumber() - 1) * (getPageSize() || 10) + index + 1}</>
      ),
    });
    if (
      (!getIsDown() &&
        projectInfo?.project_permission_urls.includes(
          "CDD_PRJ_CDD_ITEM_EDIT"
        )) ||
      projectInfo?.project_permission_urls?.includes("CDD_PRJ_COMMENT_VIEW")
    ) {
      columns.push({
        ey: "操作",
        title: "操作",
        dataIndex: "操作",
        // width: "7%",
        width: 100,
        align: "center",
        render: (text, record, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            {projectInfo?.project_permission_urls?.includes(
              "CDD_PRJ_COMMENT_VIEW"
            ) && (
              <InfoIcon
                tipsText="修订记录"
                info={{
                  id: record.id?.val,
                  comments: record.comments,
                }}
                mode={20}
                dataInfo={record}
                dataTable={dataTable}
                projectId={id}
              />
            )}
            {!getIsDown() &&
            projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EDIT"
            ) ? (
              <Tooltip placement="top" title="修订" arrowPointAtCenter>
                <FormOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    getLicenseValid().then((res) => {
                      if (res) {
                        addOrEditCompetitors("edit", record);
                      }
                    });
                  }}
                  style={{ padding: "2px" }}
                  className="cursor-pointer editIcon hoverIcon"
                ></FormOutlined>
              </Tooltip>
            ) : null}

            {!getIsDown() &&
            projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EDIT"
            ) ? (
              <Tooltip placement="top" title="删除" arrowPointAtCenter>
                <ReactSVG
                  onClick={(e) => {
                    e.stopPropagation();
                    getLicenseValid().then((res) => {
                      if (res) {
                        deleteItem(record);
                      }
                    });
                  }}
                  style={{ padding: "2px" }}
                  className="deleteIcon hoverIcon"
                  src={deleteIcon}
                ></ReactSVG>
              </Tooltip>
            ) : null}
          </div>
        ),
      });
    }

    columns = columns.sort((a, b) => a.index - b.index);
    columns2.current = columns;
  };

  const getCompetitiveHitData = () => {
    setTableLoading(true);
    let params = {
      project_id: id,
      name: dataTable,
      page: getPageNumber(),
      page_size: getPageSize(),
    };
    let filterCriteria = JSON.parse(JSON.stringify(getGroupByFields()));
    console.log(filterCriteria);
    if (filterCriteria?.length > 0) {
      params.select_filed_list = JSON.stringify(filterCriteria);
    }
    projectApi.getCompetitiveHitData(params).then((res) => {
      console.log("竞争力分析中标:-------", getPageNumber(), res);
      if (res.code === 200) {
        initColumns(res.data?.rows || []);
        setCompetitiveHitData(res.data.data);
        setTotal(res.data?.total);
        setDataInfo(res?.data);
        dataChange && dataChange(res?.data);
      } else {
        message.error(res.message);
        setCompetitiveHitData([]);
        setDataInfo({});
        setTotal(0);
      }
      setLoading(false);
      setTableLoading(false);
    });
  };

  //导出数据
  const downloadFile = () => {
    setDownLoading2(true);
    let params = {
      is_bid: 1,
    };
    let filterCriteria = JSON.parse(JSON.stringify(getGroupByFields()));
    if (filterCriteria?.length > 0) {
      params.select_filed_list = JSON.stringify(filterCriteria);
    }
    projectApi
      .exportData(dataTable, id, params)
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading2(false);
      })
      .catch((sea) => {
        const unit8_msg = new Uint8Array(sea?.response?.data);
        const string = String.fromCharCode.apply(null, unit8_msg);
        const data = JSON.parse(string);
        message.error(data.message);
        setDownLoading2(false);
      });
  };

  useImperativeHandle(ref, () => ({
    onPageChange,
    setGroupByFields,
    getCompetitiveHitData,
  }));

  return (
    <div className="competitorsTableBox">
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <>
          <div className="headerTitleBox">
            <div className="dataInput">
              更新时间：{dataInfo?.data_update_time}
              {getIsDown() && (
                <Tag color="#0068B2" style={{ marginLeft: "10px" }}>
                  中标数据正在获取中
                </Tag>
              )}
            </div>
            <div>
              {!getIsDown() &&
                projectInfo?.project_permission_urls.includes(
                  "CDD_PRJ_CDD_ITEM_EDIT"
                ) && (
                  <Tooltip placement="top" title="新增" arrowPointAtCenter>
                    <PlusCircleOutlined
                      className="hoverIcon"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        getLicenseValid().then((res) => {
                          if (res) {
                            addOrEditCompetitors("add");
                          }
                        });
                      }}
                    />
                  </Tooltip>
                )}

              {projectInfo?.project_permission_urls.includes(
                "CDD_PRJ_CDD_ITEM_EXPORT"
              ) &&
                (downLoading2 ? (
                  <LoadingOutlined
                    style={{ marginLeft: "10px" }}
                  ></LoadingOutlined>
                ) : (
                  <Tooltip placement="top" title="下载" arrowPointAtCenter>
                    <DownLoadIcon
                      style={{ marginLeft: "10px" }}
                      className="hoverIcon"
                      onClick={() => {
                        downloadFile();
                      }}
                    ></DownLoadIcon>
                  </Tooltip>
                ))}
            </div>
          </div>
          <div className="tableContentBox" ref={refTable}>
            <Table
              loading={
                tableLoading
                  ? {
                      indicator: (
                        <ReactSVG
                          src={LoadingSvg}
                          className="anticon-spin anticon myLoadingIcon"
                        />
                      ),
                    }
                  : false
              }
              ref={tableRef}
              rowKey={(record) => record.id.val}
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    clickRow(record);
                  }, // 点击行
                };
              }}
              rowClassName={(item) => {
                let className = "";
                if (item.id.val === getSelectItemId()) {
                  className = "selectItem";
                }
                return className;
              }}
              className="myFormTable"
              bordered={true}
              pagination={false}
              columns={columns2.current}
              dataSource={competitiveHitData}
              scroll={{ y: total > 0 ? size?.height - 110 + "px" : null }}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
            ></Table>
            {total > 10 ? (
              <div className="paginationBox">
                <Pagination
                  className="myPagination"
                  showQuickJumper={true}
                  current={pageNumber}
                  total={total}
                  pageSize={pageSize || 10}
                  pageSizeOptions={[10, 20, 50, 100]}
                  showSizeChanger={true}
                  onChange={onPageChange}
                  showTotal={(total) => `共 ${total} 条`}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
      <DetailsDrawer
        title={"竞争对手" + tableType + "详情"}
        visible={detailsDrawerVisible}
        dataTable={dataTable}
        item={choiceItem}
        typeName={typeName}
        onCancel={() => {
          setDetailsDrawerVisible(false);
        }}
        isBid={true}
      ></DetailsDrawer>
    </div>
  );
};
export default forwardRef(CompetitorsTable);
