import "./index.scss";
import HeaderTitle from "../../../../../components/HeaderTitle";
import {
  LeftOutlined,
  FilterFilled,
  DownOutlined,
  UpOutlined,
  UserAddOutlined,
  ApartmentOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import useUrlState from "@ahooksjs/use-url-state";
import RecordCard from "../RecordCard";
import { useEffect, useState, useRef } from "react";
import {
  Tabs,
  message,
  Tooltip,
  Table,
  Tag,
  Input,
  Checkbox,
  Button,
  Select,
  Modal,
  Pagination,
  Badge,
} from "antd";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";
import { useParams } from "react-router-dom";
import { showInfoFunc } from "../../../../../components/MyTable";
import MySpin from "../../../../../components/MySpin";
import { useSize } from "ahooks";
import { useGetState, useUnmount, useHover } from "ahooks";
import PathDiv from "../PathDiv";
import Chart from "..//Chart";
import DialogDrawer from "../../../../../components/DialogDrawer";
import { ReactSVG } from "react-svg";
import LoadingSvg from "../../../../../assets/loading.svg";
import img4 from "../../../../../assets/警告@3x.png";

import {
  downloadFile,
  getLicenseValid,
  uniqueArray,
} from "../../../../../unit/unit";
import InfoIcon from "../../../../../components/Icons/InfoIcon";
import CommentsIcon from "../../../../../components/Icons/CommentsIcon";
import DetailsDrawer from "../../../../../components/DetailsDrawer";
import pinyin from "pinyin";
import DownLoadIcon from "../../../../../components/Icons/DownLoadIcon";

const { Option } = Select;
const { TabPane } = Tabs;
/* 核查结果筛选内部组件 */
const FilterDropdownContent = (props) => {
  const {
    filterOptions,
    loading,
    confirm,
    clearFilters,
    filterHandle,
    filterList,
    type,
    setIsScroll,
  } = props;
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const ref = useRef(null);
  const isHovering = useHover(ref);

  useEffect(() => {
    setIsScroll(isHovering);
  }, [isHovering]);

  useEffect(() => {
    setCheckedOptions(
      filterOptions?.sort((a, b) => {
        let valueA = pinyin(a, {
          style: pinyin.STYLE_NORMAL,
          heteronym: true,
        }).join("");
        let valueB = pinyin(b, {
          style: pinyin.STYLE_NORMAL,
          heteronym: true,
        }).join("");
        return valueA.localeCompare(valueB);
      })
    );
    setCheckedKeys(filterList);
    if (filterList?.length === 0) {
      setCheckAll(false);
      setIndeterminate(false);
    } else if (filterOptions?.length === filterList?.length) {
      setCheckAll(true);
      setIndeterminate(false);
    } else {
      setCheckAll(false);
      setIndeterminate(true);
    }
  }, [filterOptions, filterList]);

  const allChange = (e) => {
    let arr = [];
    if (e.target.checked) {
      arr = [...new Set([...checkedOptions, ...checkedKeys])];
    }
    setCheckedKeys([...arr]);
  };

  const checkChange = (newCheckedKeys) => {
    const _checkedKeys = checkedKeys.filter(
      (item) => !checkedOptions.includes(item)
    );
    setCheckedKeys([...newCheckedKeys, ..._checkedKeys]);
  };

  useEffect(() => {
    if (checkedKeys?.length === filterOptions?.length) {
      setIndeterminate(false);
      setCheckAll(true);
    } else {
      setIndeterminate(checkedKeys?.length !== 0);
      setCheckAll(false);
    }
  }, [checkedKeys]);

  const handle = () => {
    filterHandle(checkedKeys);
    confirm();
  };

  const clear = () => {
    const _checkedKeys = checkedKeys.filter(
      (item) => !checkedOptions.includes(item)
    );
    setCheckedKeys([..._checkedKeys]);
    setIndeterminate(_checkedKeys?.length !== 0);
    setCheckAll(false);
  };
  const clearDis = () => {
    return (
      checkedKeys?.filter((_) => checkedOptions?.includes(_))?.length === 0
    );
  };

  const initDom = () => {
    //多选选择
    return (
      <>
        <Input
          placeholder="搜索"
          className="searchInput"
          onChange={(e) => {
            setCheckedOptions((prev) => {
              prev =
                filterOptions?.filter((_) => _?.includes(e.target.value)) || [];
              prev = prev.sort((a, b) => {
                let valueA = pinyin(a, {
                  style: pinyin.STYLE_NORMAL,
                  heteronym: true,
                }).join("");
                let valueB = pinyin(b, {
                  style: pinyin.STYLE_NORMAL,
                  heteronym: true,
                }).join("");
                return valueA.localeCompare(valueB);
              });
              return [...prev];
            });
          }}
        ></Input>
        {checkedOptions.length > 0 ? (
          <Checkbox
            onChange={allChange}
            indeterminate={indeterminate}
            checked={checkAll}
          >
            全选
          </Checkbox>
        ) : (
          <MyEmpty></MyEmpty>
        )}

        <div className="checkBox">
          <Checkbox.Group
            options={checkedOptions}
            value={checkedKeys}
            onChange={checkChange}
          ></Checkbox.Group>
        </div>
      </>
    );
  };

  //加载
  if (loading) {
    return (
      <div className="filterDropdownBox">
        <MySpin></MySpin>
      </div>
    );
  }

  return (
    <div className="filterDropdownBox" type={type} ref={ref}>
      {initDom()}
      <div className="filterDropdownBtnBox drawerFooterBtnBox">
        <Button
          size="small"
          type="primary"
          onClick={() => {
            handle();
          }}
        >
          确定
        </Button>
        {/* <Button size="small" className="noBg" onClick={() => confirm()}>
          取消
        </Button> */}
        <Button
          type="link"
          disabled={clearDis()}
          size="small"
          className="noBg"
          onClick={() => clear()}
        >
          重置筛选
        </Button>
      </div>
    </div>
  );
};
const FilterDropdownContent2 = (props) => {
  const {
    isContainProp,
    loading,
    confirm,
    clearFilters,
    filterHandle,
    filterList,
    type,
    setIsScroll,
  } = props;

  const [checkedKeys, setCheckedKeys] = useState([]);
  const [isContain, setIsContain] = useState("EXISTS");
  const ref = useRef(null);
  const isHovering = useHover(ref);

  useEffect(() => {
    setIsScroll(isHovering);
  }, [isHovering]);

  useEffect(() => {
    setCheckedKeys(filterList);
  }, [filterList]);

  useEffect(() => {
    setIsContain(isContainProp);
  }, [isContainProp]);

  const checkChange = (newCheckedKeys) => {
    setCheckedKeys(newCheckedKeys);
  };

  const handle = () => {
    filterHandle(isContain, checkedKeys);
    confirm();
  };

  const clear = () => {
    setIsContain("EXISTS");
    setCheckedKeys([]);
  };

  const clearDis = () => {
    return checkedKeys?.length === 0 && isContain === "EXISTS";
  };

  const initDom = () => {
    //多选选择
    return (
      <>
        <Select
          value={isContain}
          style={{
            width: "85px",
            marginRight: "10px",
            borderBottom: "1px solid rgba(0,0,0,0.08)",
          }}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode || document.body
          }
          placement="topLeft"
          onChange={(value) => {
            setIsContain(value);
            setCheckedKeys([]);
          }}
          bordered={false}
          options={[
            {
              value: "EXISTS",
              label: "包含",
            },
            {
              value: "NOT",
              label: "不包含",
            },
            {
              value: "EQUALS",
              label: "仅包含",
            },
          ]}
        />
        <Select
          value={checkedKeys}
          mode="multiple"
          placement="topLeft"
          listHeight={190}
          maxTagCount="responsive"
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode || document.body
          }
          style={{
            width: "185px",
            borderBottom: "1px solid rgba(0,0,0,0.08)",
          }}
          bordered={false}
          onChange={checkChange}
          options={[
            {
              value: "持股关系",
              label: "持股关系",
            },
            {
              value: "历史持股关系",
              label: "历史持股关系",
            },
            {
              value: "任职关系",
              label: "任职关系",
            },
            {
              value: "历史任职关系",
              label: "历史任职关系",
            },
            {
              value: "相似邮箱",
              label: "相似邮箱",
            },
            {
              value: "相似电话",
              label: "相似电话",
            },
            {
              value: "相似网址",
              label: "相似网址",
            },
            {
              value: "相近地址",
              label: "相近地址",
            },
            {
              value: "相同案件",
              label: "相同案件",
            },
            {
              value: "相同专利",
              label: "相同专利",
            },
            {
              value: "相同商标",
              label: "相同商标",
            },
            {
              value: "相同作品著作",
              label: "相同作品著作",
            },
            {
              value: "相同软件著作",
              label: "相同软件著作",
            },
          ]}
        />
      </>
    );
  };

  //加载
  if (loading) {
    return (
      <div className="filterDropdownBox">
        <MySpin></MySpin>
      </div>
    );
  }

  return (
    <div
      className="filterDropdownBox"
      style={{ width: "300px" }}
      type={type}
      ref={ref}
    >
      {initDom()}
      <div className="filterDropdownBtnBox drawerFooterBtnBox">
        <Button
          size="small"
          type="primary"
          onClick={() => {
            handle();
          }}
        >
          确定
        </Button>
        {/* <Button size="small" className="noBg" onClick={() => confirm()}>
          取消
        </Button> */}
        <Button
          type="link"
          disabled={clearDis()}
          size="small"
          className="noBg"
          onClick={() => clear()}
        >
          重置筛选
        </Button>
      </div>
    </div>
  );
};
const FilterDropdownContent3 = (props) => {
  const { type, loading, confirm, clearFilters, filterHandle, filterList } =
    props;

  const [checkedKeys, setCheckedKeys] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setCheckedKeys(filterList);
  }, [filterList]);

  useEffect(() => {
    let options = [];
    if (type === "核查对象类型") {
      options = [
        {
          label: "法人",
          value: 1,
        },
        {
          label: "自然人",
          value: 2,
        },
      ];
    } else if (type === "核查对象分类") {
      options = [
        {
          label: "供应商",
          value: "供应商",
        },
        {
          label: "经销商",
          value: "经销商",
        },
        {
          label: "客户",
          value: "客户",
        },
        {
          label: "非经营性资金往来",
          value: "非经营性资金往来",
        },
        {
          label: "其他",
          value: "其他",
        },
      ];
    } else if (type === "核查结果") {
      options = [
        {
          label: "疑似",
          value: "疑似",
        },
        {
          label: "非疑似",
          value: "非疑似",
        },
      ];
    }
    setOptions(options);
  }, [type]);

  const checkChange = (newCheckedKeys) => {
    setCheckedKeys(newCheckedKeys);
  };

  const handle = () => {
    filterHandle(type, checkedKeys);
    confirm();
  };

  const clear = () => {
    setCheckedKeys([]);
  };

  const clearDis = () => {
    return checkedKeys?.length === 0;
  };

  const initDom = () => {
    //多选选择
    return (
      <div className="filterCheckBox">
        <Checkbox.Group
          options={options}
          value={checkedKeys}
          onChange={checkChange}
        ></Checkbox.Group>
      </div>
    );
  };

  //加载
  if (loading) {
    return (
      <div className="filterDropdownBox">
        <MySpin></MySpin>
      </div>
    );
  }

  return (
    <div className="filterDropdownBox" style={{ width: "220px" }} type={type}>
      {initDom()}
      <div className="filterDropdownBtnBox drawerFooterBtnBox">
        <Button
          size="small"
          type="primary"
          onClick={() => {
            handle();
          }}
        >
          确定
        </Button>
        {/* <Button size="small" className="noBg" onClick={() => confirm()}>
          取消
        </Button> */}
        <Button
          type="link"
          disabled={clearDis()}
          size="small"
          className="noBg"
          onClick={() => clear()}
        >
          重置筛选
        </Button>
      </div>
    </div>
  );
};
const PathCollapse = (props) => {
  const { info, showDetail } = props;
  const [visable, setVisable] = useState(false);
  return (
    <div className="pathCollapse">
      <div className="pathLineBox">
        {info.path?.length > 1 && <div className="pathLineTitle">路径1:</div>}
        <PathDiv
          showDetail={showDetail}
          path={info.path?.at(0) || []}
          startNodeId={info.from_id}
        ></PathDiv>
      </div>
      {info.path?.length > 1 &&
        (!visable ? (
          <div
            className="pathMoreDiv"
            onClick={() => {
              setVisable(true);
            }}
          >
            更多
            <DownOutlined />
          </div>
        ) : (
          <>
            {info.path?.slice(1).map((path, index) => {
              return (
                <div className="pathLineBox" key={index + 2}>
                  <div className="pathLineTitle">
                    路径
                    {index + 2}:
                  </div>
                  <PathDiv
                    path={path || []}
                    startNodeId={info.from_id}
                    showDetail={showDetail}
                  ></PathDiv>
                </div>
              );
            })}
            <div
              className="pathMoreDiv"
              onClick={() => {
                setVisable(false);
              }}
            >
              收起
              <UpOutlined />
            </div>
          </>
        ))}
      {/* {info.path.map((path, index) => {
        return (
          
        );
      })} */}
    </div>
  );
};
/* ---- */
const RecordDetails = (props) => {
  const { reVerificationFunc, loading, defaultType = "核查对象" } = props;
  const { id } = useParams();
  const [state, setState] = useUrlState({});
  const [info, setInfo] = useState({});
  const [type, setType] = useState(defaultType);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  //关系图谱Modal显隐
  const [isModalVisible, setIsModalVisible] = useState(false);
  //核查结果路径是否为空
  const [pathsEmpty, setPathsEmpty] = useState(false);
  //添加关联方
  const [formList, setFormList] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  //下载
  const [downLoading, setDownLoading] = useState(false);
  //生成时间
  const [checkDate, setCheckDate] = useState(null);
  //核查结果图谱数据
  const [chartData, setChartData] = useState([]);
  //核查结果筛选
  const [filterOptionsList, setFilterOptionsList, getFilterOptionsList] =
    useGetState([]);
  const [filterLoading, getFilterLoading, setFilterLoading] =
    useGetState(false);
  const [filterList, setFilterList, getFilterList] = useGetState([]);
  const [filterTypeList, setFilterTypeList, getFilterTypeList] = useGetState(
    []
  );

  const [filterObj, setFilterObj, getFilterObj] = useGetState({});

  const [relationshipList, setRelationshipList, getRelationshipList] =
    useGetState([]);
  const [isContain, setIsContain, getIsContain] = useGetState("EXISTS");

  const [okLoading, setOkLoading] = useState(false);

  const [hasPathCompanyList, setHasPathCompanyList, getHasPathCompanyList] =
    useGetState(null);

  const [getLoading, setGetLoading] = useState(true);
  //关联事项类型
  const relatedPartiesList = useRef([]);
  //关联事项类型颜色
  const subjectScopeOptions = useRef({
    尽调对象: "#0068B2",
    关联方: "#AAD461",
    供应商: "#C88EED",
    经销商: "#6CAEFF",
    客户: "#FEC054",
    非经营性资金往来: "#C6C0FC",
    其他: "#61D4AD",
  });
  const ref = useRef(null);
  const size = useSize(ref);
  const tableRef = useRef(null);
  const timer = useRef(null);
  const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [choiceItem, setChoiceItem] = useState(null);

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);
  const [pathData, setPathData] = useState([]);
  const [total, setTotal] = useState(0);
  const [chartLoading, setChartLoading] = useState(false);

  const [isScroll, setIsScroll, getIsScroll] = useGetState(false);

  useUnmount(() => {
    clearTimeout(timer.current);
  });

  const showDetail = (suffix) => {
    const [id, str] = suffix?.split("?");
    const [str1] = str?.split("&");
    const [a, name] = str1?.split("=");
    setDataTable(name);
    setChoiceItem({
      id: { val: id },
    });
    setDetailsDrawerVisible(true);
  };

  //获取关联事项类型
  useEffect(() => {
    projectApi.getDicCodeInfo("CDD_SUB").then((res) => {
      if (res.code === 200) {
        relatedPartiesList.current =
          res.data?.map((_) => _.dic_item_name) || [];
      } else {
        relatedPartiesList.current = [];
      }
    });
  }, []);

  //获取对应的核查数据
  useEffect(() => {
    getDataInfo(state.id);
  }, [state.id]);

  const run = () => {
    if (getIsScroll()) {
      return;
    }
    let d = document.querySelector("#table .ant-table-body");
    let c = document.querySelector("#aaaa");
    let cDoms = c.childNodes;
    let cDom = []?.at?.call(cDoms, 0);
    if (cDom.scrollTop < 200) {
      if (d.scrollTop === 0) {
        d.scrollTop = 0;
      }
      cDom.scrollTop = cDom.scrollTop + 10;
      return;
    }
    // if (d?.scrollTop === 0) {
    //   console.log("滑倒顶部");
    // }
    // d.scrollTop = 0
  };

  //初始化筛选选项
  // useEffect(() => {
  //   let arr = [];
  //   (info?.paths || [])?.concat(info?.intersect_info || []).forEach((_) => {
  //     if (_.end_name) {
  //       arr.push(_.end_name);
  //     }
  //     if (_.from_name) {
  //       arr.push(_.from_name);
  //     }
  //   });
  //   setFilterList([]);
  //   setFilterOptionsList([...new Set(arr)]);
  // }, [info]);

  const getDataInfo = (_id) => {
    setGetLoading(true);
    getDataInfoFunc(_id);
  };

  const initCompanyFunc = (info) => {
    // const list = [];
    // info?.intersect_info?.forEach((item) => {
    //   list.push(item.from_name);
    // });
    // info?.paths?.forEach((path) => {
    //   path?.path?.forEach((pathItem) => {
    //     pathItem?.forEach((item) => {
    //       const [a, b, c] = item || [];
    //       list.push(a?.property?.name);
    //       list.push(c?.property?.name);
    //     });
    //   });
    // });
    setHasPathCompanyList(Object.keys(info?.subjects || {}));
  };

  const getDataInfoFunc = (_id) => {
    const params = {
      suspect_id: _id,
      page_number: getPageNumber(),
      page_size: getPageSize(),
      filter_subjects: getFilterList()?.join(),
      filter_path_operator: getIsContain(),
      filter_paths: getRelationshipList()?.join(),
      filter_subject_types: getFilterTypeList()?.join(),
    };
    projectApi.getCheckList(id, params).then((res) => {
      console.log("---------核查详情:", params, res);
      if (res.code === 200) {
        clearTimeout(timer.current);
        if (res.data?.length === 0) {
          message.error("此次核查任务已被删除!");
          setState({ id: undefined });
          return;
        }
        const info = res.data?.at(0) || {};
        info.id = info.suspect_id;
        setInfo(info);
        setPathsEmpty(info.nums === 0);
        setCheckDate(info?.update_time);
        // setChartData(info?.group_paths || []);
        // const list = initData(
        //   (info?.paths || [])?.concat(info?.intersect_info || [])
        // );
        setPathData(info?.paths || []);
        setTotal(info?.total_cnt);
        setFilterOptionsList(Object.keys(info?.subjects || {}));
        if (info.suspect_status !== "CDD_SUSPECT_STATUS_FINISH") {
          timer.current = setTimeout(() => {
            getDataInfoFunc(_id);
          }, 5000);
          setHasPathCompanyList(null);
        } else {
          initCompanyFunc(info);
        }
      } else {
        message.error(res.message);
        setState({ id: undefined });
      }
      setGetLoading(false);
    });
  };

  //页码变化
  const onPageChange = async (page, pageSize) => {
    await setPageSize(pageSize);
    await setPageNumber(page);
    var dom = tableRef.current?.getElementsByClassName("ant-table-body")[0];
    if (dom) {
      dom.scrollTop = 0;
    }
    setTimeout(() => {
      getDataInfo(state.id);
    }, 100);
  };

  //tab变化
  const tabChange = (key) => {
    setType(key);
  };

  //tag点击快速筛选
  const tagClick = (info) => {
    setPageNumber(1);
    setFilterList([info.name]);
    setIsContain("EXISTS");
    setRelationshipList([]);
    setFilterTypeList([]);
    setTimeout(() => {
      setType("核查结果");
      getDataInfo(state.id);
    }, 100);
  };

  //下载
  const downLoad = () => {
    setDownLoading(true);
    projectApi
      .downloadSuspect(id, projectInfo.company_name, checkDate)
      .then((res) => {
        if (res.code === 200) {
          downloadFile(res.data, id);
        } else {
          message.error(res.message);
        }
        setDownLoading(false);
      });
  };

  //查看公司及自然人详情
  const showInfoHandle = async (record) => {
    const item = {
      shareholderId: { val: record.id },
      name: { val: record.name },
      s_type: { val: record.type },
      click_all: true,
    };
    if (record.type === 2) {
      const { data } = await projectApi.getPersonId(id, {
        name: record.name,
        company_id: record.id,
      });
      item.shareholderId.val = data.person_id;
    }
    showInfoFunc(item, item, "主要股东", id, "name");
  };

  //筛选操作
  const filterHandle = (filterInfo) => {
    setFilterList(filterInfo);
    setPageNumber(1);
    setTimeout(() => {
      getDataInfo(state.id);
    }, 100);
  };
  //筛选操作
  const filterHandle2 = (isContain, checkedKeys) => {
    setIsContain(isContain);
    setRelationshipList(checkedKeys);
    setPageNumber(1);
    setTimeout(() => {
      getDataInfo(state.id);
    }, 100);
  };
  //筛选操作
  const filterHandle3 = (type, checkedKeys) => {
    setFilterObj((prev) => {
      prev[type] = checkedKeys;
      return { ...prev };
    });
  };

  //筛选props
  const getColumnSearchProps = (type) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => {
      if (!visible) {
        return;
      }
      // if(item.is_select){
      //   return
      // }

      return (
        <FilterDropdownContent
          type={type}
          filterHandle={filterHandle}
          filterList={getFilterList()}
          filterOptions={getFilterOptionsList() || []}
          confirm={confirm}
          clearFilters={clearFilters}
          setIsScroll={setIsScroll}
        ></FilterDropdownContent>
      );
    },
    // onFilterDropdownOpenChange: (visible) => {
    //   if (visible) {
    //     setFilterOptionsList([...filterOptionsList]);
    //   }
    // },
    filterIcon: () => {
      return (
        <FilterFilled
          type={type}
          style={{
            color: filterList?.length > 0 ? "#0068B2" : "#bfbfbf",
          }}
        />
      );
    },
  });
  //筛选props
  const getColumnSearchProps2 = (type) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => {
      if (!visible) {
        return;
      }
      // if(item.is_select){
      //   return
      // }

      return (
        <FilterDropdownContent2
          type={type}
          filterHandle={filterHandle2}
          filterList={relationshipList}
          isContainProp={isContain}
          confirm={confirm}
          clearFilters={clearFilters}
          setIsScroll={setIsScroll}
        ></FilterDropdownContent2>
      );
    },
    filterIcon: () => {
      return (
        <FilterFilled
          type={type}
          style={{
            color: relationshipList.length > 0 ? "#0068B2" : "#bfbfbf",
          }}
        />
      );
    },
  });
  //筛选props
  const getColumnSearchProps3 = (type) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => {
      if (!visible) {
        return;
      }
      // if(item.is_select){
      //   return
      // }

      return (
        <FilterDropdownContent3
          type={type}
          filterHandle={filterHandle3}
          filterList={getFilterObj()[type] || []}
          confirm={confirm}
          clearFilters={clearFilters}
        ></FilterDropdownContent3>
      );
    },
    filterIcon: () => {
      return (
        <FilterFilled
          type={type}
          style={{
            color: getFilterObj()[type]?.length > 0 ? "#0068B2" : "#bfbfbf",
          }}
        />
      );
    },
  });
  //格式化 table columns
  const initColumns2 = () => {
    const columns2 = [];
    columns2.push({
      title: "序号",
      dataIndex: "index",
      key: "index",
      width: "80px",
      align: "center",
      render: (text, record, index) => (
        <>{(getPageNumber() - 1) * getPageSize() + index + 1}</>
      ),
    });
    const columnItem = {
      title: "疑似关联方",
      dataIndex: "s_type",
      key: "s_type",
      align: "center",
      width: "30%",

      render: (text, record, index) => {
        if (record.end_id) {
          return (
            <div className="comBox">
              <a
                className="item"
                onClick={(e) => {
                  e.stopPropagation();
                  showInfoHandle({
                    id: record.from_id,
                    name: record.from_name,
                    type: record.from_type,
                  });
                }}
              >
                {record.from_name}
              </a>
              <div className="tagItem">
                {record.from_s_type?.map((tag) => (
                  <Tooltip
                    key={Math.random()}
                    placement="top"
                    title={tag.full_name}
                    arrowPointAtCenter
                  >
                    <Tag
                      style={{
                        maxWidth: "100%",
                        whiteSpace: "normal",
                        margin: 0,
                      }}
                      color={
                        subjectScopeOptions.current[tag.full_name] || "#AAD461"
                      }
                      key={Math.random()}
                    >
                      {tag.short_name}
                    </Tag>
                  </Tooltip>
                ))}
              </div>
              <div className="iconItems">{"<>"}</div>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  showInfoHandle({
                    id: record.end_id,
                    name: record.end_name,
                    type: record.end_type,
                  });
                }}
              >
                {record.end_name}
              </a>
              <div className="tagItem">
                {record.end_s_type?.map((tag) => (
                  <Tooltip
                    key={Math.random()}
                    placement="top"
                    title={tag.full_name}
                    arrowPointAtCenter
                  >
                    <Tag
                      style={{
                        maxWidth: "100%",
                        whiteSpace: "normal",
                        margin: 0,
                      }}
                      color={
                        subjectScopeOptions.current[tag.full_name] || "#AAD461"
                      }
                      key={Math.random()}
                    >
                      {tag.short_name}
                    </Tag>
                  </Tooltip>
                ))}
              </div>
            </div>
          );
        } else {
          return (
            <div className="comBox">
              <a
                className="item"
                onClick={(e) => {
                  e.stopPropagation();
                  showInfoHandle({
                    id: record.from_id,
                    name: record.from_name,
                    type: record.from_type,
                  });
                }}
              >
                {record.from_name}
              </a>
              <div className="tagItem">
                {record.from_s_type?.map((tag) => (
                  <Tooltip
                    key={Math.random()}
                    placement="top"
                    title={tag.full_name}
                    arrowPointAtCenter
                  >
                    <Tag
                      style={{
                        maxWidth: "100%",
                        whiteSpace: "normal",
                        margin: 0,
                      }}
                      color={
                        subjectScopeOptions.current[tag.full_name] || "#AAD461"
                      }
                      key={Math.random()}
                    >
                      {tag.short_name}
                    </Tag>
                  </Tooltip>
                ))}
              </div>
            </div>
          );
        }
      },
    };
    const columnItem2 = {
      title: "关联路径",
      dataIndex: "name",
      key: "name",
      align: "center",
      // className: "pathCell",
      render: (text, record, index) => {
        if (!record.path) {
          return "该核查对象自身为关联方";
        }
        return (
          <PathCollapse info={record} showDetail={showDetail}></PathCollapse>
        );
      },
    };
    // columnItem.filtered = filterList;
    if (info.suspect_status === "CDD_SUSPECT_STATUS_FINISH") {
      /* 筛选 */
      const columnSearchProps = getColumnSearchProps("疑似关联方");
      const columnSearchProps2 = getColumnSearchProps2("关联路径");

      Object.keys(columnSearchProps).forEach((key) => {
        columnItem[key] = columnSearchProps[key];
      });
      Object.keys(columnSearchProps2).forEach((key) => {
        columnItem2[key] = columnSearchProps2[key];
      });
    }
    columns2.push(columnItem);
    columns2.push(columnItem2);
    if (info.suspect_status === "CDD_SUSPECT_STATUS_FINISH") {
      columns2.push({
        title: "操作",
        dataIndex: "caozuo",
        key: "caozuo",
        width: "120px",
        align: "center",
        render: (text, record, index) => {
          const recordObj = {};
          Object.keys(record || {}).forEach((key) => {
            recordObj[key] = { val: record[key] };
          });
          if (record.end_id) {
            return (
              <div className="addItem">
                {projectInfo?.project_permission_urls.includes(
                  "CDD_PRJ_COMMENT_VIEW"
                ) && (
                  <InfoIcon
                    getData={() => {
                      getDataInfo(state.id);
                    }}
                    info={{
                      id: record.id,
                      comments: record.comments,
                    }}
                    mode={20}
                    dataInfo={recordObj}
                    dataTable={"cdd_suspect_record"}
                    typeName={"疑似关联方分析"}
                    projectId={projectInfo.id}
                  />
                )}

                {projectInfo?.project_permission_urls.includes(
                  "CDD_PRJ_COMMENT"
                ) && (
                  <CommentsIcon
                    getData={() => {
                      getDataInfo(state.id);
                    }}
                    info={{
                      id: record.id,
                    }}
                    mode={20}
                    dataInfo={recordObj}
                    dataTable={"cdd_suspect_record"}
                    typeName={"疑似关联方分析"}
                    projectId={projectInfo.id}
                  />
                )}
                {projectInfo?.project_permission_urls.includes(
                  "CDD_PRJ_CDD_ITEM_EDIT"
                ) && (
                  <Tooltip
                    placement="top"
                    title="添加关联方"
                    arrowPointAtCenter
                  >
                    <UserAddOutlined
                      onClick={() => {
                        getLicenseValid().then((res) => {
                          if (res) {
                            checkRelatedParties(record);
                          }
                        });
                      }}
                    ></UserAddOutlined>
                  </Tooltip>
                )}
              </div>
            );
          } else {
            return "";
          }
        },
      });
    }
    return columns2;
  };
  //格式化 table columns
  const initColumns = () => {
    const columns2 = [];
    columns2.push({
      title: "序号",
      dataIndex: "index",
      key: "index",
      width: "10%",
      align: "center",
      render: (text, record, index) => <>{record.index}</>,
    });
    const columnItem = {
      title: "核查对象类型",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "15%",
      render: (text, record, index) => (
        <>
          {record.type_desc ||
            (record.type === 1 ? "法人" : record.type === 2 ? "自然人" : "")}
        </>
      ),
    };
    const columnItem2 = {
      title: "核查对象分类",
      dataIndex: "s_type",
      key: "s_type",
      align: "center",
      width: "15%",
    };
    const columnItem3 = {
      title: "核查对象",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "45%",
      render: (text, record, index) => {
        if (record.is_valid) {
          return (
            <span style={{ color: "red" }}>
              {(text || "") + `（${record.msg}）`}
            </span>
          );
        }
        return (
          <>
            {record?.risk_tips && (
              <Tooltip
                placement="top"
                title="工商信息风险提示 点击查看"
                arrowPointAtCenter
              >
                <div
                  className="ripTagBox"
                  style={{ background: "rgba(0,0,0,0)" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    showInfoHandle(record);
                  }}
                >
                  <img src={img4} alt="" />
                </div>
              </Tooltip>
            )}
            <a
              onClick={(e) => {
                e.stopPropagation();
                showInfoHandle(record);
              }}
            >
              {text}
            </a>
          </>
        );
      },
    };
    const columnItem4 = {
      title: "核查结果",
      dataIndex: "results",
      key: "results",
      align: "center",
      width: "15%",
      render: (text, record, index) => {
        return (
          <>
            {getHasPathCompanyList() ? (
              getHasPathCompanyList()?.some((_) => _ === record.name) ? (
                <Badge
                  count={(info?.subjects || {})[record.name] || 0}
                  size="small"
                  offset={[-5, 0]}
                >
                  <Tag
                    style={{
                      marginLeft: "5px",
                      border: "1px solid #FE545F",
                      background: "#fff",
                      borderRadius: "4px",
                      color: "#FE545F",
                    }}
                    onClick={() => tagClick(record)}
                  >
                    疑似
                  </Tag>
                </Badge>
              ) : (
                <Tag
                  style={{
                    marginLeft: "5px",
                    border: "1px solid #61D4AD",
                    background: "#fff",
                    borderRadius: "4px",
                    color: "#61D4AD",
                  }}
                >
                  非疑似
                </Tag>
              )
            ) : null}
          </>
        );
      },
    };
    // columnItem.filtered = filterList;
    /* 筛选 */
    [columnItem, columnItem2, columnItem4].forEach((_) => {
      const columnSearchProps = getColumnSearchProps3(_.title);
      Object.keys(columnSearchProps).forEach((key) => {
        _[key] = columnSearchProps[key];
      });
    });

    columns2.push(columnItem);
    columns2.push(columnItem2);
    columns2.push(columnItem3);

    if (info.suspect_status === "CDD_SUSPECT_STATUS_FINISH") {
      columns2.push(columnItem4);
    }
    return columns2;
  };
  //核查公司是否为关联方
  const checkRelatedParties = (item) => {
    projectApi
      .checkRelatedParties(
        id,
        JSON.stringify([
          {
            type: item.from_type,
            id: item.from_id,
          },
          {
            type: item.end_type,
            id: item.end_id,
          },
        ])
      )
      .then((res) => {
        if (res.code === 200) {
          if (res.message === "正在进行关联方重算") {
            message.info("关联方计算中，无法进行新增，请稍后操作");
            return;
          }
          let arr = [];
          Object.keys(res.data || {}).forEach((key) => {
            if (res.data[key] === false) {
              arr.push(key);
            }
          });
          if (arr.length === 0) {
            message.info(
              "该关联路径核查的疑似关联方已全部添加至关联方列表，无需再次添加"
            );
            return;
          }
          arr = arr.map((_) => {
            let obj = {};
            if (Number(_) === item.from_id) {
              obj.id = item.from_id;
              obj.key = item.from_id;
              obj.person_id = item.from_id;
              obj.company_id = item.from_id;
              obj.name = item.from_name;
              obj.data_type = item.from_type;
            } else if (Number(_) === item.end_id) {
              obj.id = item.end_id;
              obj.key = item.end_id;
              obj.person_id = item.end_id;
              obj.company_id = item.end_id;
              obj.name = item.end_name;
              obj.data_type = item.end_type;
            }
            return {
              label: obj.name,
              value: JSON.stringify(obj),
            };
          });
          addRelatedParties(arr);
        } else {
          message.error(res.message);
        }
      });
  };

  //核查结果 添加关联方
  const addRelatedParties = (options) => {
    projectApi.getTableStyle("related_parties_information", id).then((res) => {
      if (res.code === 200) {
        const list = res.data.map((_, index) => {
          if (index === 0) {
            _.type = "select";
            _.options = options;
            _.addRelatedParties = true;
          }
          return _;
        });
        setFormList(list);
        setDrawerVisible(true);
      } else {
        message.error(res.message);
      }
    });
  };

  //创建成功以后的回调
  const success = (data) => {
    setOkLoading(true);
    let params = {
      name: "related_parties_information",
      project_id: id,
      data_string: JSON.stringify(data),
    };
    projectApi.setTableItem(params).then(async (res) => {
      if (res.code === 200) {
        await setDrawerVisible(false);
        message.success(res.message);
      } else {
        message.error(res.message);
      }
      setOkLoading(false);
    });
  };

  //格式化 核查结果 的展示数据
  const initData = (data) => {
    //根据关联公司筛选
    if (getFilterList().length > 0) {
      data = data.filter((_) => {
        return (
          getFilterList().includes(_.end_name) ||
          getFilterList().includes(_.from_name)
        );
      });
    }
    //根据类型筛选
    if (getFilterTypeList().length > 0) {
      let _filterTypeList = JSON.parse(JSON.stringify(getFilterTypeList()));
      if (_filterTypeList.includes("关联方")) {
        _filterTypeList = _filterTypeList.concat(relatedPartiesList.current);
      }
      data = data.filter((_) => {
        let has = false;
        if (_.end_s_type) {
          _.end_s_type.forEach((item) => {
            if (_filterTypeList.includes(item.full_name)) {
              has = true;
            }
          });
        }
        if (!has) {
          _.from_s_type.forEach((item) => {
            if (_filterTypeList.includes(item.full_name)) {
              has = true;
            }
          });
        }
        return has;
      });
    }
    data = data.map((item) => {
      let pathTypes = [];
      pathTypes = item.path?.map((_) => {
        let arr = [];
        _?.forEach((__) => {
          const [a, b, c] = __;
          arr = arr.concat(b?.type_prefix_list || []);
        });
        return [...new Set(arr)];
      });
      item.pathTypes = pathTypes;
      return item;
    });

    //根据 关联路径 筛选
    // console.log(getIsContain(), getRelationshipList(), "------------", data);
    if (getRelationshipList()?.length > 0) {
      data = data.filter((_) => {
        let has = false;
        if (getIsContain() === "包含") {
          const path = [];
          //路径包含一个选项就行
          getRelationshipList()?.forEach((item) => {
            _?.pathTypes?.forEach((_item, index) => {
              if (_item?.includes(item)) {
                has = true;
                path.push(_?.path?.at(index));
              }
            });
          });
          _.pathList = path;
        } else if (getIsContain() === "不包含") {
          const path = [];
          //路径不包含其中的选项就行
          _?.pathTypes?.forEach((_item, index) => {
            const arr = _item.filter((___) =>
              getRelationshipList()?.includes(___)
            );
            if (arr?.length === 0) {
              has = true;
              path.push(_?.path?.at(index));
            }
          });
          _.pathList = path;
        } else if (getIsContain() === "仅包含") {
          const path = [];
          //路径只包含其中的一个选项
          getRelationshipList()?.forEach((item) => {
            _?.pathTypes?.forEach((_item, index) => {
              if (_item?.length === 1 && _item?.includes(item)) {
                has = true;
                path.push(_?.path?.at(index));
              }
            });
          });
          _.pathList = path;
        }
        return has;
      });
    }
    // console.log(data);
    //添加序号
    data = data.map((_, index) => {
      _.index = index + 1;
      if (getRelationshipList()?.length > 0) {
        _.path = _?.pathList || [];
      }
      return _;
    });
    return data;
  };
  //格式化 核查结果 的展示数据
  const initData2 = (data) => {
    //根据核查对象类型筛选
    if (getFilterObj()["核查对象类型"]?.length > 0) {
      data = data.filter((_) => {
        return getFilterObj()["核查对象类型"]?.includes(_.type);
      });
    }
    //根据核查对象分类筛选
    if (getFilterObj()["核查对象分类"]?.length > 0) {
      data = data.filter((_) => {
        return getFilterObj()["核查对象分类"]?.includes(_.s_type);
      });
    }
    //根据核查结果筛选
    if (getFilterObj()["核查结果"]?.length > 0) {
      data = data.filter((_) => {
        let flag = false;
        getFilterObj()["核查结果"]?.forEach((item) => {
          if (flag) {
            return;
          }
          if (item === "疑似") {
            flag = getHasPathCompanyList()?.some((__) => __ === _.name);
          } else if (item === "非疑似") {
            flag = !getHasPathCompanyList()?.some((__) => __ === _.name);
          }
        });
        return flag;
      });
    }

    //添加序号
    data = data.map((_, index) => {
      _.index = index + 1;
      return _;
    });
    return data;
  };

  const getChartsDataFunc = () => {
    setChartLoading(true);
    projectApi.getChartsData(id, state?.id).then((res) => {
      if (res.code === 200) {
        setChartData(res?.data || []);
        setIsModalVisible(true);
      } else {
        message.error(res.msg);
        setChartData([]);
      }
      setChartLoading(false);
    });
  };

  const downloadFileFunc = () => {
    setDownLoading(true);
    projectApi
      .getVerifyObjectFile(state.id, projectInfo.company_name)
      .then((res) => {
        if (res.code === 200) {
          downloadFile(res.data, id);
        } else {
          message.error(res.message);
        }
        setDownLoading(false);
      });
  };
  //加载中状态
  if (loading) {
    return (
      <div className="RecordDetailsContent" ref={ref}>
        <MySpin></MySpin>
      </div>
    );
  }

  return (
    <div className="RecordDetailsContent" ref={ref}>
      <HeaderTitle
        title="核查任务详情"
        extra={
          <div
            onClick={() => {
              setState({ id: undefined });
            }}
            style={{ color: "#9E9E9E", fontSize: "12px", cursor: "pointer" }}
          >
            <LeftOutlined />
            返回
          </div>
        }
      ></HeaderTitle>

      <div className="tableCardBoxContent">
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "100%", width: "100%" }}
          id="aaaa"
          onScroll={() => {
            if (getIsScroll()) {
              return;
            }
            const domList = document.querySelectorAll(".ant-dropdown") || [];
            const dom = [...domList]?.find((_) => {
              const classValue = _?.getAttribute("class");
              return !classValue?.includes("ant-dropdown-hidden");
            });
            if (dom) {
              const a = dom.querySelector(".filterDropdownBox");
              const type = a?.getAttribute("type");
              const domList =
                document.querySelectorAll(".ant-dropdown-trigger") || [];
              const btnDom = [...domList]?.find((_) => {
                return (
                  _?.querySelector(".anticon").getAttribute("type") === type
                );
              });
              btnDom && btnDom?.click();
            }
          }}
        >
          <RecordCard
            key={info.id}
            info={info}
            notClick={true}
            style={{
              width: "calc(100% - 40px)",
              margin: "0px 20px 20px",
            }}
            reVerificationFunc={reVerificationFunc}
            successFunc={() => {
              setState({ id: undefined });
            }}
          ></RecordCard>
          <div className="tabBox">
            <Tabs
              className="myTabs"
              onChange={tabChange}
              activeKey={type}
              destroyInactiveTabPane={true}
              tabBarExtraContent={
                type === "核查对象" ? (
                  <div className="iconBox">
                    {/* projectInfo?.project_permission_urls.includes("CDD_PRJ_CDD_ITEM_EXPORT") && */}
                    {downLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <Tooltip
                        placement="top"
                        title="工商信息下载"
                        arrowPointAtCenter
                      >
                        <DownLoadIcon
                          onClick={() => {
                            downloadFileFunc();
                          }}
                        ></DownLoadIcon>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  <div className="dateBox">
                    {info.suspect_status === "CDD_SUSPECT_STATUS_FINISH" && (
                      <div className="searchBox">
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "auto", minWidth: "150px" }}
                          placeholder="全部"
                          showArrow={true}
                          bordered={false}
                          dropdownMatchSelectWidth={false}
                          dropdownStyle={{
                            width: "150px",
                            minWidth: "150px",
                            maxWidth: "150px",
                          }}
                          value={filterTypeList}
                          placement="bottomRight"
                          onChange={(value) => {
                            setFilterTypeList(value);
                            setPageNumber(1);
                            setTimeout(() => {
                              getDataInfo(state.id);
                            }, 100);
                          }}
                          tagRender={(props) => {
                            return (
                              <Tag
                                closable
                                onClose={props.onClose}
                                color={subjectScopeOptions.current[props.value]}
                              >
                                {props.value}
                              </Tag>
                            );
                          }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode?.parentNode
                          }
                        >
                          {Object.entries(
                            subjectScopeOptions.current || []
                          ).map((item, index) => (
                            <Option key={item[0]} value={item[0]}>
                              <Tag color={item[1]}>{item[0]}</Tag>
                            </Option>
                          ))}
                        </Select>
                      </div>
                    )}
                    {info.suspect_status === "CDD_SUSPECT_STATUS_FINISH" && (
                      <div className="icons">
                        {!pathsEmpty &&
                          (chartLoading ? (
                            <LoadingOutlined />
                          ) : (
                            <Tooltip
                              placement="top"
                              title="关系图谱"
                              arrowPointAtCenter
                            >
                              <ApartmentOutlined
                                onClick={() => {
                                  getChartsDataFunc();
                                }}
                              />
                            </Tooltip>
                          ))}
                        {downLoading ? (
                          <LoadingOutlined />
                        ) : (
                          <Tooltip
                            placement="top"
                            title="下载"
                            arrowPointAtCenter
                            onClick={downLoad}
                          >
                            <DownLoadIcon
                              style={{ marginLeft: "10px" }}
                            ></DownLoadIcon>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                )
              }
            >
              {["核查对象", "核查结果"].map((key) => (
                <TabPane tab={key} key={key}>
                  <Scrollbars
                    autoHide={true}
                    autoHideTimeout={5000}
                    style={{ height: "100%", width: "100%" }}
                  >
                    {type === "核查对象" ? (
                      <div className="tableContentBox" onScrollCapture={run}>
                        <Table
                          id="table"
                          loading={
                            getLoading
                              ? {
                                  indicator: (
                                    <ReactSVG
                                      src={LoadingSvg}
                                      className="anticon-spin anticon myLoadingIcon"
                                    />
                                  ),
                                }
                              : false
                          }
                          scroll={{
                            y: `${size?.height - 60 - 312 + 180}px`,
                          }}
                          style={{ marginBottom: "15px" }}
                          locale={{
                            emptyText: <MyEmpty></MyEmpty>,
                          }}
                          className="myFormTable"
                          bordered={true}
                          // pagination={
                          //   (info?.suspect_info || []).length > 10 ? true : false
                          // }
                          pagination={false}
                          columns={initColumns()}
                          dataSource={initData2(info?.suspect_info || [])}
                        />
                      </div>
                    ) : (
                      <div className="tableContentBox" onScrollCapture={run}>
                        {pathsEmpty ? (
                          <div className="noData">
                            <MyEmpty></MyEmpty>
                          </div>
                        ) : (
                          <>
                            <Table
                              id="table"
                              ref={tableRef}
                              loading={
                                getLoading
                                  ? {
                                      indicator: (
                                        <ReactSVG
                                          src={LoadingSvg}
                                          className="anticon-spin anticon myLoadingIcon"
                                        />
                                      ),
                                    }
                                  : false
                              }
                              rowKey={(row) => row?.id || Math.random()}
                              scroll={{
                                y: `${
                                  size?.height -
                                  60 -
                                  312 -
                                  (total > 10 ? 45 : 0) +
                                  180
                                }px`,
                              }}
                              style={{ marginBottom: "15px" }}
                              className="myFormTable"
                              bordered={true}
                              locale={{
                                emptyText: <MyEmpty></MyEmpty>,
                              }}
                              // pagination={{
                              //   current: pageNumber,
                              //   pageSize: 10,
                              //   total: total,
                              //   showTotal: (totals) => `共${totals}条`,
                              //   onChange: (page) => setPageNumber(page),
                              //   showQuickJumper: false,
                              //   showSizeChanger: false,
                              // }}
                              pagination={false}
                              columns={initColumns2()}
                              dataSource={pathData}
                            />
                            {total > 10 && (
                              <div className="paginationBox">
                                <Pagination
                                  disabled={getLoading}
                                  className="myPagination"
                                  showQuickJumper={true}
                                  current={pageNumber}
                                  total={total}
                                  pageSize={pageSize}
                                  pageSizeOptions={[10, 20, 50]}
                                  onChange={onPageChange}
                                  showTotal={(total) => `共 ${total} 条`}
                                  showSizeChanger={true}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </Scrollbars>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </Scrollbars>
      </div>
      <Modal
        centered
        footer={null}
        maskClosable={false}
        title="关系图谱"
        visible={isModalVisible}
        wrapClassName="myModal chartModal"
        onCancel={() => {
          setIsModalVisible(false);
        }}
        destroyOnClose={true}
        width={"80vw"}
      >
        <div className="content">
          <Chart chartData={chartData} checkDate={checkDate}></Chart>
        </div>
      </Modal>

      <DialogDrawer
        okLoading={okLoading}
        title="添加到关联方"
        okText="确定"
        cancelText="取消"
        formList={formList}
        onOk={success}
        visible={drawerVisible}
        typeName="关联方管理"
        onCancel={() => {
          setDrawerVisible(false);
        }}
      ></DialogDrawer>

      <DetailsDrawer
        is_suspect_record={true}
        title={"详情"}
        visible={detailsDrawerVisible}
        dataTable={dataTable}
        item={choiceItem}
        typeName={"中标列表"}
        onCancel={() => {
          setDetailsDrawerVisible(false);
        }}
      ></DetailsDrawer>
    </div>
  );
};
export default RecordDetails;
