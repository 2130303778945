import "./index.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef, useMemo } from "react";
import MySpin from "../../../../../components/MySpin";
import {
  Table,
  message,
  Modal,
  Tooltip,
  Button,
  Tag,
  Input,
  Drawer,
  Form,
  Radio,
  Upload,
  Typography,
  Popover,
  Divider,
  Select,
} from "antd";
import BidWinningSituation from "./components/BidWinningSituation";
import TypeComparison from "./components/TypeComparison";
import ViscosityAnalysis from "./components/ViscosityAnalysis";
import projectApi from "../../../../../apis/project";
import {
  PlusCircleFilled,
  VerticalAlignTopOutlined,
  SearchOutlined,
  LoadingOutlined,
  CloseOutlined,
  CaretDownOutlined,
  DeleteOutlined,
  EyeFilled,
  PayCircleFilled,
  MinusCircleOutlined,
  PlusCircleOutlined,
  LinkOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageModal from "../../../../../components/MessageModal";
import {
  addMillimeter,
  addMillimeterAndInit,
  getLicenseValid,
  saveFile,
} from "../../../../../unit/unit";
import { useGetState, useUnmount, useDebounceFn } from "ahooks";
import MyEmpty from "../../../../../components/Empty";
import deleteIcon from "../../../../../assets/delete2.svg";
import { ReactSVG } from "react-svg";
import UploadFileDrawer from "../../../../../components/UploadFileDrawer";
import { useSelector } from "react-redux";
import comSvg from "../../../../../assets/公司列表.svg";
import CompanyCard from "../CompanyCard";
import HeaderTitle from "../../../../../components/HeaderTitle";
import svg1 from "../../../../../assets/降序.svg";
import svg2 from "../../../../../assets/生序.svg";
import svg3 from "../../../../../assets/字母降序.svg";
import svg4 from "../../../../../assets/字母生序.svg";
import SummaryAssembly from "./components/SummaryAssembly";
import icon1 from "../../../../../assets/中标2.svg";
import xlsUrl3 from "../../../../../assets//招投标竞争对手提交模板.xlsx";
import upIcon from "../../../../../assets/上传.svg";
import deleteSvg from "../../../../../assets/清空.svg";
import downloadSvg from "../../../../../assets/下载1.svg";
import pinyin from "pinyin";
import debounce from "lodash/debounce";
import DownLoadIcon from "../../../../../components/Icons/DownLoadIcon";
import { isArray } from "lodash";

const { Paragraph } = Typography;
const { Dragger } = Upload;
const { Option } = Select;

const DebounceSelect = ({
  fetchOptions,
  selectChange,
  debounceTimeout = 800,
  changeClear = false,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const [searchValue, setSearchValue] = useState("");

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const valueChange = (info) => {
    const [id, name] = info?.value?.split("&&&&&&&") || [];
    const item = options.find(
      (_) => _.company_id === Number(id) && _.name === name
    );
    props.onChange && props.onChange(item);
    selectChange && selectChange(item);
    if (changeClear) {
      setOptions([]);
    }
  };
  const showCode = (row) => {
    if (row.credit_code) {
      return (
        "统一信用号:" +
        row.credit_code +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.org_number) {
      return (
        "统一信用号:" +
        row.org_number +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.reg_number) {
      return (
        "统一信用号:" +
        row.reg_number +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.legal_person_name) {
      return "法人:" + (row.legal_person_name ? row.legal_person_name : "");
    } else {
      return "无详细信息";
    }
  };

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={(value) => {
        debounceFetcher(value);
      }}
      notFoundContent={fetching ? <MySpin /> : null}
      {...props}
      onChange={valueChange}
      onBlur={() => {
        setOptions([]);
      }}
    >
      {options.map((item) => (
        <Option
          value={item.company_id + "&&&&&&&" + item.name}
          key={item.company_id + item.name}
          className="debounceSelectItem"
        >
          <div className="addComp">
            <a className="name" title={item.label}>
              {item.label}
            </a>
            <a className="info" title={showCode(item)}>
              {showCode(item)}
            </a>
          </div>
        </Option>
      ))}
    </Select>
  );
};

const CompetitivenessAnalysis = (props) => {
  const { searchTableFunc, updateTimeCallBack } = props;
  const { id } = useParams();
  const [updateTime, setUpdateTime] = useState(null);
  const [competitiveHitData, setCompetitiveHitData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [downLoading2, setDownLoading2] = useState(false);
  const [okLoading, setOkLoading] = useState(false);

  const [bidCompanyName, setBidCompanyName] = useState(null);

  const [data, setData] = useState({});
  const [drawerTitle, setDrawerTitle] = useState(null);
  const [defaultData, setDefaultData] = useState(null);
  const [formList, setFormList] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const ref = useRef();
  const [fileVisible, setFileVisible] = useState(false);
  const [upLoading, setUpLoading] = useState(false);
  const [upData, setUpData] = useState({});
  const [visible, setVisible] = useState(false);
  const [isDown, setIsDown, getIsDown] = useGetState(false);
  const [checkCompanyList, setCheckCompanyList, getCheckCompanyList] =
    useGetState([]);
  const [searchValue, setSearchValue, getSearchValue] = useGetState(null);

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [form] = Form.useForm();

  const [addType, setAddType, getAddType] = useGetState(1);

  const [
    eventCompanyOptionsList,
    setEventCompanyOptionsList,
    getEventCompanyOptionsList,
  ] = useGetState([]);

  const [addLoading, setAddLoading] = useState(true);
  const [companyList, setCompanyList, getCompanyList] = useGetState([]);
  const [oldCompanyList, setOldCompanyList, getOldCompanyList] = useGetState(
    []
  );

  const [showCompanyList, setShowCompanyList] = useState([]);
  const [chartsShowDate, setChartsShowDate] = useState({});

  const [companyLoading, setCompanyLoading] = useState(false);
  const [showDataPageNumber, setShowDataPageNumber, getShowDataPageNumber] =
    useGetState(1);
  const [showDataPageSize, setShowDataPageSize, getShowDataPageSize] =
    useGetState(50);
  const [dataTotal, setDataTotal, getDataTotal] = useGetState(0);

  const [fileList, setFileList] = useState([]);

  const [sortKey, setSortKey, getSortKey] = useGetState("中标金额");
  const [sortOrder, setSortOrder, getSortOrder] = useGetState("decline");
  const sortIconRef = useRef({
    中标金额: {
      rise: <ReactSVG className="sortReactSvg" src={svg1}></ReactSVG>,
      decline: <ReactSVG className="sortReactSvg" src={svg2}></ReactSVG>,
    },
    中标项目数: {
      rise: <ReactSVG className="sortReactSvg" src={svg1}></ReactSVG>,
      decline: <ReactSVG className="sortReactSvg" src={svg2}></ReactSVG>,
    },
    公司名称: {
      rise: <ReactSVG className="sortReactSvg" src={svg3}></ReactSVG>,
      decline: <ReactSVG className="sortReactSvg" src={svg4}></ReactSVG>,
    },
  });

  const [
    addCompetitorCompany,
    setAddCompetitorCompany,
    getAddCompetitorCompany,
  ] = useGetState(null);

  const [
    addCompetitorCompanyList,
    setAddCompetitorCompanyList,
    getAddCompetitorCompanyList,
  ] = useGetState([]);

  const { run } = useDebounceFn(
    (e) => {
      setSearchValue(e.target.value);
      searchValueChangeToCheck(e.target.value);
    },
    {
      wait: 300,
    }
  );

  useEffect(() => {
    console.log("checkCompanyList", checkCompanyList);
    initChartsShowDateFunc();
  }, [checkCompanyList]);

  const initChartsShowDateFunc = () => {
    let list = {};
    list.competitive_history_bid_count = (
      data?.competitive_history_bid_count || []
    )?.filter((item) => getCheckCompanyList()?.includes(item?.bid_winner));

    list.competitive_bid_info_type_count = (
      data?.competitive_bid_info_type_count || []
    )?.filter((item) => getCheckCompanyList()?.includes(item?.bid_winner));

    list.competitive_tendree_bid_count = (
      data?.competitive_tendree_bid_count || []
    )?.filter((item) => getCheckCompanyList()?.includes(item?.bid_winner));

    setChartsShowDate(list);
  };

  //暂时记录 竞争对手变更
  const temporaryChangeCompetitorListFunc = (companyInfo, type, handleType) => {
    let list = getCompanyList();

    if (type === 1) {
      if (list?.some((_) => _.bid_winner === companyInfo?.name)) {
        return;
      }
      list = [
        ...list,
        {
          bid_winner: companyInfo?.name,
          competitive_amount: 0,
          competitive_count: 0,
          id: companyInfo?.company_id,
        },
      ];
    } else if (type === 2) {
      if (handleType === "add") {
        if(isArray(companyInfo)){
          companyInfo.forEach(_companyInfo=>{
            if (list?.some((_) => _.bid_winner === _companyInfo?.bid_winner)) {
              return;
            }
            list = [
              ...list,
              {
                bid_winner: _companyInfo?.bid_winner,
                competitive_amount: _companyInfo?.amount,
                competitive_count: _companyInfo?.count,
                id: _companyInfo?.winner_id,
              },
            ];
          })
        }else{
          if (list?.some((_) => _.bid_winner === companyInfo?.bid_winner)) {
            return;
          }
          list = [
            ...list,
            {
              bid_winner: companyInfo?.bid_winner,
              competitive_amount: companyInfo?.amount,
              competitive_count: companyInfo?.count,
              id: companyInfo?.winner_id,
            },
          ];
        }
        
      } else if (handleType === "delete") {
        list = list?.filter((_) => _.bid_winner !== companyInfo?.bid_winner);
      }
    }

    let _list = getEventCompanyOptionsList();
    _list = _list?.map((_) => {
      let has = false;
      if (list?.some((__) => __?.bid_winner === _?.bid_winner)) {
        has = true;
      }
      return {
        ..._,
        has,
      };
    });

    setEventCompanyOptionsList(JSON.parse(JSON.stringify(_list)) || []);

    initCompanyDataAndSort(list);
  };

  //添加弹窗关闭后 更新竞争对手列表
  const updateCompetitorCompany = () => {
    let list = getCompanyList();
    let oldList = getOldCompanyList();
    let obj = {};

    list.forEach((_) => {
      obj[_.bid_winner] = 1;
    });

    oldList.forEach((_) => {
      if (obj[_.bid_winner]) {
        delete obj[_.bid_winner];
      } else {
        obj[_.bid_winner] = 2;
      }
    });

    let addList = [];
    let deleteList = [];

    Object.keys(obj).forEach((key) => {
      if (obj[key] === 1) {
        addList.push(key);
      } else if (obj[key] === 2) {
        deleteList.push(key);
      }
    });

    let apiList = [];

    if (addList.length > 0) {
      let api1 = projectApi.addBiddingCompetitiveness({
        project_id: id,
        bid_winner: addList?.join(","),
      });
      apiList.push(api1);
    }

    if (deleteList?.length > 0) {
      deleteList.forEach((_) => {
        let deleteItem = oldList?.find((__) => __.bid_winner === _);
        if (deleteItem) {
          apiList.push(
            projectApi.deleteBiddingCompetitiveness(deleteItem.id, id)
          );
        }
      });
    }

    if (apiList?.length > 0) {
      setLoading(true);
      Promise.all(apiList)
        .then((res) => {
          getBiddingCompetitivenessStatus(true);
        })
        .catch((sea) => {
          setLoading(false);
        });
    }
  };

  //公司筛选
  const searchValueChangeToCheck = (value) => {
    let list = [];
    if (value) {
      list = companyList.filter((_) => _.bid_winner?.includes(value));
    } else {
      list = companyList;
    }
    setShowDataPageNumber(1);
    setDataTotal(list?.length);
    setShowCompanyList(list?.slice(0, 1 * getShowDataPageSize()));
  };

  //清空排序规则
  const clearSortingRules = () => {
    setSortKey("中标金额");
    setSortOrder("decline");
  };

  //排序规则
  const sortingRules = (itemA, itemB) => {
    const sortKey = getSortKey();
    const sortOrder = getSortOrder(); // -1 小到大 生序 1 大到小 降序

    const getValue = (item, key) => {
      switch (key) {
        case "中标金额":
          return item?.competitive_amount;
        case "中标项目数":
          return item?.competitive_count;
        case "公司名称":
          return pinyin(item?.bid_winner, {
            style: pinyin.STYLE_NORMAL,
            segment: true
          }).join("");
        default:
          return 0;
      }
    };

    const compareValues = (valueA, valueB, isText) => {
      if (isText) {
        return sortOrder === "rise"
          ? valueB.localeCompare(valueA, "zh-CN")
          : valueA.localeCompare(valueB, "zh-CN");
      }
      return sortOrder === "rise" ? valueA - valueB : valueB - valueA;
    };

    const resolveTie = (itemA, itemB, keys) => {
      for (let key of keys) {
        let valueA = getValue(itemA, key);
        let valueB = getValue(itemB, key);
        let isText = key === "公司名称";
        if (valueA !== valueB) {
          return compareValues(valueA, valueB, isText);
        }
      }
      return 0;
    };

    let valueA = getValue(itemA, sortKey);
    let valueB = getValue(itemB, sortKey);

    console.log(valueA,valueB);
    
    let isText = sortKey === "公司名称";

    if (valueA === valueB) {
      const tieBreakerKeys = {
        中标金额: ["中标项目数", "公司名称"],
        中标项目数: ["中标金额", "公司名称"],
        公司名称: ["中标金额", "中标项目数"],
      };
      return resolveTie(itemA, itemB, tieBreakerKeys[sortKey]);
    }

    return compareValues(valueA, valueB, isText);
  };

  //更新公司列表数据并排序
  const initCompanyDataAndSort = (companyList) => {
    let dueDiligenceCompanyItem = companyList?.find(
      (_) => _.bid_winner === projectInfo.company_name
    );

    let list = companyList
      ?.filter((_) => _.bid_winner !== projectInfo.company_name)
      ?.sort((a, b) => {
        return sortingRules(a, b);
      });
    list = [dueDiligenceCompanyItem, ...list];

    // let list = companyList?.sort((a, b) => {
    //   return sortingRules(a, b);
    // });
    console.log("排列顺序后的数据", list);

    setCompanyList(list);
    setShowDataPageNumber(1);
    setDataTotal(list?.length);
    setShowCompanyList(list?.slice(0, 1 * getShowDataPageSize()));
    setCheckCompanyList(list?.map((_) => _?.bid_winner));
  };

  const getBiddingCompetitivenessAnalysis = () => {
    setLoading(true);
    let params = {
      project_id: id,
      page: 1,
      page_size: 10000,
    };
    projectApi.getBiddingCompetitivenessAnalysis(params).then((res) => {
      console.log("竞争力分析:-------", res);
      if (res.code === 200) {
        let _list = res.data?.competitive_data || [];
        initCompanyDataAndSort(_list);
        setOldCompanyList(JSON.parse(JSON.stringify(_list)));
        setBidCompanyName(
          (
            (res.data.competitive_data || [])?.find((_) => _.is_cdd_company) ||
            {}
          )?.bid_winner
        );
        setData(res.data);
        setLoading(false);
        setUpdateTime(res.data.data_update_time);
        // if (getCheckCompanyList()?.length === 0) {
        //   setCheckCompanyList(
        //     res.data.competitive_data?.slice(0, 5)?.map((_) => _.bid_winner)
        //   );
        // }
        initChartsShowDateFunc();
      } else {
        message.error(res.message);
        setCompetitiveHitData([]);
        setOldCompanyList([]);
        setCompanyList([]);
        setShowCompanyList([]);
        setBidCompanyName(null);
        setData({});
        setChartsShowDate({});
        setBidCompanyName(null);
        setUpdateTime(null);
        setLoading(false);
      }
    });
  };

  //加载更多
  const loadMoreData = () => {
    console.log("----------loadMoreData", getShowDataPageNumber());
    let pageNumber = getShowDataPageNumber() + 1;
    setShowDataPageNumber(pageNumber);
    setShowCompanyList(
      companyList?.slice(0, pageNumber * getShowDataPageSize())
    );
  };

  useEffect(() => {
    let updateTimeHtml = (
      <div style={{ color: "#9E9E9E" }}>
        更新时间：{updateTime || "-"}
        {getIsDown() && (
          <Tag color="#0068B2" style={{ marginLeft: "10px" }}>
            中标数据正在获取中
          </Tag>
        )}
      </div>
    );
    updateTimeCallBack && updateTimeCallBack(updateTimeHtml);
  }, [updateTime, isDown]);

  const timer = useRef(null);

  useUnmount(() => {
    clearInterval(timer.current);
  });

  useEffect(() => {
    getBiddingCompetitivenessStatus(true);
    timer.current = setInterval(() => {
      getBiddingCompetitivenessStatus();
    }, 5000);
    // getBiddingCompetitivenessAnalysis();
  }, []);

  const getOptions = () => {
    setAddLoading(true);
    setEventCompanyOptionsList([]);
    projectApi?.getBiddingCompetitiveness(id).then((res) => {
      let list = res?.data?.data;
      list = list?.map((_) => {
        let _list = getCompanyList();
        let has = false;
        if (_list?.some((__) => __?.bid_winner === _?.bid_winner)) {
          has = true;
        }
        return {
          ..._,
          has,
        };
      });
      setEventCompanyOptionsList(list || []);
      setAddLoading(false);
    });
  };

  //获取 数据获取状态
  const getBiddingCompetitivenessStatus = (first) => {
    projectApi.getBiddingCompetitivenessStatus(id).then((res) => {
      console.log("查询状态:", res.data?.complete_flag);
      if (res.code === 200) {
        if (!getIsDown() && res.data?.complete_flag === 0) {
          message.info("竞争对手已添加，准备获取中标数据");
        }
        if ((res.data?.complete_flag === 1 && getIsDown() === true) || first) {
          getBiddingCompetitivenessAnalysis();
        }
        if (res.data?.complete_flag === 1 && getIsDown() === true) {
          message.success("中标数据获取完成，更新分析数据");
        }
        if (res.data?.complete_flag === 0) {
          setIsDown(true);
        } else {
          setIsDown(false);
        }
      } else {
        setIsDown(false);
        message.error(res.message);
      }
    });
  };

  const deleteCompetitorsAll = () => {
    MessageModal({
      type: "warning",
      description: "确定要删除所有竞争对手吗？",
      title: "删除",
      onOk: () => {
        projectApi.deleteBiddingCompetitivenessAll(id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getBiddingCompetitivenessStatus(true);
            setCheckCompanyList([]);
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const deleteCompetitors = (item) => {
    MessageModal({
      type: "warning",
      description: `确定要删除竞争对手"${item.bid_winner}"吗？`,
      title: "删除",
      onOk: () => {
        projectApi.deleteBiddingCompetitiveness(item.id, id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setCheckCompanyList((prev) => {
              prev = prev?.filter((_) => _ !== item?.bid_winner);
              return [...prev];
            });
            setTimeout(() => {
              getBiddingCompetitivenessAnalysis();
            }, 100);
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const addCompetitors = () => {
    getLicenseValid().then((res) => {
      if (res) {
        setDrawerTitle("新增竞争对手");
        getOptions();
        setDrawerVisible(true);
        setTimeout(() => {
          setAddType(1);
        }, 100);
      }
    });
  };

  async function fetchComList2(username) {
    if (!username || username?.length < 2) {
      return [];
    }
    return projectApi.getCompanyAndPerson(username, id).then((res) => {
      if (res.code !== 200) {
        message.error(res.message);
        return [];
      }
      return res.data.map((item) => ({
        ...item,
        label: item.name,
        value: item.person_id || item.company_id,
      }));
    });
  }

  const success = (obj) => {
    setOkLoading(true);
    projectApi
      .addBiddingCompetitiveness({
        project_id: id,
        bid_winner: obj.bid_winner,
      })
      .then((res) => {
        if (res.code === 200) {
          setDrawerVisible(false);
          // getBiddingCompetitivenessAnalysis();
          getBiddingCompetitivenessStatus(true);
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        setOkLoading(false);
      });
  };

  const columns = useRef([
    {
      key: "序号",
      title: "序号",
      dataIndex: "序号",
      align: "center",
      width: "80px",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      key: "bid_winner",
      title: "竞争对手",
      dataIndex: "bid_winner",
      align: "center",
      render: (text, record, index) => (
        <>
          {text}
          {getIsDown() ? (
            <span style={{ color: "#9e9e9e" }}>（数据获取中）</span>
          ) : (
            <span style={{ color: "#0068B2" }}>
              （{record?.competitive_count}）
            </span>
          )}
        </>
      ),
    },
    {
      key: "操作",
      title: "操作",
      dataIndex: "操作",
      align: "center",
      width: "80px",
      render: (text, record, index) => (
        <Tooltip placement="top" title="删除" arrowPointAtCenter>
          <ReactSVG
            onClick={(e) => {
              e.stopPropagation();
              deleteCompetitors(record);
            }}
            className="deleteIcon hoverIcon"
            src={deleteIcon}
          ></ReactSVG>
        </Tooltip>
      ),
    },
  ]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "竞争对手列表",
        is_bid: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  //上传成功回调
  const uploadFileSuccess = (files) => {
    setUpLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file[]", file?.originFileObj);
    });
    projectApi.checkUploadBiddingCompetitiveness(id, formData).then((res) => {
      if (res.code === 200) {
        setUpData(res.data);
        setDrawerVisible(false);
        setFileList([]);
        setVisible(true);
      } else {
        message.error(res.message);
      }
      setUpLoading(false);
    });
  };

  const uploadSuccess = () => {
    setLoading(true);
    projectApi
      .upBiddingCompetitiveness(
        id,
        JSON.stringify(upData?.success_data || []),
        upData?.file_name
      )
      .then((res) => {
        if (res.code === 200) {
          setVisible(false);
          // getBiddingCompetitivenessAnalysis();
          getBiddingCompetitivenessStatus(true);
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        setLoading(false);
      });
    // onOk();
  };

  const formHandle = () => {
    let value = null;
    if (getAddType() === 1) {
      value = form.getFieldValue("bid_winner");
    } else if (getAddType() === 2) {
      value = form.getFieldValue("bid_winners");
      if (value?.length === 0) {
        value = null;
      }
    }
    if (!value) {
      form.setFields([
        {
          name: "bid_winner",
          errors: getAddType() === 1 ? ["不能为空"] : [],
          // 如果需要重置字段的错误信息，可以将errors设置为[]
        },
        {
          name: "bid_winners",
          errors: getAddType() === 2 ? ["不能为空"] : [],
          // 如果需要重置字段的错误信息，可以将errors设置为[]
        },
      ]);
      return;
    }
    if (getAddType() === 1) {
      success({
        bid_winner: value?.name,
      });
    } else if (getAddType() === 2) {
      let nameStr = (value || [])
        ?.map((_) => _?.split("&&&&&")?.at(0))
        ?.join(",");
      success({
        bid_winner: nameStr,
      });
    }
  };

  const columns2 = useRef([
    {
      title: "行号",
      dataIndex: "idx",
      key: "idx",
      width: 70,
    },
    {
      title: "竞争对手",
      dataIndex: "company",
      key: "company",
      width: 240,
    },
    {
      title: "校验结果",
      key: "fail_msg",
      render: (_, record) => (
        <div style={{ color: "red" }}>{record.fail_msg}</div>
      ),
    },
  ]);

  const columns3 = useRef([
    {
      title: "行号",
      dataIndex: "idx",
      key: "idx",
      render: (_, record) => {
        return (
          <div className="addCompetitorFormTableCellItem">
            <div className="titleBox">{record?.bid_winner}</div>
            <div className="tagBox">
              <Tag>竞争对手</Tag>
            </div>
          </div>
        );
      },
    },
    {
      title: "竞争对手",
      dataIndex: "company",
      key: "company",
      width: 210,
      render: (_, record) => (
        <div className="addCompetitorFormTableCellItem">
          <div style={{ height: "22px" }}></div>
          <div className="iconTextBox">
            <Tooltip placement="top" title="项目数" arrowPointAtCenter>
              <ReactSVG className="selectItemIcon" src={icon1}></ReactSVG>
            </Tooltip>
            <div className="text">{record?.count}</div>
            <Tooltip placement="top" title="金额" arrowPointAtCenter>
              <PayCircleFilled style={{ margin: "0 5px 0  10px" }} />
            </Tooltip>
            <div className="text">
              {addMillimeter(
                parseFloat(
                  record?.amount < 10000
                    ? record?.amount
                    : record?.amount / 10000
                )?.toFixed(2)
              )}
              <span>{record?.amount < 10000 ? "元" : "万元"}</span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "校验结果",
      key: "hsa",
      width: 24,
      render: (_, record) => {
        return (
          <div className="addCompetitorFormTableCellItem">
            {record?.has ? (
              <MinusCircleOutlined
                style={{ color: "#9E9E9E", cursor: "pointer" }}
                onClick={() => {
                  temporaryChangeCompetitorListFunc(record, 2, "delete");
                }}
              />
            ) : (
              <PlusCircleOutlined
                style={{ color: "#0068B2", cursor: "pointer" }}
                onClick={() => {
                  temporaryChangeCompetitorListFunc(record, 2, "add");
                }}
              />
            )}
          </div>
        );
      },
    },
  ]);

  const downloadTemplate = () => {
    let link = document.createElement("a");
    link.style.display = "none";
    let title = "";
    link.href = xlsUrl3;
    title = "招投标竞争对手提交模板.xlsx";
    link.setAttribute("download", title);
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const fileChange = (info) => {
    if (info.file?.size / 1048576 > 10) {
      message.info("请上传10MB以下的文件");
      return;
    }

    setFileList((prev) => {
      if (props.multiple) {
        prev.push(info.file);
      } else {
        prev = [info.file];
      }
      return [...prev];
    });
  };

  const fileUpFinish = () => {
    if (fileList.length === 0) {
      message.info("请至少上传一个文件");
      return;
    }
    uploadFileSuccess(fileList);
  };

  if (!projectInfo?.project_permission_urls.includes("CDD_PRJ_CDD_ITEM_EDIT")) {
    columns.current = columns.current?.filter((_) => _.key !== "操作");
  }

  return loading ? (
    <MySpin></MySpin>
  ) : (
    <div className="competitivenessAnalysisContent">
      {/* <div className="dataInput">
        更新时间：{updateTime || "-"}
        {getIsDown() && (
          <Tag color="#0068B2" style={{ marginLeft: "10px" }}>
            中标数据正在获取中
          </Tag>
        )}
      </div> */}

      <div className="chartsBox">
        <div className="chartsLeftBox">
          {/* 竞争对手列表 */}
          <div className="chartBox">
            <div className="headerTitleBox">
              {/* <div className="imgTitleBox">
                <img src={comSvg}></img> 公司列表
              </div> */}
              <HeaderTitle
                title={
                  <>
                    公司列表
                    {/* <span
                      style={{
                        color: "#535353",
                        fontSize: "12px",
                        marginLeft: "8px",
                        fontWeight: "400",
                      }}
                    >
                      ({companyList?.length}/20)
                    </span> */}
                  </>
                }
                showTip={false}
              ></HeaderTitle>
            </div>
            <div className="tableContentBox">
              {/* {companyList?.length > 0 && (
                <Input
                  placeholder="输入公司名称搜索"
                  onChange={run}
                  prefix={<SearchOutlined />}
                  style={{
                    width: "calc(100% - 32px)",
                    borderRadius: "18px",
                    margin: "0 16px 10px 16px",
                  }}
                ></Input>
              )} */}
              <div className="sortContentBox">
                <Popover
                  title={null}
                  overlayClassName="sortPopover"
                  placement="bottomLeft"
                  content={
                    <div className="sortPopoverContentBox">
                      {[
                        {
                          label: "中标金额",
                          order: "decline",
                        },
                        {
                          label: "中标金额",
                          order: "rise",
                        },
                        {
                          label: "中标项目数",
                          order: "decline",
                        },
                        {
                          label: "中标项目数",
                          order: "rise",
                        },
                        {
                          label: "公司名称",
                          order: "decline",
                        },
                        {
                          label: "公司名称",
                          order: "rise",
                        },
                      ]?.map((_) => {
                        return (
                          <div
                            className={
                              getSortKey() === _.label &&
                              getSortOrder() === _.order
                                ? "sortPopoverItem sortPopoverActiveItem"
                                : "sortPopoverItem"
                            }
                            onClick={() => {
                              //排序
                              if (
                                getSortKey() === _.label &&
                                getSortOrder() === _.order
                              ) {
                                return;
                              }
                              //切换排序
                              setSortKey(_.label);
                              setSortOrder(_.order);
                              setTimeout(() => {
                                initCompanyDataAndSort(getCompanyList());
                              }, 100);
                            }}
                          >
                            {_.label}
                            {(sortIconRef.current[_.label] || {})[_.order]}
                          </div>
                        );
                      })}
                    </div>
                  }
                >
                  <div className="sortItem">
                    排序
                    <CaretDownOutlined />
                    <span className="sortItemText">
                      {sortKey}
                      {(sortIconRef.current[sortKey] || {})[sortOrder]}
                    </span>
                  </div>
                </Popover>
                <div className="handleIconBox">
                  {projectInfo?.project_permission_urls.includes(
                    "CDD_PRJ_CDD_ITEM_EDIT"
                  ) && (
                    <Tooltip
                      placement="top"
                      title="请先添加竞争对手"
                      arrowPointAtCenter
                      open={getCompanyList()?.length === 1}
                    >
                      <div
                        className="searchItemBox"
                        onClick={() => {
                          addCompetitors();
                        }}
                      >
                        <PlusCircleFilled />
                        添加
                      </div>
                    </Tooltip>
                  )}
                  <div
                    className="searchItemBox"
                    onClick={() => {
                      if (
                        getCompanyList()?.length ===
                        getCheckCompanyList()?.length
                      ) {
                        setCheckCompanyList([projectInfo?.company_name]);
                      } else {
                        setCheckCompanyList(
                          getCompanyList()?.map((_) => _.bid_winner)
                        );
                      }
                    }}
                  >
                    <EyeFilled />
                    显示
                  </div>
                  {/* {projectInfo?.project_permission_urls.includes(
                    "CDD_PRJ_CDD_ITEM_EDIT"
                  ) && (
                    <div
                      className="searchItemBox"
                      onClick={() => {
                        getLicenseValid().then((res) => {
                          if (res) {
                            setFileVisible(true);
                          }
                        });
                      }}
                    >
                      <VerticalAlignTopOutlined />
                      上传
                    </div>
                  )} */}
                  {projectInfo?.project_permission_urls.includes(
                    "CDD_PRJ_CDD_ITEM_EXPORT"
                  ) && (
                    <div
                      className="searchItemBox"
                      onClick={() => {
                        if (companyList?.length === 0) {
                          return;
                        }
                        downloadFile();
                      }}
                    >
                      {downLoading ? (
                        <LoadingOutlined></LoadingOutlined>
                      ) : (
                        <DownLoadIcon
                          className="anticon"
                          src={downloadSvg}
                        ></DownLoadIcon>
                      )}
                      下载
                    </div>
                  )}
                  {projectInfo?.project_permission_urls.includes(
                    "CDD_PRJ_CDD_ITEM_EDIT"
                  ) && (
                    <div
                      className="searchItemBox"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteCompetitorsAll();
                      }}
                    >
                      {/* <DeleteOutlined /> */}
                      <ReactSVG className="anticon" src={deleteSvg}></ReactSVG>
                      清空
                    </div>
                  )}
                </div>
              </div>

              <div className="dividerBox">
                <span></span>
              </div>
              <div className="searchCompanyBox">
                <Input
                  placeholder="输入公司名称搜索"
                  onChange={run}
                  suffix={<SearchOutlined />}
                  // bordered={false}
                  allowClear
                  style={{
                    width: "250px",
                    margin: "10px 0px 0px 0px",
                    // borderBottom: "#d9d9d9 1px solid",
                    borderRadius: "4px",
                  }}
                ></Input>
              </div>
              <div id="checkBox" className="checkBox">
                {showCompanyList?.length !== 0 ? (
                  <InfiniteScroll
                    dataLength={showCompanyList.length}
                    next={loadMoreData}
                    loader={<Divider className="loadingBox">Loading</Divider>}
                    hasMore={showCompanyList.length < dataTotal}
                    scrollableTarget="checkBox"
                    // endMessage={<Divider className="noDataDivider"></Divider>}
                  >
                    {showCompanyList?.map((item, index) => (
                      <CompanyCard
                        key={item?.id}
                        info={item}
                        index={index}
                        dataType={"竞争对手分析"}
                        type="竞争对手"
                        deleteFunc={deleteCompetitors}
                        checkCompanyList={checkCompanyList}
                        dataList={companyList}
                        setCheckCompanyList={setCheckCompanyList}
                        notShowIndex={index === 0}
                        searchTableFunc={searchTableFunc}
                      ></CompanyCard>
                    ))}
                  </InfiniteScroll>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MyEmpty></MyEmpty>
                  </div>
                )}
              </div>
              {/* <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "100%", width: "100%", marginTop: "12px" }}
              >
                {companyList?.length > 0 ? (
                  <div style={{ width: "100%", height: "100%" }}>
                    {showCompanyList?.length !== 0 ? (
                      showCompanyList?.map((item, index) => (
                        <CompanyCard
                          key={item.id}
                          info={item}
                          index={index + 1}
                          type="竞争对手"
                          deleteFunc={deleteCompetitors}
                          checkCompanyList={checkCompanyList}
                          dataList={companyList}
                          setCheckCompanyList={setCheckCompanyList}
                        ></CompanyCard>
                      ))
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MyEmpty></MyEmpty>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{ padding: "0 16px", width: "100%", height: "100%" }}
                  >
                    {[1, 2, 3, 4, 5]?.map((item) => (
                      <CompanyCard
                        key={item}
                        index={item}
                        empty={true}
                      ></CompanyCard>
                    ))}
                  </div>
                )}
              </Scrollbars> */}
            </div>
          </div>
        </div>

        <div className="chartsRightBox">
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{ height: "100%", width: "100%" }}
          >
            {/* 概述 */}
            <div className="chartBox">
              <SummaryAssembly
                data={data}
                checkCompanyList={checkCompanyList}
                keyList={getCompanyList()?.length === 1 ? [] : companyList}
                searchTableFunc={searchTableFunc}
              ></SummaryAssembly>
            </div>

            {/* 竞争对手历年中标情况 */}
            <div className="chartBox">
              <BidWinningSituation
                data={chartsShowDate?.competitive_history_bid_count || []}
                bidCompanyName={bidCompanyName}
                keyList={getCompanyList()?.length === 1 ? [] : companyList}
                checkCompanyList={checkCompanyList?.slice(0, 11)}
                searchTableFunc={searchTableFunc}
              ></BidWinningSituation>
            </div>

            {/* 竞争对手项目类型对比分析 */}
            <div className="chartBox">
              <TypeComparison
                data={chartsShowDate?.competitive_bid_info_type_count || []}
                bidCompanyName={bidCompanyName}
                keyList={getCompanyList()?.length === 1 ? [] : companyList}
                checkCompanyList={checkCompanyList?.slice(0, 11)}
                searchTableFunc={searchTableFunc}
              ></TypeComparison>
            </div>

            {/* 竞争对手客户粘度对比分析 */}
            <div className="chartBox">
              <ViscosityAnalysis
                data={chartsShowDate?.competitive_tendree_bid_count || []}
                bidCompanyName={bidCompanyName}
                keyList={getCompanyList()?.length === 1 ? [] : companyList}
                checkCompanyList={checkCompanyList?.slice(0, 11)}
                searchTableFunc={searchTableFunc}
              ></ViscosityAnalysis>
            </div>
          </Scrollbars>
        </div>
      </div>

      <Drawer
        title="新增竞争对手"
        placement="right"
        width="600px"
        className="dialogDrawer"
        onClose={() => {
          setDrawerVisible(false);
          setAddCompetitorCompany(null);
          updateCompetitorCompany();
          setFileList([]);
        }}
        visible={drawerVisible}
        push={false}
        closable={false}
        destroyOnClose={true}
        maskClosable={true}
        keyboard={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              setDrawerVisible(false);
              setAddCompetitorCompany(null);
              updateCompetitorCompany();
              setFileList([]);
            }}
          />
        }
        footer={
          addType === 3 ? (
            <div className="drawerFooterBtnBox">
              <Button
                type="primary"
                onClick={() => {
                  // formHandle();
                  fileUpFinish();
                }}
                loading={upLoading}
              >
                上传
              </Button>
              <Button
                className="noBg"
                onClick={() => {
                  setDrawerVisible(false);
                  setFileList([]);
                  setAddCompetitorCompany(null);
                  // updateCompetitorCompany();
                }}
              >
                取消
              </Button>
            </div>
          ) : null
        }
      >
        <div className="addCompetitorForm">
          <div>
            <span className="dialogDrawerFormItemRequireSpan">*</span>添加方式
          </div>
          <Radio.Group
            onChange={(e) => {
              setAddType(e.target.value);
              setAddCompetitorCompany(null);
            }}
            value={addType}
          >
            <Radio value={1}>手工输入</Radio>
            <Radio value={2}>从事件中选择</Radio>
            <Radio value={3}>上传文件</Radio>
          </Radio.Group>

          {addType === 1 && (
            <DebounceSelect
              showSearch={true}
              placeholder="请输入公司名称搜索"
              fetchOptions={fetchComList2}
              changeClear={true}
              allowClear
              style={{
                width: "100%",
              }}
              value={addCompetitorCompany}
              selectChange={(a) => {
                setAddCompetitorCompany(null);
                if (a) {
                  message.success("添加成功");
                  temporaryChangeCompetitorListFunc(a, 1);
                }
              }}
              disabled={addType !== 1}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
            />
          )}

          {addType === 2 && (
            <>
              <div className="addAllDiv">
                <span
                  onClick={() => {
                    console.log(getEventCompanyOptionsList());

                    let list = getEventCompanyOptionsList()?.filter(
                      (_) => !_.has
                    );
                    temporaryChangeCompetitorListFunc(list, 2, "add");
                  }}
                >
                  全部添加
                  <PlusCircleOutlined style={{ marginLeft: "5px" }} />
                </span>
              </div>
              <Table
                loading={addLoading}
                className="addCompetitorTable"
                showHeader={false}
                locale={{
                  emptyText: <MyEmpty></MyEmpty>,
                }}
                columns={columns3.current}
                dataSource={getEventCompanyOptionsList()}
              />
            </>
          )}

          {addType === 3 && (
            <div className="fileUpDiv">
              {upLoading ? (
                <div
                  style={{ width: "100%", height: "100%", minHeight: "300px" }}
                >
                  <MySpin tip="文件解析中"></MySpin>
                </div>
              ) : (
                <div className="uploadFileDrawerContent">
                  <div className="downloadTemplate">
                    <div></div>
                    <div className="iconBox" onClick={() => downloadTemplate()}>
                      <DownLoadIcon></DownLoadIcon>
                      <span className="text">下载导入模板</span>
                    </div>
                  </div>

                  <Dragger
                    className="draggerBox"
                    fileList={[]}
                    name="file"
                    multiple={props.multiple}
                    action=""
                    onChange={fileChange}
                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  >
                    <div className="uploadBox">
                      {/* <CloudFilled /> */}
                      <ReactSVG className="uploadSvg" src={upIcon}></ReactSVG>
                      <div className="text">
                        将文件拖拽到此处或<span>点击上传</span>
                      </div>
                    </div>
                  </Dragger>

                  <div className="fileTip">
                    （只能上传xls/xlsx文件，且不超过10MB）
                  </div>

                  {fileList.map((file, index) => (
                    <div className="fileLineItem" key={Math.random()}>
                      <div className="fileItem">
                        <LinkOutlined />
                        <Paragraph
                          ellipsis={{
                            rows: 1,
                            tooltip: file.name,
                            expandable: false,
                          }}
                          style={{
                            width: `520px`,
                            color: "#313131",
                          }}
                        >
                          {file.name}
                        </Paragraph>
                      </div>
                      <CloseCircleOutlined
                        className="closeIcon"
                        onClick={() => {
                          setFileList((prev) => {
                            prev = prev.filter((_) => {
                              return _.uid !== file.uid;
                            });
                            return [...prev];
                          });
                        }}
                      ></CloseCircleOutlined>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </Drawer>

      <UploadFileDrawer
        loading={upLoading}
        visible={fileVisible}
        onClose={() => setFileVisible(false)}
        successFunc={uploadFileSuccess}
        title="上传竞争对手"
        multiple={true}
      ></UploadFileDrawer>

      <Modal
        title="上传数据校验"
        wrapClassName="uploadModal"
        visible={visible}
        width={900}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setVisible(false);
          // getBiddingCompetitivenessAnalysis();
        }}
        centered
      >
        <div className="uploadFileModalContent">
          <div className="uploadInfo">
            共读取到
            <span style={{ fontSize: "16px", color: "#313131" }}>
              {upData?.total}
            </span>
            条数据，校验通过
            <span style={{ fontSize: "16px", color: "#61D4AD" }}>
              {upData?.success}
            </span>
            条，校验失败
            <span style={{ fontSize: "16px", color: "#FE545F" }}>
              {upData?.fail}
            </span>
            条
          </div>
          {upData?.fail_data?.length > 0 ? (
            <div className="uploadTip">
              以下数据校验失败，请修改后再重新上传，如果继续上传将忽略以下数据。
            </div>
          ) : null}
          {upData?.fail_data?.length > 0 ? (
            <Table
              className="myFormTable"
              rowClassName="upLoadFileModalTableRow"
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              rowKey={(record) => record.idx}
              bordered={true}
              pagination={false}
              scroll={{
                y: "50vh",
              }}
              columns={columns2.current}
              dataSource={upData?.fail_data || []}
            />
          ) : null}

          <div className="uploadBtnBox">
            <Button
              className="noBg"
              onClick={() => {
                setVisible(false);
                // getBiddingCompetitivenessAnalysis();
              }}
            >
              暂不上传
            </Button>
            <Button
              type="primary"
              onClick={() => {
                uploadSuccess();
              }}
              loading={loading}
            >
              继续上传
            </Button>
            {/* <Button
              type="primary"
              onClick={() => {
                setVisible(false);
                getBiddingCompetitivenessAnalysis();
              }}
              loading={loading}
            >
              确定
            </Button> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CompetitivenessAnalysis;
