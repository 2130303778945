import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
  unifyNumber,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import { LoadingOutlined } from "@ant-design/icons";
import YearSelect from "../../../../TenderingAndBidding/components/YearSelect";
import DownLoadIcon from "../../../../../../components/Icons/DownLoadIcon";

const TopCustomers = (props) => {
  const { data = {}, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [yearList, setYearList, getYearList] = useGetState([]);

  const myChart = useRef(null);

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let amountMax = 0;
    const newEchartsData = echartsData
      ?.filter((_) => _.amount >= 0)
      ?.sort((a, b) => b.amount - a.amount)
      ?.slice(0, 5);
    newEchartsData?.forEach((item) => {
      if (item?.amount >= amountMax) {
        amountMax = item?.amount;
      }
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("topCustomersContentBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    const option = {
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        bottom: "0px",
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        data: ["开票金额"],
      },
      yAxis: [
        {
          type: "category",
          data: newEchartsData
            ?.sort((a, b) => a.amount - b.amount)
            ?.map((item) => item?.trading_company_name),
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            interval: 0,
            // rotate: -15,
            formatter: (value) => {
              if (value?.length > 4) {
                return value?.slice(0, 4) + "...";
              }
              return value;
            },
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
          filterMode: "none",
        },
      ],
      xAxis: [
        {
          type: "value",
          max: amountMax <= 5 ? 5 : null,
          interval: amountMax <= 5 ? 1 : null,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            show: false,
          },
          axisLabel: {
            formatter: function (value, index) {
              if (value >= 100000000) {
                return unifyNumber(value / 100000000) + "亿";
              } else if (value >= 10000) {
                return unifyNumber(value / 10000) + "万";
              } else if (value <= -10000) {
                return value / 10000 + "万";
              } else if (value <= -100000000) {
                return value / 100000000 + "亿";
              } else {
                return value;
              }
            },
          },
        },
      ],
      grid: {
        top: "20px",
        left: "0%",
        right: "20px",
        bottom: "30px",
        containLabel: true,
      },
      series: [
        {
          name: "开票金额",
          type: "bar",
          stack: "Total",
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },

          itemStyle: {
            color: "#0068B2",
            barBorderRadius: [0, 4, 4, 0],
          },
          data: newEchartsData?.map((item) => item?.amount),
        },
      ],
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  const initData = (data) => {
    let yearArr = Object?.keys(data)
      ?.filter((_) => _ !== "name")
      .sort((a, b) => Number(a) - Number(b));
    const nowYear = yearArr?.at(-1);

    setYearList(yearArr);
    setSelectYear(nowYear);
    initEcharts(data[nowYear]);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "TOP客户",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="topCustomersContent">
      <div className="headerBox">
        TOP客户
        {!noData && (
          <div className="yearSelectBox">
            <YearSelect
              value={selectYear}
              defaultOptions={yearList}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value?.at(0));
                setTimeout(() => {
                  initEcharts(data[value?.at(0)]);
                }, 50);
              }}
            ></YearSelect>
          </div>
        )}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <DownLoadIcon
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                ></DownLoadIcon>
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="topCustomersContentBox">
          <div id="topCustomersContentBox"></div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default TopCustomers;
