import { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useGetState } from "ahooks";
import { Button, Popover } from "antd";
import moment from "moment";
import { addMillimeter, umberFormats } from "../../../../../../../unit/unit";
import { useSelector } from "react-redux";
import projectApi from "../../../../../../../apis/project";
import { useParams } from "react-router-dom";

const SummaryAssembly = (props) => {
  const { data, checkCompanyList } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();

  const [winningCount, setWinningCount, getWinningCount] = useGetState(0);
  const [winningAmount, setWinningAmount, getWinningAmount] = useGetState(0);
  const [customerCount, setCustomerCount, getCustomerCount] = useGetState(0);
  const [biddingCount, setBiddingCount, getBiddingCount] = useGetState(0);
  const [customerAmountSum, setCustomerAmountSum, getCustomerAmountSum] =
    useGetState(0);

  const [yearObj, setYearObj, getYearObj] = useGetState({});
  const [customObj, setCustomObj, getCustomObj] = useGetState({});

  const [
    dueDiligenceCompanyData,
    setDueDiligenceCompanyData,
    getDueDiligenceCompanyData,
  ] = useGetState({});

  const initNumberFunc = (data) => {
    let winningCount = 0;
    let winningAmount = 0;
    let customerCount = 0;
    let biddingCount = 0;
    let customerAmountSum = 0;
    let yearObj = {};
    let customObj = {};
    let dueDiligenceCompanyData = {};

    (data.supplier_list || []).forEach((_) => {
      if (customObj[_?.bid_winner]) {
        customObj[_?.bid_winner] =
          (customObj[_?.bid_winner] || 0) + _?.bid_winning_amo;
      } else {
        customObj[_?.bid_winner] = _?.bid_winning_amo;
      }

      customerAmountSum = customerAmountSum + _?.bid_winning_amo;
    });

    (data.hit_bit_amount_list || []).forEach((_) => {
      if (yearObj[_?.year]) {
        yearObj[_?.year].winningAmount =
          (yearObj[_?.year].winningAmount || 0) + _?.year_amount_sum;
      } else {
        yearObj[_?.year] = {
          winningAmount: _?.year_amount_sum || 0,
        };
      }

      winningAmount = winningAmount + (_?.year_amount_sum || 0);

      if (_?.bid_winner === projectInfo.company_name) {
        dueDiligenceCompanyData.winningAmount =
          (dueDiligenceCompanyData.winningAmount || 0) +
          (_?.year_amount_sum || 0);
      }
    });

    (data.hit_bit_list || []).forEach((_) => {
      if (yearObj[_?.year]) {
        yearObj[_?.year].winningCount =
          (yearObj[_?.year].winningCount || 0) + _?.count;
      } else {
        yearObj[_?.year] = {
          winningCount: _?.count || 0,
        };
      }

      winningCount = winningCount + (_?.count || 0);

      if (_?.bid_winner === projectInfo.company_name) {
        dueDiligenceCompanyData.winningCount =
          (dueDiligenceCompanyData.winningCount || 0) + (_?.count || 0);
      }
    });

    (data.invite_bit_list || []).forEach((_) => {
      if (yearObj[_?.year]) {
        yearObj[_?.year].biddingCount =
          (yearObj[_?.year].biddingCount || 0) + _?.count;
      } else {
        yearObj[_?.year] = {
          biddingCount: _?.count || 0,
        };
      }

      biddingCount = biddingCount + (_?.count || 0);

      if (_?.bid_winner === projectInfo.company_name) {
        dueDiligenceCompanyData.biddingCount =
          (dueDiligenceCompanyData.biddingCount || 0) + (_?.count || 0);
      }
    });

    customerCount = Object.keys(customObj).length;

    setWinningCount(winningCount);
    setWinningAmount(winningAmount);
    setCustomerCount(customerCount);
    setBiddingCount(biddingCount);
    setCustomerAmountSum(customerAmountSum);
    setCustomObj(customObj);
    setYearObj(yearObj);
    setDueDiligenceCompanyData(dueDiligenceCompanyData);
  };

  const initPopoverContentDom = (type) => {
    let yearArr = [
      moment(new Date())?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
    ];

    let yearData = {};
    let suffix = "";
    let key = "";
    let isMoney = false;

    if (type === "已公布结果项目数") {
      yearData = getYearObj();
      suffix = "个";
      key = "winningCount";
    } else if (type === "招标金额") {
      yearData = getYearObj();
      suffix = "万元";
      key = "winningAmount";
      isMoney = true;
    } else if (type === "供应商数") {
      yearData = getYearObj();
      suffix = "个";
      key = "customerCount";
    } else if (type === "招标项目数") {
      yearData = getYearObj();
      suffix = "个";
      key = "biddingCount";
    }

    return (
      <div className="popoverContentDom">
        <div className="popoverContentDomTitle">{type}</div>
        {yearArr?.map((year) => {
          return (
            <div className="popoverContentDomText">
              <span>{year}</span>
              <span>
                {(yearData[year] || {})[key]
                  ? (isMoney
                      ? addMillimeter(yearData[year][key] / 10000)
                      : yearData[year][key]) + suffix
                  : "-"}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const initDescribeDom = () => {
    let isHasDueDiligenceCompany = checkCompanyList?.some(
      (_) => _ === projectInfo?.company_name
    );
    let onlyDueDiligenceCompany =
      isHasDueDiligenceCompany && checkCompanyList?.length === 1;
    let customObj = getCustomObj();

    let customList = Object.keys(customObj)
      .filter((_) => _)
      .sort((a, b) => customObj[b] - customObj[a]);

    let topCustomList = customList?.slice(0, 3);

    let topProjectList = (data?.big_top3_list || [])
      ?.sort((a, b) => b.bid_winning_amo - a.bid_winning_amo)
      ?.slice(0, 3);

    let dueDiligenceCompanyData = getDueDiligenceCompanyData();
    return (
      <>
        <div className="describeItem">
          <i></i>
          <div>
            近5年，
            {onlyDueDiligenceCompany ? (
              <>
                尽调公司
                <span className="companySpan">{projectInfo.company_name}</span>
              </>
            ) : (
              `选择的${checkCompanyList?.length}家公司（${
                isHasDueDiligenceCompany ? "" : "不"
              }含尽调公司）`
            )}
            累计招标项目数
            <span className="numberSpan">{getBiddingCount()}</span>
            个，已公布结果项目数
            <span className="numberSpan">{getWinningCount()}</span>
            个，累计招标金额
            <span className="numberSpan">
              {umberFormats(getWinningAmount() / 10000)}
            </span>
            万元。
            {!onlyDueDiligenceCompany && isHasDueDiligenceCompany && (
              <>
                其中，尽调公司
                <span className="companySpan">{projectInfo.company_name}</span>
                累计招标项目数
                <span className="numberSpan">
                  {dueDiligenceCompanyData?.biddingCount}
                </span>
                个，已公布结果项目数
                <span className="numberSpan">
                  {dueDiligenceCompanyData?.winningCount}
                </span>
                个，累计招标金额
                <span className="numberSpan">
                  {umberFormats(dueDiligenceCompanyData?.winningAmount / 10000)}
                </span>
                万元。
              </>
            )}
          </div>
        </div>
        <div className="describeItem">
          <i></i>
          <div>
            近5年，
            {onlyDueDiligenceCompany
              ? "尽调公司"
              : `选择的${checkCompanyList?.length}家公司（${
                  isHasDueDiligenceCompany ? "" : "不"
                }含尽调公司）`}
            的供应商有<span className="numberSpan">{getCustomerCount()}</span>个
            {getCustomerCount() === 0 ? (
              "。"
            ) : (
              <>
                ，其中Top3的分别是：
                {topCustomList.map((_item, index) => (
                  <>
                    <span className="companySpan">{_item}</span>中标金额
                    <span className="numberSpan">
                      {umberFormats(customObj[_item] / 10000)}
                    </span>
                    万元（占比
                    <span className="numberSpan">
                      {(
                        (Number(customObj[_item]) / getCustomerAmountSum()) *
                        100
                      ).toFixed(2)}
                      %
                    </span>
                    ）{topCustomList.length === index + 1 ? "。" : "、"}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
        {topProjectList?.length > 0 && (
          <div className="describeItem">
            <i></i>
            <div>
              近5年，
              {onlyDueDiligenceCompany
                ? "尽调公司"
                : `选择的${checkCompanyList?.length}家公司（${
                    isHasDueDiligenceCompany ? "" : "不"
                  }含尽调公司）`}
              中标的Top3项目分别是：
              {topProjectList?.map((_, index) => (
                <>
                  <span className="companySpan">{_.anc_title}</span>（中标金额
                  <span className="numberSpan">
                    {umberFormats(_.bid_winning_amo / 10000)}
                  </span>
                  万元）
                  {topProjectList.length === index + 1 ? "。" : "、"}
                </>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    initNumberFunc(data);
  }, [data]);

  return (
    <div className="summaryAssemblyContent2">
      <div className="headerBox">
        <span className="headerBoxTitle">
          <span className="block"></span>
          概述
        </span>
      </div>
      <div className="numberContentBox">
        <div className="numberItem">
          <Popover
            overlayClassName="summaryNumberItemPopover"
            placement="right"
            title={null}
            content={initPopoverContentDom("招标项目数")}
          >
            <div className="numberItemTitle">招标项目数</div>
            <div className="numberItemText">
              {biddingCount}
              <span>个</span>
            </div>
          </Popover>
        </div>
        <span className="line"></span>

        <div className="numberItem">
          <Popover
            overlayClassName="summaryNumberItemPopover"
            placement="right"
            title={null}
            content={initPopoverContentDom("已公布结果项目数")}
          >
            <div className="numberItemTitle">已公布结果项目数</div>
            <div className="numberItemText">
              {winningCount}
              <span>个</span>
            </div>
          </Popover>
        </div>
        <span className="line"></span>

        <div className="numberItem">
          <Popover
            overlayClassName="summaryNumberItemPopover"
            placement="left"
            title={null}
            content={initPopoverContentDom("招标金额")}
          >
            <div className="numberItemTitle">招标金额</div>
            <div className="numberItemText">
              {umberFormats(winningAmount / 10000)}
              <span>万元</span>
            </div>
          </Popover>
        </div>
        <span className="line"></span>

        <div className="numberItem">
          <div className="numberItemTitle">供应商数</div>
          <div className="numberItemText">
            {customerCount}
            <span>个</span>
          </div>
        </div>
      </div>
      <div className="describeContentBox">{initDescribeDom()}</div>

      <div className="annotationDiv">注：统计时间为近五年数据</div>
    </div>
  );
};
export default SummaryAssembly;
