import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { Input, Button, Pagination, message, Typography, Tag } from "antd";
import {
  FolderAddFilled,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import MyCard from "../../components/MyCard";
import Scrollbars from "react-custom-scrollbars";
import { useNavigate } from "react-router-dom";
import { useGetState, useDebounce, useUnmount, useUnmountedRef } from "ahooks";
import MySpin from "../../components/MySpin";
import ProjectApi from "../../apis/project/index";
import { debounce, getLicenseValid } from "../../unit/unit";
import CreatProjectDrawer from "../CreatProjectDrawer";
import MessageModal from "../../components/MessageModal";
import MyEmpty from "../../components/Empty";
import UserDrawer from "./components/UserDrawer";
import { useSelector } from "react-redux";
import icon from "../../assets/删除2.svg";
import icon2 from "../../assets/查看.svg";
import icon3 from "../../assets/成员管理.svg";
import icon4 from "../../assets/关闭项目.svg";
import icon5 from "../../assets/启用.svg";
import { ReactSVG } from "react-svg";
import HeadPortrait from "../../components/HeadPortrait";
import HeadPortraitLine from "../../components/HeadPortraitLine";
const { Paragraph } = Typography;

const ProjectManagement = (props) => {
  const navigate = useNavigate();

  const [scene, setScene, getScene] = useGetState("all");
  const [projectType, setProjectType, getProjectType] = useGetState("all");
  const [sceneOptions, setSceneOptions] = useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = useState([]);
  const [status, setStatus, getStatus] = useGetState("all");
  const [keyword, setKeyword, getKeyword] = useGetState(null);

  const [orderField, setOrderField, getOrderField] = useGetState("create_time"); //排序字段
  const [orderAsc, setOrderAsc, getOrderAsc] = useGetState(-1); //排序顺序：1升序/-1降序

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [visible, setVisible] = useState(false);
  const [userVisible, setUserVisible] = useState(false);
  const [changeItem, setChangeItem] = useState({});

  const [projectsStatusObj, setProjectsStatusObj] = useState({});
  const timer = useRef();

  const debouncedValue = useDebounce(keyword, { wait: 500 });
  const userPermission = useSelector((state) => state.User.userPermission);
  const userInfo = useSelector((state) => state.User.userInfo);

  const columns = useRef([]);

  useEffect(() => {
    let list = [
      {
        label: "尽调场景",
        key: "scene_code_name",
        width: "50%",
        type: "text",
      },
      {
        label: "项目状态",
        key: "status_name",
        width: "50%",
        type: "tag",
      },
      {
        label: "创建人",
        key: "name",
        width: "50%",
        type: "text",
        format: (data, info) => {
          if (localStorage.getItem("show_project_type_tag")) {
            if (info?.project_type === "team_project") {
              return "系统导入";
            }
          }
          return (
            <Paragraph
              style={{
                width: `100%`,
                color: "#535353",
              }}
              ellipsis={{
                tooltip: (
                  <span style={{ fontSize: "12px" }}>{info?.show_name}</span>
                ),
              }}
            >
              <HeadPortrait
                size={20}
                title={
                  info.show_name +
                  (info.assist_name ? "/" + info.assist_name : "")
                }
                src={info?.pf_photo}
                style={{ marginRight: "3px" }}
              ></HeadPortrait>
              {info?.show_name}
            </Paragraph>
          );
        },
      },
      {
        label: "创建时间",
        key: "create_time",
        width: "50%",
        type: "text",
      },
      {
        label: "尽调对象",
        key: "company_name",
        width: "100%",
        type: "text",
        render: (text, item) => {
          return (
            <div
              className="value"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ marginRight: "8px" }}>{text}</span>
              {item.is_listed_company === 1 && item.stock_block && (
                <Tag className="companyTypeTag">{item.stock_block}</Tag>
              )}
              {item.is_listed_company === 1 && item.stock_code && (
                <Tag className="companyTypeTag">{item.stock_code}</Tag>
              )}
            </div>
          );
        },
      },
      {
        label: "项目成员",
        key: "member_list",
        width: "50%",
        type: "text",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        format: (data, info) => {
          // let str = data?.map((item) => item.name).join("、");
          return (
            // <Paragraph
            //   ellipsis={{
            //     // tooltip: {
            //     //   title: <div className="tableColumnsParagraphTip">{str}</div>,
            //     //   overlayClassName: "tableColumnsParagraphTooltip",
            //     // },
            //     rows: 1,
            //     expandable: false,
            //   }}
            //   style={{
            //     width: `100%`,
            //     marginBottom: "0px",
            //   }}
            // >
            //   {data?.map((item) => (
            //     <HeadPortrait
            //       size={20}
            //       title={item?.name}
            //       src={item?.pf_photo}
            //       onClick={() => userChange(info)}
            //       style={{ marginRight: "3px", cursor: "pointer" }}
            //     ></HeadPortrait>
            //   ))}
            // </Paragraph>
            <HeadPortraitLine
              data={data}
              size={20}
              num={6}
              // onClick={() => userChange(info)}
            ></HeadPortraitLine>
          );
        },
      },
      {
        label: "尽调成功",
        key: "cdd_success_count",
        width: "50%",
        type: "text",
        render: (text, item) => {
          return (
            <div className="value">
              <span
                style={{
                  marginRight: "2px",
                  color: "#0068b2",
                  fontWeight: "bold",
                }}
              >
                {text}
              </span>
              次
            </div>
          );
        },
      },
    ];
    if (userInfo?.co_type_code === "INDIV_ORG") {
      list = list?.filter((_) => _.label !== "项目成员");
    }
    columns.current = list;
  }, [userInfo]);

  const unmountedRef = useUnmountedRef();

  useUnmount(() => {
    clearTimeout(timer.current);
  });

  useEffect(() => {
    if (getKeyword() !== null) {
      getData();
    }
  }, [debouncedValue]);

  const getSceneList = () => {
    ProjectApi.getScenes().then((res) => {
      let list = [];
      if (res.code === 200) {
        list = JSON.parse(JSON.stringify(res.data));
      }
      list.unshift({
        id: "all",
        scene_name: "全部",
        scene_code: "all",
      });
      setSceneOptions(list);
    });
  };

  const getProjectTypeList = () => {
    ProjectApi.getProjectTypes().then((res) => {
      let list = [];
      if (res.code === 200) {
        list = JSON.parse(JSON.stringify(res.data));
      }
      list.unshift({
        id: "all",
        project_type: "全部",
        code: "all",
      });
      setProjectTypeOptions(list);
    });
  };

  useEffect(() => {
    getData();
    getSceneList();
    if (localStorage.getItem("show_project_type_tag")) {
      getProjectTypeList();
    }
  }, []);

  /* 获取数据列表 */
  const getData = () => {
    setLoading(true);
    let params = {
      page_size: pageSize,
      page_number: getPageNumber(),
      order_field: getOrderField(),
      order_asc: getOrderAsc(),
    };
    if (getScene() && getScene() !== "all") {
      params.scene_code = getScene();
    }
    if (getStatus() && getStatus() !== "all") {
      params.status = getStatus();
    }
    if (getProjectType() && getProjectType() !== "all") {
      params.project_type = getProjectType();
    }
    if (getKeyword()) {
      params.keyword = getKeyword();
    }
    ProjectApi.getProjectList(params)
      .then((res) => {
        if (res.code === 200) {
          if (res.data.data?.length === 0 && getPageNumber() !== 1) {
            setPageNumber(1);
            setTimeout(() => {
              getData();
            }, 100);
            return;
          }
          setData(res.data.data);
          setTotal(res.data.total);
          getProjectsStatus();
          setLoading(false);
        } else {
          setLoading(false);
          message.error(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        // message.error(error.message);
      });
  };

  /* 排列顺序改变 */
  const orderChange = async (e, key, orderChange) => {
    if (orderField === key) {
      const asc = await getOrderAsc();
      await setOrderAsc(asc === 1 ? -1 : 1);
    } else {
      await setOrderField(key);
      await setOrderAsc(orderChange);
    }
    setTimeout(() => {
      getData();
    }, 100);
  };

  /* 查询条件发生变化 */
  const searchOptionChange = async (key, value) => {
    await setPageNumber(1);
    switch (key) {
      case "keyword":
        await setKeyword(value);
        return;
        break;
      case "status":
        await setStatus(value);
        break;
      case "scene":
        await setScene(value);
        break;
      case "projectType":
        await setProjectType(value);
        break;
      default:
        break;
    }
    getData();
  };

  /* 页码变化 */
  const pageChange = async (page) => {
    await setPageNumber(page);
    getData();
  };

  /* 收藏操作 */
  const collectHandle = (isCollection, data) => {
    ProjectApi.changeProjectStar(data.id, isCollection ? 1 : 0).then((res) => {
      if (res.code === 200) {
        setData((prev) => {
          prev = prev.map((item) => {
            if (item.id === data.id) {
              item.is_star_mark = isCollection ? 1 : 0;
            }
            return item;
          });
          return [...prev];
        });
      } else {
        message.error(res.message);
      }
    });
  };

  /* 项目状态改变 */
  const statusChange = (status, data) => {
    console.log(data);
    //关闭 重试 启用
    let message = "关闭项目后将不再更新尽调信息和推送订阅信息";
    if (status === "重试") {
      if (data.cdd_success_is_over_day) {
        message = "距离上次尽调已超过90天，需要重新尽调";
      } else if (data.cdd_success_count === 0) {
        message = "该企业还未完成尽调，需要重新尽调";
      }
    }
    MessageModal({
      type: status === "关闭" ? "warning" : "",
      description: `确定要${status}项目吗？`,
      message: status === "启用" ? null : message,
      title: `${status}项目`,
      okText: status === "重试" ? "重新尽调" : status,
      onOk: () => {
        statusChangeHandle(status, data);
      },
    });
  };

  const statusChangeHandle = (status, item) => {
    let codeObj = {
      关闭: "CDD_PRJ_STATUS_CLS",
      启用: "CDD_PRJ_STATUS_ING",
      重试: "CDD_PRJ_STATUS_ING",
      删除: "CDD_PRJ_STATUS_DEL",
    };

    ProjectApi.changeProjectStatus(item.id, codeObj[status]).then((res) => {
      if (res.code === 200) {
        getData();
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    });
  };

  /* 删除项目 */
  const deleteItem = (item) => {
    MessageModal({
      type: "warning",
      description: "确定要删除项目吗？",
      message: "删除项目后尽调信息和生成的底稿将不再保留",
      title: "删除",
      onOk: () => {
        // statusChangeHandle(status, data);
        ProjectApi.deleteProject(item.id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getData();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const userChange = (item) => {
    getLicenseValid().then((res) => {
      if (res) {
        setUserVisible(true);
        setChangeItem(item);
      }
    });
  };

  const goInfo = (item) => {
    let url = window.location.origin + "/company/" + item.id;
    window.open(url, "_blank");
  };

  /* 初始化 按钮列表 */
  const initBtnList = (item) => {
    let btnArr = [];
    if (
      item.status_name === "尽调中" ||
      item.status_name === "尽调完成" ||
      item.status_name === "尽调失败"
    ) {
      if (item?.project_permission_urls?.includes("CDD_PRJ_CLOSE")) {
        btnArr.push(
          <Button
            className="cardBtn"
            icon={<ReactSVG className="cardBtnSvg" src={icon4}></ReactSVG>}
            onClick={() => statusChange("关闭", item)}
          >
            关闭项目
          </Button>
        );
      }
      if (item.status_name === "尽调失败") {
        if (item?.project_permission_urls?.includes("CDD_PRJ_OPEN")) {
          btnArr.push(
            <Button
              className="cardBtn"
              icon={<ReactSVG className="cardBtnSvg" src={icon5}></ReactSVG>}
              onClick={() => {
                getLicenseValid().then((res) => {
                  if (res) {
                    statusChange("重试", item);
                  }
                });
              }}
            >
              重试
            </Button>
          );
        }
      }
      if (
        item?.project_permission_urls?.includes("CDD_PRJ_MBR_MGT") &&
        userInfo?.co_type_code !== "INDIV_ORG"
      ) {
        btnArr.push(
          <Button
            className="cardBtn"
            icon={<ReactSVG className="cardBtnSvg" src={icon3}></ReactSVG>}
            onClick={() => userChange(item)}
          >
            成员管理
          </Button>
        );
      }
      if (item?.project_permission_urls?.includes("CDD_PRJ_VIEW")) {
        btnArr.push(
          <Button
            className="cardBtn"
            icon={<ReactSVG className="cardBtnSvg" src={icon2}></ReactSVG>}
            onClick={() => goInfo(item)}
          >
            查看
          </Button>
        );
      }
    } else {
      if (item?.project_permission_urls?.includes("CDD_PRJ_DEL")) {
        btnArr.push(
          <Button
            className="cardBtn"
            icon={<ReactSVG className="cardBtnSvg" src={icon}></ReactSVG>}
            onClick={() => deleteItem(item)}
          >
            删除
          </Button>
        );
      }
      if (item?.project_permission_urls?.includes("CDD_PRJ_OPEN")) {
        btnArr.push(
          <Button
            className="cardBtn"
            icon={<ReactSVG className="cardBtnSvg" src={icon5}></ReactSVG>}
            onClick={() => {
              getLicenseValid().then((res) => {
                if (res) {
                  statusChange("启用", item);
                }
              });
            }}
          >
            启用项目
          </Button>
        );
      }
      if (item?.project_permission_urls?.includes("CDD_PRJ_VIEW")) {
        btnArr.push(
          <Button
            className="cardBtn"
            icon={<ReactSVG className="cardBtnSvg" src={icon2}></ReactSVG>}
            onClick={() => goInfo(item)}
          >
            查看
          </Button>
        );
      }
    }
    return btnArr;
  };

  const creatSuccess = () => {
    setVisible(false);
    setUserVisible(false);
    getData();
  };

  const getProjectsStatus = () => {
    ProjectApi.getProjectStatus().then((res) => {
      console.log("项目排队情况", res, new Date().getTime());
      if (res.code === 200) {
        setProjectsStatusObj(res.data || {});
        // if (Object.keys(res?.data || {}).length > 0) {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        if (unmountedRef.current) {
          console.log("组件已经卸载");
          return;
        }
        timer.current = setTimeout(() => {
          getProjectsStatus();
        }, 2.5 * 1000);
        // }
      } else {
        setProjectsStatusObj({});
        message.error(res.message);
      }
    });
  };
  useEffect(() => {
    getProjectsStatus();
  }, []);

  const initProjectStatus = (status, id) => {
    return projectsStatusObj[id] || "";
  };

  return (
    <div className="projectManagementContent">
      <div
        className="topBox"
        style={{
          height: localStorage.getItem("show_project_type_tag")
            ? "170px"
            : "150px",
        }}
      >
        <div className="searchBox">
          {localStorage.getItem("show_project_type_tag") && (
            <div className="searchItem">
              <div className="label">项目类型</div>
              <div className="optionsBox">
                {projectTypeOptions.map((item) => (
                  <div
                    key={item.code}
                    className="optionItem"
                    style={{
                      color: projectType !== item.code ? "#9E9E9E" : "#0068B2",
                    }}
                    onClick={() => {
                      projectType !== item.code &&
                        searchOptionChange("projectType", item.code);
                    }}
                  >
                    {item.project_type}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="searchItem">
            <div className="label">尽调场景</div>
            <div className="optionsBox">
              {sceneOptions.map((item) => (
                <div
                  key={item.scene_code}
                  className="optionItem"
                  style={{
                    color: scene !== item.scene_code ? "#9E9E9E" : "#0068B2",
                  }}
                  onClick={() => {
                    scene !== item.scene_code &&
                      searchOptionChange("scene", item.scene_code);
                  }}
                >
                  {item.scene_name}
                </div>
              ))}
            </div>
          </div>
          <div className="searchItem">
            <div className="label">项目状态</div>
            <div className="optionsBox">
              {[
                { label: "全部", key: "all" },
                { label: "尽调中", key: "CDD_PRJ_STATUS_ING" },
                { label: "尽调失败", key: "CDD_PRJ_STATUS_FAIL" },
                { label: "尽调完成", key: "CDD_PRJ_STATUS_CPL" },
                { label: "关闭", key: "CDD_PRJ_STATUS_CLS" },
              ].map((item) => (
                <div
                  key={item.key}
                  className="optionItem"
                  style={{ color: status !== item.key ? "#9E9E9E" : "#0068B2" }}
                  onClick={() => {
                    status !== item.key &&
                      searchOptionChange("status", item.key);
                  }}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
          <div className="searchItem">
            <div className="label" style={{ marginRight: "10px" }}>
              项目名称/尽调对象名称
            </div>
            <div className="optionsBox">
              <Input
                bordered={false}
                className="borderBottom"
                onChange={(e) => searchOptionChange("keyword", e.target.value)}
                allowClear
              />
            </div>
          </div>
        </div>
        <div className="creatBox">
          {userPermission?.includes("CDD_PRJ_CREATE") && (
            <Button
              type="primary"
              icon={<FolderAddFilled />}
              onClick={() => {
                getLicenseValid().then((res) => {
                  if (res) {
                    setVisible(true);
                  }
                });
              }}
            >
              新建项目
            </Button>
          )}
        </div>
      </div>

      <span className="filterItem">
        {[
          {
            label: "创建时间",
            key: "create_time",
            width: "65px",
            defaultAsc: -1,
          },
          {
            label: "项目名称",
            key: "project_name",
            width: "65px",
            defaultAsc: 1,
          },
          {
            label: "尽调对象名称",
            key: "company_name",
            width: "85px",
            defaultAsc: 1,
          },
        ].map((item) => (
          <span
            key={item.key}
            className="optionItem"
            onClick={debounce(orderChange, 200, item.key, item.defaultAsc || 1)}
            style={{
              color: orderField === item.key ? "#0068b2" : "#535353",
              width: item.width,
            }}
          >
            {item.label}
            {orderField === item.key &&
              (orderAsc === 1 ? <CaretUpOutlined /> : <CaretDownOutlined />)}
          </span>
        ))}
      </span>
      <div
        className="cardContent"
        style={{
          height: `calc(100% - ${
            localStorage.getItem("show_project_type_tag") ? 210 : 190
          }px)`,
        }}
      >
        {/* <PieMenu></PieMenu> */}
        {loading ? (
          <MySpin></MySpin>
        ) : data?.length > 0 ? (
          <>
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{
                height: "calc(100% - 90px)",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <div className="cardBox">
                {data.map((item, index) => (
                  <MyCard
                    title={item.project_name}
                    key={item.id}
                    pic={item.logo_url}
                    cardData={item}
                    collectHandle={collectHandle}
                    columns={columns.current}
                    btnList={initBtnList(item)}
                    isCollection={item.is_star_mark}
                    imgClick={goInfo}
                    projectStatus={initProjectStatus(item.status_name, item.id)}
                    showCollection={
                      item?.project_permission_urls?.includes("CDD_PRJ_FAV") &&
                      item.status !== "CDD_PRJ_STATUS_CLS"
                    }
                  ></MyCard>
                ))}
              </div>
            </Scrollbars>
            <div className="paginationBox">
              <Pagination
                onChange={pageChange}
                className="myPagination"
                pageSize={pageSize}
                current={pageNumber}
                total={total}
                showTotal={(total) => `共 ${total} 条`}
                showSizeChanger={false}
              />
            </div>
          </>
        ) : (
          <div className="noData">
            <MyEmpty></MyEmpty>
          </div>
        )}
      </div>
      <CreatProjectDrawer
        visible={visible}
        onClose={() => setVisible(false)}
        successFunc={creatSuccess}
      ></CreatProjectDrawer>

      <UserDrawer
        visible={userVisible}
        onClose={() => setUserVisible(false)}
        successFunc={creatSuccess}
        info={changeItem}
      ></UserDrawer>
    </div>
  );
};
export default ProjectManagement;
