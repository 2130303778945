import "./index.scss";
import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Table, message, Tooltip, Tag, Typography } from "antd";
import { InfoCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import wrapper from "./wrapper";
import { isArray } from "lodash";
import moment from "moment";
import projectApi from "../../../../../apis/project";
import MyEmpty from "../../../../../components/Empty";
import MySpin from "../../../../../components/MySpin";
import DetailsDrawer from "../../../../../components/DetailsDrawer";
import {
  addMillimeter,
  addMillimeterAndInit,
  amountFormats,
  saveFile,
  umberFormats,
} from "../../../../../unit/unit";
import peopleImg from "../../../../../assets/people.png";
import comImg from "../../../../../assets/com.png";
import { showInfoFunc } from "../../../../../components/MyTable";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import CompanyInfoDrawer from "../../../../../components/CompanyInfoDrawer";
import PersonInfoDrawer from "../../../../../components/PersonInfoDrawer";
import { useSize } from "ahooks";
import EditIcon from "../../../../../components/Icons/EditIcon";
import DownLoadIcon from "../../../../../components/Icons/DownLoadIcon";

const { Paragraph } = Typography;

const TableModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
  const [choiceItem, setChoiceItem] = useState(null);
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [filterCriteria, setFilterCriteriao] = useState([]);
  const [downLoading, setDownLoading] = useState(false);
  const refTable = useRef(null);
  const size = useSize(refTable);

  const columns2 = useRef([
    {
      key: "序号",
      title: "序号",
      dataIndex: "序号",
      align: "center",
      width: "80px",
      render: (text, record, index) => <>{index + 1}</>,
    },
  ]);

  const tableTypeNameList = useRef({
    招标公告: "listed_invite_bid_pubic",
    招标结果: "listed_invite_bid_res",
    投标结果: "listed_hit_bid_res",
  });

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    onOk,
    isBid,
    filterOptions,
    projectId,
    notOk = false,
    typeName = "投标结果",
    tableColumns,
    tableData,
    notGetData,
    pageSize = 10,
  } = props;

  //公司或者自然人图标
  const initLogo = (item, info, isEspecially, relatedPartiesTypeList) => {
    let isPeople = false;
    if (item.click_legal_request) {
      isPeople = false;
    } else if (item.click_natural_request) {
      isPeople = true;
    } else if (item.click_all) {
    }
    let bgColor = isPeople ? "#FEC054" : "#AAD461";
    //招投标需要特殊处理(不同类型公司展示不同颜色)
    if (relatedPartiesTypeList && !isPeople) {
      if ((relatedPartiesTypeList || [])?.length === 0) {
        bgColor = "#BBBBBB";
      }
      if (
        (relatedPartiesTypeList || [])?.some((_) => _.short_name === "竞争对手")
      ) {
        bgColor = "#B85DDC";
      }
    }
    if (isEspecially) {
      bgColor = isPeople ? "#FE545F" : "#0068B2";
      if (item.is_show_actual_controller_person) {
        bgColor = "#FE545F";
      }
    }
    return (
      <div className="comOrPeopleLogo" style={{ background: bgColor }}>
        <img src={isPeople ? peopleImg : comImg} alt="" />
      </div>
    );
  };

  //格式化 table columns
  const initColumns = (rows, type) => {
    const minWidth = 26;
    let columns = Object.keys(rows)
      .filter((key) => rows[key]?.name !== "最终收益股份")
      .map((key, index) => {
        const item = rows[key];
        let column = {
          key: key,
          title: item.name,
          dataIndex: key,
          // width,
          align: "center",
        };

        column.render = (text, record, index) => {
          if (
            record[key]?.val === null ||
            record[key]?.val === undefined ||
            record[key]?.val === ""
          ) {
            return "-";
          }
          //企业列表
          if (item.is_company_list) {
            if (record[key]?.val?.length === 0) {
              return "-";
            }
            return (
              <div>
                {(record[key]?.val || [])?.map((_item, _index) => (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "center",
                      textAlign: "left",
                      marginTop: _index ? "5px" : null,
                    }}
                  >
                    {initLogo(
                      {
                        click_legal_request: _item?.winner_type === 1,
                        click_natural_request: _item?.winner_type === 2,
                      },
                      record,
                      (_item?.relatedPartiesType || [])?.some(
                        (_) => _.short_name === "尽调对象"
                      ),
                      _item?.relatedPartiesType || []
                    )}
                    <div style={{ width: "calc(100% - 35px)" }}>
                      <div className="tagBox">
                        {(_item?.relatedPartiesType || []).map((tag) => {
                          // const len = (tag.length || 0) * 15;
                          return (
                            <Tooltip
                              key={Math.random()}
                              placement="top"
                              title={tag.full_name}
                              arrowPointAtCenter
                            >
                              <div>
                                <Tag
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "normal",
                                    flex: "none",
                                  }}
                                  key={Math.random()}
                                >
                                  {tag.short_name}
                                </Tag>
                              </div>
                            </Tooltip>
                          );
                        })}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Paragraph
                          ellipsis={{
                            rows: 1,
                            tooltip: {
                              title: _item?.bid_winner,
                            },
                          }}
                          style={{
                            width: `calc(100% - ${
                              item?.is_show_amount ? "120px" : "0"
                            })`,
                          }}
                        >
                          {(_item?.relatedPartiesType || [])?.length > 0 ? (
                            <a
                              onClick={(e) => {
                                e.stopPropagation();
                                if (_item?.winner_type === 1) {
                                  CompanyInfoDrawer({
                                    projectId: projectId,
                                    companyId: _item?.winner_id,
                                    companyName: _item?.bid_winner,
                                  });
                                } else if (_item?.winner_type === 2) {
                                  PersonInfoDrawer({
                                    projectId: projectId,
                                    personName: _item?.bid_winner,
                                    params: {
                                      person_id: _item?.winner_id,
                                      companyId: _item?.winner_id,
                                    },
                                  });
                                }
                              }}
                            >
                              {_item?.bid_winner}
                            </a>
                          ) : (
                            _item?.bid_winner
                          )}
                        </Paragraph>
                        {item?.is_show_amount && (
                          <span
                            style={{
                              fontSize: "12px",
                              width: "120px",
                              textAlign: "right",
                            }}
                          >
                            {_item?.bid_winning_amo
                              ? umberFormats(
                                  Number(_item?.bid_winning_amo) / 10000
                                ) + "万元"
                              : "-"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          }
          if (item.is_object) {
            return JSON.stringify(record[key]?.val);
          }
          if (item.need_millimeter) {
            //带单位
            if (item.join_field) {
              return (
                addMillimeterAndInit(record[key]?.val, 2) +
                record[item.join_field]?.val
              );
            }
            return addMillimeter(record[key]?.val, 2);
          }
          //转化为百分比
          if (item.need_percent) {
            return record[key]?.val
              ? (Number(record[key]?.val) * 100).toFixed(2) + "%"
              : "0%";
          }
          //带上主体为范围
          if (item.is_show_related_parties) {
            if (record[key]?.val) {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                    textAlign: "left",
                  }}
                >
                  {initLogo(
                    item,
                    record,
                    (record?.relatedPartiesType?.val || [])?.some(
                      (_) => _.short_name === "尽调对象"
                    )
                  )}
                  <div>
                    <div className="tagBox">
                      {(record?.relatedPartiesType?.val || []).map((tag) => {
                        // const len = (tag.length || 0) * 15;
                        return (
                          <Tooltip
                            key={Math.random()}
                            placement="top"
                            title={tag.full_name}
                            arrowPointAtCenter
                          >
                            <div>
                              <Tag
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "normal",
                                  flex: "none",
                                }}
                                key={Math.random()}
                              >
                                {tag.short_name}
                              </Tag>
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>

                    <Paragraph
                      ellipsis={{
                        rows: 2,
                        tooltip: {
                          title: record[key]?.val,
                        },
                      }}
                    >
                      {record?.winner_id?.val !== -1 ? (
                        <a
                          onClick={(e) => {
                            e.stopPropagation();
                            showInfoFunc(
                              item,
                              record,
                              "中标列表",
                              projectId,
                              key
                            );
                          }}
                        >
                          {record[key]?.val}
                        </a>
                      ) : (
                        record[key]?.val
                      )}
                    </Paragraph>
                  </div>
                </div>
              );
            }
            return "-";
          }
          //公司名称可查看 自然人可查看
          if (
            item.click_natural_request ||
            item.click_legal_request ||
            item.click_all
          ) {
            return (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <div className="comOrPeopleContent">
                {initLogo(
                  item,
                  record,
                  item.is_show_actual_controller_person &&
                    record?.is_actual_controller_person?.val
                )}
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    tooltip: {
                      title: record[key]?.val,
                    },
                  }}
                >
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      showInfoFunc(item, record, "中标列表", projectId, key);
                    }}
                  >
                    {record[key]?.val}
                  </a>
                </Paragraph>
                {/* {item.is_show_actual_controller_person &&
              record?.is_actual_controller_person?.val ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                  <Tag className="controller">疑似实际控制人</Tag>
                </div>
              ) : null} */}
              </div>
            );
          }
          //tips显示
          if (item.is_ellipsis || item.name === "采购人") {
            let rows = 2;
            let placement = "top";
            return (
              <Paragraph
                ellipsis={{
                  rows,
                  tooltip: {
                    title: (
                      <div className="tableColumnsParagraphTip">
                        {record[key]?.val}
                      </div>
                    ),
                    overlayClassName: "tableColumnsParagraphTooltip",
                    placement,
                  },
                }}
              >
                <div style={{ textAlign: "left", color: "#535353" }}>
                  {record[key]?.val}
                </div>
              </Paragraph>
            );
          }
          return record[key]?.val;
        };

        column.index = item.index + 1;
        if (item.width) {
          column.width = item.width;
        }
        return column;
      });
    //添加序号
    columns.unshift({
      index: 1,
      key: "序号",
      title: "序号",
      dataIndex: "_index",
      width: 80,
      align: "center",
      render: (text, record, index) => <>{text}</>,
    });

    columns = columns.sort((a, b) => a.index - b.index);
    columns2.current = columns;
  };

  const getDataFunc = (filterOptions, isBid) => {
    setVisible(true);
    setLoading(true);
    let filterCriteria = [];
    Object.keys(filterOptions)?.forEach((key) => {
      let obj = {};
      if (key === "供应商") {
        obj.is_input_select = true;
        obj.key = "supplier";
        obj.values = isArray(filterOptions[key])
          ? filterOptions[key]
          : [filterOptions[key]];
      } else if (key === "采购人") {
        obj.is_input_select = true;
        obj.key = "tenderee";
        obj.values = isArray(filterOptions[key])
          ? filterOptions[key]
          : [filterOptions[key]];
      } else if (key === "公告标题") {
        obj.is_input_select = true;
        obj.key = "anc_title";
        obj.values = [filterOptions[key]];
      } else if (key === "年份") {
        obj.key = "anc_date";
        obj.is_date_select = 1;
        if (filterOptions[key] === "all") {
          obj.values = [
            moment(moment(new Date()).subtract(4, "years"))
              .startOf("year")
              ?.format("YYYY-MM-DD"),
            moment(moment(new Date())).endOf("year")?.format("YYYY-MM-DD"),
          ];
        } else {
          obj.values = [
            moment(filterOptions[key]).startOf("year")?.format("YYYY-MM-DD"),
            moment(filterOptions[key]).endOf("year")?.format("YYYY-MM-DD"),
          ];
        }
      } else if (key === "区域") {
        obj.key = "bidding_area";
        obj.values = [filterOptions[key]];
      } else if (key === "项目类型") {
        obj.key = "bid_info_type";
        obj.values = [filterOptions[key]];
      } else if (key === "ids") {
        obj.key = "bid_id";
        obj.values = filterOptions[key];
      }
      filterCriteria.push(obj);
    });
    setFilterCriteriao(filterCriteria);
    let params = {
      page: 1,
      page_size: 10000,
    };
    if (filterCriteria?.length > 0) {
      params.select_filed_list = JSON.stringify(filterCriteria);
    }
    if (isBid) {
      params.project_id = projectId;
      params.name = tableTypeNameList.current[typeName];
      projectApi.getCompetitiveHitData(params).then((res) => {
        if (res.code === 200) {
          initColumns(res.data?.rows || []);
          setDataInfo(res?.data);
          setData(res.data.data);
        } else {
          setData([]);
          setDataInfo({});
          message.error(res.message);
        }
        setLoading(false);
      });
    } else {
      projectApi
        .getDataInfo(tableTypeNameList.current[typeName], projectId, params)
        .then((res) => {
          if (res.code === 200) {
            initColumns(res.data?.rows || []);
            setDataInfo(res?.data);
            setData(res.data.data);
          } else {
            setData([]);
            setDataInfo({});
            message.error(res.message);
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (notGetData) {
      setVisible(true);
      return;
    }
    getDataFunc(filterOptions, isBid);
  }, []);

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const handleOk = () => {
    onOk && onOk(filterCriteria, isBid, typeName);
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  //点击行
  const clickRow = (item) => {
    setDetailsDrawerVisible(true);
    setChoiceItem(item);
  };

  //导出数据
  const downloadFile = () => {
    setDownLoading(true);
    let params = {};
    if (isBid) {
      params.is_bid = 1;
    }
    let _filterCriteria = JSON.parse(JSON.stringify(filterCriteria));
    if (_filterCriteria?.length > 0) {
      params.select_filed_list = JSON.stringify(_filterCriteria);
    }
    projectApi
      .exportData(tableTypeNameList.current[typeName], projectId, params)
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      })
      .catch((sea) => {
        const unit8_msg = new Uint8Array(sea?.response?.data);
        const string = String.fromCharCode.apply(null, unit8_msg);
        const data = JSON.parse(string);
        message.error(data.message);
        setDownLoading(false);
      });
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handleCancel}
      //   onOk={handleOk}
      wrapClassName="tableModal"
      centered
      footer={null}
      maskClosable={true}
      width="100vw"
    >
      <div className="tableModalBox" ref={refTable}>
        {loading ? (
          <MySpin tip="数据获取中..."></MySpin>
        ) : (
          <div>
            <div className="tableModalBoxTitle">
              {!notOk && (
                <Button
                  type="primary"
                  icon={<EditIcon></EditIcon>}
                  onClick={handleOk}
                >
                  数据修订
                </Button>
              )}

              {downLoading ? (
                <LoadingOutlined
                  style={{ marginLeft: "20px" }}
                ></LoadingOutlined>
              ) : (
                <DownLoadIcon
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  className="hoverIcon"
                  onClick={() => {
                    downloadFile();
                  }}
                ></DownLoadIcon>
              )}
            </div>
            <Table
              rowKey={() => Math.random()}
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    if (notGetData) {
                      return;
                    }
                    clickRow(record);
                  }, // 点击行
                };
              }}
              className="myFormTable"
              bordered={true}
              style={{height: size?.height - ((tableData ? tableData : data)?.length > 10 ? 20 : 30) + "px"}}
              pagination={
                (tableData ? tableData : data)?.length > 10
                  ? {
                      showQuickJumper: false,
                      showSizeChanger: false,
                      // pageSizeOptions: ["10", "20", "50", "100"],
                      pageSize: pageSize,
                      showTotal: (total) => `共 ${total} 条`,
                    }
                  : false
              }
              columns={tableColumns ? tableColumns : columns2.current}
              dataSource={(tableData ? tableData : data)?.map((_, index) => {
                _._index = index + 1;
                return _;
              })}
              scroll={{
                y:
                  size?.height -
                  (80 +
                    ((tableData ? tableData : data)?.length > 10 ? 50 : 0)) +
                  "px",
              }}
              locale={{
                emptyText: <MyEmpty description="暂无数据"></MyEmpty>,
              }}
            ></Table>
          </div>
        )}
      </div>

      <DetailsDrawer
        title={isBid ? "详情" : "详情"}
        visible={detailsDrawerVisible}
        dataTable={tableTypeNameList.current[typeName]}
        item={choiceItem}
        typeName={"中标列表"}
        projectId={projectId}
        onCancel={() => {
          setDetailsDrawerVisible(false);
        }}
        isBid={isBid}
      ></DetailsDrawer>
    </Modal>
  );
};

export default wrapper(TableModal);
