import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import {
  LoadingOutlined,
  PayCircleFilled,
} from "@ant-design/icons";
import {
  addMillimeter,
  calcCompanyNameFunc,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import { message, Tooltip } from "antd";
import MyEmpty from "../../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useGetState } from "ahooks";
import TableModal from "../../../TableModal";
import moment from "moment";
import { chartColorMap } from "../../../../../../../setting";
import DownLoadIcon from "../../../../../../../components/Icons/DownLoadIcon";

const BidWinningSituation = (props) => {
  const {
    data,
    checkCompanyList,
    searchTableFunc,
    notHasData,
    chartsLoading,
    getCompanyList,
    checkAllCompanyList,
  } = props;
  const { id } = useParams();
  const [yearsObj, setYearsObj, getYearsObj] = useGetState({});
  const [noData, setNoData] = useState(false);

  const [chartType, setChartType, getChartType] = useGetState("金额");
  const [initType, setInitType, getInitType] = useGetState("按公司分组");

  const [downLoading, setDownLoading] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [selectType, setSelectType, getSelectType] = useGetState([]);

  const myChart = useRef(null);

  useEffect(() => {
    setSelectType(checkCompanyList);
    if (notHasData) {
      return;
    }
    // setTimeout(() => {
    //   initECharts(getYearsObj());
    // }, 100);
  }, [checkCompanyList]);

  const initECharts = (yearsObj) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let countMin = 0,
      countMax = 0,
      amountMin = 0,
      amountMax = 0;
    if (getInitType() === "按公司分组") {
      for (const year in yearsObj) {
        getSelectType()?.forEach((_) => {
          const _item = data?.filter(
            (__) => __.bid_winner === _ && __.anc_date === year
          );
          const amount = _item?.reduce((a, b) => a + b?.bid_amount, 0);
          const count = _item?.reduce((a, b) => a + b?.bid_count, 0);
          if (count > countMax) {
            countMax = count;
          }
          if (amount > amountMax) {
            amountMax = amount;
          }
        });
      }
    } else {
      for (const key in yearsObj) {
        let item = yearsObj[key];
        if (item?.count > countMax) {
          countMax = item?.count;
        }
        if (item?.amount > amountMax) {
          amountMax = item?.amount;
        }
      }
    }
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("bidWinningSituationBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    const initSeries = (yearsObj) => {
      let series = [];
      if (getInitType() === "按公司分组") {
        const colorList = [
          "#6CAEFF",
          "#AAD461",
          "#FEC054",
          "#FE545F",
          "#16D8D8",
        ];
        series = Object.keys(yearsObj)?.map((year, index) => {
          const _data = getSelectType()?.map((_) => {
            const _item = data?.filter(
              (__) => __.bid_winner === _ && __.anc_date === year
            );
            return _item?.reduce(
              (a, b) =>
                a + (getChartType() === "金额" ? b?.bid_amount : b.bid_count),
              0
            );
          });
          return {
            name: year,
            type: "bar",
            barMaxWidth: 40,
            tooltip: {
              valueFormatter: function (value) {
                return getChartType() === "金额"
                  ? addMillimeter(value, 2) + "元"
                  : value + "个";
              },
            },
            itemStyle: {
              color: chartColorMap[index],
              barBorderRadius: [4, 4, 0, 0],
            },
            data: _data,
          };
        });
      } else {
        series = [
          {
            name: getChartType() === "金额" ? "中标金额" : "中标数量",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return getChartType() === "金额"
                  ? addMillimeter(value, 2) + "元"
                  : value + "个";
              },
            },
            itemStyle: {
              color: "#0068B2",
              barBorderRadius: [4, 4, 0, 0],
            },
            data: Object.keys(yearsObj)?.map((key) =>
              getChartType() === "金额"
                ? yearsObj[key]?.amount
                : yearsObj[key]?.count
            ),
          },
        ];
      }
      return series;
    };

    const series = initSeries(yearsObj);

    const max = getChartType() === "金额" ? amountMax : countMax;
    let isEllipsis =
      getInitType() === "按公司分组" && getSelectType()?.length >= 20;
    const option = {
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      // toolbox: {
      //   feature: {
      //     dataView: { show: true, readOnly: false },
      //     magicType: { show: true, type: ['line', 'bar'] },
      //     restore: { show: true },
      //     saveAsImage: { show: true }
      //   }
      // },
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        top: "10px",
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        // data: ["中标项目数", "中标金额"],
        data:
          getInitType() === "按公司分组"
            ? Object.keys(yearsObj)
            : [getChartType()],
      },
      xAxis: [
        {
          type: "category",
          data:
            getInitType() === "按公司分组"
              ? getSelectType()
              : Object.keys(yearsObj),
          axisPointer: {
            type: "shadow",
            label: {
              formatter: (params) => {
                let str = params.value;
                if (str.length > 30) {
                  str = str?.slice(0, 30) + "...";
                }
                return str;
              },
            },
          },
          axisLabel: {
            interval: 0,
            formatter:
              getInitType() === "按公司分组"
                ? function (value) {
                    return calcCompanyNameFunc(
                      value,
                      getSelectType()?.length,
                      "bidWinningSituationBox"
                    );
                  }
                : undefined,
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      // dataZoom: isEllipsis
      //   ? [
      //       {
      //         type: "slider",
      //         realtime: true,
      //         start: 0,
      //         end: parseInt((15 / getSelectType()?.length) * 100),
      //         show: true,
      //         height: 10,
      //         bottom: 0,
      //         handleSize: 0, // 左右2个滑动条的大小
      //         borderColor: "#fff", // 滑动通道的边框颜色
      //         showDetail: false,
      //         fillerColor: "#eee",
      //         backgroundColor: "#fff", // 未选中的滑动条的颜色
      //         // showDataShadow: false, // 是否显示数据阴影 默认auto
      //       },
      //     ]
      //   : [
      //       {
      //         type: "inside",
      //       },
      //     ],
      yAxis: [
        {
          type: "value",
          // show:false,
          // name: 'Precipitation',
          // min: countMin,
          max: max <= 5 ? 5 : null,
          interval: max <= 5 ? 1 : null,
          // // axisLabel: {
          //   formatter: '{value} ml'
          // }
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            show: false,
          },
          axisLabel: {
            formatter: function (value, index) {
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else {
                return value;
              }
            },
          },
        },
      ],
      grid: {
        top: "50px",
        left: "0%",
        right: "0",
        bottom: isEllipsis ? "15px" : "0",
        containLabel: true,
      },
      series: series,
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (a) => {
      let filterOptions = {};
      if (getInitType() === "按公司分组") {
        filterOptions = {
          供应商: a?.name,
          年份: a?.seriesName,
        };
      } else {
        filterOptions = {
          年份: a?.name,
        };
      }
      TableModal({
        title: "中标详情",
        filterOptions,
        onOk: searchTableFunc,
        projectId: id,
      });
    });
  };

  const initData = (data) => {
    let yearArr = [
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date())?.format("YYYY"),
    ];
    let yearsObj = {};
    yearArr.forEach((year) => {
      yearsObj[year] = { count: 0, amount: 0 };
    });

    let bidIds = [];
    data.forEach((item) => {
      if (yearsObj[item.anc_date]) {
        yearsObj[item.anc_date].amount =
          yearsObj[item.anc_date].amount + item.bid_amount;
      } else {
        yearsObj[item.anc_date] = {
          amount: item.bid_amount,
        };
      }

      if (!bidIds.some((____) => ____ === item?.bid_id)) {
        yearsObj[item.anc_date].count =
          (yearsObj[item.anc_date].count || 0) + (item.bid_count || 0);
        bidIds.push(item?.bid_id);
      }
    });
    setYearsObj(yearsObj);
    initECharts(yearsObj);
  };

  const downloadImg = () => {
    // myChart.current.dispose()
    let src = myChart.current.getDataURL({
      pixelRatio: 2,
      backgroundColor: "#fff",
    });

    downloadBase64Img(src, "历年中标情况.png");
  };

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "历年中标情况",
        company_ids: JSON.stringify(
          checkAllCompanyList?.map((_) => {
            let item = getCompanyList()?.find((__) => __.bid_winner === _);
            return item.winner_id;
          })
        ),
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  useEffect(() => {
    if (notHasData) {
      return;
    }
    initData(data);
  }, [data]);

  useEffect(() => {
    setNoData(notHasData);
  }, [notHasData]);

  return (
    <div className="bidWinningSituationContent33">
      <div className="headerBox">
        <span className="headerBoxTitle">
          <span className="block"></span>
          历年中标情况
        </span>
        {!noData && !chartsLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="selectBox2">
              <div
                className={
                  initType === "按公司分组"
                    ? "selectItem2 activeSelectItem2"
                    : "selectItem2"
                }
                onClick={() => {
                  if (getInitType() === "按公司分组") {
                    return;
                  }
                  setInitType("按公司分组");
                  setTimeout(() => {
                    initECharts(yearsObj);
                  }, 50);
                }}
              >
                按公司分组
              </div>
              <div
                className={
                  initType === "按公司合并"
                    ? "selectItem2 activeSelectItem2"
                    : "selectItem2"
                }
                onClick={() => {
                  if (getInitType() === "按公司合并") {
                    return;
                  }
                  setInitType("按公司合并");
                  setTimeout(() => {
                    initECharts(yearsObj);
                  }, 50);
                }}
              >
                按公司合并
              </div>
            </div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <DownLoadIcon
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  ></DownLoadIcon>
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      {!noData && (
        <div className="selectBox">
          <div
            className={
              chartType === "金额"
                ? "selectItem activeSelectItem"
                : "selectItem"
            }
            onClick={() => {
              if (getChartType() === "金额") {
                return;
              }
              setChartType("金额");
              setTimeout(() => {
                initECharts(yearsObj);
              }, 50);
            }}
          >
            金额
          </div>

          <div
            className={
              chartType === "数量"
                ? "selectItem activeSelectItem"
                : "selectItem"
            }
            onClick={() => {
              if (getChartType() === "数量") {
                return;
              }
              setChartType("数量");
              setTimeout(() => {
                initECharts(yearsObj);
              }, 50);
            }}
          >
            项目数
          </div>
        </div>
      )}
      <div id="bidWinningSituationBox">{noData && <MyEmpty></MyEmpty>}</div>
      <div className="annotationDiv">
        注：根据公司列表排序展示选择的前10家公司
      </div>
    </div>
  );
};
export default BidWinningSituation;
